import { AdminLayout } from "layout/AdminLayout"
import { useGetAllMembers } from 'hooks/queries/community'
import { useParams } from "react-router-dom";
import { MemberBox } from "components/admin/members";
import { useState } from "react";
import { Icon } from "assets/icons";
import { AnimatedButton } from "components/globals/buttons";

export const AdminUsersPage = () => {

    const { communityId } = useParams();
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const { data: members, isLoading: isMembersLoading, isError: isMembersError } = useGetAllMembers({ communityId, refetchOnMount: false });

    // page = 0, limit = 20, search = '', orderBy = 'created_at', isWaiting = false

    return (
        <AdminLayout>
            <div className="bg-app h-full w-full overflow-hidden">
                <div className="h-full w-full bg-app flex flex-col gap-8 items-center p-intermediate overflow-auto">
                    <div className="w-full flex flex-col gap-3">
                        <p className="font-light text-sm opacity-80">Ma communauté &gt; Membres</p>
                        <h1 className="text-left w-full text-xl">Les membres de ma communauté</h1>
                        <div className="flex flex-col items-center justify-between border p-3 rounded-lg gap-3">
                            <div className="flex items-center justify-between w-full">
                                <p className="text-gray-400 font-light">Filtrer mes membres</p>
                                <AnimatedButton className="bg-gray-100 rounded-lg p-2" onClick={() => setIsFiltersOpen(!isFiltersOpen)}>
                                    {!isFiltersOpen ? <Icon type="ArrowSquareDown" size={20} /> : <Icon type="ArrowSquareUp" size={20} />}
                                </AnimatedButton>
                            </div>
                            {
                                isFiltersOpen && (
                                    <div className="w-full flex flex-col gap-4 items-center justify-start">
                                        <div className="w-full flex flex-col gap-2">
                                            <select className="bg-gray-100 rounded-lg p-2 w-full">
                                                <option value="all">Tous</option>
                                                <option value="active">Actifs</option>
                                                <option value="inactive">Inactifs</option>
                                            </select>
                                            <input type="text" placeholder="Rechercher un membre" className="w-full bg-gray-100 rounded-lg py-2 px-4" />
                                        </div>
                                        <AnimatedButton className="bg-gray-200 hover:bg-gray-300 rounded-lg py-2.5 w-full">Rechercher</AnimatedButton>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <hr className="w-full" />
                    <div className="w-full flex flex-col items-center justify-start gap-2">
                        {
                            isMembersError && (
                                <div className="w-full bg-red-200 px-5 py-4 rounded-lg flex flex-col items-start justify-start">
                                    <p className="text-red-500 text-lg">Aïe.. Une erreur est survenue</p>
                                    <span className="font-light">Notre équipe technique est sur le coup, vous pouvez rafraîchir la page et reessayer</span>
                                </div>
                            )
                        }
                        {
                            !isMembersError && !isMembersLoading && members?.map((member: any) => (
                                <MemberBox key={member.id} member={member} />
                            ))
                        }
                        {
                            !isMembersError && !isMembersLoading && members?.length === 0 && (
                                <div className="w-full bg-gray-100 px-5 py-4 rounded-lg flex flex-col items-start justify-start">
                                    <p className="text-gray-500 text-lg">Aucun membre trouvé</p>
                                    <span className="font-light">Aucun membre ne correspond à votre recherche</span>
                                </div>
                            )
                        }
                        {
                            isMembersLoading && Array.from({ length: 8 }).map((_, index) => (
                                <div className="w-full flex items-center justify-between border border-gray-100 p-3 rounded-lg" key={index}>
                                    <div className="flex gap-2 items-center justify-start">
                                        <div className="w-9 h-9 rounded-full bg-gray-100 animate-pulse" />
                                        <div className="bg-gray-100 animate-pulse w-20 h-4 rounded-md" />
                                    </div>
                                    <div className="flex items-center justify-end gap-2 font-light text-gray-400 text-sm">
                                        <div className="bg-gray-100 animate-pulse w-20 h-4 rounded-md" />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}