import { CommunityLayout } from "layout/CommunityLayout";
import { 
    LoginPage, 
    HomePage, 
    RegisterPage, 
    EmailValidationPage, 
    ProfileInformationPage, 
    CommunityFeedPage, 
    CommunityHomePage, 
    CreateCommunityPage, 
    AdminHomePage, 
    AdminThemePage, 
    AdminUsersPage, 
    AdminReportsPage, 
    AdminSettingsPage, 
    AdminQuickStartPage, 
    AdminLandingPage, 
    AdminChannelsPage, 
    CommunityPage, 
    ChannelsPage,
    SavedPage, 
    MessagesPages, 
    AdminJoinSettings, 
    AdminSettingsImages, 
    AdminEmployeesPage,
    ProfilePage,
    CommunityReferralPage, 
    CommunityLeaderboardPage,
    AdminSettingsGamificationPage,
    ThreadPage,
    ManageSubscriptions,
    AboutPage, 
    CategoriesPage,
    CategoryPage,
    StripeConfigPage,
    UpdateMyProfilePage,
    AdminCategoriesPage,
    AdminContentPage,
    AdminContentItem,
    ResourcesPage,
} from "../pages";
import { MessageConversationPage } from "pages/messageConversation";
import { AdminAboutPage } from "pages/admin/about";
import { useBreakpointContext } from "context/BreakpointContext";


export enum PublicRoutes {
    login = '/login',
    register = '/register',
}

export enum ProtectedRoutes {
    noWifi = '/no-wifi',
    home = '/',
    community = '/community',
    profile = '/user/:userId',
    saved = '/saved',
    messages = '/messages',
    conversationMessages = '/messages/:conversationId',
    updateProfile = '/update-profile',
    verifyEmail = '/onboarding/email',
    completeProfile = '/onboarding/profile',
    interests = '/onboarding/interests',
    createCommunity = '/create-community',
    manageSubscriptions = '/manage-subscriptions',
    categories = '/categories',
    category = '/categories/:slug',
}

export enum CommunityRoutes {
    communityLayout = '/:communityId/*',
    communityHome = '',
    communityFeed = 'feed',
    communityContents = 'contents',
    communitySaved = 'saved',
    communityChannels = 'channels',
    communityChannelId = ':channelId',
    communityReferral = 'referral',
    communityLeaderboard = 'leaderboard',
    communityThread = ':channelId/:postId',
    communityAbout = 'about',
    communityResource = 'resources',
}

export enum AdminRoutes {
    admin = '/admin/:communityId',
    adminUsers = '/admin/:communityId/users',
    adminReports = '/admin/:communityId/reports',
    adminSettings = '/admin/:communityId/settings',
    adminSettingsImages = '/admin/:communityId/settings/images',
    adminSettingsJoin = '/admin/:communityId/settings/join',
    adminTheme = '/admin/:communityId/theme',
    adminQuickStart = '/admin/:communityId/quick-start',
    adminLanding = '/admin/:communityId/landing',
    adminAbout = '/admin/:communityId/about',
    adminChannels = '/admin/:communityId/channels',
    adminEmployees = '/admin/:communityId/employees',
    adminGamification = '/admin/:communityId/gamification',
    adminStripeConfig = '/admin/:communityId/settings/stripe-config',
    adminCategories = '/admin/:communityId/categories',
    adminContent = '/admin/:communityId/content',
    adminContentItem = '/admin/:communityId/content/:contentId',
}

export const Routes = {
    ...PublicRoutes,
    ...ProtectedRoutes,
    ...CommunityRoutes,
    ...AdminRoutes,
} as const;

export const useAppRoutes = () => {

    const { isDesktop } = useBreakpointContext();

    return {
        config: [
            { path: Routes.login, element: <LoginPage /> },
            { path: Routes.register, element: <RegisterPage /> },
            { path: Routes.verifyEmail, element: <EmailValidationPage /> },
            { path: Routes.completeProfile, element: <ProfileInformationPage /> },

            { path: Routes.home, element: <HomePage /> },
            { path: Routes.community, element: <CommunityPage /> },
            { path: Routes.saved, element: <SavedPage/> },
            { path: Routes.messages, element: <MessagesPages /> },
            { path: Routes.conversationMessages, element: <MessageConversationPage /> },
            { path: Routes.createCommunity, element: <CreateCommunityPage /> },
            { path: Routes.profile, element: <ProfilePage />},
            { path: Routes.updateProfile, element: <UpdateMyProfilePage />},
            { path: Routes.manageSubscriptions, element: <ManageSubscriptions />},
            { path: Routes.categories, element: <CategoriesPage />},
            { path: Routes.category, element: <CategoryPage /> },

            {
                path: Routes.communityLayout,
                element: <CommunityLayout />,
                children: [
                    { path: Routes.communityHome, element: <CommunityHomePage /> },
                    { path: Routes.communityChannels, element: <ChannelsPage /> },
                    { 
                        path: Routes.communityChannelId, 
                        element: <CommunityFeedPage />,
                        children: isDesktop ? [
                            { path: ':postId', element: <CommunityFeedPage /> }
                        ] : []
                    },
                    { path: Routes.communityReferral, element: <CommunityReferralPage /> },
                    { path: Routes.communityLeaderboard, element: <CommunityLeaderboardPage /> },
                    ...(!isDesktop ? [
                        { path: Routes.communityThread, element: <ThreadPage />}
                    ] : []),
                    { path: Routes.communityAbout, element: <AboutPage /> },
                    { path: Routes.communityResource, element: <ResourcesPage /> }
                ]
            },

            { path: Routes.admin, element: <AdminHomePage />  },
            { path: Routes.adminUsers, element: <AdminUsersPage />  },
            { path: Routes.adminReports, element: <AdminReportsPage />  },
            { path: Routes.adminSettings, element: <AdminSettingsPage /> },
            { path: Routes.adminSettingsImages, element: <AdminSettingsImages /> },
            { path: Routes.adminSettingsJoin, element: <AdminJoinSettings /> },
            { path: Routes.adminTheme, element: <AdminThemePage />  },
            { path: Routes.adminQuickStart, element: <AdminQuickStartPage />},
            { path: Routes.adminLanding, element: <AdminLandingPage /> },
            { path: Routes.adminAbout, element: <AdminAboutPage /> },
            { path: Routes.adminChannels, element: <AdminChannelsPage /> },
            { path: Routes.adminEmployees, element: <AdminEmployeesPage />},
            { path: Routes.adminGamification, element: <AdminSettingsGamificationPage /> },
            { path: Routes.adminStripeConfig, element: <StripeConfigPage /> },
            { path: Routes.adminCategories, element: <AdminCategoriesPage /> },
            { path: Routes.adminContent, element: <AdminContentPage /> },
            { path: Routes.adminContentItem, element: <AdminContentItem /> }
        ],
    };
}