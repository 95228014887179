import { AdminLayout } from "layout/AdminLayout";
import { useBreakpointContext } from "context/BreakpointContext";
import { AdminBuilderTopbar } from "components/globals/topbar/AdminBuilderTopbar";
import { AboutCommunityPage } from "components/community/about/aboutPage";
import { useParams } from "react-router-dom";

export const AdminAboutPage = () => {

    const { communityId } = useParams<{ communityId: string }>();
    const { isDesktop } = useBreakpointContext();

    if(!communityId) return null;

    return (
        <AdminLayout>
            <div className="w-full flex flex-col flex-1">
                {isDesktop && <AdminBuilderTopbar />}
                <div className="h-full w-webkit bg-app flex gap-8 items-start p-intermediate overflow-auto">
                    <AboutCommunityPage isAdmin={true} communityId={communityId} />
                </div>
            </div>
        </AdminLayout>
    )
}