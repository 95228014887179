import React, { useState } from 'react';
import { CategorieItem } from 'components/communities/Categories/CategorieItem';
import { UserItem } from 'components/users/userItem';
import { CommunityCards, CommunityCardsSkeleton } from 'components/communities';
import { CommunityListObj } from '@hooks/queries/communities';
import { UserType } from 'interfaces/users';
import { CategoryItemType } from 'interfaces/categories';
import { UserItemSkeleton } from 'components/users/userItemSkeleton';
import { CategorieItemSkeleton } from 'components/communities/Categories/CategorieItemSkeleton';

type Community = CommunityListObj;
type User = UserType;
type Category = CategoryItemType;
type SearchData = { communities: Community[]; users: User[]; categories: Category[]; };

interface SearchResultsTabsProps {
    data: SearchData;
    previousData: SearchData;
    isLoading: boolean;
    isError: boolean;
    searchTerm: string;
}

const TabContent: React.FC<{ data: SearchData; activeTab: string; isLoading: boolean }> = ({ data, activeTab, isLoading }) => {
    switch (activeTab) {
        case 'communities':
            return (
                <div className='w-full flex flex-col items-start justify-start gap-3'>
                    {data.communities.map((community) => (
                        <CommunityCards key={community.id} community={community} isInline={true} isModale={true}/>
                    ))}
                    {isLoading && <CommunityCardsSkeleton isInline={true} />}
                </div>
            );
        case 'users':
            return (
                <div className='w-full flex flex-col items-start justify-start gap-3'>
                    {data.users.map((user) => (
                        <UserItem key={user.uuid} user={user} />
                    ))}
                    {isLoading && <UserItemSkeleton />}
                </div>
            );
        case 'categories':
            return (
                <ul>
                    {data.categories.map((category) => (
                        <CategorieItem key={category.id} category={category} />
                    ))}
                    {isLoading && <CategorieItemSkeleton />}
                </ul>
            );
        default:
            return null;
    }
};

const MessageDisplay: React.FC<{ icon: React.ReactNode; message: string }> = ({ icon, message }) => (
    <div className="flex flex-col items-center justify-center h-72">
        {icon}
        <p className="mt-4 text-gray-500 text-center md:max-w-72">{message}</p>
    </div>
);

const NoResults: React.FC = () => (
    <MessageDisplay
        icon={
            <svg className="w-16 h-16 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        }
        message="Aucun résultat"
    />
);

const ErrorMessage: React.FC = () => (
    <MessageDisplay
        icon={
            <svg className="w-16 h-16 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        }
        message="Une erreur est survenue. Veuillez réessayer."
    />
);

const InitialMessage: React.FC = () => (
    <MessageDisplay
        icon={
            <svg className="w-16 h-16 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
        }
        message="Entrez au moins 3 caractères pour lancer la recherche"
    />
);

export const SearchResultsTabs: React.FC<SearchResultsTabsProps> = ({ data, previousData, isLoading, isError, searchTerm }) => {
    const [activeTab, setActiveTab] = useState('communities');

    const tabs = [
        { key: 'communities', label: 'Communautés' },
        { key: 'users', label: 'Utilisateurs' },
        { key: 'categories', label: 'Catégories' },
    ];

    const currentData = data || previousData || { communities: [], users: [], categories: [] };

    return (
        <div className="w-full">
            <div className="w-full flex gap-0 items-center justify-center sticky top-0 bg-white z-20 mt-4">
                {tabs.map((tab) => (
                    <button
                        key={tab.key}
                        className={`w-full text-center py-2.5 cursor-pointer text-black z-40 ${activeTab === tab.key ? 'border-b-2 border-black' : ''}`}
                        onClick={() => setActiveTab(tab.key)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            <div className="mt-4">
                {isError ? (
                    <ErrorMessage />
                ) : searchTerm.trim().length <= 2 ? (
                    <InitialMessage />
                ) : currentData[activeTab as keyof SearchData].length > 0 || isLoading ? (
                    <TabContent data={currentData} activeTab={activeTab} isLoading={isLoading} />
                ) : (
                    <NoResults />
                )}
            </div>
        </div>
    );
};