import { useMutator } from "@hooks/queries/api"
import { loginSelector, useAuthStore } from "stores/auth";
import { useState } from "react"
import { useNavigate } from "react-router-dom";
import { ProtectedRoutes } from "navigation/useAppRoutes";
import { InputWithIcon } from "components/globals/inputs";
import { AnimatedButton } from "components/globals/buttons";
import { motion } from "framer-motion";

export type LoginPassBody = {
    email: string;
    pass: string;
}

export type profileType = {
    email: string;
    user_name: string;
    id: string;
    isEmailVerified: boolean;
    isProfileComplete: boolean;
    interests?: string[];
}

export type LoginFormProps = {
    context?: string,
    displayIn?: string,
    callbackFunction?: ({ }: any) => void
}

export const LoginForm = ({ context, displayIn, callbackFunction }: LoginFormProps) => {

    context = context || "signinAndJoinCommunity"
    displayIn = displayIn || "page"

    const { mutateAsync: enterPassMutation, isError, error, isPending } = useMutator<LoginPassBody>("/auth/login")
    const login = useAuthStore(loginSelector);
    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const checkProfileAndNavigate = (profile: profileType) => {
        if (!profile.isEmailVerified) return ProtectedRoutes.verifyEmail
        if (!profile.isProfileComplete) return ProtectedRoutes.completeProfile
        return ProtectedRoutes.home
        //if(profile.interests.length === 0) return ProtectedRoutes.interests
    }

    const handleLogin = async (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        const body = { email, pass: password }
        const tryLogin = await enterPassMutation(body)
        await login(email, tryLogin).catch(() => { console.log('Error saving tokens') });
        const nextRoute = checkProfileAndNavigate(tryLogin);
        if (displayIn === "page") navigate(nextRoute);
        if (displayIn === "modale") {
            callbackFunction && callbackFunction(tryLogin)
        }
    }

    return (
        <motion.div
            key="logo-animation"
            className="w-full h-full"
        >
            <form onSubmit={handleLogin} className="w-full flex flex-col justify-center items-center gap-3">
                {/* @ts-ignore */}
                {isError && <div className="w-full bg-red-200 text-red-800 p-3 rounded-md">{error.response.data.error}</div>}
                <InputWithIcon icon="Sms" placeholder="Votre email" value={email} onChange={setEmail} type="email" />
                <InputWithIcon icon="KeySquare" placeholder="Mot de passe" value={password} onChange={setPassword} type="password" withView={true} />
                <AnimatedButton type="submit" className="w-full bg-black text-white py-mini rounded-tiny">{isPending ? "Loading" : "Me connecter"}</AnimatedButton>
            </form>
        </motion.div>
    )
}