import { useMyAdminCommunities } from "hooks/queries/communities";
import { CommunitiesList } from "./communitiesList";

export type MyCommunitiesListProps = {
    isSlider?: boolean;
    isHomePage?: boolean;
    onDisableDrag?: (disabled: boolean) => void;
};

export const MyCommunitiesList = ({ isSlider, onDisableDrag }: MyCommunitiesListProps) => {

    isSlider = isSlider || false;

    const { data: adminCommunities = { page: 0, list: [] }, isLoading: isAdminCommunityLoading, isError: isAdminCommunityError, error } = useMyAdminCommunities({ refetchOnMount: false });

    const handleWheel = (boolean: boolean) => {
        onDisableDrag?.(boolean);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <div className="w-full flex flex-col gap-4">
                <div className="w-full flex flex-col gap-0">
                    <h2 className="lg:text-xl text-lg">Mes communautés</h2>
                </div>
                <CommunitiesList
                    communities={adminCommunities}
                    isError={isAdminCommunityError}
                    isLoading={isAdminCommunityLoading}
                    onWheel={handleWheel}
                    error={error}
                />
            </div>
        </div>
    );
};
