import { useCommunitiesByCategories, useGetCategoryBySlug } from "@hooks/queries/categories"
import { CommunityCards } from "components/communities"
import { useParams } from "react-router-dom"

export const CategoryPage = () => {

    const { slug } = useParams<{ slug: string }>()

    const { data: categoryData, isError: isCategoryDataError, isLoading: isCategoryDataLoading } = useGetCategoryBySlug({ slug, refetchOnMount: false })
    const { data: communities, isError: isCommunitiesError, isLoading: isCommunitiesLoading } = useCommunitiesByCategories({ slug, refetchOnMount: false })

    return (
        <div className="bg-app h-full w-full overflow-hidden relative flex items-start justify-center pt-intermediate px-intermediate">
            <div className="w-full max-w-[1080px] flex flex-col gap-8">
                {
                    isCategoryDataLoading || isCategoryDataError ? (
                        <div className="flex flex-col gap-4">
                            Loading...
                        </div>
                    ) : (
                        <div className="w-full flex flex-col gap-8 items-center overflow-auto relative z-20">
                            <div className="w-full h-28 bg-cover overflow-hidden p-mini relative rounded-md z-0" style={{ backgroundColor: categoryData.assets.primaryColor }}>
                                <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-black/0 z-2" />
                                <div className="h-20 w-20 rounded-tiny shadow-xl absolute bottom-[-20px] right-[-20px] rotate-6 z-2 bg-center bg-cover" style={{ backgroundImage: `url(https://pub-388e882330ee44c7b74215d8e0935cb9.r2.dev/community%2F${categoryData.id}%2Fbg})` }} />
                                <p className="text-white font-bold absolute top-4 left-4 z-20">{categoryData.name}</p>
                            </div>
                        </div>
                    )
                }
                <div className="w-full">
                    {
                        isCommunitiesLoading || isCommunitiesError ? (
                            <div className="flex flex-col gap-4">
                                Loading...
                            </div>
                        ) : (
                            <div className="w-full flex items-start justify-start gap-3" >
                                {communities.list.map((community: any, index: number) => (
                                    <CommunityCards key={index} community={community} />
                                ))}
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}