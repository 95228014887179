import { useAllChannels } from "@hooks/queries/channels";
import { useThemeCommunity } from "stores/themeCommunity";
import { Icon } from "assets/icons"
import { useSidebarMenu } from "navigation/useSidebarMenu";
import { Link, useNavigate } from "react-router-dom";
import { useModaleStore } from "stores/modale";
import { useMessageStore } from "stores/feed/channelMessage";
import { useCallback } from "react";
import { shallow } from 'zustand/shallow'

export type SidebarProps = {
    communityId?: number;
}

interface ChannelWithoutMessages {
    id: number;
    community_id: number;
    name: string;
    channel_type: number;
    new_posts_count: string;
    can_users_publish: boolean;
    description: string;
    members?: number;
    created_at?: string;
    created_by?: string;
    is_displayed?: boolean;
  }

export const ChannelSidebar = ({ communityId }: SidebarProps) => {

    const navigationMenuPages = useSidebarMenu();
    const { theme } = useThemeCommunity();
    const { setModale } = useModaleStore();
    const navigate = useNavigate();
    const allChannels = useMessageStore(
        useCallback(
          (state) => 
            Object.values(state.channels)
              .filter(channel => channel.community_id === communityId)
              .map(({ messages, ...channelWithoutMessages }: any) => channelWithoutMessages as ChannelWithoutMessages),
          [communityId]
        ),
        shallow
      );

    if (!communityId) return null;

    const { isLoading, /* isError, error */ } = useAllChannels({ communityId, refetchOnMount: false, withNotifications: true });
    const skeletonArray = Array.from({ length: 8 }, (_, i) => i);


    const handleOpenTab = ({ path, isComingSoon }: { path: string, isComingSoon: boolean }) => {
        if (isComingSoon) {
            setModale(true, 'ComingSoonModale', 'modale');
        } else {
            // @ts-ignore
            navigate(path.replace(':communityId', communityId));
        }
    }

    return (
        <div className="h-full flex flex-col gap-3 px-tiny pb-tiny w-[250px] lg:w-[350px] shadow-[inset_-.125rem_0_.0625rem_rgba(0,0,0,.05),inset_0_0_.0625rem_rgba(66,71,76,.45)]">
            <div className="w-full flex flex-col gap-6 pt-6">
                <div className="w-full flex flex-col gap-1">
                    {navigationMenuPages.map(({ key, path, icon, label, isComingSoon, isDisplayOnDesktopSidebar }) => {

                        if (!isDisplayOnDesktopSidebar) return null;

                        return (
                            <div key={key} onClick={() => handleOpenTab({ path, isComingSoon })} className="cursor-pointer w-full flex gap-2 items-center justify-start hover:bg-grey-200 p-tiny rounded-tiny">
                                <Icon type={icon} color={isComingSoon ? "#c8c8c8" : "#000"} size={16} />
                                <p className={`${isComingSoon ? "text-gray-500" : ""} text-sm font-light`}>{label}</p>
                                {
                                    isComingSoon && <div className="rounded-full" style={{ backgroundColor: theme.quaternaryColor }}>
                                        <p className="text-[9px] text-white px-3 py-[2px]">soon</p>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>

                <hr />

                <div className="w-full flex flex-col gap-1">
                    {
                        isLoading && skeletonArray.map((_, index) => (
                            <div className="w-full flex gap-2 items-center justify-start bg-grey-100 p-tiny rounded-tiny cursor-pointer h-[36px]" key={index} />
                        ))
                    }

                    {
                        allChannels.map((channel, index) => (
                            <Link to={`/${communityId}/${channel.id}`} key={index}>
                                <div className="w-full flex gap-2 items-center justify-between hover:bg-grey-200 p-tiny rounded-tiny cursor-pointer">
                                    <div className="w-max flex gap-2 items-center justify-start hover:bg-grey-200 rounded-tiny cursor-pointer">
                                        <Icon type={channel.can_users_publish ? "Hashtag" : "Lock1"} color="#000" size={16} />
                                        <p className="text-sm font-medium">{channel.name}</p>
                                    </div>
                                    { channel.new_posts_count && parseInt(channel.new_posts_count) > 0 && <div className=" h-min rounded-full flex items-center justify-center py-0.5 px-3 gap-2 bg-red-600">
                                        <span className="text-xs text-white">{channel.new_posts_count}</span>
                                    </div> }
                                </div>
                            </Link>
                        ))
                    }
                </div>
            </div>
            <div>

            </div>
        </div>
    );
}