
import { useMutator } from "@hooks/queries/api";
import { Icon } from "assets/icons";
import { AnimatedButton, SwitchButton } from "components/globals/buttons";
import { useState } from "react";
import { useModaleStore } from "stores/modale";

export const CreateRewardsModale = () => {

    const { modaleProps, setModale } = useModaleStore()
    const [rewardName, setRewardName] = useState<string>(modaleProps?.type === "update" ? modaleProps?.reward.name : "")
    const [rewardReferral, setRewardReferral] = useState<number>(modaleProps?.type === "update" ? modaleProps?.reward.need_for_unlock.referral : 1)
    const [modalError, setModalError] = useState<string>("")
    const [isActive, setIsActive] = useState<boolean>(modaleProps?.type === "update" ? modaleProps?.reward.is_active : true)

    const { mutateAsync: createNewChannel, isError:isCreateError, isPending:isCreateLoading } = useMutator<any>(`/community/${modaleProps?.communityId}/admin/gamification/reward/new`)
    const { mutateAsync: updateChannel, isError:isUpdateError, isPending:isUpdateLoading } = useMutator<any>(`/community/${modaleProps?.communityId}/admin/gamification/reward/${modaleProps?.reward?.id}/update`)

    const handleSubmit = async () => {
        setModalError("")
        if(modaleProps?.type === "create") {
            if(rewardName.length === 0){
                setModalError("Le nom de la récompense est obligatoire")
            } else {
                const newReward = {
                    description: null,
                    name: rewardName,
                    is_active: isActive,
                    community_id: modaleProps?.communityId,
                    need_for_unlock: { referral: rewardReferral }
                }
                await createNewChannel(newReward)
                await modaleProps?.refetchRewards()
                setModale(false, "", "", modaleProps)
            }
        } else if(modaleProps?.type === "update") {
            if(rewardName.length === 0){
                setModalError("Le nom de la récompense est obligatoire")
            } else {
                const updatedReward = {
                    id: modaleProps?.reward.id,
                    description: null,
                    name: rewardName,
                    is_active: isActive,
                    community_id: modaleProps?.communityId,
                    need_for_unlock: { referral: rewardReferral }
                }
                await updateChannel(updatedReward)
                await modaleProps?.refetchRewards()
                setModale(false, "", "", modaleProps)
            }
        }
    }

    const handleAddReferralCount = () => {
        setRewardReferral(rewardReferral + 1)
    }

    const handleMinusReferralCount = () => {
        if (rewardReferral > 1) {
            setRewardReferral(rewardReferral - 1)
        }
    }

        return (
            <div className="modal-container md:px-4 md:py-6 flex flex-col items-start space-y-4 md:w-[500px] cursor-auto">
                <h1>{modaleProps?.type === "create" ? ("Créer une récompense") : ("Modifier une récompense")}</h1>
                <div className="w-full flex flex-col items-start gap-8">
                    <div className="bg-gray-100 rounded-mini py-5 flex flex-col items-center w-full justify-center gap-5">
                        <p>Nombre de parrainages nécéssaires :</p>
                        <div className="w-full flex items-center justify-center gap-0">
                            <Icon type="Minus" size={23} color="black" onClick={handleMinusReferralCount} />
                            <p className="text-5xl w-4/12 text-center">{rewardReferral}</p>
                            <Icon type="Add" size={23} color="black" onClick={handleAddReferralCount} />
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                        <label>Nom de la récompense</label>
                        <input onChange={(e) => setRewardName(e.target.value)} type="text" placeholder="ex : Coaching" defaultValue={modaleProps?.type === "update" ? rewardName : undefined} className="bg-transparent w-full focus:outline-none border h-10 rounded-lg px-3" />
                    </div>
                    <div className="flex items-center justify-between w-full">
                            <span className="text-base">Activer dès maintenant</span>
                            <SwitchButton
                                isOn={isActive}
                                handleToggle={(checked) => setIsActive(checked)}
                            />
                        </div>
                    <AnimatedButton onClick={handleSubmit} className="bg-gray-100 hover:bg-grey-200 rounded-md flex items-center justify-center cursor-pointer w-full py-2.5">
                        {
                            isCreateLoading || isUpdateLoading ? (
                                <span className="text-gray-600 italic font-[300]">Chargement</span>
                            ) : (
                                <span className="">{modaleProps?.type === "create" ? "Créer la récompense" : "Modifier la récompense"}</span>
                            )
                        }
                    </AnimatedButton>
                    {/* @ts-ignore */}
                    {isCreateError || isUpdateError && <div className="w-full bg-red-200 text-red-800 p-3 rounded-md">{ isError.response.data.error }</div>}
                    {modalError.length > 0 && <div className="w-full bg-red-200 text-red-800 p-3 rounded-md">{ modalError }</div>}
                </div>
            </div>
        )
    }