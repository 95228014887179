import { useEffect, useRef } from 'react';
import { useWebSocketStore } from './websocketStore';
import { useAuthStore } from 'stores/auth';

export const useGlobalWebSocket = () => {
  const { connect, disconnect, service } = useWebSocketStore();
  const token = useAuthStore(state => state.tokens.accessToken) ?? '';
  const uuid = useAuthStore(state => state.uuid);
  const isLogged = useAuthStore(state => state.isLogged);
  
  const prevIsLoggedRef = useRef(isLogged);

  useEffect(() => {
    console.log('useGlobalWebSocket', isLogged, !!token, !!uuid, !!service);

    if (isLogged && token && uuid) {
      if (!service) {
        console.log('Connecting WebSocket');
        connect(token, uuid);
      }
    } else {
      if (prevIsLoggedRef.current && !isLogged) {
        console.log('User logged out, disconnecting WebSocket');
        disconnect();
      }
    }

    prevIsLoggedRef.current = isLogged;

    return () => {
      if (!isLogged && service) {
        console.log('Component unmounting, disconnecting WebSocket');
        disconnect();
      }
    };
  }, [isLogged, token, uuid, connect, disconnect, service]);
};