import { useEffect, useRef } from 'react';
import { useCommunities, useMyAdminCommunities, useMyCommunities } from "@hooks/queries/communities";
import { useTopbarMobileData } from "stores/topbarMobileData";
import { DiscoverPage } from "components/home/discoverPage";
import { HomeWithCommunity } from "components/home/homeWithCommunity";
//import { PullToRefresh } from 'layout/PullToRefreshLayout';

export const HomePage = () => {
  const { setTopbarMobileData } = useTopbarMobileData();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTopbarMobileData({ pageName: 'Accueil', showTopbar: true, communityName: 'Communautés', withLogo: true });
  }, []);

  useCommunities({ refetchOnMount: false });
  const { data: myCommunities, isError: isMyCommunitiesError, error: myCommunitiesError, isLoading: isMyCommunitiesLoading } = useMyCommunities({ refetchOnMount: false });
  const { data: myAdminCommunities, isError: isAdminMyCommunitiesError, error: myAdminCommunitiesError, isLoading: isMyAdminCommunitiesLoading } = useMyAdminCommunities({ refetchOnMount: false });

  // const handleRefresh = () => {
  //   window.location.reload();
  // };

  return (
      <div ref={scrollContainerRef} className="w-full h-screen overflow-auto flex flex-col gap-3 items-center px-intermediate py-intermediate">
        <div className="w-full lg:w-[1080px] flex flex-col items-center justify-center gap-6">
          <>
            {
              (isMyCommunitiesError || isAdminMyCommunitiesError) && (
                <>
                  {isMyCommunitiesError && <p className="text-red-400">{(myCommunitiesError as any).error}</p>}
                  {isAdminMyCommunitiesError && <p className="text-red-400">{(myAdminCommunitiesError as any).error}</p>}
                </>
              )
            }
            {
              !isMyAdminCommunitiesLoading && !isMyCommunitiesLoading && !isMyCommunitiesError && !isAdminMyCommunitiesError && (
                <>
                  {
                    myAdminCommunities?.list.length !== 0 || myCommunities?.list.length !== 0 ? (
                      <HomeWithCommunity scrollContainerRef={scrollContainerRef} />
                    ) : (
                      <DiscoverPage />
                    )
                  }
                </>
              )
            }
          </>
        </div>
      </div>
  );
};