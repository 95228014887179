import { useMyAdminCommunities } from "@hooks/queries/communities";
import { useModaleStore } from "stores/modale";
import { AnimatedButton } from "components/globals/buttons";
import { useNavigate } from "react-router-dom";
import { Config } from "services/config";

export const ProfileDrawerCommunityBlock = () => {

    const { data: communities = { page: 0, list: [] }, isLoading } = useMyAdminCommunities({ refetchOnMount: false });


    const { setModale } = useModaleStore();
    const navigate = useNavigate()

    const handleGoToAdmin = (communityId: string) => {
        setModale(false, '', '')
        navigate(`/admin/${communityId}`)
    }

    return (
        <>
            {
                isLoading ? (
                    <div className="w-full flex flex-col gap-3 overflow-hidden h-[40%]">
                        <p className="text-grey-700 text-lg">Mes communautés</p>
                        <div className="w-full lg:grid lg:grid-cols-2 flex flex-col items-center gap-2 overflow-auto hideScrollBar">
                            {[1, 2].map((_, index) => (
                                <div className="w-full flex items-center gap-3 bg-grey-100 rounded-mini p-2 relative animate-pulse" key={index}>
                                    <div className="h-14 w-20 bg-grey-300 rounded-tiny" />
                                    <div className="">

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="w-full flex flex-col gap-3 overflow-hidden h-[55%]">
                        <p className="text-grey-700 text-lg">Mes communautés</p>
                        {
                            communities.list.length > 0 && (
                                <div className="w-full lg:grid lg:grid-cols-2 flex flex-col items-center gap-2 overflow-auto hideScrollBar">
                                    
                                    {communities.list.map((community, index) => {
                                        
                                        const communityBackground = Config.CDN_HOST + "community%2F" + community.id + "%2Fbg";

                                        return (
                                            <AnimatedButton className="cursor-pointer w-full flex items-center gap-3 bg-grey-100 rounded-mini p-2 relative" key={index} onClick={() => handleGoToAdmin(community.id || '')}>
                                                <div className="h-14 w-20 rounded-tiny" style={{ background: `url(${communityBackground}) center center / cover` }} />
                                                <div className="flex flex-col gap-0 items-start">
                                                    <div className="flex gap-0 items-center">
                                                        <h2 className="font-bold">{community.name}</h2>
                                                        <span className="italic">Club</span>
                                                    </div>
                                                    <p className="text-grey-400 font-light">1200 membres</p>
                                                </div>
                                            </AnimatedButton>
                                        )
                                    })}

                                </div>
                            )
                        }
                    </div>
                )
            }
        </>
    )
}