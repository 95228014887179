import { ConversationSidebar } from "components/conversation/Sidebar"
import { ReactNode } from "react"
import { useBreakpointContext } from "context/BreakpointContext";
import { useAuthStore } from "stores/auth";
import { useModaleStore } from "stores/modale";
import { AnimatedButton } from "components/globals/buttons";

export const MessageLayout = ({ children }: { children: ReactNode }) => {

    const { isMobile } = useBreakpointContext();
    const { isLogged } = useAuthStore();
    const { setModale } = useModaleStore();

    const handleLogin = () => {
        setModale(true, 'AuthModale', 'modale', {});
    }

    if (!isLogged) {
        return (
            <div className="w-full flex flex-row flex-1 h-full">
                <div className="w-11/12 mx-auto md:w-full h-full flex flex-col items-center justify-center gap-4">
                    <p className="text-black text-lg md:text-xl text-center">Connectez-vous pour voir vos messages</p>
                    <AnimatedButton onClick={handleLogin} className="bg-black py-tiny px-intermediate rounded-tiny text-white flex items-center justify-center gap-2">
                        <span>Me connecter</span>
                    </AnimatedButton>
                </div>
            </div>
        )
    }

    return (
        <div className="w-full flex flex-row flex-1 h-full">
            {!isMobile && <ConversationSidebar />}
            <div className="w-full flex flex-col h-full">
                {children}
            </div>
        </div>
    )
}