import { LogoTopBar } from "assets/logo"
import { Link } from "react-router-dom"
import { ActualCommunityTopBar } from "./actualCommunity"
import { NotificationTopbar } from "./notification"
import { TopBarUserPic } from "./userPic"
import { MainMenuDesktop } from "./mainMenuDesktop"
import { useMatchRoutes } from "hooks/useMatchRoutes"
import { ProtectedRoutes } from "navigation/useAppRoutes"
import { SearchBox } from "./searchBox"

export const DesktopTopBar = ({ uuid } : { uuid : string }) => {

    const shouldDisplayBottomBar = useMatchRoutes([
        ProtectedRoutes.home,
        ProtectedRoutes.community,
        ProtectedRoutes.saved,
        ProtectedRoutes.profile,
        ProtectedRoutes.messages,
        ProtectedRoutes.conversationMessages,
        ProtectedRoutes.manageSubscriptions,
    ])

    return (
        <div className="text-black bg-white flex justify-between items-center p-mini relative z-50 shadow-topbar">
            <div className="flex items-center justify-start gap-4 w-3/12">
                <Link to="/" className="flex items-center justify-start gap-4">
                    <LogoTopBar />
                </Link>
                <ActualCommunityTopBar />
            </div>
            { shouldDisplayBottomBar && <MainMenuDesktop /> }
            <div className="flex items-center justify-end gap-4 w-3/12">
                <SearchBox />
                <NotificationTopbar />
                <TopBarUserPic uuid={uuid} />
            </div>
        </div>
    )
}