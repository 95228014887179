import { useMutator } from "@hooks/queries/api";
import { useAllAdminChannels } from "@hooks/queries/channels";
import { AnimatedButton } from "components/globals/buttons";
import { useEffect } from "react";
import { useModaleStore } from "stores/modale";

export const DeleteChannelFromAdmin = () => {

    const { modaleProps, setModale } = useModaleStore();
    const communityIdProps = modaleProps?.communityId;

    const { refetch } = useAllAdminChannels({
        communityId: communityIdProps ? communityIdProps : undefined,
        refetchOnMount: false,
        enabled: !!communityIdProps
    });

    const { mutateAsync: deleteChannel, isError, isPending, isSuccess } = useMutator<any>("/community/" + modaleProps?.communityId + "/admin/channel/" + modaleProps?.channel?.id + "/delete");

    const handleClose = () => {
        setModale(false, "", '', {});
    }

    const handleDeleteChannel = async () => {
        try {
            await deleteChannel({});
        } catch (error) {
            console.error("Error deleting channel:", error);
        }
    }

    useEffect(() => {
        if (isSuccess) {
            refetch().then(() => {
                setModale(false, "", '', {});
            });
        }
    }, [isSuccess, refetch, setModale]);

    return (
        <div className="modal-container px-4 py-6 flex flex-col items-center space-y-8 md:w-[500px] cursor-auto">
            <div className="flex flex-col items-start justify-start w-full gap-5 font-light">
                <div className="flex flex-col items-start justify-start w-full gap-5 font-light">
                    <h2 className="text-red-600 text-xl">Supprimer le channel <span className="font-bold">"{modaleProps?.channel?.name}"</span></h2>
                    <hr className="w-full" />
                    <p>Êtes-vous sûr de vouloir supprimer ce channel ? Cette action est irréversible</p>
                    <div className="flex w-full items-center justify-start gap-3">
                        <AnimatedButton onClick={handleClose} className="bg-grey-100 hover:bg-grey-200 rounded-md py-1.5 px-3 flex items-center justify-center cursor-pointer">Annuler</AnimatedButton>
                        <AnimatedButton onClick={handleDeleteChannel} className="bg-red-300 hover:bg-red-400 rounded-md py-1.5 px-3 flex items-center justify-center cursor-pointer">Confirmer</AnimatedButton>
                    </div>
                    {isError && <p className='text-red-500'>Une erreur est survenue, si c'est le dernier channel, il n'est pas possible de le supprimer.</p>}
                    {isPending && <p>En cours...</p>}
                    {isSuccess && <p>Channel correctement supprimé</p>}
                </div>
            </div>
        </div>
    )
}