import { Icon } from "assets/icons"
import { useState } from 'react';


export type InputWithIconType = {
    icon: string;
    placeholder: string;
    value: string;
    onChange: (value: string) => void;
    type: string;
    withView?: boolean;
}

export const InputWithIcon = ({ icon, placeholder, value, onChange, type, withView = false }: InputWithIconType) => {

    const [inputType, setInputType] = useState(type);

    const toggleView = () => {
        setInputType(inputType === 'password' ? 'text' : 'password');
    };


    return (
        <div className="flex bg-lightGray items-center w-full justify-start gap-3 py-mini rounded-micro px-small">
            {
                icon === "search" ? (
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12.1094" cy="12.1094" r="6.51042" stroke="#8E8E93" strokeWidth="1.82292" />
                        <path d="M20.3125 20.3125L17.1875 17.1875" stroke="#8E8E93" strokeWidth="1.82292" strokeLinecap="round" />
                    </svg>

                ) : (
                    <Icon type={icon} size={24} />
                )
            }
            <input type={inputType} id={placeholder} value={value} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} className="bg-transparent w-full focus:outline-none font-[300]" />
            {withView && <div className="cursor-pointer" onClick={toggleView}><Icon type="EyeSlash" size={18} /></div>}
        </div>
    )
}