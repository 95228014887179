import { CommunitiesList } from "components/communities"
import { CategoriesList } from "components/communities/Categories";
import { AnimatedButton } from "components/globals/buttons";
import { FeaturedCommunityBox } from "components/communities/FeaturedCommunity";
import { TopsCreators } from "components/communities/TopCreators";
import { useCommunities, useFeaturedCommunitiesAndCreators } from "@hooks/queries/communities";
import { useNavigate } from "react-router-dom";

export const DiscoverPage = ({ onDisableDrag }: { onDisableDrag?: (disabled: boolean) => void }) => {

    const { data: communities = { page: 0, list: [] }, isLoading, isError, error } = useCommunities({ refetchOnMount: false });
    useFeaturedCommunitiesAndCreators({ refetchOnMount: false });

    const navigate = useNavigate();

    const handleGoToCategories = () => {
        navigate('/categories')
    }

    return (
        <div className="w-full flex flex-col gap-6">
            <div className="w-full flex flex-col gap-4">
                <div className="w-full flex flex-col gap-0">
                    <h2 className="lg:text-xl text-lg">Rejoindre ma première communauté</h2>
                    <p className="text-sm font-[300] text-textGray">Communautés les plus populaires</p>
                </div>
                <CommunitiesList communities={communities} isLoading={isLoading} isError={isError} onWheel={onDisableDrag} error={error}/>
            </div>
            <div className="w-full flex flex-col gap-4 lg:gap-6">
                <div className="w-full flex justify-between items-center">
                    <div className="w-max flex flex-col gap-0">
                        <h2 className="lg:text-xl text-lg">Top Catégories</h2>
                        <p className="text-sm font-[300] text-textGray">Catégories les plus populaires</p>
                    </div>
                    <AnimatedButton className="text-textGray text-sm font-light" onClick={handleGoToCategories}>Voir tout</AnimatedButton>
                </div>
                <CategoriesList />
            </div>
            <div className="w-full flex flex-col gap-4 lg:gap-6">
                <div className="w-max flex flex-col gap-0">
                    <h2 className="lg:text-xl text-lg">Communauté du moment</h2>
                </div>
                <FeaturedCommunityBox />
            </div>
            <div className="w-full flex flex-col gap-4 lg:gap-6">
                <div className="w-max flex flex-col gap-0">
                    <h2 className="lg:text-xl text-lg">Les créateurs du moment</h2>
                </div>
                <TopsCreators />
            </div>
        </div>

    )
}