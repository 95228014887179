import { useMemo } from "react";
import { Routes } from "./useAppRoutes";

export const useBottomBarMenu = () => {
    return useMemo(
        () =>
            [
                {
                    key: "home",
                    label: "Accueil",
                    path: Routes.home,
                    icon: "Home",
                },
                {
                    key: "communities",
                    label: "Communautés",
                    path: Routes.community,
                    icon: "UserOctagon",
                },
                {
                    key: "activity",
                    label: "Activité",
                    path: Routes.saved,
                    icon: "Medal",
                },
                {
                    key: "message",
                    label: "Messages",
                    path: Routes.messages,
                    icon: "Message",
                },
                // {
                //     key: "profil",
                //     label: "Profil",
                //     path: Routes.home,
                //     icon: "ProfilePic",
                // }
                
            ] as any[],
        [],
    );
}