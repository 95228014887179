import { useEmojis } from 'stores/emojis'; // Assure-toi que le chemin d'importation est correct

export const useFetchEmojis = () => {
    const { emojis, setEmojis, setError } = useEmojis(state => ({
        emojis: state.emojis,
        setEmojis: state.setEmojis,
        setError: state.setError
    }));

    const fetchEmojis = async (forceFetch = false) => {

        if (!forceFetch && Object.keys(emojis).length > 0) {
            return; // S'il y a déjà des emojis et qu'on ne force pas la requête, on ne fait rien
        }

        try {
            const response = await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setEmojis(jsonData);
            setError(false);
        } catch (error) {
            console.error("Failed to fetch emojis: ", error);
            setError(true);
        }
    };

    return { fetchEmojis };
};
