import { useCallback } from 'react';
import { useWebSocketStore } from './websocketStore';

export const useSocket = () => {
  const service = useWebSocketStore(state => state.service);
  const sendMessage = useWebSocketStore(state => state.sendMessage);

  const subscribeToCommunity = useCallback((communityId: string) => {
    if (service) {
      service.subscribeToCommunity({ communityId });
    } else {
      console.error('WebSocket service not initialized');
    }
  }, [service]);

  const unsubscribeFromCommunity = useCallback((communityId: string) => {
    if (service) {
      service.unsubscribeFromCommunity({ communityId });
    } else {
      console.error('WebSocket service not initialized');
    }
  }, [service]);

  return { 
    subscribeToCommunity, 
    unsubscribeFromCommunity,
    sendMessage,
    isConnected: !!service && service.isConnected()
  };
};