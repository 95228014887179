import { useState, useCallback } from "react";
import { useAllAdminChannels } from "hooks/queries/channels";
import { ChannelAdminItems } from "./channelItem";

export type AdminChannelsListProps = {
    communityId: number;
}

export const AdminChannelsList = ({ communityId }: AdminChannelsListProps) => {
    const [openChannelId, setOpenChannelId] = useState<number | null>(null);

    const { 
        data: allChannels = [], 
        isLoading, 
        isError, 
        error, 
        refetch 
    } = useAllAdminChannels({ communityId, refetchOnMount: false });

    const handleRefetch = useCallback(async () => {
        await refetch();
    }, [refetch]);

    const handleToggleChannel = useCallback((channelId: number) => {
        setOpenChannelId(prevId => prevId === channelId ? null : channelId);
    }, []);

    const renderSkeleton = () => (
        <div className="w-full flex flex-col gap-2">
            {Array.from({ length: 4 }).map((_, index) => (
                <div className="grid grid-cols-12 gap-3 h-12 items-center bg-gray-100 px-5 py-3" key={index}>
                    <div className="col-span-5 flex items-center gap-2">
                        <div className="w-4 h-4 rounded bg-gray-200 animate-pulse" />
                        <div className="w-12 h-5 rounded bg-gray-200 animate-pulse" />
                    </div>
                    <div className="w-5 h-5 rounded bg-gray-200 animate-pulse" />
                    <div className="col-span-4">
                        <div className="w-12 h-5 rounded bg-gray-200 animate-pulse" />
                    </div>
                    <div className="w-4 h-4 rounded bg-gray-200 animate-pulse" />
                </div>
            ))}
        </div>
    );

    if (isLoading) {
        return renderSkeleton();
    }

    if (isError) {
        return <div>{error ? (error as Error).message : "Une erreur est survenue"}</div>;
    }

    if (allChannels.length === 0) {
        return <div className="text-center">Aucun channel disponible</div>;
    }

    return (
        <div className="w-full flex flex-col gap-2">
            {allChannels.map((channel) => (
                <ChannelAdminItems 
                    key={channel.id} 
                    channel={channel} 
                    refetch={handleRefetch}
                    isOpen={channel.id === openChannelId}
                    onToggle={() => handleToggleChannel(channel.id as number)}
                />
            ))}
        </div>
    );
}