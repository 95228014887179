import { FormStep } from 'components/modales/allModales';
import { useFetcher, usePublicFetcher } from './api'
import { PostFeed, type CommunityListObj } from './communities'

export type CommunityRequestTypes = {
    refetchOnMount?: boolean;
    communityId?: string;
    enabled?: boolean;
};

export type CommunitiesResponse = {
    isMember: boolean;
    isAdmin: boolean;
    template?: LandingDataType;
    landingData?: LandingDataType;
    isWaiting: boolean;
    theme: string;
}

export type AdminCommunityResponse = {
    isAdmin: boolean;
    role: number;
}

export type LandingDataType = {
    json_content: string;
}

export type CommunityFormType = {
    additionalInfo: FormStep[];
}

export type AdminAnalyticsDataEvolution = {
    date: string,
    total: string
}

export type AdminAnalytics = {
    members_total: string;
    posts_total: string;
    members_evolution: AdminAnalyticsDataEvolution[];
    posts_evolution: AdminAnalyticsDataEvolution[];
}

export const useCheckCommunityById = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return usePublicFetcher<any, CommunitiesResponse>({ key: `community-check-${communityId}`, path: `/community/${communityId}/check`, refetchOnMount })
}

export const useCheckCommunityAdminById = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return useFetcher<any, AdminCommunityResponse>({ key: `community-admin-check-${communityId}`, path: `/community/${communityId}/admin/check`, refetchOnMount })
}

export const useCommunityById = ({ communityId, refetchOnMount = true, enabled = true }: CommunityRequestTypes) => {
    return usePublicFetcher<any, CommunityListObj>({ key: `community-${communityId}`, path: `/community/${communityId}`, refetchOnMount, enabled })
}

export const useCommunityLanding = ({ communityId, refetchOnMount = true, enabled = true }: CommunityRequestTypes) => {
    return usePublicFetcher<any, any>({ key: `community-${communityId}-landing`, path: `/community/${communityId}/landing`, refetchOnMount, enabled })
}

export const useCommunityAbout = ({ communityId, refetchOnMount = true, enabled = true }: CommunityRequestTypes) => {
    return usePublicFetcher<any, any>({ key: `community-${communityId}-about`, path: `/community/${communityId}/about`, refetchOnMount, enabled })
}

export const useCommunityForm = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return useFetcher<any, CommunityFormType>({ key: `community-form-${communityId}`, path: `/community/${communityId}/form`, refetchOnMount })
}

// Add filters
export const useGetAllMembers = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return useFetcher<any, any>({ key: `community-members-${communityId}`, path: `/community/${communityId}/admin/members`, refetchOnMount })
}

export const useGetMyReferral = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return useFetcher<any, any>({ key: `community-referred-${communityId}`, path: `/community/${communityId}/referred`, refetchOnMount })
}

export const useGetAnalytics = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return useFetcher<any, any>({ key: `community-admin-analytics-${communityId}`, path: `/community/${communityId}/admin/analytics`, refetchOnMount })
}

export const useGetActionsTypes = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return useFetcher<any, any>({ key: `community-actions-types-${communityId}`, path: `/community/${communityId}/actions/types`, refetchOnMount })
}

export const useGetCommunityName = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return usePublicFetcher<any, any>({ key: `community-name-${communityId}`, path: `/community/${communityId}/name`, refetchOnMount })
}

export const useGetCommunityLandingStatistique = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return usePublicFetcher<any, any>({ key: `community-stat-${communityId}`, path: `/community/${communityId}/landing/statistics`, refetchOnMount })
}

export const useSubscribeCommunity = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return useFetcher<any, any>({ key: `subscribe-community-${communityId}`, path: `/community/${communityId}/subscribe`, refetchOnMount })
}

export const useUnsubscribeCommunity = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return useFetcher<any, any>({ key: `unsubscribe-community-${communityId}`, path: `/community/${communityId}/unsubscribe`, refetchOnMount })
}

export const useCommunityFeed = ({ communityId, page = 1, refetchOnMount, keepPreviousData, enabled } : { communityId: string | number, page?: number, refetchOnMount?: boolean, keepPreviousData?: boolean, enabled:boolean }) => {
    return useFetcher<any, PostFeed[]>({
      key: `page-${page}-community-${communityId}-feed`,
      path: `/community/${communityId}/feed`,
      refetchOnMount,
      keepPreviousData,
      enabled
    });
  };