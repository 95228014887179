import { CommunityListObj } from "hooks/queries/communities"
import { Config } from "services/config"
import { useNavigate } from "react-router-dom"
import { AnimatedButton } from "components/globals/buttons"
import { useModaleStore } from "stores/modale"

export type CommunityCardProps = {
    community: CommunityListObj
    isModale?: boolean
    isInline?: boolean
}

export const CommunityCards = ({ community, isInline = false, isModale = false }: CommunityCardProps) => {

    const background_url = Config.CDN_HOST + "community%2F" + community.id + "%2Fbg";
    const owner_url = Config.CDN_HOST + "user%2F" + community.owner_uuid;
    const navigate = useNavigate()
    const { setModale } = useModaleStore()

    const handleClicked = () => {
        if (isModale) {
            setModale(false, '', '', {})
        }
        navigate(`/${community.id}`)
    }

    if (isInline) {
        return (
            <AnimatedButton className="w-full bg-gray-100 py-2 pl-2 pr-5 rounded-tiny overflow-hidden flex items-center justify-between" onClick={handleClicked}>
                <div className="w-full flex items-center justify-start gap-3">
                    <div className="h-[224px] w-[126px] rounded-mimi" style={{ background: `url(${background_url}) center center / cover` }} />
                    <div className="flex flex-col items-start gap-0.5">
                        <h2 className="text-sm">{community.name}</h2>
                        <div className="flex gap-1 items-center justify-start">
                            <div className="w-[20px] lg:w-[30px] h-[20px] lg:h-[30px] rounded-full" style={{ background: `url(${owner_url}) center center / cover` }} />
                            <p className="text-xs font-[300] text-gray-500">Albaninho</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-end justify-end">
                    <p className="text-gray-800 font-[600]">{community.members}</p>
                    <p className="text-gray-600 text-xs font-light">membres</p>
                </div>
            </AnimatedButton>
        )
    }

    return (
        <div>
            <div className="w-full bg-white overflow-hidden flex flex-col gap-2" onClick={handleClicked}>
                <div className="h-[224px] w-[166px] rounded-tiny" style={{ background: `url(${background_url}) center center / cover` }} />
                <div className="w-full flex gap-1.5 items-center">
                    <div className="w-[25px] lg:w-[30px] h-[25px] lg:h-[30px] rounded-full" style={{ background: `url(${owner_url}) center center / cover` }} />
                    <div className="flex flex-col gap-0 items-start justify-start">
                        <h2 className="text-sm">{community.name}</h2>
                        <p className="text-textGray text-xs font-light">{community.members} membres</p>
                    </div>
                </div>
            </div>
        </div>
    )
}