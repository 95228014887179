//import { Icon } from 'assets/icons';
import React, { ReactNode } from 'react';

interface EditableBlockLayoutProps {
    id: string;
    isAdmin: boolean;
    onUpdate: (id: string, data: any) => void;
    children: (isEditing: boolean, onDataChange: (data: any) => void) => ReactNode;
  }
  
  export const EditableBlockLayout: React.FC<EditableBlockLayoutProps> = ({
    id,
    isAdmin,
    onUpdate,
    children
  }) => {
    const [isEditing, setIsEditing] = React.useState(false);
  
    const handleSave = () => {
      setIsEditing(false);
    };
  
    return (
      <div className="w-full">
        {children(isEditing, (data) => onUpdate(id, data))}
        
        {isAdmin && (
          <div className="mt-4 flex justify-end">
            {!isEditing ? (
              <button
                onClick={() => setIsEditing(true)}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Modifier
              </button>
            ) : (
              <button
                onClick={handleSave}
                className="bg-green-500 text-white px-4 py-2 rounded"
              >
                Sauvegarder
              </button>
            )}
          </div>
        )}
      </div>
    );
  };