import React, { useCallback, useMemo } from 'react';
import { useAllChannels } from "@hooks/queries/channels";
import { useAllPostOfChannel } from "@hooks/queries/posts";
import { useMessageStore } from "stores/feed/channelMessage";
import { InputTipTapFeed } from "components/community/feed/tiptap/inputTipTap";
import { MessagesFeed } from "components/community/feed/messages"
import { useParams } from "react-router-dom";
import { AnimatedButton } from "components/globals/buttons";
import { useEffect, useRef, useState } from "react";
import { useTopbarMobileData } from "stores/topbarMobileData";
import { ChannelActivity } from "components/activity/ChannelActivity";
import { useActivityStore } from "stores/activityStore";
import { format, isAfter, isSameDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import { PostSkeleton } from 'skeleton/community/postSkeleton';
import { useAuthStore } from 'stores/auth';
import { Thread } from "components/thread";
import { useThreadModaleStore } from "stores/threadModale";
import { useBreakpointContext } from 'context/BreakpointContext';

interface DateSeparatorProps {
    date: Date;
}

const DateSeparator: React.FC<DateSeparatorProps> = ({ date }) => {
    return (
        <>
            <div className="relative w-full my-4">
                <div className="absolute inset-0 flex items-center w-full" style={{ top: '50%', transform: 'translateY(-50%)' }}>
                    <div className="w-full border-t border-gray-200"></div>
                </div>
            </div>
            <div className="sticky top-2 w-full z-10 md:mt-[-16px] mt-[-12px]">
                <div className='w-full flex items-center justify-center'>
                    <div className="bg-gray-100 px-4 rounded-full text-sm text-gray-500 w-36 md:w-48 border border-gray-200 h-7 md:h-9 flex items-center justify-center">
                        <span className="relative px-2 text-xs md:text-sm">{format(date, 'd MMMM', { locale: fr })}</span>
                    </div>
                </div>
            </div>
        </>
    );
};

const NewMessagesSeparator = ({ onNewMessageClick }: { onNewMessageClick: () => void; }) => (
    <>
        <div className="relative w-full" style={{ height: '0px', marginTop: '18px', marginBottom: '18px' }}>
            <div className="absolute inset-0 flex items-center w-full" style={{ top: '50%', transform: 'translateY(-50%)' }}>
                <div className="w-full border-t border-red-500"></div>
            </div>
        </div>
        <div className="sticky top-2 w-full z-10" style={{ marginTop: '-16px' }}>
            <div className='w-full flex items-center justify-end px-small'>
                <div
                    className="bg-app rounded-full text-sm text-red-500 w-max h-7 md:h-9 flex items-center justify-center bg-red-100 border border-red-500 cursor-pointer"
                    onClick={onNewMessageClick}
                >
                    <span className="relative px-small text-xs md:text-sm">Nouveau</span>
                </div>
            </div>
        </div>
    </>
);

export const CommunityFeedPage: React.FC = () => {

    const { communityId, channelId, postId } = useParams<{ communityId: string; channelId: string, postId?: string }>();
    const [isNewPost, setIsNewPost] = useState<boolean>(false);
    if (!communityId || !channelId) return null;

    const numberCommunityId = parseInt(communityId);
    const numberChannelId = parseInt(channelId);
    const bottomRef = useRef<HTMLDivElement>(null);
    const { setTopbarMobileData } = useTopbarMobileData();
    const { getLastChannelOpenDate } = useActivityStore();
    const { uuid } = useAuthStore();
    const { threadOpen, setThread } = useThreadModaleStore();
    const { isDesktop } = useBreakpointContext();

    useAllChannels({ communityId: numberCommunityId, refetchOnMount: false, withNotifications: true });
    const { isLoading: isPostLoading } = useAllPostOfChannel({ communityId: numberCommunityId, channelId: numberChannelId, refetchOnMount: false });

    let messages = useMessageStore((state) => state.channels[channelId]?.messages || []);
    const cleanUnreadMessages = useMessageStore(state => state.resetNewPostsCount);
    let channelInfo = useMessageStore((state) => state.channels[channelId]);

    messages = messages.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

    const scrollToBottom = () => {
        setIsNewPost(false);
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView();
        }
    };

    useEffect(() => {
        if (!isPostLoading) {
            cleanUnreadMessages(channelId);
            setTimeout(() => {
                scrollToBottom();
            }, 200);
        }
    }, [channelId, isPostLoading]);

    useEffect(() => {
        setTopbarMobileData({ pageName: `#${channelInfo?.name || "test"}`, communityId, showTopbar: true, withGoBack: true, withGoHome: false, withLogo: false });
    }, [channelInfo]);

    const newMessageRef = useRef<HTMLDivElement>(null);

    const scrollToNewMessage = useCallback(() => {
        if (newMessageRef.current) {
            newMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, []);

    const lastConnectionDate = useMemo(() => getLastChannelOpenDate(channelId), [channelId, getLastChannelOpenDate]);

    const renderMessageWithSeparators = useCallback(() => {
        let currentDate: Date | null = null;
        let hasShownNewMessagesSeparator = false;

        return messages.flatMap((post) => {
            const messageDate = new Date(post.created_at);
            const elements: JSX.Element[] = [];

            if (!currentDate || !isSameDay(messageDate, currentDate)) {
                elements.push(<DateSeparator key={`date-${post.id}`} date={messageDate} />);
                currentDate = messageDate;
            }

            // Vérifier si le message n'est pas de l'utilisateur actuel avant d'afficher le séparateur
            if (lastConnectionDate && !hasShownNewMessagesSeparator && isAfter(messageDate, lastConnectionDate) && post.owner_uuid !== uuid) {
                elements.push(<NewMessagesSeparator key={`new-messages-${post.id}`} onNewMessageClick={scrollToNewMessage} />);
                hasShownNewMessagesSeparator = true;
                elements.push(<div key={`new-message-ref-${post.id}`} ref={newMessageRef} style={{ height: 0 }} />);
            }

            elements.push(
                <MessagesFeed
                    key={post.id}
                    post={post}
                    communityId={communityId || ''}
                    channelId={channelId || ''}
                />
            );

            return elements;
        });
    }, [messages, lastConnectionDate, scrollToNewMessage, communityId, channelId, uuid]);


    useEffect(() => {
        if (postId) {
            setThread(true, postId, { communityId: numberCommunityId, channelId: channelId });
        } else {
            setThread(false, '', {});
        }
    }, [postId, channelId]);



    return (
        <div className='w-full h-full flex items-start justify-start'>
            <div className="w-full h-full flex flex-col bg-app overflow-hidden">
                <ChannelActivity channelId={numberChannelId} />
                <div className="flex-grow overflow-hidden relative">
                    {isNewPost && (
                        <AnimatedButton
                            className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-red-400 rounded-full px-4 py-0.5 z-20"
                            onClick={scrollToBottom}
                        >
                            <span>Nouveau message</span>
                        </AnimatedButton>
                    )}
                    <div className="w-full h-full overflow-y-auto hideScrollBar">
                        {isPostLoading ? (
                            Array.from({ length: 15 }).map((_, index) => (
                                <PostSkeleton key={index} />
                            ))
                        ) : (
                            <>
                                {renderMessageWithSeparators()}
                                <div ref={bottomRef}></div>
                            </>
                        )}
                    </div>
                </div>
                <InputTipTapFeed communityId={numberCommunityId} channelId={numberChannelId} />
            </div>
            {isDesktop && threadOpen && (
                <div className="h-full w-full overflow-y-auto bg-white  shadow-[-.125rem_0_.0625rem_rgba(0,0,0,.05),inset_0_0_.0625rem_rgba(66,71,76,.45)] z-[5]">
                    <Thread />
                </div>
            )}
        </div>
    );
};