import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAdminCommunity } from 'stores/adminCommunity';

const AdminListener = () => {
    
    const params = useParams();
    const communityId = params.communityId;
    const { setAdminCommunity } = useAdminCommunity();

    useEffect(() => {
        const userRole = 1;

        if (communityId) {
            setAdminCommunity(communityId, userRole);
        }
    }, [communityId, setAdminCommunity]);

    return null;

};

export default AdminListener;
