import { useFetcher } from "./api";

export type CommunityRequestTypes = {
    refetchOnMount?: boolean;
    communityId?: string;
};

type LeaderboardItem = {
    created_at: string;
    points: number;
    position: string;
    user_name: string;
    uuid: string;
};

export const useGetLeaderBoard = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return useFetcher<any, LeaderboardItem[]>({ key: `community-${communityId}-leaderboard`, path: `/community/${communityId}/leaderboard/tops`, refetchOnMount })
}

//community/{communityId}/leaderboard/tops