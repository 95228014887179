import { useModaleStore } from "stores/modale";
import { BlockProps } from 'components/community/landing/types';

// Définissez une liste des types de blocs disponibles
const availableBlocks = [
    { type: 'text', label: 'Texte' },
    { type: 'cta', label: "Appel à l'action" },
    { type: 'title', label: 'Titre' },
    { type: 'gallery', label: 'Galerie' },
    { type: 'stats', label: 'Statistiques' },
    { type: 'reviews', label: 'Avis' },
];

export const AddAdminLandingBlock: React.FC = () => {
    const { modaleProps, setModale } = useModaleStore();
    const onUpdateBlock = modaleProps?.addNewBlock as (newBlock: BlockProps) => void;

    const handleAddBlock = (type: string | BlockProps) => {
        onUpdateBlock(type as BlockProps);
        setModale(false, "", "", {});
    };

    return (
        <div className="p-4 w-full lg:w-[500px] cursor-auto flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <h2 className="text-xl font-[500]">Ajouter un nouveau bloc</h2>
                <p className="text-gray-600 font-light">Vous pouvez ajouter un nouveau bloc que vous pourrez déplacer par la suite :</p>
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-2">
                {availableBlocks.map(({ type, label }) => (
                    <button
                        key={type}
                        onClick={() => handleAddBlock(type)}
                        className="py-2.5 bg-gray-200 text-black rounded hover:bg-black hover:text-white transition-colors w-full"
                    >
                        {label}
                    </button>
                ))}
            </div>
        </div>
    );
};