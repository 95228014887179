import React, { useEffect } from 'react';
import { MyCommunitiesList } from "components/communities";
import { useTopbarMobileData } from "stores/topbarMobileData";
import { VirtualizedInfinitePostsList } from "components/community/feed/messages/VirtualizedInfinitePostsList";

export const HomeWithCommunity: React.FC<{
  scrollContainerRef: React.RefObject<HTMLDivElement>
}> = ({ scrollContainerRef }) => {
    const { setTopbarMobileData } = useTopbarMobileData();

    useEffect(() => {
        setTopbarMobileData({ pageName: 'Accueil', showTopbar: true, withGoBack: false, withLogo: true });
    }, [setTopbarMobileData]);

    return (
        <>
            <MyCommunitiesList isSlider={true} />

            <div className="w-full flex flex-col gap-4">
                <div className="w-full flex flex-col gap-0">
                    <h2 className="lg:text-xl text-lg">Vos fils d'actualités</h2>
                    <p className="text-sm font-[300] text-textGray">Pour vous</p>
                </div>
                <VirtualizedInfinitePostsList isFullWidth={true} parentRef={scrollContainerRef} />
            </div>
        </>
    );
};