import { CategoryItemType } from "interfaces/categories"
import { AnimatedButton } from "components/globals/buttons"
import { useNavigate } from "react-router-dom";

export const CategorieSkeleton = () => {
    return (
        <div className="w-full h-28 bg-gray-100 overflow-hidden p-mini relative rounded-md z-0 animate-pulse">
            <div className="h-20 w-20 rounded-tiny shadow-xl absolute bottom-[-20px] right-[-20px] rotate-6 z-2 bg-gray-200" />
            <div className="h-4 w-24 bg-gray-200 rounded absolute top-4 left-4 z-20" />
        </div>
    );
};

export const CategorieItem = ({ category }: { category: CategoryItemType }) => {
    
    const imagePath = `https://pub-388e882330ee44c7b74215d8e0935cb9.r2.dev/community%2F63%2Fbg`;
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(`/categories/${category.slug}`);
    }

    return (
        <AnimatedButton onClick={handleNavigate} key={category.id} className="w-full h-28 bg-cover overflow-hidden p-mini relative rounded-md z-0" style={{ backgroundColor: category.assets.primaryColor }}>
            <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-black/0 z-2" />
            <div className="h-20 w-20 rounded-tiny shadow-xl absolute bottom-[-20px] right-[-20px] rotate-6 z-2 bg-center bg-cover" style={{ backgroundImage: `url(${imagePath})` }} />
            <p className="text-white font-bold text-sm absolute top-4 left-4 z-20">{category.name}</p>
        </AnimatedButton>
    );
};