import { create } from 'zustand'
import { BlockProps, BlockType } from 'components/community/landing/types'

interface LandingStore {
    blocks: BlockProps[];
    setBlocks: (blocks: BlockProps[]) => void;
    updateBlock: (id: string, updateFn: (block: BlockProps) => BlockProps) => void;
    reorderBlocks: (newOrder: string[]) => void;
    saveTemplate: () => Promise<void>;
    addNewBlock: (type: BlockType) => void;
}

export const useLandingStore = create<LandingStore>((set, get) => ({
    blocks: [],

    setBlocks: (blocks) => set({ blocks }),

    updateBlock: (id, updateFn) => set((state) => ({
        blocks: state.blocks.map((block) =>
            block.id === id ? updateFn(block) : block
        ),
    })),

    reorderBlocks: (newOrder) => set((state) => {
        const blockMap = new Map(state.blocks.map((block) => [block.id, block]));
        const newBlocks = newOrder.map((id) => blockMap.get(id)).filter((block): block is BlockProps => block !== undefined);
        return { blocks: newBlocks };
    }),

    saveTemplate: async () => {
        const { blocks } = get();
        // Ici, vous devriez implémenter la logique pour sauvegarder le template
        // Par exemple, envoyer une requête à votre API
        console.log('Sauvegarde du template:', blocks);
        // Exemple d'appel API (à remplacer par votre véritable logique d'API)
        // await api.saveTemplate(blocks);
    },

    addNewBlock: (type: BlockType) => set((state) => {
        const newBlock: BlockProps = {
            id: `new-${type}-${Date.now()}`,
            type,
            // Initialiser les propriétés spécifiques à chaque type de bloc
            ...(type === 'text' && { content: 'Nouveau texte' }),
            ...(type === 'title' && { content: 'Nouveaut titre' }),
            ...(type === 'cta' && { text: 'Rejoindre la communauté' }),
            ...(type === 'gallery' && {
                images: [
                    {
                        url: "https://img.freepik.com/photos-gratuite/beaute-abstraite-automne-dans-motif-veines-feuilles-multicolores-genere-par-ia_188544-9871.jpg?size=626&ext=jpg",
                        alt: "Image 1"
                    },
                    {
                        url: "https://img.freepik.com/photos-gratuite/beaute-abstraite-automne-dans-motif-veines-feuilles-multicolores-genere-par-ia_188544-9871.jpg?size=626&ext=jpg",
                        alt: "Image 2"
                    },
                    {
                        url: "https://img.freepik.com/photos-gratuite/beaute-abstraite-automne-dans-motif-veines-feuilles-multicolores-genere-par-ia_188544-9871.jpg?size=626&ext=jpg",
                        alt: "Image 3"
                    }
                ]
            }),
        } as BlockProps;

        return { blocks: [...state.blocks, newBlock] };
    }),
}));