import { create } from 'zustand'

export interface ThemeCommunityType {
    communityId: string;
    theme: any;
    setThemeCommunity: (communityId: string, theme: any) => void;
}

export const themeSelector = (state: ThemeCommunityType) => state.theme;

export const useThemeCommunity = create<ThemeCommunityType>(set => ({
    communityId: '',
    theme : {},
    setThemeCommunity: (communityId: string, theme: any) => {
        set({ communityId: communityId, theme: theme });
    },
}));
