import { useAllChannels } from "@hooks/queries/channels";
import { Icon } from "assets/icons";
import { Link, useParams } from "react-router-dom";

export const ChannelsPage = () => {

    const { communityId } = useParams();
    if (!communityId) return null

    const numberCommunityId = parseInt(communityId)

    const { data: allChannels = [], isLoading, /* isError, error */ } = useAllChannels({ communityId: numberCommunityId, refetchOnMount: false, withNotifications: true });

    return (
        <div>
            {
                !isLoading && allChannels.map((channel, index) => (
                    <Link to={`/${communityId}/${channel.id}`} key={index}>
                        <div className="w-full flex gap-2 items-center justify-start hover:bg-grey-200 p-tiny rounded-tiny cursor-pointer">
                            <Icon type={channel.can_users_publish ? "Hashtag" : "Lock1"} color="#000" size={16} />
                            <p className="text-sm font-medium">{channel.name}</p>
                        </div>
                    </Link>
                ))
            }
        </div>
    )
}