import { useConversationsStore } from "stores/conversation"
import { useFetcher } from "./api" 
import { useEffect } from "react"

import { useMutator } from "@hooks/queries/api";
import { v4 as uuidv4 } from 'uuid';
import { uuidSelector, useAuthStore, userNameSelector } from "stores/auth"

export type SendMessageType = {
    recipientId: string;
};

export type SendMessageContent = {
    content: any;
}   

//     id?: number;
//     temporaryId?: string;
//     correspondantId: string;
//     user_name: string;
//     content: MessageContent;
//     created_at: string;
//     waiting?: boolean;

export const useSendConversationMessage = ({ recipientId } : SendMessageType ) => {
    
    const { mutateAsync: sendNewMessage, isPending } = useMutator(`/messages/${recipientId}/new`)
    const addWaitingConversationMessage = useConversationsStore(state => state.addWaitingConversationMessage);
    const uuid : any = useAuthStore(uuidSelector);
    const userName : any = useAuthStore(userNameSelector);
    
    const sendMessage = ({ content }: SendMessageContent ) => {
        const uniqueId = uuidv4() + "_" + new Date().getTime();
        //@ts-ignore
        addWaitingConversationMessage(recipientId, { content, temporaryId: uniqueId, sender_uuid: uuid, waiting: true, owner_uuid: uuid, user_name: userName, id: uniqueId, is_pinned: false, created_at: new Date() }, uniqueId);
        console.log('waiting => ', { content, temporaryId: uniqueId, sender_uuid: uuid, waiting: true, owner_uuid: uuid, user_name: userName, id: uniqueId, is_pinned: false, created_at: new Date() })
        return sendNewMessage({ content, temporaryId: uniqueId, user_name: userName });
    };

    return { sendMessage, isPending };
};



export const useAllConversation = ({ refetchOnMount = false, enabled = true }: any) => {

    const setAllConversations = useConversationsStore((state: any) => state.setAllConversations);

    const { data, refetch, isError, error, isLoading } = useFetcher<any, any[] >({ key: `user-conversations`, path: `/messages/conversations`, refetchOnMount, enabled });

    useEffect(() => {
        if (data && data.length > 0) {
            setAllConversations(data);
        }
    }, [data, setAllConversations]);

    return { data, refetch, isError, error, isLoading };

}

export const useMessagesConversation = ({ userId, refetchOnMount = false }: any) => {

    const addConversationMessages = useConversationsStore((state: any) => state.addConversationMessages);
    
    const { data, refetch, isError, error, isLoading } = useFetcher<any, any[] >({ key: `user-messages-${userId}`, path: `/messages/${userId}/conversation`, refetchOnMount });

    useEffect(() => {
        if (data && data.length > 0) {
            addConversationMessages(userId, data);
        }
    }, [data, userId, addConversationMessages]);

    return { data, refetch, isError, error, isLoading };

}