import { useCommunityForm } from "@hooks/queries/community";
import { useModaleStore } from "stores/modale";
import { AnimatedButton } from "components/globals/buttons";
import { useParams } from "react-router-dom";
import { useMutator } from "@hooks/queries/api";
import { useState } from "react";

export type Option = {
    id: number;
    value: string;
};

export type FormField = {
    id: number;
    label: string;
    placeholder: string;
    type: 'text';
    options?: Option[];
    required: boolean;
    position: number;
};

export type FormStep = {
    stepId: number;
    fields: FormField[];
};

const fakeForm: FormStep[] = [
    {
        stepId: 1,
        fields: [
            {
                id: 1,
                label: 'Souhaitez-vous ajouter une information ?',
                placeholder: 'Je souhaite rejoindre car...',
                type: 'text',
                required: false,
                position: 1,
            },
        ],
    }
];

export const JoinCommunityForm = () => {
    let { communityId } = useParams<{ communityId: string }>();
    const { modaleProps } = useModaleStore();
    communityId = communityId || modaleProps.communityId;

    // TODO
    const { data: communityForm, isLoading } = useCommunityForm({ communityId, refetchOnMount: false });
    const { mutateAsync: submitForm, /*isError, error, isPending: isSubmitLoading */ } = useMutator<any>("/community/" + communityId + "/join/form/response");

    const [formResponses, setFormResponses] = useState<{ [key: string]: string }>({});

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormResponses({
            ...formResponses,
            [name]: value,
        });
    };

    const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const response = Object.keys(formResponses).map(key => ({
            question: key,
            response: formResponses[key]
        }));
        await submitForm({
            formData : response
        });
    };

    return (
        <>
            {isLoading ? (
                <div>
                    <p>Loading..</p>
                </div>
            ) : (
                <div className="w-full h-full flex flex-col gap-4 p-4">
                    <form onSubmit={handleSubmitForm} className="w-full h-full flex flex-col gap-4">
                        {(communityForm?.additionalInfo || fakeForm).map((step, index) => (
                            <div key={index} className="flex flex-col gap-2">
                                {step.fields.map((field) => (
                                    <div key={field.id} className="flex flex-col gap-2">
                                        <label htmlFor={`field-${field.id}`} className="text-xl">{field.label}</label>
                                        <input
                                            type={field.type}
                                            placeholder={field.placeholder}
                                            name={field.label}
                                            required={field.required}
                                            onChange={handleChange}
                                            className="bg-transparent w-full focus:outline-none border h-10 rounded px-3"
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                        <AnimatedButton className="bg-app text-white w-full h-12 rounded-md bg-black" type="submit">Valider</AnimatedButton>
                    </form>
                </div>
            )}
        </>
    );
};
