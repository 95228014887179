import { useFetcher, usePublicFetcher } from "./api";

type RefetchOnMountType = {
    refetchOnMount?: boolean;
    communityId: string;
    enabled?: boolean;
}

//reviews/all
//reviews/admin/all
//reviews/mine

export const useGetAllCommunityReviews = ({ communityId, refetchOnMount = true, enabled }: RefetchOnMountType) => {
    return usePublicFetcher<any, any>({
        key: `reviews-${communityId}`,
        path: `/community/${communityId}/reviews/all`,
        refetchOnMount,
        enabled: enabled,
    });
}

export const useGetAllAdminCommunityReviews = ({ communityId, refetchOnMount = true, enabled }: RefetchOnMountType) => {
    return useFetcher<any, any>({
        key: `reviews-admin-${communityId}`,
        path: `/community/${communityId}/reviews/admin/all`,
        refetchOnMount,
        enabled: enabled,
    });
}

export const useGetMineCommunityReviews = ({ communityId, refetchOnMount = true, enabled }: RefetchOnMountType) => {
    return usePublicFetcher<any, any>({
        key: `reviews-mine-${communityId}`,
        path: `/community/${communityId}/reviews/mine`,
        refetchOnMount,
        enabled: enabled,
    });
}