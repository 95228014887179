import { useAdminCommunity } from "stores/adminCommunity";
import { Icon } from "assets/icons"
import { useAdminSidebarMenu } from "navigation/useAdminSidebarMenu";
import { Link } from "react-router-dom";

export const AdminSidebar = () => {

    const { communityId } = useAdminCommunity();
    const navigationMenuPages = useAdminSidebarMenu();

    return (
        <div className="h-full flex flex-col gap-3 p-tiny w-full min-w-[300px] max-w-[300px] border-r">
            <div className="w-full flex flex-col gap-6">

                <div className="w-full flex flex-col gap-1">
                    {navigationMenuPages.map(({ key, path, icon, label }) => (
                        <Link to={path.replace(':communityId', communityId)} key={key}>
                            <div className="w-full flex gap-2 items-center justify-start hover:bg-grey-200 p-tiny rounded-tiny">
                                <Icon type={icon} color="#000" size={16} />
                                <p className="">{label}</p>
                            </div>
                        </Link>
                    ))}
                </div>

            </div>
            <div>

            </div>
        </div>
    );
}