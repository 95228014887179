import { Config } from "services/config";
import { CommunityListObj } from "hooks/queries/communities"
import { useNavigate } from "react-router-dom";
import { AnimatedButton } from "components/globals/buttons";
import { truncateString } from "services/truncateString";

export type CommunityCardProps = {
    community: CommunityListObj
    isSlider?: boolean
}

export const MyCommunityCards = ({ community, isSlider }: CommunityCardProps) => {

    isSlider = isSlider || false;

    const background_url = Config.CDN_HOST + "community%2F" + community.id + "%2Fbg";
    const owner_url = Config.CDN_HOST + "user%2F" + community.owner_uuid;
    const navigate = useNavigate();

    const handleClicked = () => {
        navigate(`/${community.id}`);
    }

    return (
        <AnimatedButton className={isSlider ? "min-w-48 lg:w-72" : "w-full"} onClick={handleClicked}>
            <div className="w-full rounded-micro overflow-hidden flex flex-col p-1.5 bg-lightGray gap-2 text-black relative">
                <div className="absolute inset-0 top-0 left-0 w-full h-full z-10 opacity-70">
                    <svg width="175" height="82" viewBox="0 0 175 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="175" height="82" rx="5" fill="url(#paint0_radial_3117_8528)" fillOpacity="0.2" />
                        <defs>
                            <radialGradient id="paint0_radial_3117_8528" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(168 -6.00001) rotate(144.033) scale(69.8087 148.982)">
                                <stop stopColor="#222C67" />
                                <stop offset="1" stopColor="#222C67" stopOpacity="0" />
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
                <div className="w-full flex relative z-20 gap-2 h-full items-stretch justify-start">
                    <div className="w-16 h-16 bg-center bg-cover rounded-mimi" style={{ background: `url(${background_url}) center center / cover` }} />
                    <div className="flex flex-col gap-0 items-between justify-between w-max py-1">
                        <div className="w-5 h-5 rounded-full" style={{ background: `url(${owner_url}) center center / cover` }} />
                        <h2 className="text-xs font-[400] leading-none text-left">{truncateString(community.name || "", 10)}</h2>
                        <p className="text-textGray text-xs font-light text-left">{community.members} membres</p>
                    </div>
                </div>
            </div>
        </AnimatedButton>

    )
}