import { usePublicFetcher } from './api'

export type RefetchOnMountType = {
    refetchOnMount?: boolean;
    search: string;
    enabled?: boolean;
}

export const useGetSearch = ({ search, refetchOnMount = true }: RefetchOnMountType) => {
    const enabled = search.trim().length > 2;    
    return usePublicFetcher<any, any>({
        key: `search-${search}`,
        path: `/search/${search}`,
        refetchOnMount,
        enabled: enabled,
    });
}

export const useGetSearchUsers = ({ search, refetchOnMount = true }: RefetchOnMountType) => {
    const enabled = search.trim().length > 2;    
    return usePublicFetcher<any, any>({
        key: `search-users-${search}`,
        path: `/search/users/${search}`,
        refetchOnMount,
        enabled: enabled,
    });
}