import { Icon } from "assets/icons"
import { useBottomBarMenu } from "navigation/useBottomBarMenu";
import { Link } from "react-router-dom"
import { useLocation } from "react-use";
//import { TopBarUserPic } from "../bottombar/userPic";
//import { useAuthStore } from "stores/auth";

// const HomeSvg = ({ variant }: { variant: string }) => {

//     return (
//         <>
//             {
//                 variant === "Bold" ? (
//                     <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
//                         <path d="M2.45447 6.86451L3.0113 7.53946L3.0113 7.53946L2.45447 6.86451ZM8 21.8144V22.6894C8.48325 22.6894 8.875 22.2977 8.875 21.8144H8ZM14 21.8144H13.125C13.125 22.2977 13.5168 22.6894 14 22.6894V21.8144ZM19.5455 6.86451L18.9887 7.53946L18.9887 7.53946L19.5455 6.86451ZM13.5455 1.91451L14.1024 1.23956L14.1024 1.23956L13.5455 1.91451ZM8.45447 1.91451L7.89763 1.23955L7.89763 1.23955L8.45447 1.91451ZM1.875 9.95C1.875 9.01716 2.29174 8.1331 3.0113 7.53946L1.89763 6.18956C0.77511 7.11564 0.125 8.49478 0.125 9.95H1.875ZM1.875 17.8144V9.95H0.125V17.8144H1.875ZM5 20.9394C3.27411 20.9394 1.875 19.5403 1.875 17.8144H0.125C0.125 20.5068 2.30761 22.6894 5 22.6894V20.9394ZM8 20.9394H5V22.6894H8V20.9394ZM8.875 21.8144V17.8145H7.125V21.8144H8.875ZM8.875 17.8145C8.875 16.6408 9.8264 15.6895 11 15.6895V13.9395C8.8599 13.9395 7.125 15.6744 7.125 17.8145H8.875ZM11 15.6895C12.1736 15.6895 13.125 16.6408 13.125 17.8145H14.875C14.875 15.6744 13.1401 13.9395 11 13.9395V15.6895ZM13.125 17.8145V21.8144H14.875V17.8145H13.125ZM17 20.9394H14V22.6894H17V20.9394ZM20.125 17.8144C20.125 19.5403 18.7259 20.9394 17 20.9394V22.6894C19.6924 22.6894 21.875 20.5068 21.875 17.8144H20.125ZM20.125 9.95V17.8144H21.875V9.95H20.125ZM18.9887 7.53946C19.7083 8.1331 20.125 9.01716 20.125 9.95H21.875C21.875 8.49477 21.2249 7.11564 20.1024 6.18956L18.9887 7.53946ZM12.9887 2.58946L18.9887 7.53946L20.1024 6.18956L14.1024 1.23956L12.9887 2.58946ZM9.0113 2.58946C10.166 1.63685 11.834 1.63685 12.9887 2.58946L14.1024 1.23956C12.3011 -0.246518 9.69894 -0.246519 7.89763 1.23955L9.0113 2.58946ZM3.0113 7.53946L9.0113 2.58946L7.89763 1.23955L1.89763 6.18956L3.0113 7.53946Z" fill="#262626" />
//                     </svg>
//                 ) : (
//                     <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
//                         <path d="M2.45447 6.86451L3.0113 7.53946L3.0113 7.53946L2.45447 6.86451ZM8 21.8144V22.6894C8.48325 22.6894 8.875 22.2977 8.875 21.8144H8ZM14 21.8144H13.125C13.125 22.2977 13.5168 22.6894 14 22.6894V21.8144ZM19.5455 6.86451L18.9887 7.53946L18.9887 7.53946L19.5455 6.86451ZM13.5455 1.91451L14.1024 1.23956L14.1024 1.23956L13.5455 1.91451ZM8.45447 1.91451L7.89763 1.23955L7.89763 1.23955L8.45447 1.91451ZM1.875 9.95C1.875 9.01716 2.29174 8.1331 3.0113 7.53946L1.89763 6.18956C0.77511 7.11564 0.125 8.49478 0.125 9.95H1.875ZM1.875 17.8144V9.95H0.125V17.8144H1.875ZM5 20.9394C3.27411 20.9394 1.875 19.5403 1.875 17.8144H0.125C0.125 20.5068 2.30761 22.6894 5 22.6894V20.9394ZM8 20.9394H5V22.6894H8V20.9394ZM8.875 21.8144V17.8145H7.125V21.8144H8.875ZM8.875 17.8145C8.875 16.6408 9.8264 15.6895 11 15.6895V13.9395C8.8599 13.9395 7.125 15.6744 7.125 17.8145H8.875ZM11 15.6895C12.1736 15.6895 13.125 16.6408 13.125 17.8145H14.875C14.875 15.6744 13.1401 13.9395 11 13.9395V15.6895ZM13.125 17.8145V21.8144H14.875V17.8145H13.125ZM17 20.9394H14V22.6894H17V20.9394ZM20.125 17.8144C20.125 19.5403 18.7259 20.9394 17 20.9394V22.6894C19.6924 22.6894 21.875 20.5068 21.875 17.8144H20.125ZM20.125 9.95V17.8144H21.875V9.95H20.125ZM18.9887 7.53946C19.7083 8.1331 20.125 9.01716 20.125 9.95H21.875C21.875 8.49477 21.2249 7.11564 20.1024 6.18956L18.9887 7.53946ZM12.9887 2.58946L18.9887 7.53946L20.1024 6.18956L14.1024 1.23956L12.9887 2.58946ZM9.0113 2.58946C10.166 1.63685 11.834 1.63685 12.9887 2.58946L14.1024 1.23956C12.3011 -0.246518 9.69894 -0.246519 7.89763 1.23955L9.0113 2.58946ZM3.0113 7.53946L9.0113 2.58946L7.89763 1.23955L1.89763 6.18956L3.0113 7.53946Z" fill="#262626" />
//                     </svg>
//                 )
//             }
//         </>
//     )
// }

export const BottomBar = () => {

    const bottomBarItems = useBottomBarMenu();
    const location = useLocation();
    //const { uuid } = useAuthStore();

    return (
        <div className="w-full flex justify-between fixed bottom-0 bg-white border-t safeAreaBottom" style={{ zIndex: 25 }}>
            {
                bottomBarItems.map(({ key, icon, path }) => {
                    return (
                        <div key={key} className="w-full py-4">
                            <Link to={path} className="w-full flex flex-col items-center justify-center gap-0.5">
                                {
                                    //icon === "ProfilePic" ? (<TopBarUserPic uuid={uuid} />) : key === "home" ? (<HomeSvg variant={location.pathname === path ? "Bold" : "Linear"} />) : <Icon type={icon} color="#000" size={26} variant={location.pathname === path ? "Bold" : "Linear"} />
                                    <Icon type={icon} color="#000" size={26} variant={location.pathname === path ? "Bold" : "Linear"} />
                                }
                            </Link>
                        </div>
                    )
                })
            }
        </div>
    )
}