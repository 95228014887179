import { useModaleStore } from "stores/modale"
import { AnimatedButton } from "components/globals/buttons";
import { Config } from "services/config";
import { useAuthStore } from "stores/auth";
import anonymePic from "assets/png/anonymeUser.png";

type TopBarUserPicProps = {
    uuid: string;
}

export const TopBarUserPic = ({ uuid }: TopBarUserPicProps) => {

    const url = Config.CDN_HOST + "user%2F" + uuid;

    const { setModale } = useModaleStore();
    const isLogged = useAuthStore(state => state.isLogged)

    return (
        <>
            {
                isLogged ? (
                    <AnimatedButton
                        onClick={() => setModale(true, 'ProfileDrawer', 'drawer', { context: 'signin'})}
                        className="w-[35px] h-[35px] rounded-full bg-center bg-cover overflow-hidden"
                    >
                        <img src={url} alt="" className="object-cover w-full h-full" />
                    </AnimatedButton>
                ) : (
                    <AnimatedButton
                        onClick={() => setModale(true, 'AuthModale', 'modale', { context: 'signin'})}
                        className="w-[35px] h-[35px] rounded-full bg-center bg-cover overflow-hidden bg-pink-200"
                    >
                        <img src={anonymePic} alt="" className="object-cover w-full h-full" />
                    </AnimatedButton>
                )
            }
        </>
    )
}
