import { useGetUserNotifications } from "@hooks/queries/notifications";
import { useModaleStore } from "stores/modale";
import { AnimatedButton } from "components/globals/buttons";
import { Config } from "services/config";
import { useNavigate } from "react-router-dom";
import { useNotificationStore } from "stores/notifications";
import { timeSince } from 'services/timeSince'
import { useMutator } from "@hooks/queries/api";

const NotificationSkeleton = () => {
    return (
        <div className="w-full p-3 flex items-center justify-center gap-2">
            <div className="w-7 h-7 min-w-7 min-h-7 rounded-full bg-gray-100 animate-pulse" />
            <div className="w-webkit flex flex-col items-start justify-start gap-1.5">
                <div className="w-9/12 h-2.5 bg-gray-100 animate-pulse rounded" />
                <div className="w-full h-1.5 bg-gray-100 animate-pulse rounded" />
            </div>
        </div>
    )
}

const NotificationMemberRequestApproved = ({ notification }: { notification: any }) => {

    const { updateNotification } = useNotificationStore();
    const { mutateAsync: setNotificationViewed, isError, isPending } = useMutator<any>("/notifications/" + notification.id + "/viewed");

    const handleSetNotificationViewed = async () => {
        await setNotificationViewed({});
        updateNotification(notification.id, { ...notification, is_viewed: true });
    }

    return (
        <div className="w-full p-3 rounded-lg transition-all flex flex-col items-start justify-start gap-4">
            <div className="w-full flex items-center justify-center gap-6">
                <div className="w-9 h-9 min-w-9 min-h-9 rounded relative border">
                    <img src={`${Config.CDN_HOST}community%2F${notification.community_id}%2Fbg`} alt="Commu" className="w-full h-full object-cover rounded" />
                    <div className="w-5 h-5 min-w-5 min-h-5 rounded-full bg-center bg-cover overflow-hidden absolute bottom-[-5px] right-[-5px]"
                        style={{
                            backgroundImage: `url('${Config.CDN_HOST}user%2F${notification.notifier_uuid}')`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover'
                        }}
                    />
                </div>
                <div className="w-webkit">
                    <div className="w-full flex items-center justify-between">
                        <div className="flex items-center justify-start gap-2">
                            {!notification.is_viewed && <div className="w-2 h-2 rounded-full bg-red-700" />}
                            <p className="text-md">{notification.title}</p>
                        </div>
                        <p className="text-sm font-light text-gray-500">{timeSince(notification.created_at)}</p>
                    </div>
                    <p className="font-light text-sm">{notification.description.replace("{Sender}", "Albaninho")}</p>
                </div>
            </div>
            {
                !notification.is_viewed && !isPending && (
                    <div className="flex items-center justify-center w-full gap-2">
                        <AnimatedButton onClick={handleSetNotificationViewed} className="w-full border rounded-tiny hover:bg-gray-200 w-full text-sm font-light py-2 transition-all">Marquer comme lu</AnimatedButton>
                    </div>
                )
            }
            {
                isPending && (
                    <div className="w-full h-2 rounded-full bg-gray-100 animate-pulse" />
                )
            }
            {
                isError && (
                    <div className="w-full flex items-center justify-center gap-3 py-2">
                        <p className="text-red-500">Une erreur est survenue lors de la mise à jour de la notification</p>
                    </div>
                )
            }
        </div>
    )
}

const NotificationNewComment = ({ notification }: { notification: any }) => {
    const navigate = useNavigate();
    const { updateNotification } = useNotificationStore();
    const { mutateAsync: setNotificationViewed, isError, isPending } = useMutator<any>("/notifications/" + notification.id + "/viewed");

    const handleSetNotificationViewed = async () => {
        await setNotificationViewed({});
        updateNotification(notification.id, { ...notification, is_viewed: true });
    }

    const handleGoToPost = () => {
        setNotificationViewed({});
        updateNotification(notification.id, { ...notification, is_viewed: true });
        navigate(`/${notification.community_id}/${notification.channel_id}/${notification.post_id}`);
    }

    return (
        <div className="w-full p-3 rounded-lg transition-all flex flex-col items-start justify-start gap-4">
            <div className="w-full flex items-center justify-center gap-6">
                <div className="w-9 h-9 min-w-9 min-h-9 rounded relative border">
                    <img src={`${Config.CDN_HOST}community%2F${notification.community_id}%2Fbg`} alt="Commu" className="w-full h-full object-cover rounded" />
                    <div className="w-5 h-5 min-w-5 min-h-5 rounded-full bg-center bg-cover overflow-hidden absolute bottom-[-5px] right-[-5px]"
                        style={{
                            backgroundImage: `url('${Config.CDN_HOST}user%2F${notification.notifier_uuid}')`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover'
                        }}
                    />
                </div>
                <div className="w-webkit">
                    <div className="w-full flex items-center justify-between">
                        <div className="flex items-center justify-start gap-2">
                            {!notification.is_viewed && <div className="w-2 h-2 rounded-full bg-red-700" />}
                            <p className="text-md">{notification.title}</p>
                        </div>
                        <p className="text-sm font-light text-gray-500">{timeSince(notification.created_at)}</p>
                    </div>
                    <p className="font-light text-sm">{notification.description.replace("{Sender}", "Albaninho")}</p>
                </div>
            </div>
            {
                !notification.is_viewed && !isPending && (
                    <div className="flex items-center justify-center w-full gap-2">
                        <AnimatedButton onClick={handleSetNotificationViewed} className="w-full border rounded-tiny hover:bg-gray-200 w-full text-sm font-light py-2 transition-all">Marquer comme lu</AnimatedButton>
                        <AnimatedButton onClick={handleGoToPost} className="w-full border rounded-tiny hover:bg-blue-200 w-full text-sm font-light py-2 transition-all">Voir la réponse</AnimatedButton>
                    </div>
                )
            }
            {
                isPending && (
                    <div className="w-full h-2 rounded-full bg-gray-100 animate-pulse" />
                )
            }
            {
                isError && (
                    <div className="w-full flex items-center justify-center gap-3 py-2">
                        <p className="text-red-500">Une erreur est survenue lors de la mise à jour de la notification</p>
                    </div>
                )
            }
        </div>
    )
}

const NotificationNewEmployeeRequest = ({ notification }: { notification: any }) => {

    const naviate = useNavigate();
    const { setModale } = useModaleStore();
    const { updateNotification } = useNotificationStore();
    const { mutateAsync: updateInvitation, isError, isPending } = useMutator<any>("/community/team/invitation/" + notification.invitation_id);

    const handleGoToCommunity = () => {
        naviate(`/${notification.community_id}`);
        setModale(false, '', '')
    }

    const handleSendResponse = async (response: string) => {
        await updateInvitation({ response });
        updateNotification(notification.id, { ...notification, is_viewed: true });
    }

    return (
        <div className="w-full p-3 rounded-lg transition-all flex flex-col items-start justify-start gap-4">
            <div className="w-full flex items-center justify-center gap-6">
                <div className="w-9 h-9 min-w-9 min-h-9 rounded relative border">
                    <img src={`${Config.CDN_HOST}community%2F${notification.community_id}%2Fbg`} alt="Commu" className="w-full h-full object-cover rounded" />
                    <div className="w-5 h-5 min-w-5 min-h-5 rounded-full bg-center bg-cover overflow-hidden absolute bottom-[-5px] right-[-5px]"
                        style={{
                            backgroundImage: `url('${Config.CDN_HOST}user%2F${notification.notifier_uuid}')`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover'
                        }}
                    />
                </div>
                <div className="w-webkit">
                    <div className="w-full flex items-center justify-between">
                        <p className="text-md">{notification.title}</p>
                        <p className="text-sm font-light text-gray-500">{timeSince(notification.created_at)}</p>
                    </div>
                    <p className="font-light text-sm">{notification.description.replace("{Sender}", "Albaninho")}</p>
                </div>
            </div>
            {
                !notification.is_viewed && (
                    <div className="flex items-center justify-center w-full gap-2">
                        <AnimatedButton onClick={handleGoToCommunity} className="w-full border rounded-tiny hover:bg-gray-200 w-full text-sm font-light py-2 transition-all">Voir la communauté</AnimatedButton>
                        {
                            !isPending && !isError ? (
                                <div className="flex items-center justify-center w-full gap-2">
                                    <AnimatedButton onClick={() => handleSendResponse("declined")} className="w-full border border-red rounded-tiny hover:bg-red-100 hover:text-red-700 w-full text-sm font-light py-2 transition-all">Refuser</AnimatedButton>
                                    <AnimatedButton onClick={() => handleSendResponse("confirmed")} className="w-full border border-green rounded-tiny hover:bg-green-100 hover:text-green-700 w-full text-sm font-light py-2 transition-all">Accepter</AnimatedButton>
                                </div>
                            ) : (
                                <div className="w-full h-2 rounded-full bg-gray-100 animate-pulse" />
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

const NotificationEmployeeRequestApproved = ({ notification }: { notification: any }) => {

    const { updateNotification } = useNotificationStore();
    const { mutateAsync: setNotificationViewed, isError, isPending } = useMutator<any>("/notifications/" + notification.id + "/viewed");

    const handleSetNotificationViewed = async () => {
        await setNotificationViewed({});
        updateNotification(notification.id, { ...notification, is_viewed: true });
    }

    return (
        <div className="w-full p-3 rounded-lg transition-all flex flex-col items-start justify-start gap-4">
            <div className="w-full flex items-center justify-center gap-6">
                <div className="w-9 h-9 min-w-9 min-h-9 rounded relative border">
                    <img src={`${Config.CDN_HOST}community%2F${notification.community_id}%2Fbg`} alt="Commu" className="w-full h-full object-cover rounded" />
                    <div className="w-5 h-5 min-w-5 min-h-5 rounded-full bg-center bg-cover overflow-hidden absolute bottom-[-5px] right-[-5px]"
                        style={{
                            backgroundImage: `url('${Config.CDN_HOST}user%2F${notification.notifier_uuid}')`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover'
                        }}
                    />
                </div>
                <div className="w-webkit">
                    <div className="w-full flex items-center justify-between">
                        <div className="flex items-center justify-start gap-2">
                            {!notification.is_viewed && <div className="w-2 h-2 rounded-full bg-red-700" />}
                            <p className="text-md">{notification.title}</p>
                        </div>
                        <p className="text-sm font-light text-gray-500">{timeSince(notification.created_at)}</p>
                    </div>
                    <p className="font-light text-sm">{notification.description.replace("{Sender}", "Albaninho")}</p>
                </div>
            </div>
            {
                !notification.is_viewed && !isPending && (
                    <div className="flex items-center justify-center w-full gap-2">
                        <AnimatedButton onClick={handleSetNotificationViewed} className="w-full border rounded-tiny hover:bg-gray-200 w-full text-sm font-light py-2 transition-all">Marquer comme lu</AnimatedButton>
                    </div>
                )
            }
            {
                isPending && (
                    <div className="w-full h-2 rounded-full bg-gray-100 animate-pulse" />
                )
            }
            {
                isError && (
                    <div className="w-full flex items-center justify-center gap-3 py-2">
                        <p className="text-red-500">Une erreur est survenue lors de la mise à jour de la notification</p>
                    </div>
                )
            }
        </div>
    )
}

export const NotificationDrawer = () => {

    const { isLoading, isError } = useGetUserNotifications({ refetchOnMount: false });
    const { setModale } = useModaleStore();
    const { notifications } = useNotificationStore();

    return (
        <div className="h-full w-full flex flex-col gap-3 p-intermediate items-center justify-start cursor-default overflow-hidden">
            <div className="w-full flex items-center justify-between">
                <p className="font-[500] text-xl">Vos notifications</p>
                <AnimatedButton className="w-8 h-8 rounded-full hover:bg-gray-100 flex items-center justify-center" onClick={() => setModale(false, '', '')}>
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </AnimatedButton>
            </div>
            <div className="flex flex-col w-full items-stretch justify-start overflow-auto hideScrollBar">
                {
                    isLoading && !isError && (
                        Array.from({ length: 7 }).map((_, index) => (
                            <NotificationSkeleton key={index} />
                        ))
                    )
                }
                {
                    isError && (
                        <div className="w-full flex flex-col items-center justify-center gap-3 py-20">
                            <svg className="w-16 h-16 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <p className="text-red-500">Une erreur est survenue lors du chargement des notifications</p>
                        </div>
                    )
                }
                {
                    notifications && notifications.length > 0 ? (notifications.map((notification: any, index: number) => {
                        switch (notification.type_id) {
                            case 1:
                                return <NotificationNewEmployeeRequest notification={notification} key={index} />
                            case 3:
                                return <NotificationNewComment notification={notification} key={index} />
                            case 4:
                                return <NotificationEmployeeRequestApproved notification={notification} key={index} />
                            case 7:
                                return <NotificationMemberRequestApproved notification={notification} key={index} />        
                            default:
                                return null
                        }
                    })) : (
                        <div className="w-full flex flex-col items-center justify-center gap-3 py-20">
                            <svg className="w-16 h-16 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <p className="">Aucune notification pour le moment</p>
                        </div>
                    )
                }
            </div>
        </div>
    )
}