import { useModaleStore } from "stores/modale";
import { LoginForm, RegisterForm } from "components/auth"
import { AnimatedButton } from "components/globals/buttons";
import { useState } from "react";
import { AUTH_MODALE_DEFAULT_PROPS } from "data/modaleDefaultProps";
import { EmailValidationForm } from "components/onboarding/EmailValidationForm";
import { CompleteProfileForm } from "components/onboarding/completeProfileForm";
import { joinCommunityType } from "stores/joinAfterOnboarding";


export const AuthModale = () => {

    const [type, setType] = useState('login');
    const { modaleProps, setModale } = useModaleStore();

    const context = modaleProps?.context || AUTH_MODALE_DEFAULT_PROPS.context;
    const community = modaleProps?.community || {} as joinCommunityType;

    const handleAuthStageChange = (props: any) => {
        
        if (context === "signin" && props?.isEmailVerified && props?.isProfileComplete) {
            setModale(false, "", "", { ...modaleProps })
        }

        if(!props?.isEmailVerified) {
            setType('validateEmail')
            return
        }

        if(!props?.isProfileComplete) {
            setType('completeProfil')
            return 
        }

    }


    return (
        <div className="flex flex-col gap-3 items-center w-full lg:w-[500px] lg:p-6 cursor-auto">
            {
                type === 'login' && (
                    <div className="flex flex-col gap-6 items-center justify-center w-full">
                        <p className="font-bold md:text-3xl text-2xl">{modaleProps?.loginTitle || AUTH_MODALE_DEFAULT_PROPS.loginTitle}</p>
                        <div className="flex flex-col gap-3 items-center justify-center w-full">
                            <LoginForm context={context} displayIn="modale" callbackFunction={handleAuthStageChange} />
                            <AnimatedButton onClick={() => setType('register')} className="w-full bg-grey-100 text-black py-mini rounded-tiny">Créer un compte</AnimatedButton>
                        </div>
                    </div>
                )
            }
            {
                type === 'register' && (
                    <div className="flex flex-col gap-6 items-center justify-center w-full">
                        <p className="font-bold md:text-3xl text-2xl">{modaleProps?.registerTitle || AUTH_MODALE_DEFAULT_PROPS.registerTitle}</p>
                        <div className="flex flex-col gap-3 items-center justify-center w-full">
                            <RegisterForm context={context} community={community} displayIn="modale"  />
                            <AnimatedButton onClick={() => setType('login')} className="w-full bg-grey-100 text-black py-mini rounded-tiny">J'ai déjà un compte</AnimatedButton>
                        </div>
                    </div>
                )
            }
            {
                type === 'validateEmail' && (
                    <div className="flex flex-col gap-6 items-center justify-center w-full">
                        <p className="font-bold md:text-3xl text-2xl">{modaleProps?.registerTitle || AUTH_MODALE_DEFAULT_PROPS.registerTitle}</p>
                        <div className="flex flex-col gap-3 items-center justify-center w-full">
                            <EmailValidationForm context={context} displayIn="modale" callbackFunction={handleAuthStageChange} />
                        </div>
                    </div>
                )
            }
            {
                type === 'completeProfil' && (
                    <div className="flex flex-col gap-6 items-center justify-center w-full">
                        <p className="font-bold md:text-3xl text-2xl">{modaleProps?.registerTitle || AUTH_MODALE_DEFAULT_PROPS.registerTitle}</p>
                        <div className="flex flex-col gap-3 items-center justify-center w-full">
                            <CompleteProfileForm context={context} displayIn="modale" callbackFunction={handleAuthStageChange} />
                        </div>
                    </div>
                )
            }
        </div>
    )
}