import { useCallback, useEffect, useMemo, useState } from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Heading from '@tiptap/extension-heading'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Image from '@tiptap/extension-image'
import { useParams } from 'react-router-dom'
import { useContentById } from '@hooks/queries/content'
import { AnimatedButton } from 'components/globals/buttons'
import { Save, ImagePlus } from 'lucide-react'
import { AdminLayout } from 'layout/AdminLayout'
import { useFileMutator, useMutator } from '@hooks/queries/api'
import { Editor } from '@tiptap/core'
import { generateHTML } from '@tiptap/html'
import { Config } from 'services/config'

interface ContentData {
    title: string;
    content: any;
    [key: string]: any;
}

interface MenuBarProps {
    editor: Editor | null;
    onImageAdd: () => void;
}

const MenuBar = ({ editor, onImageAdd }: MenuBarProps) => {
    if (!editor) return null

    return (
        <div className="flex gap-2 flex-wrap items-center mb-4">
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={`px-3 py-1.5 rounded-md ${editor.isActive('heading', { level: 1 })
                    ? 'bg-gray-200'
                    : 'hover:bg-gray-100'
                    }`}
            >
                H1
            </button>
            <button
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                className={`px-3 py-1.5 rounded-md ${editor.isActive('heading', { level: 2 })
                    ? 'bg-gray-200'
                    : 'hover:bg-gray-100'
                    }`}
            >
                H2
            </button>
            <button
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={`px-3 py-1.5 rounded-md ${editor.isActive('bold') ? 'bg-gray-200' : 'hover:bg-gray-100'
                    }`}
            >
                Bold
            </button>
            <button
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={`px-3 py-1.5 rounded-md ${editor.isActive('italic') ? 'bg-gray-200' : 'hover:bg-gray-100'
                    }`}
            >
                Italic
            </button>
            <button
                onClick={onImageAdd}
                className="px-3 py-1.5 rounded-md hover:bg-gray-100 flex items-center gap-2"
            >
                <ImagePlus className="w-4 h-4" />
                Image
            </button>
        </div>
    )
}

export const AdminContentItem = () => {
    const { communityId, contentId = "0" } = useParams()
    const [title, setTitle] = useState("")

    const { data: contentData, isLoading } = useContentById({
        communityId,
        contentId,
        refetchOnMount: false
    })

    const { mutateAsync: updateContent, isPending: isUpdateLoading } = useMutator<ContentData>(
        `/community/${communityId}/admin/content/${contentId}/update`
    )

    const { mutateAsync: uploadImage } = useFileMutator<any>("/community/" + communityId + "/assets/new");

    // Configuration des extensions commune
    const extensions = [
        Document,
        Paragraph,
        Text,
        Heading.configure({
            levels: [1, 2],
        }),
        Bold,
        Italic,
        Image.configure({
            HTMLAttributes: {
                class: 'max-w-full rounded-lg',
            },
        }),
    ]

    // Simplifions la logique de récupération du contenu initial
    const initialContent = useMemo(() => {
        if (!contentData?.content?.content) return ''

        // Si le contenu est déjà au format HTML
        if (typeof contentData.content.content === 'string') {
            return contentData.content.content
        }

        // Si le contenu est au format JSON TipTap
        try {
            return generateHTML(contentData.content.content, extensions)
        } catch (error) {
            console.error("Error generating HTML:", error)
            return ''
        }
    }, [contentData])

    useEffect(() => {
        if (contentData) {
            setTitle(contentData.title || '')
        }
    }, [contentData])

    const editor = useEditor({
        extensions,
        content: initialContent,
        editorProps: {
            attributes: {
                class: 'prose max-w-none focus:outline-none min-h-[300px]',
            },
        },
    })

    const handleImageAdd = useCallback(async () => {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = 'image/*'

        input.onchange = async () => {
            if (!input.files?.length) return

            const file = input.files[0]
            const formData = new FormData()
            formData.append('file', file)

            try {
                const response = await uploadImage(formData)
                // Log pour voir la structure exacte de la réponse
                console.log('Upload response:', response.path)

                // Si l'URL est dans une propriété différente, ajustez ici
                const imageUrl = Config.CDN_HOST + response.path

                if (imageUrl && editor) {
                    editor.chain().focus().setImage({
                        src: imageUrl,
                        alt: file.name
                    }).run()
                }
            } catch (error) {
                console.error('Failed to upload image:', error)
            }
        }
        input.click()
    }, [editor, uploadImage])

    const handleSave = async () => {
        if (!editor) return

        await updateContent({
            ...(contentData || {}),
            title,
            content: editor.getJSON()
        })
    }

    // Mise à jour du contenu quand contentData change
    useEffect(() => {
        if (editor && initialContent) {
            editor.commands.setContent(initialContent)
        }
    }, [editor, initialContent])

    if (isLoading) return <div>Loading...</div>

    return (
        <AdminLayout>
            <div className="bg-app h-full w-full overflow-hidden">
                <div className="h-full w-full bg-app flex flex-col gap-8 items-center overflow-auto">
                    <div className="sticky top-0 z-50 border-b w-full bg-white">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="py-4 flex items-center justify-between">
                                <h1 className="text-lg font-medium">Edit Article</h1>
                                <AnimatedButton
                                    onClick={handleSave}
                                    disabled={isUpdateLoading}
                                    className="flex items-center gap-2"
                                >
                                    <Save className="w-4 h-4" />
                                    {isUpdateLoading ? 'Saving...' : 'Save'}
                                </AnimatedButton>
                            </div>
                        </div>
                    </div>

                    <div className="w-full max-w-7xl mx-auto p-intermediate">
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Article Title"
                            className="w-full text-4xl font-bold mb-8 bg-transparent border-none outline-none"
                        />

                        <MenuBar editor={editor} onImageAdd={handleImageAdd} />
                        <EditorContent editor={editor} />
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}