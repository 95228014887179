import { useEffect, useCallback } from "react";
import { useActivityStore } from "stores/activityStore";

export const ChannelActivity = ({ channelId }: { channelId: number }) => {
    const updateChannelTimestamp = useActivityStore(state => state.updateChannelTimestamp);
    
    const updateTimestamp = useCallback(() => {
        updateChannelTimestamp(`${channelId}`);
    }, [channelId, updateChannelTimestamp]);

    useEffect(() => {
        updateTimestamp();
        const interval = setInterval(updateTimestamp, 5000);
        return () => clearInterval(interval);
    }, [updateTimestamp]);

    return null;
}