import { useState } from "react";
import { EditableTitleBlockProps } from "../types";
import { Icon } from "assets/icons";

export const TitleBlock: React.FC<EditableTitleBlockProps> = ({ id, content, isAdmin, onUpdate }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [editedContent, setEditedContent] = useState(content);
    const [isHovered, setIsHovered] = useState(false);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
        onUpdate(id, editedContent);
        setIsEditing(false);
    };

    return (
        <div
            className="w-full relative"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {isAdmin && !isEditing && isHovered && (
                <button
                    className="absolute top-0 right-0 bg-gray-500 text-white p-2 rounded"
                    onClick={handleEdit}
                >
                    <Icon type="Edit2" size={15} />
                </button>
            )}

            {isEditing ? (
                <div>
                    <textarea
                        className="w-full p-3 border rounded-md"
                        value={editedContent}
                        onChange={(e) => setEditedContent(e.target.value)}
                        rows={5}
                    />
                    <button
                        className="mt-2 bg-green-500 text-white p-2 rounded-md flex items-center justify-center gap-1 w-full"
                        onClick={handleSave}
                    >
                        <Icon type="ArrowRight" />
                        <span>Valider</span>
                    </button>
                    <hr className="w-full mt-5" />
                </div>
            ) : (
                <h3 className="font-bold whitespace-pre-wrap text-xl">{content}</h3>
            )}
        </div>
    );
};
