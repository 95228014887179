import { useFeaturedCommunitiesAndCreators } from "@hooks/queries/communities";
import { useNavigate } from "react-router-dom";
import { type CreatorType } from "@hooks/queries/communities";
import { AnimatedButton } from "components/globals/buttons";

const CreatorCard = ({ creator }: { creator: CreatorType }) => {

    const navigate = useNavigate();

    const handleClickOnCreator = () => {
        navigate(`/${creator.id}`);
    }

    return (
        <div className="w-full flex flex-col items-start justify-start gap-2">
            <AnimatedButton onClick={handleClickOnCreator}
                className="w-20 h-20 rounded-full border-4 border-red-400 bg-cover bg-center"
                style={{ background: `url(https://pub-388e882330ee44c7b74215d8e0935cb9.r2.dev/user%2F${creator.uuid}) center center / cover` }}
            />
            <div className="w-full flex flex-col items-start justify-start gap-0">
                <p className="w-full text-left text-textGray font-[400] text-sm">{creator.user_name}</p>
                <p className="w-full text-left text-red-400 font-[300] text-xs">{creator.name}</p>
            </div>
        </div>
    )
};

const SkeletonCard = () => (
    <div className="w-full flex flex-col items-start justify-start gap-2">
        <div className="w-20 h-20 rounded-full bg-gray-200 animate-pulse" />
        <div className="w-full flex flex-col items-start justify-start gap-1">
            <div className="w-3/4 h-4 bg-gray-200 rounded animate-pulse" />
            <div className="w-1/2 h-3 bg-gray-200 rounded animate-pulse" />
        </div>
    </div>
);

export const TopsCreators = () => {

    const { data: featured, isLoading: isFeaturedLoading, isError: isFeaturedError } = useFeaturedCommunitiesAndCreators({ refetchOnMount: false });

    return (
        <>
            {isFeaturedLoading && (
                <div className="w-full flex items-center justify-between">
                    {Array.from({ length: 3 }).map((_, index) => (
                        <SkeletonCard key={index} />
                    ))}
                </div>
            )}

            {isFeaturedError && (
                <div className="bg-red-100 rounded-mini border border-red-700 w-full p-3">
                    <p className="text-red-700">Une erreur est survenue</p>
                </div>
            )}

            {!isFeaturedLoading && !isFeaturedError && featured && (
                <div className="w-full flex items-center justify-between">
                    {featured.creators.slice(0, 3).map((creator) => (
                        <CreatorCard key={creator.id} creator={creator} />
                    ))}
                </div>
            )}
        </>
    );
};