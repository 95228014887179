import { create } from 'zustand'

export interface AdminCommunityType {
    communityId: string;
    userRole: number;
    setAdminCommunity: (communityId: string, userRole: number) => void;
}

export const communityIdSelector = (state: AdminCommunityType) => state.communityId;
export const userRoleSelector = (state: AdminCommunityType) => state.userRole;

export const useAdminCommunity = create<AdminCommunityType>(set => ({
    communityId: '',
    userRole : 0,
    setAdminCommunity: (communityId: string, userRole: number) => {
        set({ communityId: communityId, userRole: userRole });
    },
}));
