import { AnimatedButton } from "components/globals/buttons";
import { CallToActionProps } from "../types";

export const CallToActionBlock: React.FC<CallToActionProps> = ({ text, theme, joinCommunity, isWaiting }) => (
    <div className="w-full ">
        <AnimatedButton
            className="w-full text-white py-small rounded-md"
            style={{ backgroundColor: theme?.primaryColor}}
            onClick={joinCommunity}
        >{isWaiting ? "Modifier ma demande" : text}</AnimatedButton>
    </div>
);