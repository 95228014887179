import { useGetAnalytics } from "@hooks/queries/community";

export const MainKpis = ({ communityId }: { communityId?: string }) => {

    const { data, isLoading, isError } = useGetAnalytics({ communityId, refetchOnMount: false });

    return (
        <>
            {
                isLoading ? (
                    <div className="flex flex-col md:flex-row items-stretch md:items-center justify-stretch w-full gap-3">
                        <div className="bg-white shadow w-full p-4 rounded-lg border flex flex-row md:flex-col gap-2 items-center md:items-start justify-between md:justify-start">
                            <p className="text-gray-400 font-light text-sm">Nombre de membres</p>
                            <div className="w-28 bg-gray-100 h-6 rounded" />
                        </div>
                        <div className="bg-white shadow w-full p-4 rounded-lg border flex flex-row md:flex-col gap-2 items-center md:items-start justify-between md:justify-start">
                            <p className="text-gray-400 font-light text-sm">Nombre de messages</p>
                            <div className="w-28 bg-gray-100 h-6 rounded" />
                        </div>
                    </div>
                ) : isError ? (
                    <div className="w-full border border-red-700 bg-red-100 rounded-mini p-mini">
                        <p className="text-red-700 text-center w-full">Une erreur est survenue veuillez réessayer</p>
                    </div>
                ) : (
                    <div className="flex flex-col md:flex-row items-stretch md:items-center justify-stretch w-full gap-3">
                        <div className="bg-white shadow w-full p-4 rounded-lg border flex flex-row md:flex-col gap-2 items-center md:items-start justify-between md:justify-start">
                            <p className="text-gray-400 font-light text-sm">Nombre de membres</p>
                            <p className="text-gray-700 font-bold text-2xl">{data.members_total}</p>
                        </div>
                        <div className="bg-white shadow w-full p-4 rounded-lg border flex flex-row md:flex-col gap-2 items-center md:items-start justify-between md:justify-start">
                            <p className="text-gray-400 font-light text-sm">Nombre de messages</p>
                            <p className="text-gray-700 font-bold text-2xl">{data.posts_total}</p>
                        </div>
                    </div>
                )
            }
        </>
    )
}