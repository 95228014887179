const REVIEW_SCORE = 4.5;
const REVIEW_COUNT = 1200;

const Star = ({ filled }: { filled: boolean }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="28"
        height="28"
        fill={filled ? '#ca8a04' : 'none'}
        stroke="#ca8a04"
        strokeWidth="0.5"
        className="transition-all duration-300"
    >
        <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
    </svg>
);

const StarRating = ({ score }: { score: number }) => {
    const fullStars = Math.floor(score);
    const partialStar = score % 1;

    return (
        <div className="flex items-center">
            {[...Array(5)].map((_, index) => (
                <div key={index} className="relative">
                    <Star filled={index < fullStars} />
                    {index === fullStars && partialStar > 0 && (
                        <div
                            className="absolute top-0 left-0 overflow-hidden"
                            style={{ width: `${partialStar * 100}%` }}
                        >
                            <Star filled={true} />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export const ReviewsBlock = () => {
    return (
        <div className="w-full flex flex-col items-start justify-start bg-gray-100 rounded-md p-4 gap-3">
            <div className="w-full flex flex-col items-start justify-start gap-1">
                <h3 className="text-xl font-semibold">Les avis des membres :</h3>
                <p className="text-sm text-gray-600 font-light">
                    Les membres de la communauté ont la possibilité de mettre un avis, voici la synthèse.
                </p>
            </div>
            <div className="w-full flex items-center justify-start gap-4">
                <StarRating score={REVIEW_SCORE} />
                <div className="flex items-center justify-start gap-1">
                    <p className="text-lg font-semibold text-gray-800">{REVIEW_SCORE.toFixed(1)}</p>
                    <p className="text-lg text-gray-600 font-light">/5</p>
                    <p className="text-lg text-gray-600 font-light">(sur {REVIEW_COUNT} avis)</p>
                </div>
            </div>
            <hr className="w-full border-gray-300" />
            <p className="text-sm text-gray-600 font-light">
                Tous les avis sont vérifiés et certifiés, le créateur n'a pas la possibilité de modifier sa note.
            </p>
        </div>
    );
};

export default ReviewsBlock;