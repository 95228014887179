import { useMessagesConversation } from "@hooks/queries/messages";
import { useConversationsStore } from "stores/conversation";
import { MessagesConversation } from "components/conversation";
import { InputTipTapMessage } from "components/community/feed/tiptap/messageTipTap";
import { useParams } from "react-router-dom";
import { Config } from "services/config";
import { MessageLayout } from "layout/MessageLayout";

// const fake_conversation = [
//     {
//         id: 222,
//         correspondantId: "5853235c-0276-4dab-8ee6-195e3a0227a1",
//         content: {
//             type: "doc",
//             content: [
//                 {
//                     type: "paragraph",
//                     content: [
//                         {
//                             text: "test",
//                             type: "text"
//                         }
//                     ]
//                 }
//             ]
//         },
//         reactions: {},
//         comments: 0,
//         is_pinned: false,
//         created_at: "2024-09-10T22:29:06.857Z",
//         is_comment_disabled: false,
//         user_name: "albaninho",
//         had_user_react: false,
//         user_reacts: null
//     },
// ]

export const MessageConversationPage = () => {

    const { conversationId: userId } = useParams<string>();
    const conversations = useConversationsStore((state: any) => state.conversations);

    const { isLoading, isError } = useMessagesConversation({ userId, refetchOnMount: false });

    if (!userId) return null;

    return (
        <MessageLayout>
            <div className="h-full w-full flex flex-col">
                <div className="flex-1 overflow-hidden relative">
                    <div className={`h-full w-full flex flex-col items-end ${!isLoading && !isError && !conversations[userId] ? "justify-center" : "justify-end"} overflow-auto`}>
                        {isLoading && Array.from({ length: 10 }).map((_, i) => (
                            <ConversationMessageSkeleton isFullWidth={false} key={i} />
                        ))}
                        {!isLoading && !isError && !conversations[userId] && <StartNewConversation userId={userId} />}
                        {!isLoading && !isError && conversations[userId] && (
                            <div className="w-full h-full overflow-auto hideScrollBar bg-app">
                                {conversations[userId]?.map((message: any, i: number) => (
                                    <MessagesConversation key={i} message={message} />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                {userId && <InputTipTapMessage recipientId={userId} />}
            </div>
        </MessageLayout>
    )
}

export const ConversationMessageSkeleton = ({ isFullWidth = true }) => {

    return (
        <div className={`w-full flex flex-col gap-0 relative cursor-pointer animate-pulse`}>
            <div className={`w-full flex gap-3 ${isFullWidth ? "px-none" : "px-small"} py-small`}>
                <div className="">
                    <div className="w-[40px] !h-[40px] rounded-full bg-center bg-cover cursor-pointer bg-gray-100" />
                    <div className="flex w-full lg:h-webkit items-center justify-center">
                        <div className="h-full w-[1.5px] bg-gray-100"></div>
                    </div>
                </div>
                <div className="flex flex-col gap-3 w-full">
                    <div className="flex w-full items-center justify-between">
                        <div className="bg-gray-100 h-4 w-32 rounded" />
                    </div>
                    <div className="w-full flex flex-col gap-1">
                        <div className="bg-gray-100 w-full rounded h-2"></div>
                        <div className="bg-gray-100 w-full rounded h-2"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const StartNewConversation = ({ userId }: { userId: string }) => {

    const url = Config.CDN_HOST + "user%2F" + userId;

    return (
        <div className="w-full flex flex-col items-center justify-center py-large gap-4">
            <img src={url} alt="user" className="w-32 h-32 rounded-full object-fit-cover" />
            <p className="text-3xl">Albaninho</p>
            <p className="text-gray-600 italic font-light">Démarrez une conversation avec Albaninho</p>
        </div>
    )
}