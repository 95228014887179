import { useAuthStore } from "stores/auth";
import { AnimatedButton } from "components/globals/buttons"
import { useGetUserProfile } from "@hooks/queries/users";
import { useState } from "react";
import { UploadProfileImage } from "components/onboarding/uploadProfileImage";
import { Navigate } from "react-router-dom";
import { useMutator } from "@hooks/queries/api";
import { CompleteProfileFormProps } from "components/onboarding/completeProfileForm";

export const UpdateMyProfilePage = () => {

    const { uuid, isLogged } = useAuthStore();
    const { isError: isProfileError, isLoading: isProfileLoading } = useGetUserProfile({ userId: uuid, refetchOnMount: false, enabled: true });

    if (!isLogged) {
        return <Navigate to="/" replace />;
    }
    if (isProfileLoading) return updateProfileSkeleton();
    if (isProfileError) return updateProfileError();

    return (
        <div className="h-full w-full flex flex-col gap-3 items-center px-intermediate py-intermediate">
            <div className="w-full md:w-full md:max-w-[1080px] flex flex-col items-center">
                <div className="w-full flex flex-col gap-5 items-center pb-5">
                    <h1>Modifier mon profil</h1>
                    <UpdateProfileComponent />
                </div>
            </div>
        </div>
    )
}

const UpdateProfileComponent = () => {

    const { uuid } = useAuthStore();
    const { data: profile, refetch } = useGetUserProfile({ userId: uuid, refetchOnMount: false, enabled: true });
    const { mutateAsync: completeProfileRequest, isError, isPending } = useMutator<CompleteProfileFormProps>("/profile/update");
    const [new_username, setNewUsername] = useState("");
    const [new_first_name, setNewFirstName] = useState("");
    const [new_last_name, setNewLastName] = useState("");
    const [new_bio, setNewBio] = useState("");

    const handleUpdateProfile = async (e: any) => {
        e.preventDefault();
        const body = { 
            user_name: new_username || profile.user_name, 
            first_name: new_first_name || profile.first_name, 
            last_name: new_last_name || profile.last_name, 
            bio: new_bio || profile.bio, 
            sexe: profile.sexe 
        };
        await completeProfileRequest(body);
        await refetch();
    }

    return (
        <div className="w-full flex flex-col justify-start gap-6">
            <UploadProfileImage />
            <form className="w-full flex flex-col items-start justify-start gap-3" onSubmit={handleUpdateProfile}>
                <div className="flex flex-col gap-2 items-start justify-start w-full">
                    <p className="text-black text-lg font-bold">Nom d'utilisateur</p>
                    <input type="text" className="w-full h-10 rounded-md border border-gray-300 p-2" defaultValue={profile.user_name} onChange={(e) => setNewUsername(e.target.value)} />
                </div>
                <div className="flex flex-col gap-2 items-start justify-start w-full">
                    <p className="text-black text-lg font-bold">Prénom</p>
                    <input type="text" className="w-full h-10 rounded-md border border-gray-300 p-2" defaultValue={profile.first_name} onChange={(e) => setNewFirstName(e.target.value)}/>
                </div>
                <div className="flex flex-col gap-2 items-start justify-start w-full">
                    <p className="text-black text-lg font-bold">Nom</p>
                    <input type="text" className="w-full h-10 rounded-md border border-gray-300 p-2" defaultValue={profile.last_name} onChange={(e) => setNewLastName(e.target.value)}/>
                </div>
                <div className="flex flex-col gap-2 items-start justify-start w-full">
                    <p className="text-black text-lg font-bold">Bio</p>
                    <textarea className="w-full h-20 rounded-md border border-gray-300 p-2" defaultValue={profile.bio} onChange={(e) => setNewBio(e.target.value)}/>
                </div>
                {isError && <div className="w-full bg-red-200 text-red-800 p-3 rounded-md">Une erreur est survenue, veuillez réessayer plus tard</div>}
                <AnimatedButton
                    className="w-full bg-darkBlue text-white rounded-lg flex items-center justify-center py-3 hover:bg-gray-300 transition"
                    type="submit"
                >
                    {isPending ? "Chargement..." : "Enregistrer"}
                </AnimatedButton>
            </form>
        </div>
    )
}

const updateProfileSkeleton = () => {
    return (
        <div>loading...</div>
    )
}

const updateProfileError = () => {
    return (
        <div>error...</div>
    )
}