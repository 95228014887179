import { useCallback } from 'react';
import { useLandingStore } from "stores/landing";
import { AnimatedButton } from "components/globals/buttons";
import { Reorder } from "framer-motion";
import { GripVertical, Lock } from 'lucide-react';
import { BlockProps } from 'components/community/landing/types';
import { useModaleStore } from 'stores/modale';

export const BuilderSidebar = () => {
    const { blocks, setBlocks, addNewBlock } = useLandingStore();
    const { setModale } = useModaleStore();

    const getBlockName = (block: BlockProps) => {
        switch (block.type) {
            case 'header':
                return `Header`;
            case 'text':
                return `"${block.content}"`;
            case 'image':
                return `Image: ${block.alt}`;
            case 'gallery':
                return `Bloc d'images`;
            case 'stats':
                return `Stats de la communauté`;
            case 'checklist':
                return `Checklist`;
            case 'cta':
                return `Call to action`;
            case 'title':
                return `Title: ${block.content}`;
            case 'joinCommunity':
                return `CTA principal`;
            case 'reviews':
                return `Avis des membres`;
            default:
                return null;
        }
    };

    const headerBlock = blocks.find(block => block.type === 'header');
    const joinCommunityBlock = blocks.find(block => block.type === 'joinCommunity');
    const reorderableBlocks = blocks.filter(block => 
        block.type !== 'header' && 
        block.type !== 'joinCommunity' && 
        getBlockName(block) !== null
    );

    const handleReorder = (reorderedBlocks : any) => {
        const newBlocks = [
            ...(headerBlock ? [headerBlock] : []),
            ...reorderedBlocks,
            ...(joinCommunityBlock ? [joinCommunityBlock] : [])
        ];
        setBlocks(newBlocks);
    };

    const handleTriggerModale = useCallback(() => {
        setModale(true, 'AddAdminLandingBlock', 'modale', { addNewBlock: addNewBlock });
    }, [addNewBlock, setModale]);

    const renderFixedBlock = (block : BlockProps) => (
        <div key={block.id} className="px-2 py-2.5 bg-white border border-gray-200 rounded-md shadow-sm flex items-center">
            <div className="mr-2">
                <Lock size={20} color='gray' />
            </div>
            <span className="flex-grow select-none font-light text-black text-sm">{getBlockName(block)}</span>
        </div>
    );

    return (
        <div className="flex flex-col gap-3 w-full">
            <div className="w-full flex flex-col gap-2">
                <div className="w-full flex flex-col gap-1 bg-gray-200 rounded-md p-2">
                    {headerBlock && renderFixedBlock(headerBlock)}
                    <Reorder.Group axis="y" values={reorderableBlocks} onReorder={handleReorder} className='w-full flex flex-col gap-1'>
                        {reorderableBlocks.map((block) => (
                            <Reorder.Item key={block.id} value={block}>
                                <div className="px-2 py-2.5 bg-white border border-gray-200 rounded-md shadow-sm flex items-center">
                                    <div className="cursor-grab active:cursor-grabbing mr-2 hover:bg-gray-200 rounded">
                                        <GripVertical size={16} color='gray' />
                                    </div>
                                    <span className="flex-grow select-none font-light text-black text-sm">{getBlockName(block)}</span>
                                </div>
                            </Reorder.Item>
                        ))}
                    </Reorder.Group>
                    {joinCommunityBlock && renderFixedBlock(joinCommunityBlock)}
                </div>

                <AnimatedButton onClick={handleTriggerModale} className='w-full py-2.5 flex items-center justify-center font-[300] border border-0.5 rounded-lg transition-all bg-gray-200 border-gray-200 hover:bg-black hover:border hover:border-0.5 hover:border-black hover:text-white'>
                    <span className="text-sm">Ajouter un bloc</span>
                </AnimatedButton>
            </div>
        </div>
    );
};