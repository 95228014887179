import CharacterCount from '@tiptap/extension-character-count'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import { EditorContent, useEditor } from '@tiptap/react'
import { useMemo, useEffect, useCallback, useRef } from 'react'
import { generateHTML } from '@tiptap/html'
import { Parser } from "html-to-react"
import { useAboutStore } from 'stores/about'

const limit = 280

export const TextBlock = ({ isAdmin }: { isAdmin: boolean }) => {
    const { content, setContent } = useAboutStore()
    const editorRef = useRef(null)

    const output = useMemo(() => {
        if (!content || !content.type) {
            return ''
        }
        try {
            return generateHTML(content, [Document, Paragraph, Text])
        } catch (error) {
            console.error("Error generating HTML:", error)
            return ''
        }
    }, [content])

    const onUpdate = useCallback(({ editor } : { editor : any }) => {
        const editorContent = editor?.getJSON()
        if (editorContent && JSON.stringify(editorContent) !== JSON.stringify(content)) {
            setContent(editorContent)
        }
    }, [content, setContent])

    const editor = useEditor({
        extensions: [
            Document,
            Paragraph,
            Text,
            CharacterCount.configure({ limit }),
        ],
        editorProps: {
            attributes: {
                class: 'w-full !px-4 py-3 !rounded-md border bg-white',
            },
        },
        content: output,
        editable: isAdmin,
        onUpdate,
    })

    useEffect(() => {
        if (editor && !editor.isDestroyed) {
            editor.setEditable(isAdmin)
        }
    }, [editor, isAdmin])

    useEffect(() => {
        if (editor && !editor.isDestroyed && output !== editor.getHTML()) {
            editor.commands.setContent(output)
        }
    }, [editor, output])

    if (!content || !content.type) {
        return null
    }

    return (
        <div className="w-full flex flex-col items-start justify-start">
            {isAdmin ? (
                <div className='w-full flex flex-col items-center justify-center editorParentBox'>
                    <EditorContent editor={editor} ref={editorRef} />
                </div>
            ) : (
                <div className="font-light text-left">
                    {output ? Parser().parse(output) : null}
                </div>
            )}
        </div>
    )
}

export const TextBlockSkeleton = () => {
    return (
        <div className="w-full flex flex-col items-start justify-start gap-2">
            <div className="w-full h-5 bg-gray-200 animate-pulse rounded-md" />
            <div className="w-full h-5 bg-gray-200 animate-pulse rounded-md" />
            <div className="w-full h-5 bg-gray-200 animate-pulse rounded-md" />
            <div className="w-full h-5 bg-gray-200 animate-pulse rounded-md" />
        </div>
    )
}