import { useMutator } from "@hooks/queries/api"
import { AnimatedButton } from "components/globals/buttons"
import { useModaleStore } from "stores/modale"

export const CreateContentModale = () => {

    const { modaleProps, setModale } = useModaleStore()
    const { mutateAsync: createContent } = useMutator<any>(`/community/${modaleProps.communityId}/admin/content/new`)

    const handleSubmit = async () => {
        try {
            await createContent({
                title: "New content",
                content_type: 1,
                visibility: "public",
                status: "draft",
                content : null,
            })
            setModale(false, "", "", { ...modaleProps })
        } catch (error) {
            console.error("Error creating content:", error)
        }
    }

    return (
        <div>
            CreateContentModale
            <AnimatedButton onClick={handleSubmit} className="bg-white hover:bg-gray-100 border border-black text-black py-nano rounded-micro w-full text-center font-[400] text-sm">Créer</AnimatedButton>
        </div>
    )
}