import { useModaleStore } from "stores/modale";
import { Icon } from "assets/icons"
import { AnimatedButton } from "components/globals/buttons";
import { useGetUserNotifications } from "@hooks/queries/notifications";
import { useAuthStore } from "stores/auth";
import { useNotificationStore } from "stores/notifications";
import { useGetActivities } from "@hooks/queries/activity";
import { useActivityStore } from "stores/activityStore";
import { useEffect } from "react";

export const NotificationTopbar = () => {

    const { setModale } = useModaleStore();
    const { setApiResponse } = useActivityStore();
    const { isLogged } = useAuthStore();

    useGetUserNotifications({ refetchOnMount: false, enabled: isLogged });
    const { hasUnviewedNotifications } = useNotificationStore();

    const openModale = () => {
        setModale(true, 'NotificationDrawer', 'drawer');
    };

    const { data, isLoading, isError } = useGetActivities({ refetchOnMount: false, enabled: isLogged });

    useEffect(() => {
        if (data) {
            setApiResponse(data);
        }
    }, [setApiResponse, data, isLoading, isError]);

    if (!isLogged) return null;

    return (
        <AnimatedButton
            className="relative"
            onClick={openModale}
        >
            {
                hasUnviewedNotifications() && (
                    <div className="absolute w-3 h-3 rounded-full bg-red-200 top-[-5px] right-[-5px] flex items-center justify-center animate-pulse">
                        <div className="w-1.5 h-1.5 rounded-full bg-red-700" />
                    </div>
                )
            }
            <Icon type="Notification" size={20} color="black" />
        </AnimatedButton>
    )
}