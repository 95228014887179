import { Config } from "services/config";
import { HeroBlockProps } from "../types";
import { Icon } from "assets/icons";
import { useCommunityById } from "@hooks/queries/community";

const HeroBlockSkeleton = ({ communityId }: { communityId: string }) => {

    const background_url = `${Config.CDN_HOST}community%2F${communityId}%2Fbg`;

    return (
        <div className="w-full flex flex-col items-start justify-start gap-3">
            <div className="w-full h-[350px] rounded-lg lg:rounded-xl flex items-end justify-start relative" style={{ background: `url(${background_url}) center center / cover` }}>
                <div className="w-full h-[350px] absolute z-10 rounded-lg lg:rounded-xl" style={{ background: "linear-gradient(12deg, rgba(9,9,121,1) 0%, rgba(255,255,255,0) 90%)" }} />
                <div className="flex flex-col gap-1 items-start z-20 p-big text-white">
                    <div className="h-6 w-30 bg-gray-100 rounded-md animate-pulse" />
                </div>
            </div>
            <div className="w-full flex items-center justify-between">
                <div className="flex items-center justify-center gap-3">
                    <div className="h-12 w-12 bg-gray-100 rounded-full animate-pulse" />
                    <div className="h-4 w-24 bg-gray-100 rounded animate-pulse" />
                </div>
                <div className="flex items-center justify-end gap-2">
                    <div className="h-12 w-12 bg-gray-100 rounded-full animate-pulse" />
                    <div className="h-4 w-24 bg-gray-100 rounded animate-pulse" />
                </div>
            </div>
        </div>
    )
}

export const HeroBlock: React.FC<HeroBlockProps> = ({ communityId }) => {

    const { data: communityData, isLoading: isGetLoading } = useCommunityById({ communityId, refetchOnMount: false });
    const background_url = `${Config.CDN_HOST}community%2F${communityId}%2Fbg`;
    const ownerUrl = `${Config.CDN_HOST}user%2F${communityData?.owner_uuid || ''}`;

    if (isGetLoading) {
        return <HeroBlockSkeleton communityId={communityId || ""} />;
    }

    return (
        <div className="w-full flex flex-col items-start justify-start gap-3">
            <div className="w-full h-[350px] rounded-lg lg:rounded-xl flex items-end justify-start relative" style={{ background: `url(${background_url}) center center / cover` }}>
                <div className="w-full h-[350px] absolute z-10 rounded-lg lg:rounded-xl" style={{ background: "linear-gradient(12deg, rgba(9,9,121,1) 0%, rgba(255,255,255,0) 90%)" }} />
                <div className="flex flex-col gap-1 items-start z-20 p-big text-white">
                    <h1 className="text-4xl font-bold">{communityData?.name}</h1>
                </div>
            </div>
            <div className="w-full flex items-center justify-between">
                <div className="flex items-center justify-center gap-3">
                    <img src={ownerUrl} alt={communityData?.owner_name} className="w-12 h-12 rounded-full object-fit-cover" />
                    <p className="text-xl">{communityData?.owner_name}</p>
                </div>
                <div className="flex items-center justify-end gap-2">
                    {
                        communityData?.type_id === 2 ? <Icon type="Lock" size={26} color={'#6B7280'} />
                            : communityData?.type_id === 1 ? <Icon type="Unlock" size={26} color={'#6B7280'} />
                                : <Icon type="Money" size={26} color={'#6B7280'} />
                    }
                    <p className="font-semibold text-lg text-gray-500">Communauté <span>{communityData?.type_id === 2 ? "Privée" : communityData?.type_id === 1 ? "Publique" : "Payante"}</span></p>
                </div>
            </div>
        </div>
    )
};