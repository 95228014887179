import { ChannelSidebar, type SidebarProps } from "./channels";


export const Sidebar = ({ communityId } : SidebarProps) => {

    return (
        <div className="flex items-center relative z-50">
            <ChannelSidebar communityId={communityId} />
        </div>
    );
}

