import React from 'react';
import { UserItem } from 'components/users/userItem';
import { UserItemSkeleton } from 'components/users/userItemSkeleton';
import { UserType } from 'interfaces/users';

type User = UserType;
type SearchData = User[]

interface UserSearchResultsProps {
    data: SearchData;
    previousData: SearchData;
    isLoading: boolean;
    isError: boolean;
    searchTerm: string;
    setStep: (step: number) => void;
    userSelected: (user: UserType) => void;
}

const MessageDisplay: React.FC<{ icon: React.ReactNode; message: string }> = ({ icon, message }) => (
    <div className="flex flex-col items-center justify-center h-72">
        {icon}
        <p className="mt-4 text-gray-500 text-center md:max-w-72">{message}</p>
    </div>
);

const NoResults: React.FC = () => (
    <MessageDisplay
        icon={
            <svg className="w-16 h-16 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        }
        message="Aucun résultat"
    />
);

const ErrorMessage: React.FC = () => (
    <MessageDisplay
        icon={
            <svg className="w-16 h-16 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        }
        message="Une erreur est survenue. Veuillez réessayer."
    />
);

const InitialMessage: React.FC = () => (
    <MessageDisplay
        icon={
            <svg className="w-16 h-16 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
        }
        message="Entrez au moins 3 caractères pour lancer la recherche"
    />
);

export const UserSearchResults: React.FC<UserSearchResultsProps> = ({ data, previousData, isLoading, isError, searchTerm, setStep, userSelected }) => {
    const currentData = data || previousData || [];

    return (
        <div className="w-full">
            <div className="mt-4">
                {isError ? (
                    <ErrorMessage />
                ) : searchTerm.trim().length <= 2 ? (
                    <InitialMessage />
                ) : currentData?.length > 0 || isLoading ? (
                    <div className='w-full flex flex-col items-start justify-start gap-3'>
                        {currentData?.map((user) => (
                            <UserItem key={user.uuid} user={user} setStep={setStep} userSelected={userSelected}/>
                        ))}
                        {isLoading && <UserItemSkeleton />}
                    </div>
                ) : (
                    <NoResults />
                )}
            </div>
        </div>
    );
};