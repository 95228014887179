import { useAllEmployee } from "@hooks/queries/employee"
import { timeSince } from "services/timeSince";
import { AdminLayout } from "layout/AdminLayout"
import { useParams } from "react-router-dom";
import { Config } from "services/config";
import { AnimatedButton } from "components/globals/buttons";
import { Icon } from "assets/icons";
import { useBreakpointContext } from "context/BreakpointContext";
import { useState } from "react";
import { useModaleStore } from "stores/modale";

export const AdminEmployeesPage = () => {

    const { communityId } = useParams();
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);

    const { isMobile } = useBreakpointContext();
    const { data, isLoading, isError, refetch } = useAllEmployee({ communityId, refetchOnMount: false })
    const { setModale } = useModaleStore();


    const handleCreateNewEmployee = () => {
        setModale(true, "SearchUsersModale", "modale", { communityId: communityId, refetch: refetch });
    }

    const handleOpenEmployeeSettings = (employee:any) => {
        setModale(true, "EmployeeSettings", "modale", { employee: employee, refetch: refetch });
    }

    return (
        <AdminLayout>
            <div className="bg-app h-full w-full overflow-hidden">
                <div className="h-full w-full bg-app flex flex-col gap-8 items-center p-intermediate overflow-auto">
                    <div className="w-full flex flex-col gap-6">
                        <div className="flex justify-between items-start">
                            <div className="flex flex-col gap-1">
                                <p className="font-light text-sm opacity-80">Ma communauté &gt; Ma team</p>
                                <h1 className="text-left w-full text-xl">Ma team complète</h1>
                            </div>
                            <AnimatedButton className="bg-black py-tiny px-intermediate rounded-tiny text-white flex items-center justify-center gap-2" onClick={handleCreateNewEmployee}>
                                <Icon type="Add" size={18} color={"white"} />
                                {!isMobile && <span>Inviter un membre</span>}
                            </AnimatedButton>
                        </div>
                        <div className="flex flex-col items-center justify-between border p-3 rounded-lg gap-3">
                            <div className="flex items-center justify-between w-full">
                                <p className="text-gray-400 font-light">Filtrer mes membres</p>
                                <AnimatedButton className="bg-gray-100 rounded-lg p-2" onClick={() => setIsFiltersOpen(!isFiltersOpen)}>
                                    {!isFiltersOpen ? <Icon type="ArrowSquareDown" size={20} /> : <Icon type="ArrowSquareUp" size={20} />}
                                </AnimatedButton>
                            </div>
                            {
                                isFiltersOpen && (
                                    <div className="w-full flex flex-col gap-4 items-center justify-start">
                                        <div className="w-full flex flex-col gap-2">
                                            <select className="bg-gray-100 rounded-lg p-2 w-full">
                                                <option value="all">Tous</option>
                                                <option value="active">Actifs</option>
                                                <option value="inactive">Inactifs</option>
                                            </select>
                                            <input type="text" placeholder="Rechercher un membre" className="w-full bg-gray-100 rounded-lg py-2 px-4" />
                                        </div>
                                        <AnimatedButton className="bg-gray-200 hover:bg-gray-300 rounded-lg py-2.5 w-full">Rechercher</AnimatedButton>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <hr className="w-full" />
                    <div className="w-full flex flex-col items-center justify-start gap-2">
                        {
                            isLoading && (
                                <div className="w-full flex flex-col gap-2">
                                    {Array.from({ length: 4 }).map((_, index) => (
                                        <div className="grid grid-cols-12 gap-3 h-12 items-center bg-gray-100 px-5 py-3 rounded" key={index} />
                                    ))}
                                </div>
                            )
                        }
                        {
                            isError && <div>Une erreur est survenue</div>
                        }
                        {
                            data?.length === 0 ? (
                                <div>Aucun membre trouvé</div>
                            ) : (
                                data?.map((employee, index) => {

                                    const url = Config.CDN_HOST + "user%2F" + employee.member_uuid;

                                    return (
                                        <div className={`w-full flex items-center justify-between border ${employee.status === "waiting" ? "border-blue-400" : employee.status === "confirmed" ? "border-green-400" : employee.status === "declined" ? "border-red-400" : ""} p-3 rounded-lg`} key={index}>
                                            <div className="flex gap-2 items-center justify-start">
                                                <img src={url} className="w-9 h-9 rounded-full bg-blue-100 object-fit-cover" />
                                                <div className="flex flex-col gap-0 items-start justify-start">
                                                    <div className="flex gap-2 items-center justify-start">
                                                        <span>{employee.first_name} {employee.last_name}</span>
                                                        {employee.status === "waiting" && <div className="w-2 h-2 rounded-full bg-blue-500" />}
                                                        {employee.status === "declined" && <div className="w-2 h-2 rounded-full bg-red-500" />}
                                                        {employee.status === "confirmed" && <div className="w-2 h-2 rounded-full bg-green-500" />}
                                                        { !isMobile && employee.status === "waiting" && <span className="py-0.5 px-2 bg-gray-100 text-gray-500 font-light rounded-md">(en attente)</span>}
                                                    </div>
                                                    <span className="text-sm text-gray-500">{employee.role_id === 1 ? "Propriétaire" : employee.role_id === 2 ? "Administrateur" : employee.role_id === 3 ? "Manager" : employee.role_id === 4 ? "Modérateur" : ""}</span>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-end gap-2 font-light text-gray-400 text-sm">
                                                <p className="hidden md:block">Dans la team depuis : </p>
                                                <span>{timeSince(employee.created_at)}</span>
                                                <div className="bg-gray-200 rounded-md p-1 cursor-pointer" onClick={() => handleOpenEmployeeSettings(employee)}>
                                                    <Icon type="More" size={20} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }))
                        }
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}