import { useTopbarMobileData } from "stores/topbarMobileData";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//import { useAuthStore } from "stores/auth";
import { Config } from "services/config";
import { AnimatedButton } from "components/globals/buttons";
import {
    useGetUserProfile,
    useGetUserCommunityCount,
    useGetUserFollowingStatus,
    useGetUserFollwerCount,
    useGetUserFollwerSimilar,
    useGetUserCommunities,
    useGetUserPosts
} from "@hooks/queries/users";
import { useAuthStore } from "stores/auth";
import { useMutator } from "@hooks/queries/api";
import { CommunityProfileTab, CommunityProfileTabError, CommunityProfileTabSkeleton, DiscussionProfileTab, DiscussionProfileTabError, DiscussionProfileTabSkeleton } from 'components/profile'
//import { useModaleStore } from "stores/modale";


const profileMenu = [
    {
        key: "community",
        label: "Communautés",
    },
    {
        key: "discussions",
        label: "Discussions",
    },
]

export const ProfilePage = () => {

    const { userId } = useParams<{ userId: string }>();
    if (!userId) return null;

    const { setTopbarMobileData } = useTopbarMobileData();
    const isLogged = useAuthStore(state => state.isLogged)
    const [selectedMenu, setSelectedMenu] = useState("community");
    const navigate = useNavigate();
    //const { setModale } = useModaleStore();

    const { data: profile, isError: isProfileError, isLoading: isProfileLoading } = useGetUserProfile({ userId, refetchOnMount: false });
    const { data: followerCount, isError: isFollowerCountError, isLoading: isFollowerCountLoading, refetch: refetchFollowerCount } = useGetUserFollwerCount({ userId, refetchOnMount: false });
    const { data: communityCount, isError: isCommunityCountError, isLoading: isCommunityCountLoading } = useGetUserCommunityCount({ userId, refetchOnMount: false });
    const { data: followingStatus, isError: isFollowingStatusError, isLoading: isFollowingStatusLoading, refetch: refetchStatus, isFetching: isFollowingStatusFetching } = useGetUserFollowingStatus({ userId, refetchOnMount: false });
    const { data: followerSimilar, isError: isFollowerSimilarError, isLoading: isFollowerSimilarLoading } = useGetUserFollwerSimilar({ userId, refetchOnMount: false });
    const { data: communities, isError: isCommunitiesError, isLoading: isCommunitiesLoading } = useGetUserCommunities({ userId, refetchOnMount: false });
    const { data: posts, isError: isPostsError, isLoading: isPostsLoading } = useGetUserPosts({ userId, refetchOnMount: false });


    const { mutateAsync: followUser, isError: isFollowUserError, isPending: isFollowUserLoading } = useMutator<any>("/user/" + userId + "/follow");
    const { mutateAsync: unfollowUser, isError: isUnfollowUserError, isPending: isUnfollowUserLoading } = useMutator<any>("/user/" + userId + "/unfollow");

    const handleFollowUser = async () => {
        await followUser({});
        refetchStatus();
        refetchFollowerCount();
    }

    const handleUnfollowUser = async () => {
        await unfollowUser({});
        refetchStatus();
        refetchFollowerCount();
    }

    const handleChangeTab = (tab: string) => {
        setSelectedMenu(tab);
    }

    const handleUpdateProfile = () => {
        navigate("/update-profile");
        //setModale(true, 'updateProfileModal', 'drawer', {});
    }

    useEffect(() => {
        setTopbarMobileData({ pageName: 'Profil', showTopbar: true, communityName: 'Communautés', withGoBack: true });
    }, []);

    const url = Config.CDN_HOST + "user%2F" + userId;

    return (
        <div className="h-full w-full flex flex-col gap-3 items-center px-intermediate py-intermediate">
            {
                isProfileError ? (
                    <div className="w-full bg-red-100 border border-red-500 text-red-500 rounded-lg">
                        <p className="text-red-500">Une erreur est survenue</p>
                    </div>
                ) : (
                    <div className="w-full md:w-full md:max-w-[1080px] flex flex-col items-center">
                        <div className="w-full flex flex-col gap-5 items-center pb-5">
                            <div className="w-full flex items-center justify-center gap-4">
                                <img src={url} alt="" className="w-20 h-20 rounded-full" />
                                {
                                    isFollowerCountError || isCommunityCountError ? (
                                        <div className="w-full bg-red-100 border border-red-500">
                                            <p className="text-red-500">Une erreur est survenue</p>
                                        </div>
                                    ) : (
                                        <div className="w-full flex gap-2 items-start justify-start">
                                            <div className="flex flex-col gap-1 w-full items-center justify-center">
                                                {
                                                    isCommunityCountLoading ? (
                                                        <div className="bg-gray-100 animate-pulse h-8 w-12 rounded-lg" />
                                                    ) : (
                                                        <p className="text-lg font-[500]">{communityCount.count}</p>
                                                    )
                                                }
                                                <p className="text-sm font-light">commus</p>
                                            </div>
                                            <div className="flex flex-col gap-1 w-full items-center justify-center">
                                                {
                                                    isFollowerCountLoading ? (
                                                        <div className="bg-gray-100 animate-pulse h-8 w-12 rounded-lg" />
                                                    ) : (
                                                        <p className="text-lg font-[500]">{followerCount.followers}</p>
                                                    )
                                                }
                                                <p className="text-sm font-light">followers</p>
                                            </div>
                                            <div className="flex flex-col gap-1 w-full items-center justify-center">
                                                {
                                                    isFollowerCountLoading ? (
                                                        <div className="bg-gray-100 animate-pulse h-8 w-12 rounded-lg" />
                                                    ) : (
                                                        <p className="text-lg font-[500]">{followerCount.followed}</p>
                                                    )
                                                }
                                                <p className="text-sm font-light">suivi(e)s</p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="w-full flex flex-col gap-3 items-start justify-start">
                                <div className="flex items-center gap-2 justify-start">
                                    {
                                        isProfileLoading ? (
                                            <div className="bg-gray-100 animate-pulse h-6 w-28 rounded-lg" />
                                        ) : (
                                            <p className="">{profile.first_name} {profile.last_name}</p>
                                        )
                                    }
                                    <div className="bg-gray-100 rounded-full px-4 py-0.5 text-base flex items-center justify-center gap-0.5 font-[400]">
                                        @
                                        {
                                            isProfileLoading ? (
                                                <div className="bg-gray-200 animate-pulse h-5 w-20 rounded-lg" />
                                            ) : (
                                                <span className="text-base font-[500]">{profile.user_name}</span>
                                            )
                                        }
                                    </div>
                                </div>
                                {
                                    isProfileLoading ? (
                                        <div className="bg-gray-200 animate-pulse h-5 w-full rounded-lg" />
                                    ) : (
                                        <p className="font-light">{profile.bio}</p>
                                    )
                                }
                                <div className="flex items-center gap-2 cursor-pointer w-max">
                                    <div className="flex -space-x-2">
                                        <div className="w-5 h-5 rounded-full bg-cover bg-center" style={{ backgroundImage: "url(/img/memojis/memoji1.png)" }} />
                                        <div className="w-5 h-5 rounded-full bg-cover bg-center" style={{ backgroundImage: "url(/img/memojis/memoji2.png)" }} />
                                        <div className="w-5 h-5 rounded-full bg-cover bg-center" style={{ backgroundImage: "url(/img/memojis/memoji3.png)" }} />
                                    </div>
                                    {
                                        isLogged && !isFollowerSimilarError && (
                                            <div className="flex items-center gap-1 w-full">
                                                {
                                                    isFollowerSimilarLoading ? (
                                                        <div className="bg-gray-200 animate-pulse h-5 w-32 rounded-lg" />
                                                    ) : (
                                                        <span className="text-grey-600 text-sm">Suivi(e) par {followerSimilar.count} de vos ami(e)s</span>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            {
                                isFollowingStatusError ? (
                                    <div className="flex w-full gap-2 items-center justify-center">
                                        <AnimatedButton className="bg-blue-500 hover:bg-blue-600 border border-black text-white py-nano rounded-micro w-full text-center">S'abonner</AnimatedButton>
                                        <AnimatedButton className="bg-white hover:bg-gray-100 border border-black text-black py-nano rounded-micro w-full text-center" onClick={() => navigate("/messages/" + userId)}>Message</AnimatedButton>
                                    </div>
                                ) : (
                                    <div className="flex w-full gap-2 items-center justify-center">
                                        {
                                            isFollowingStatusLoading ? (
                                                <div className="bg-gray-100 animate-pulse h-10 w-full rounded-lg" />
                                            ) : followingStatus.isMe ? (
                                                <AnimatedButton className="bg-blue-500 hover:bg-blue-600 border border-black text-white py-nano rounded-micro w-full text-center" onClick={handleUpdateProfile} >Modifier mon profil</AnimatedButton>
                                            ) : followingStatus.alreadyFollowing ? (
                                                <AnimatedButton className="bg-blue-500 hover:bg-blue-600 border border-black text-white py-nano rounded-micro w-full text-center" onClick={handleUnfollowUser}>{isUnfollowUserLoading || isFollowingStatusFetching ? "..." : "Se désabonner"}</AnimatedButton>
                                            ) : (
                                                <AnimatedButton className="bg-blue-500 hover:bg-blue-600 border border-black text-white py-nano rounded-micro w-full text-center" onClick={handleFollowUser}>{isFollowUserLoading || isFollowingStatusFetching ? "..." : "S'abonner"}</AnimatedButton>
                                            )
                                        }
                                        {
                                            isFollowingStatusLoading ? (
                                                <div className="bg-gray-100 animate-pulse h-10 w-full rounded-lg" />
                                            ) : !followingStatus.isMe && (
                                                <AnimatedButton className="bg-white hover:bg-gray-100 border border-black text-black py-nano rounded-micro w-full text-center" onClick={() => navigate("/messages/" + userId)}>Message</AnimatedButton>
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                isFollowUserError || isUnfollowUserError && (
                                    <div className="w-full bg-red-100 border border-red-500 text-red-500 rounded-lg">
                                        <p className="text-red-500">Une erreur est survenue</p>
                                    </div>
                                )
                            }
                        </div>
                        <div className="w-screen h-max overflow-y-hidden">
                            <div className="w-full overflow-auto flex items-center md:justify-center px-3 border-b">
                                {
                                    profileMenu.map(({ key, label }) => (
                                        <div key={key} className="h-max">
                                            <div className={`w-full flex gap-2 items-center justify-start px-small py-mini w-max cursor-pointer ${key === selectedMenu ? "border-b-2" : ""}`} onClick={() => handleChangeTab(key)}>
                                                <p className={`text-base ${key === selectedMenu ? "font-[700]" : "font-[300]"}`}>{label}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className="w-full">
                            {selectedMenu === "discussions" && (
                                <div className="w-full flex flex-col gap-3 items-center justify-start">
                                    {
                                        isPostsLoading ? <DiscussionProfileTabSkeleton />
                                            : isPostsError ? <DiscussionProfileTabError />
                                                : <DiscussionProfileTab posts={posts} />
                                    }
                                </div>
                            )}
                            {selectedMenu === "community" && (
                                <div className="w-full flex flex-col gap-3 items-center justify-start">
                                    {
                                        isCommunitiesLoading ? <CommunityProfileTabSkeleton />
                                            : isCommunitiesError ? <CommunityProfileTabError />
                                                : <CommunityProfileTab communities={communities} />
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        </div>
    )
}