import { useMemo, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
//import { useAuthStore } from "stores/auth";
import { Config } from "services/config";
import { Icon } from "assets/icons";
import { timeSince } from "services";
import { useModaleStore } from "stores/modale";
import Document from '@tiptap/extension-document';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Image from '@tiptap/extension-image';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Link from '@tiptap/extension-link';
import { generateHTML } from '@tiptap/html';
import { ReactConversation } from "../community/feed/reactions/reactConversation";
import { useBreakpointContext } from "context/BreakpointContext";

const postContentStyles = `
  .post-content img {
    display: none;
  }
`;

export const MessagesConversation = ({ message, isThread = false, isPreview = false, isFullWidth = false }: any) => {

    //const { uuid } = useAuthStore();
    const navigate = useNavigate();
    const { setModale } = useModaleStore();
    const { isMobile } = useBreakpointContext();
    const [isHovered, setIsHovered] = useState(false);

    const url = Config.CDN_HOST + "user%2F" + message.sender_uuid;

    const handleGoToProfile = () => {
        navigate("/user/" + message.sender_uuid);
    };

    const handleTriggerSettings = () => {
        setModale(true, 'MessageSettingsModale', 'snapped', { message });
    };

    const triggerImageModal = useCallback((imageUrl: string) => {
        setModale(true, 'ImageDisplayModale', 'modale', { imageUrl, user_name: message.user_name, owner_uuid: message.sender_uuid });
    }, [setModale]);

    const [contentHtml, imageUrls] = useMemo(() => {
        let html = '';
        const images: string[] = [];
        if (typeof message?.content === "string") {
            try {
                const contentJSON = JSON.parse(message.content);
                html = generateHTML(contentJSON, [
                    Document, Paragraph, Text, Mention, Image, Bold, Italic, Strike, Link
                ]);
            } catch {
                html = message.content;
            }
        } else {
            html = generateHTML(message?.content, [
                Document, Paragraph, Text, Mention, Image, Bold, Italic, Strike, Link
            ]);
        }

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const imgElements = doc.getElementsByTagName('img');
        Array.from(imgElements).forEach((img) => {
            images.push(img.src);
        });

        return [doc.body.innerHTML, images];
    }, [message.content]);

    return (
        <div
            className={`w-full flex flex-col gap-0 relative ${!isPreview ? "md:hover:bg-grey-100" : ""} cursor-pointer`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <style dangerouslySetInnerHTML={{ __html: postContentStyles }} />

            {isHovered && !isMobile && !isThread && (
                <div className="absolute top-2 right-5 z-10 -top-5 bg-white rounded-md flex justify-end items-center p-1 gap-1 cursor-pointer">
                    <div className="bg-gray-50 cursor-pointer hover:bg-gray-200 h-10 w-10 rounded flex items-center justify-center" onClick={() => setModale(true, 'ReactModale', 'snapped', { messageId: message.id, type: "message" })}>
                        <Icon type="EmojiHappy" size={17} color="black" className="cursor-pointer" />
                    </div>
                    <div className="bg-gray-50 cursor-pointer hover:bg-gray-200 h-10 w-10 rounded flex items-center justify-center" onClick={handleTriggerSettings}>
                        <Icon type="More" size={17} color="black" className="cursor-pointer" />
                    </div>
                </div>
            )}

            <div className={`w-full flex gap-3 ${isFullWidth ? "px-none" : "px-small"} py-small`}>
                <div className="w-min">
                    <div onClick={handleGoToProfile} className="w-[40px] !h-[40px] rounded-full bg-center bg-cover cursor-pointer" style={{ backgroundImage: `url(${url})` }} />
                </div>
                <div className="flex flex-col gap-3 w-full">
                    <div className="flex w-full items-center justify-between">
                        <div className="flex gap-2 items-center">
                            <p className="text-base font-medium">{message.user_name}</p>
                            {!isMobile && <p className="text-sm font-light text-grey-600">{message.waiting && <span className="italic text-grey-400">En attente - </span>}{timeSince(message.created_at)}{message.is_update && <span className="italic text-grey-400"> - ( Modifié )</span>}</p>}
                        </div>
                        {isMobile && (
                            <div className="flex gap-2 items-center">
                                <p className="text-sm font-light text-grey-600">{timeSince(message.created_at)}{message.waiting && <span className="italic text-grey-400"> - En attente</span>}</p>
                                <Icon type={"More"} size={17} color={"black"} onClick={handleTriggerSettings} />
                            </div>
                        )}
                    </div>
                    <motion.div className="w-full">
                        <div className="font-light text-left post-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />
                        {imageUrls.length > 0 && (
                            <div className="flex flex-wrap gap-2 mt-2">
                                {imageUrls.map((url, index) => (
                                    <img
                                        key={index}
                                        src={url}
                                        alt={`Image ${index + 1}`}
                                        className="w-20 h-20 object-cover rounded-md cursor-pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            triggerImageModal(url);
                                        }}

                                        
                                    />
                                ))}
                            </div>
                        )}
                    </motion.div>
                    <div className="flex flex-col-reverse md:flex-row gap-2 items-start md:items-stretch justify-start">
                        <ReactConversation message={message} type="message" />
                    </div>
                </div>
            </div>
        </div>
    );
};