import { SelectColor } from "components/admin/settings/selectColor"
import { AdminLayout } from "layout/AdminLayout"
import { useState } from "react"

export const AdminThemePage = () => {

    
    const [ selectedTheme, setSelectedTheme ] = useState("Blue")
    // @ts-ignore
    const [ actualTheme, setActualTheme ] = useState("Green")

    const handleChangeTheme = (theme : string) => {
        setSelectedTheme(theme)
    }

    return (
        <AdminLayout>
            <div className="bg-app h-full w-full overflow-auto flex flex-col gap-8 justify-start items-center p-intermediate">
                <SelectColor selectedTheme={selectedTheme} setSelectedTheme={handleChangeTheme} actualTheme={actualTheme} />
            </div>
        </AdminLayout>
    )
}