import { create } from 'zustand'

export interface threadType {
    threadOpen: boolean;
    threadId: string;
    threadProps: any;
    setThread: (isOpen: boolean, id: string, props?: any) => void;
}

export const useThreadModaleStore = create<threadType>(set => ({
    threadOpen: false,
    threadId : '',
    threadProps: {},
    setThread: (isOpen: boolean, id: string, props: any) => {
        set({ threadOpen: isOpen, threadId: id, threadProps: props});
    },
}));
