import { lazy, useEffect, Suspense } from "react";
import { useThreadModaleStore } from "stores/threadModale";
import { useNavigate } from 'react-router-dom';
import { Icon } from 'assets/icons';

const LazyThreadPage = lazy(() => import('pages').then(module => ({ default: module.ThreadPage })));

const LoadingFallback = () => (
    <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
    </div>
);

export const Thread = () => {
    const { threadProps } = useThreadModaleStore();
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'visible';
        };
    }, []);

    return (
        <div className='relative w-full border-l h-full bg-white'>
            <div className='absolute top-2 right-2 z-10 cursor-pointer' onClick={() => navigate(`/${threadProps?.communityId}/${threadProps?.channelId}`)} >
                <Icon type="CloseCircle" size={24} color="black" />
            </div>
            <Suspense fallback={<LoadingFallback />}>
                <LazyThreadPage />
            </Suspense>
        </div>
    );
};

