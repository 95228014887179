import { useThemeCommunity } from "stores/themeCommunity"
import { BgPrimary } from "assets/backgroundSvgs/bgPrimary"
import { CollectReviews, TextBlock, TextBlockSkeleton } from 'components/community/about/component'
import { Config } from "services/config"
import { Icon } from "assets/icons"
import { useCommunityAbout, useCommunityById } from "@hooks/queries/community"
import { useAboutStore } from "stores/about"
import { useEffect } from "react"


export const AboutCommunityPage = ({ isAdmin, communityId }: { isAdmin: boolean, communityId: string }) => {

    const { theme } = useThemeCommunity()

    const { data: communityAbout, isLoading: isCommunityAboutLoading, isError: isCommunityAboutError } = useCommunityAbout({ communityId, refetchOnMount: false });
    const { data: communityData } = useCommunityById({ communityId, refetchOnMount: false });
    const { setContent, content } = useAboutStore()

    useEffect(() => {
        if (communityAbout) {
            setContent(communityAbout);
        }
    }, [communityAbout, isCommunityAboutLoading, isCommunityAboutError]);

    const background_url = `${Config.CDN_HOST}community%2F${communityId}%2Fbg`;
    const ownerUrl = `${Config.CDN_HOST}user%2F${communityData?.owner_uuid}`;

    return (
        <div className="bg-app h-full w-full overflow-hidden">
            <div className="h-full w-full bg-appp flex flex-col gap-8 items-center md:p-intermediate pb-intermediate overflow-auto relative">
                <div className="fixed bottom-[-200px] right-[-200px] w-full z-0 flex justify-end opacity-50">
                    <BgPrimary color={theme.primaryColor} />
                </div>
                <div className="fixed top-[-200px] left-[-200px] w-full z-0 flex justify-end opacity-20">
                    <BgPrimary color={theme.primaryColor} />
                </div>

                <div className="max-w-[1080px] flex flex-col items-center justify-center gap-6 relative w-full relative z-10">
                    <div className="w-full flex flex-col items-center justify-center gap-4">
                        <div className="w-full h-[350px] md:rounded-lg lg:rounded-xl flex items-end justify-start relative" style={{ background: `url(${background_url}) center center / cover` }}>
                            <div className="w-full h-[350px] absolute z-10 rounded-lg lg:rounded-xl" style={{ background: "linear-gradient(12deg, rgba(9,9,121,1) 0%, rgba(255,255,255,0) 90%)" }} />
                            <div className="flex flex-col gap-1 items-start z-20 p-big text-white">
                                <h1 className="text-4xl font-bold">{communityData?.name}</h1>
                            </div>
                        </div>
                        <div className="w-11/12 md:w-full flex items-center justify-between">
                            <div className="flex items-center justify-center gap-3">
                                <img src={ownerUrl} alt={communityData?.owner_name} className="w-12 h-12 rounded-full object-fit-cover" />
                                <div className="flex flex-col items-start justify-start">
                                    <p className="text-xl">{communityData?.owner_name}</p>
                                    <div className="flex md:hidden items-center justify-end gap-2">
                                        {
                                            communityData?.type_id === 2 ? <Icon type="Lock" size={18} color={'#6B7280'} />
                                                : communityData?.type_id === 1 ? <Icon type="Unlock" size={18} color={'#6B7280'} />
                                                    : <Icon type="Money" size={18} color={'#6B7280'} />
                                        }
                                        <p className="font-semibold text-sm text-gray-500">Communauté <span>{communityData?.type_id === 2 ? "Privée" : communityData?.type_id === 1 ? "Publique" : "Payante"}</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:flex items-center justify-end gap-2">
                                {
                                    communityData?.type_id === 2 ? <Icon type="Lock" size={26} color={'#6B7280'} />
                                        : communityData?.type_id === 1 ? <Icon type="Unlock" size={26} color={'#6B7280'} />
                                            : <Icon type="Money" size={26} color={'#6B7280'} />
                                }
                                <p className="font-semibold text-lg text-gray-500">Communauté <span>{communityData?.type_id === 2 ? "Privée" : communityData?.type_id === 1 ? "Publique" : "Payante"}</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="w-11/12 md:w-full max-w-[1080px] flex flex-col gap-6 relative relative z-10">
                        {/* {
                            isCommunityAboutLoading && content ? <TextBlockSkeleton /> : <TextBlock isAdmin={isAdmin} />
                        } */}
                        {
                            isCommunityAboutLoading || !content ? <TextBlockSkeleton /> : <TextBlock isAdmin={isAdmin} />
                        }
                        <hr className="w-full" />
                        <CollectReviews isAdmin={isAdmin} communityId={communityId} />
                    </div>
                </div>

            </div>
        </div>
    )
}