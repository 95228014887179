import React, { useEffect, useMemo, useCallback } from "react";
import { Outlet, useParams, useLocation } from "react-router-dom";
import { useCheckCommunityById, useCommunityById, useGetCommunityName } from "@hooks/queries/community";
import { Sidebar } from "components/globals";
import { CommunityLandingPage } from "pages/community/landing";
import { useOpenedCommunity } from "stores/openedCommunity";
import { CommunitySkeleton } from "skeleton";
import { useBreakpointContext } from "context/BreakpointContext";
import { MobileCommunityTopBar } from "components/globals/topbar/MobileCommunity";
import { CommunityThemeConfig } from "data/communityThemeConfig";
import { useThemeCommunity } from 'stores/themeCommunity';
import { CommunityActivity } from "components/activity/CommunityActivity";
import { useAuthStore } from "stores/auth";


export const CommunityLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

    const { communityId, channelId } = useParams<{ communityId: string; channelId: string }>();
    const { isLogged } = useAuthStore()
    const location = useLocation();

    const numberCommunityId = useMemo(() => communityId ? parseInt(communityId) : 0, [communityId]);
    const { setOpenedCommunity } = useOpenedCommunity();
    const { setThemeCommunity } = useThemeCommunity();

    const { isDesktop, isMobileOrTable } = useBreakpointContext();

    const { data: community, isError, error, isLoading, refetch } = useCheckCommunityById({ communityId: communityId || '', refetchOnMount: false });
    const { data: communityName } = useGetCommunityName({ communityId: communityId || '', refetchOnMount: false });
    useCommunityById({ communityId, refetchOnMount: false });

    const setupCommunity = useCallback(() => {
        if (community && communityId) {
            const theme = community.theme.toLowerCase();
            setThemeCommunity(communityId, CommunityThemeConfig[theme as keyof typeof CommunityThemeConfig]);
        }
    }, [community, communityId, setThemeCommunity]);

    const cleanupCommunity = useCallback(() => {
        if (community && communityId) {
            setOpenedCommunity("", '');
            setThemeCommunity('', {});
        }
    }, [community, communityId, , setOpenedCommunity, setThemeCommunity]);

    useEffect(() => {
        setupCommunity();
        return cleanupCommunity;
    }, [setupCommunity, cleanupCommunity]);

    useEffect(() => {
        if (communityName && communityId) {
            setOpenedCommunity(communityId, communityName.name);
        }
    }, [communityId, communityName]);

    useEffect(() => {
        if (communityId) {
            refetch();
        }
    }, [isLogged]);
    
    

    const renderContent = useMemo(() => {
        if (isLoading) {
            console.log('isLoading', isLoading)
            return <CommunitySkeleton />;
        }

        if (community?.isAdmin || (community?.isMember && community?.isWaiting === false)) {
            return (
                <div className="w-full flex lg:flex-row flex-col flex-1 h-full relative">
                    <CommunityActivity communityId={numberCommunityId} />
                    {isDesktop && <Sidebar communityId={numberCommunityId} />}
                    {isMobileOrTable && communityId && location.pathname !== `/${communityId}` && !channelId && (
                        <MobileCommunityTopBar communityId={numberCommunityId} />
                    )}
                    <div className="flex-1 relative">
                        {children || <Outlet />}
                    </div>
                </div>
            );
        }

        return (
            <CommunityLandingPage
                communityId={communityId || ''}
                template={community?.landingData?.json_content}
                refetch={refetch}
                isWaiting={community?.isWaiting}
            />
        );
    }, [isLoading, community, isDesktop, isMobileOrTable, location.pathname, communityId, channelId, numberCommunityId, children, refetch]);

    if (!communityId) return null;

    return (
        <div className="w-full flex lg:flex-row h-full flex-col flex-1 relative"> 
            { /* @ts-ignore */}
            {isError && <div>{error.response.data.error}</div>}
            {renderContent}
        </div>
    );
};