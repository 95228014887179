import { useState, useEffect } from 'react';
import { useMyCommunities } from "@hooks/queries/communities";
import { Config } from "services/config";
import { Icon } from "assets/icons";
import { useBreakpointContext } from "context/BreakpointContext";
import { useTopbarMobileData } from "stores/topbarMobileData";
import { timeSince } from 'services/timeSince';
import { useModaleStore } from 'stores/modale';

export const ManageSubscriptions = () => {

    const { setModale } = useModaleStore();
    const [searchTerm, setSearchTerm] = useState('');
    const { setTopbarMobileData } = useTopbarMobileData();

    useEffect(() => {
        setTopbarMobileData({
            pageName: "Abonnements",
            showTopbar: true,
            withLogo: false,
            withGoBack: true,
        });
    }, [setTopbarMobileData]);

    const { data: myCommunities, isError: isMyCommunitiesError, isLoading: isMyCommunitiesLoading, refetch } = useMyCommunities({ refetchOnMount: false });

    const { isMobile } = useBreakpointContext();

    const filteredCommunities = myCommunities?.list.filter((community:any) =>
        community.name && community.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleLeaveCommunity = (community: any) => {
        setModale(true, 'LeaveCommunityModale', 'modale', { community, refetch });
    }

    return (
        <div className="h-full w-full flex flex-col gap-3 items-center px-intermediate pt-intermediate">
            <div className="w-full lg:max-w-[1080px] flex flex-col gap-6 lg:gap-10">
                <div className="w-full flex flex-col gap-0">
                    <h2 className="lg:text-xl text-lg">Gérer vos abonnements aux communautés</h2>
                    <p className="text-gray-500 italic font-light">Simplement et en 1 clic</p>
                </div>
                <input 
                    type="text" 
                    placeholder="Rechercher une communauté" 
                    className="border h-12 px-3 rounded-tiny"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="w-full flex flex-col items-start justify-start gap-2">
                    {
                        !isMyCommunitiesLoading && !isMyCommunitiesError && filteredCommunities?.map((community: any) => (
                            <div key={community.id} className="w-full flex items-center justify-between gap-3 p-3 bg-lightGray rounded-tiny">
                                <div className="flex items-center gap-3">
                                    <img src={Config.CDN_HOST + "community%2F" + community.id + "%2Fbg"} alt={community.name} className="w-10 h-10 rounded-mimi" />
                                    <div>
                                        <p className="text-black font-bold">{community.name}</p>
                                        <p className="text-gray-400 italic font-light text-sm">{timeSince(community.created_at)}</p>
                                    </div>
                                </div>
                                {
                                    isMobile ? (
                                        <Icon type="LogoutCurve" size={20} color="#EF4444" onClick={() => handleLeaveCommunity(community)}/>
                                    ) : (
                                        <button className="bg-red-500 hover:bg-red-600 font-[300] text-white p-2 rounded-micro" onClick={() => handleLeaveCommunity(community)}>Quitter la communauté</button>
                                    )
                                }
                            </div>
                        ))
                    }
                    {
                        isMyCommunitiesLoading && Array.from({ length: 5 }).map((_, index) => (
                            <div key={index} className="w-full h-16 bg-gray-100 animate-pulse rounded-tiny" />
                        ))
                    }
                    {
                        !isMyCommunitiesLoading && isMyCommunitiesError && (
                            <div className="w-full h-16 bg-red-100 rounded-tiny flex items-center justify-center">
                                <p className="text-red-500">Erreur lors du chargement des communautés</p>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}