import { Config } from "services/config";
import { AnimatedButton } from "components/globals/buttons";
import { useNavigate } from "react-router-dom";
import { useAuthStore, logoutSelector } from "stores/auth"
import { useModaleStore } from "stores/modale";
import { useMyAdminCommunities } from "hooks/queries/communities";
import { ProfileDrawerCommunityBlock } from "./profileDrawerCommunityBlock";
import WebSocketStatusIndicator from "components/globals/sidebar/status/WebsocketStatus";

export const ProfileDrawer = () => {

    useMyAdminCommunities({});

    const logout = useAuthStore(logoutSelector)
    const { uuid, userName, isLogged } = useAuthStore.getState()
    const { setModale } = useModaleStore();
    const navigate = useNavigate()

    const url = Config.CDN_HOST + "user%2F" + uuid;

    const handleLogout = async () => {
        setModale(false, '', '')
        await logout()
    }

    const handleCreateCommunity = async () => {
        setModale(false, '', '')
        navigate('/create-community')
    }

    const handleManagaSubscriptions = async () => {
        setModale(false, '', '')
        navigate('/manage-subscriptions')
    }

    const handleGoToProfile = () => {
        setModale(false, '', '')
        navigate('/user/' + uuid)
    }

    return (
        <div className="h-full w-full flex flex-col gap-3 p-intermediate items-center justify-start cursor-default overflow-hidden">
            <div className="w-full flex flex-col justify-start gap-6">
                <div className="w-full flex flex-col items-center justify-center gap-3">
                    <AnimatedButton
                        onClick={handleGoToProfile}
                        className="w-[100px] h-[100px] rounded-full bg-center bg-cover" style={{ backgroundImage: `url(${url})` }}
                    />
                    <div className="flex items-center justify-start gap-2">
                        <p className="text-black text-xl font-bold"><span className="font-medium">@</span>{userName}</p>
                        <WebSocketStatusIndicator />
                    </div>
                </div>
                <div className="flex flex-col gap-2 w-full">
                    <AnimatedButton
                        className="bg-grey-500 text-white p-mini rounded-micro w-full text-center"
                        onClick={handleCreateCommunity}
                    >
                        Créer une communauté
                    </ AnimatedButton>
                    {
                        isLogged && (
                            <AnimatedButton
                                className="bg-grey-400 text-white p-mini rounded-micro w-full text-center"
                                onClick={handleManagaSubscriptions}
                            >
                                Gérer mes abonnements
                            </ AnimatedButton>
                        )
                    }
                    <AnimatedButton
                        className="bg-grey-300 text-white p-mini rounded-micro w-full text-center"
                        onClick={handleLogout}
                    >
                        Déconnexion
                    </ AnimatedButton>
                </div>
                <hr />
                <ProfileDrawerCommunityBlock />
            </div>
        </div>
    )
}