//import { emojiData } from 'data/emojis'
import { lazy, Suspense } from 'react';

const EmojiPicker = lazy(() => import('./reactPicker/EmojiPicker'));

export const ReactComponent = ({ isClosing = false } : { isClosing?: boolean}) => {
    return (
        <div className='w-full flex flex-col h-[85vh] md:h-[50vh]'>
            <Suspense fallback={<div>Loading...</div>}>
                <EmojiPicker isClosing={isClosing}/>
            </Suspense>
        </div>
    );
};