import { timeSince } from "services/timeSince"
import { Icon } from "assets/icons"
import { AnimatedButton, SwitchButton } from "components/globals/buttons"
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useMutator } from "@hooks/queries/api"
import { motion, AnimatePresence } from "framer-motion"
import { useModaleStore } from "stores/modale"

type ChannelsListObj = Partial<{
    id: number;
    community_id: number;
    name: string;
    description: string;
    members: number;
    created_at: string;
    created_by: string;
    can_users_publish: boolean;
    new_posts_count?: string;
}>

const itemVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: {
        opacity: 1,
        y: 0,
        transition: { type: "spring", stiffness: 300, damping: 24 }
    },
    exit: { opacity: 0, y: -10, transition: { duration: 0.2 } }
}

const containerVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: {
        opacity: 1,
        height: "auto",
        transition: {
            when: "beforeChildren",
            staggerChildren: 0.05,
            duration: 0.3,
        },
    },
    exit: {
        opacity: 0,
        height: 0,
        transition: {
            when: "afterChildren",
            staggerChildren: 0.05,
            staggerDirection: -1,
            duration: 0.3,
        }
    }
}

export const ChannelAdminItems = ({
    channel,
    refetch,
    isOpen,
    onToggle
}: {
    channel: ChannelsListObj,
    refetch: () => void,
    isOpen: boolean,
    onToggle: () => void
}) => {
    const [editedChannel, setEditedChannel] = useState<ChannelsListObj>({});
    const { communityId } = useParams();

    const { mutateAsync: updateChannel, isError, isPending } = useMutator<any>("/community/" + communityId + "/admin/channel/" + channel.id + "/update");

    useEffect(() => {
        setEditedChannel(channel);
    }, [channel]);

    const handleInputChange = (key: keyof ChannelsListObj, value: string | boolean) => {
        setEditedChannel(prev => ({ ...prev, [key]: value }));
    };

    const handleSubmit = async () => {
        try {
            await updateChannel(editedChannel);
            refetch();
            onToggle();
        } catch (error) {
            console.error("Error updating channel:", error);
        }
    };

    const { setModale } = useModaleStore();

    const handleDelete = async () => {
        setModale(true, "DeleteChannelFromAdmin", "modale", { communityId: communityId, channel, refetch: refetch });
    }

    return (
        <motion.div
            className={`flex flex-col w-full gap-6 border rounded-tiny ${!isOpen && "hover:bg-grey-100 cursor-pointer"} py-mini px-intermediate`}
            layout
            transition={{ duration: 0.3, ease: "easeInOut" }}
        >
            <div className="grid grid-cols-12 gap-3 cursor-pointer" onClick={onToggle}>
                <div className="col-span-5 flex items-center gap-2">
                    <Icon type={"Hashtag"} size={12} />
                    <span>{channel.name}</span>
                </div>
                <div className="col-span-2">{channel.members}</div>
                <div className="col-span-5 text-right">
                    <span className="text-grey-600 font-light text-sm">{channel.created_at ? timeSince(channel.created_at) : "..."}</span>
                </div>
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        className="flex flex-col gap-4 items-start justify-start"
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                        <motion.div variants={itemVariants} className="flex flex-col gap-2 items-start justify-start w-full">
                            <p className="text-base font-[400]">Modifier le nom</p>
                            <input
                                type="text"
                                className="bg-transparent w-full focus:outline-none border h-10 rounded-lg px-3"
                                value={editedChannel.name || ''}
                                onChange={(e) => handleInputChange('name', e.target.value)}
                            />
                        </motion.div>

                        <motion.div variants={itemVariants} className="flex flex-col gap-2 items-start justify-start w-full">
                            <p className="text-base font-[400]">Modifier la description</p>
                            <input
                                type="text"
                                className="bg-transparent w-full focus:outline-none border h-10 rounded-lg px-3"
                                value={editedChannel.description || ''}
                                onChange={(e) => handleInputChange('description', e.target.value)}
                            />
                        </motion.div>
                        <motion.div variants={itemVariants} className="flex items-center justify-between w-full">
                            <span className="text-base">Les utilisateurs peuvent publier ?</span>
                            <SwitchButton
                                isOn={editedChannel.can_users_publish || false}
                                handleToggle={(checked) => handleInputChange('can_users_publish', checked)}
                            />
                        </motion.div>
                        <motion.div variants={itemVariants} className="flex w-full items-center justify-between gap-3">
                            <div className="flex w-8/12 items-center justify-start gap-3">
                                <AnimatedButton onClick={onToggle} className="bg-grey-100 hover:bg-grey-200 rounded-md py-1.5 px-3 flex items-center justify-center cursor-pointer">Annuler</AnimatedButton>
                                <AnimatedButton onClick={handleDelete} className="bg-red-300 hover:bg-red-400 rounded-md py-1.5 px-3 flex items-center justify-center cursor-pointer">Supprimer</AnimatedButton>
                            </div>
                            <AnimatedButton onClick={handleSubmit} className="bg-green-300 hover:bg-green-400 rounded-md py-1.5 px-3 flex items-center justify-center cursor-pointer">
                                {isPending ? 'Chargement...' : 'Confirmer'}
                            </AnimatedButton>
                        </motion.div>
                        {isError && <motion.p variants={itemVariants} className="text-red-500">Une erreur est survenue lors de la mise à jour du channel.</motion.p>}
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    )
}