export const UserItemSkeleton = () => {
    return (
        <div className="w-full flex items-center justify-start gap-3 animate-pulse">
            <div className="w-[35px] h-[35px] rounded-full bg-gray-100" />
            <div className="flex flex-col items-start justify-start gap-1">
                <div className="h-4 w-24 bg-gray-100 rounded" />
                <div className="h-3 w-32 bg-gray-100 rounded" />
            </div>
        </div>
    );
};