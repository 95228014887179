import { Icon } from "assets/icons";
import { useBottomBarMenu } from "navigation/useBottomBarMenu";
import { Link } from "react-router-dom";
import { useLocation } from "react-use";

export const MainMenuDesktop = () => {

    const bottomBarItems = useBottomBarMenu();
    const location = useLocation();

    return (
        <div className="w-4/12 flex justify-center items-center h-full gap-3">
            {
                bottomBarItems.map(({ key, label, path, icon }) => {
                    return (
                        <div key={key} className="w-full h-full flex items-center justify-center">
                            <Link to={path} className={`w-full h-full flex flex-col items-center justify-center rounded-lg gap-1`}>
                                <Icon type={icon} size={24} color={"black"} variant={location.pathname === path ? "Bold" : "Linear"}/>
                                <span className={`text-xs`}>{label}</span>
                            </Link>
                        </div>
                    )
                })
            }
        </div>
    )
}