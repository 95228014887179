import { Icon } from "assets/icons";
import { AnimatedButton } from "components/globals/buttons";
import { Config } from "services/config";
import { useModaleStore } from "stores/modale";
import { useNavigate } from "react-router-dom";
import { useMutator } from "@hooks/queries/api";

export const EmployeeSettings = () => {

    const { modaleProps, setModale } = useModaleStore();
    const navigate = useNavigate();
    const { mutateAsync: deleteEmployee, isError, isPending } = useMutator<any>("/community/" + modaleProps.employee.community_id + "/admin/team/delete/" + modaleProps.employee.member_uuid);

    const url = Config.CDN_HOST + "user%2F" + modaleProps.employee.member_uuid;

    const handleGoToProfile = () => {
        navigate("/user/" + modaleProps.employee.member_uuid);
        setModale(false, '', '', {...modaleProps });
    }

    const handleDeleteEmployee = async () => {
        await deleteEmployee({});
        setModale(false, '', '', {...modaleProps});
        await modaleProps?.refetch();
    }

    return (
        <div className="flex flex-col gap-6 items-center w-full lg:w-[500px] lg:p-3 cursor-auto">
            <div className="w-full flex items-center justify-center gap-3">
                <img src={url} className="h-12 w-12 rounded-full object-fit-cover" alt="" />
                <div className="w-webkit flex flex-col items-start justify-start gap-0">
                    <p className="text-xl font-light">{modaleProps.employee.first_name} {modaleProps.employee.last_name}</p>
                    <p className="text-base flex items-center justify-center gap-0.5 font-[400]">@<span className="text-base font-[500]">{modaleProps.employee.user_name}</span></p>
                </div>
                <div className="flex items-center justify-end gap-2">
                    <Icon type="ArrowSquareLeft" color={"black"} className='cursor-pointer' />
                    <Icon type="ArrowSquareRight" color={"black"} className='cursor-pointer' />
                </div>
            </div>
            <div className="flex w-full gap-2 items-center justify-center">
                <AnimatedButton onClick={handleGoToProfile} className="bg-white hover:bg-gray-100 border border-black text-black py-nano rounded-micro w-full text-center font-[400] text-sm">Voir le profil</AnimatedButton>
                <AnimatedButton className="bg-white hover:bg-gray-100 border border-black text-black py-nano rounded-micro w-full text-center font-[400] text-sm">Message</AnimatedButton>
            </div>
            <hr className="w-full" />
            <AnimatedButton className="w-full bg-red-200 text-red-500 font-bold rounded-md py-2" onClick={handleDeleteEmployee}>
                {isPending ? "Suppression en cours..." : "Supprimer"}
            </AnimatedButton>
            {isError && <div className="w-full py-1.5 font-light text-red-500 bg-red-100">Une erreur est survenue</div>}
        </div>
    )
}