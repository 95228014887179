import { Config } from "services/config";
import { Link } from "react-router-dom";

export type CommunitiesTypes = {
    id: number,
    name: string,
    description: string,
    pricing: string,
    owner_uuid: string,
    members: number,
    type_id: number
}

export const CommunityProfileTab = ({ communities }: { communities: CommunitiesTypes[] }) => {

    return (
        <>
            {
                communities.length > 0 ? (
                    <div className="w-full flex flex-col items-center justify-start gap-2 pb-intermediate pt-5">
                        {communities.map((community, index) => (
                            <Link to={'/' + community.id} key={index} className="w-full">
                                <div className="w-full h-36 bg-cover bg-center flex items-end justify-start rounded-xl p-3 relative" style={{ backgroundImage: `url(${Config.CDN_HOST}community%2F${community.id}%2Fbg)` }}>
                                    <div className="absolute z-0 top-0 inset-0 left-0 bg-black bg-opacity-30 rounded-xl" />
                                    <div>
                                        <p className="text-white font-bold relative z-10">{community.name}</p>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                ) : (
                    <div className="w-full flex items-center justify-center bg-gray-100">
                        <p className="text-gray-400 font-light text-lg">Aucune communauté</p>
                    </div>
                )
            }
        </>
    )
}

export const CommunityProfileTabSkeleton = () => {
    return (
        <div className="w-full flex flex-col items-center justify-start gap-2 pb-intermediate pt-5">
            {Array.from({ length: 2 }).map((_, index) => (
                <div key={index} className="w-full h-36 bg-gray-100 flex items-end justify-start rounded-lg p-3 relative">
                    <div className="bg-gray-200 w-28 rounded-lg h-7" />
                </div>
            ))}
        </div>
    )
}

export const CommunityProfileTabError = () => {
    return (
        <div className="w-full flex items-center justify-center bg-red-100">
            <p className="text-red-400 font-light text-lg">Aucune communauté</p>
        </div>
    )
}