import { createRoot } from "react-dom/client";
import { App } from "./App";

const MOUNT_NODE = document.getElementById("root") as HTMLDivElement;

const initReactApp = async () => {
  const root = createRoot(MOUNT_NODE); // createRoot(container!) if you use TypeScript
  root.render(<App />);
};

initReactApp();
