import React, { useState } from 'react'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import { Icon } from 'assets/icons'
import { AnimatedButton } from "components/globals/buttons"
import { useSendComment } from "@hooks/feed/comments"
import { useModaleStore } from 'stores/modale'
import { useFileMutator } from '@hooks/queries/api'
import { Config } from 'services/config'

interface UploadedImage {
  url: string;
  isLoading: boolean;
}

export const InputComments = ({ communityId, channelId, postId }: any) => {
    const { sendComment } = useSendComment({ communityId, channelId, postId });
    const [isExpanded, setIsExpanded] = useState(false);
    const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>([]);
    const { setModale } = useModaleStore();
    const { mutateAsync: uploadImage } = useFileMutator<any>("/assets/image/new")
    const [isUploading, setIsUploading] = useState(false);
  
    const editor = useEditor({
      extensions: [
        StarterKit,
        Image,
        Link,
        Placeholder.configure({
          placeholder: 'Type your comment...',
        }),
      ],
      editorProps: {
        attributes: {
          class: 'focus:outline-none min-h-[20px] max-h-[300px] overflow-y-auto p-3 font-light',
        },
      },
    })
  
    const handleSendComment = async () => {
      if (!editor || isUploading) return;
      const content = editor.getJSON();
  
      // Add uploaded images to the end of the JSON content
      uploadedImages.forEach(image => {
        content.content?.push({
          type: 'image',
          attrs: { src: image.url }
        });
      });
  
      editor.commands.clearContent();
      setUploadedImages([]);
      await sendComment({ content });
      setIsExpanded(false);
    };
  
    const toggleFormat = (format: 'bold' | 'italic') => {
      if (!editor) return;
  
      switch (format) {
        case 'bold':
          editor.chain().focus().toggleBold().run();
          break;
        case 'italic':
          editor.chain().focus().toggleItalic().run();
          break;
      }
    };
  
    const insertLink = () => {
      const url = prompt('Enter the URL:');
      if (url) {
        editor?.chain().focus().setLink({ href: url }).run();
      }
    };
  
    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setIsUploading(true);
        const tempUrl = URL.createObjectURL(file);
        setUploadedImages(prev => [...prev, { url: tempUrl, isLoading: true }]);

        try {
          const formData = new FormData();
          formData.append("file", file);
          const response = await uploadImage(formData);
          setUploadedImages(prev => 
            prev.map(img => 
              img.url === tempUrl ? { url: Config.CDN_HOST + response.path, isLoading: false } : img
            )
          );
        } catch (error) {
          console.error("Error uploading image:", error);
          setUploadedImages(prev => prev.filter(img => img.url !== tempUrl));
        } finally {
          setIsUploading(false);
        }
      }
    };
  
    const removeImage = (index: number) => {
      setUploadedImages(prev => prev.filter((_, i) => i !== index));
    };
  
    const triggerModale = (url: string) => {
      setModale(true, 'ImageDisplayModale', 'modale', { imageUrl: url });
    }

  return (
    <div className='w-full px-mini pb-mini'>
      <div className="bg-white rounded-lg shadow-md border border-gray-300 overflow-hidden w-full">
        {isExpanded && (
          <div className="flex items-center space-x-2 p-2 border-b border-gray-200">
            <button onClick={() => toggleFormat('bold')} className="p-1 rounded hover:bg-gray-100">
              <Icon type="TextBold" size={20} color={editor?.isActive('bold') ? 'blue' : 'black'} />
            </button>
            <button onClick={() => toggleFormat('italic')} className="p-1 rounded hover:bg-gray-100">
              <Icon type="TextItalic" size={20} color={editor?.isActive('italic') ? 'blue' : 'black'} />
            </button>
            <button onClick={insertLink} className="p-1 rounded hover:bg-gray-100">
              <Icon type="Link" size={20} color={editor?.isActive('link') ? 'blue' : 'black'} />
            </button>
          </div>
        )}
        <div className="p-2" onClick={() => setIsExpanded(true)}>
          <EditorContent editor={editor} />
        </div>
        {uploadedImages.length > 0 && (
          <div className="flex flex-wrap gap-2 p-2">
            {uploadedImages.map((image, index) => (
              <AnimatedButton key={index} className="relative w-20 h-20" onClick={() => triggerModale(image.url)}>
                <img 
                  src={image.url} 
                  alt={`Uploaded ${index}`} 
                  className={`w-full h-full object-cover rounded-md shadow-md border border-gray-400 ${image.isLoading ? 'opacity-50' : ''}`} 
                />
                {image.isLoading && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <Icon type="Loading" size={24} color="black" />
                  </div>
                )}
                {!image.isLoading && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      removeImage(index);
                    }}
                    className="absolute top-2 right-2 bg-white rounded-full"
                  >
                    <Icon type="CloseCircle" size={18} color="black" />
                  </div>
                )}
              </AnimatedButton>
            ))}
          </div>
        )}
        <div className="flex items-center justify-between p-2">
          <div className="flex items-center space-x-2">
            <label htmlFor="image-upload-comment" className="cursor-pointer p-1 rounded hover:bg-gray-200">
              <Icon type="Image" size={20} color="gray" />
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
              id="image-upload-comment"
              disabled={isUploading}
            />
            <button className="p-1 rounded hover:bg-gray-200">
              <Icon type="Emoji" size={20} color="gray" />
            </button>
            <button className="p-1 rounded hover:bg-gray-200">
              <Icon type="Mention" size={20} color="gray" />
            </button>
          </div>
          <AnimatedButton
            className={`bg-darkBlue text-white rounded-md px-4 py-1 flex items-center justify-center ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
            type="button"
            onClick={handleSendComment}
            disabled={isUploading}
          >
            <Icon type="Send" size={16} color="white" variant="Bold" className="mr-1" />
            Send
          </AnimatedButton>
        </div>
      </div>
    </div>
  )
}