import { useActivityStore } from 'stores/activityStore';
import { create } from 'zustand';

interface Comment {
  id?: number;
  type: string;
  post_id: number;
  comment: string;
  created_at: string | Date;
  user_uuid: string;
  channel_id: number;
  comment_count: number;
  tempId?: string;
  waiting?: boolean;
  reactions?: any;
  comment_id?: number | null;
  user_name?: string;
  waitingReactions: any;
  user_reacts: any;
  had_user_react: boolean;
  is_saved?: boolean;
  is_signaled?: boolean;
}

interface Message {
  id?: number | string;
  owner_uuid: string;
  tempId?: string;
  postId?: string;
  waiting?: boolean;
  content: string;
  reactions?: any;
  comments?: number;
  is_pinned: boolean;
  created_at: string | Date;
  channel_id: number;
  is_comment_disabled: boolean;
  is_archived: boolean;
  is_banned: boolean;
  comment_details: Comment[];
  user_name: string;
  had_user_react?: boolean;
  user_reacts?: any;
  waitingReactions?: any;
  temporaryReactionId: string;
  reactionType: string;
  react_uuid: string;
  reactOn?: string;
  post_id?: number;
  commentId?: number;
  is_update?: boolean;
}

export interface ChannelInterface {
  id: number;
  community_id: number;
  name: string;
  channel_type: number;
  new_posts_count: string;
  can_users_publish: boolean;
  description: string;
  members?: number;
  created_at?: string;
  created_by?: string;
  is_displayed?: boolean;
  messages: Message[];
}

interface MessageState {
  channels: Record<string, ChannelInterface>;
  addMessage: (message: Message) => void;
  addMessages: (channelId: number, newMessages: Message[]) => void;
  addWaitingMessage: (channelId: number, message: Message, tempId: string) => void;
  confirmMessage: (channelId: number, tempId: string) => void;
  addWaitingUpdateMessage: (channelId: number, message: Partial<Message>) => void;
  addUpdateMessage: (channelId: number, postId: number, content: string) => void;
  addComment: (comment: Comment) => void;
  addComments: (channelId: number, messageId: number, comments: Comment[]) => void;
  addWaitingComment: (channelId: number, messageId: number, comment: Comment, tempId: string) => void;
  addReaction: (post: Message, uuid: string) => void;
  deleteReaction: (post: Message, uuid: string) => void;
  addWaitingReaction: (post: Message, tempId: string, userId: string, type: string) => void;
  updateChannelInfo: (channelId: number, channelInfo: Partial<ChannelInterface>) => void;
  addChannels: (channels: ChannelInterface[]) => void;
  resetNewPostsCount: (channelId: string) => void;
  updateMessageSavedStatus: (channelId: number, messageId: number, isSaved: boolean) => void;
  updateMessageSignaledStatus: (channelId: number, messageId: number, isSignaled: boolean) => void;
}

export const useMessageStore = create<MessageState>((set) => ({
  channels: {},

  addMessage: (message) => {
    set((state) => {
      const channelId = message.channel_id.toString();
      const channel = state.channels[channelId] || { messages: [], new_posts_count: "0" };
      const existingIndex = channel.messages.findIndex(msg => msg.id === message.id || msg.tempId === message.postId);

      if (existingIndex === -1) {
        // C'est un nouveau message
        const activityStore = useActivityStore.getState();
        const lastVisit = activityStore.channelTimestamps.permanent[channelId] || 0;
        const messageDate = new Date(message.created_at).getTime();

        let newPostsCount = parseInt(channel.new_posts_count || "0");
        if (messageDate > lastVisit) {
          newPostsCount += 1;
        }

        return {
          channels: {
            ...state.channels,
            [channelId]: {
              ...channel,
              messages: [...channel.messages, message],
              new_posts_count: newPostsCount.toString()
            }
          }
        };
      } else {
        // C'est une mise à jour d'un message existant
        const updatedMessages = [...channel.messages];
        updatedMessages[existingIndex] = { ...updatedMessages[existingIndex], ...message, tempId: undefined, waiting: false };

        return {
          channels: {
            ...state.channels,
            [channelId]: {
              ...channel,
              messages: updatedMessages
            }
          }
        };
      }
    });
  },

  addMessages: (channelId, newMessages) => {
    set((state) => {
      const channel = state.channels[channelId.toString()] || { messages: [] };
      const existingMessages = channel.messages;
      const filteredMessages = newMessages.filter(newMsg => !existingMessages.some(msg => msg.id === newMsg.id));
      return {
        channels: {
          ...state.channels,
          [channelId.toString()]: {
            ...channel,
            messages: [...existingMessages, ...filteredMessages]
          }
        }
      };
    });
  },

  addWaitingMessage: (channelId, message, tempId) => {
    set((state) => {
      const channel = state.channels[channelId.toString()] || { messages: [] };
      const newMessage = { ...message, waiting: true, tempId };
      return {
        channels: {
          ...state.channels,
          [channelId.toString()]: {
            ...channel,
            messages: [...channel.messages, newMessage]
          }
        }
      };
    });
  },

  addWaitingUpdateMessage: (channelId, message) => {
    set((state: any) => {
      const channel = state.channels[channelId.toString()];
      if (!channel) return state;

      const updatedMessages = channel.messages.map((msg: Message) => {
        if (msg.id === message.id) {
          return {
            ...msg,
            content: message.content,
            is_update: true,
            waiting: true,
          };
        }
        return msg;
      });

      return {
        channels: {
          ...state.channels,
          [channelId.toString()]: {
            ...channel,
            messages: updatedMessages
          }
        }
      };
    });

  },

  addUpdateMessage: (channelId, postId, content) => {
    set((state) => {
      const channel = state.channels[channelId.toString()];
      if (!channel) return state;

      const updatedMessages = channel.messages.map(msg => {
        console.log(msg.id, postId);
        if (msg.id === postId) {
          console.log('test');
          return {
            ...msg,
            content: content,
            waiting: false,
          };
        }
        return msg;
      });

      return {
        channels: {
          ...state.channels,
          [channelId.toString()]: {
            ...channel,
            messages: updatedMessages
          }
        }
      };
    });

  },

  confirmMessage: (channelId, tempId) => {
    set((state) => {
      const channel = state.channels[channelId.toString()];
      if (!channel) return state;

      const updatedMessages = channel.messages.map(msg =>
        msg.tempId === tempId ? { ...msg, waiting: false } : msg
      );
      return {
        channels: {
          ...state.channels,
          [channelId.toString()]: {
            ...channel,
            messages: updatedMessages
          }
        }
      };
    });
  },

  addComment: (comment) => {
    set((state) => {
      const channelId = comment.channel_id.toString();
      const channel = state.channels[channelId];
      if (!channel) return state;

      const updatedMessages = channel.messages.map(msg => {
        if (msg.id === parseInt(comment.post_id as any)) {
          let updatedCommentDetails = msg.comment_details ?? [];
          const index = updatedCommentDetails.findIndex(cmt => cmt.tempId === comment.tempId || cmt.id === comment.id);

          if (index > -1) {
            updatedCommentDetails[index] = { ...updatedCommentDetails[index], ...comment, waiting: false, tempId: undefined };
          } else {
            updatedCommentDetails.push(comment);
          }

          return { ...msg, comment_details: updatedCommentDetails, comments: updatedCommentDetails.length };
        }
        return msg;
      });

      return {
        channels: {
          ...state.channels,
          [channelId]: {
            ...channel,
            messages: updatedMessages
          }
        }
      };
    });
  },

  addComments: (channelId, messageId, comments) => {
    set((state) => {
      const channel = state.channels[channelId.toString()];
      if (!channel) return state;

      const updatedMessages = channel.messages.map((msg) => {
        if (msg.id === parseInt(messageId as any)) {
          const existingCommentIds = new Set(msg.comment_details?.map(cmt => cmt.id));
          const filteredComments = comments.filter(cmt => !existingCommentIds.has(cmt.id));
          const newCommentDetails = [...(msg.comment_details ?? []), ...filteredComments];
          return { ...msg, comment_details: newCommentDetails };
        }
        return msg;
      });

      return {
        channels: {
          ...state.channels,
          [channelId.toString()]: {
            ...channel,
            messages: updatedMessages
          }
        }
      };
    });
  },

  addWaitingComment: (channelId, messageId, comment, tempId) => {
    set((state) => {
      const channel = state.channels[channelId.toString()];
      if (!channel) return state;

      const updatedMessages = channel.messages.map(msg => {
        if (msg.id === parseInt(messageId as any)) {
          const newCommentDetails = [...(msg.comment_details ?? []), { ...comment, waiting: true, tempId }];
          return { ...msg, comment_details: newCommentDetails };
        }
        return msg;
      });

      return {
        channels: {
          ...state.channels,
          [channelId.toString()]: {
            ...channel,
            messages: updatedMessages
          }
        }
      };
    });
  },

  addReaction: (post, uuid) => {
    set((state) => {
      const channelId = post.channel_id.toString();
      const channel = state.channels[channelId];
      if (!channel) return state;

      const updatedMessages = channel.messages.map(msg => {
        if (post.reactOn === "post" && msg.id === post.id) {
          const newWaitingReactions = { ...msg.waitingReactions };
          const userReacts = msg.user_reacts || [];
          let hadUserReact = msg.had_user_react || false;

          if (post.temporaryReactionId && newWaitingReactions[post.temporaryReactionId]) {
            delete newWaitingReactions[post.temporaryReactionId];
          }

          if (post.react_uuid === uuid && !userReacts.includes(post.reactionType)) {
            userReacts.push(post.reactionType);
            hadUserReact = true;
          }

          return {
            ...msg,
            reactions: post.reactions,
            waitingReactions: newWaitingReactions,
            user_reacts: userReacts,
            had_user_react: hadUserReact,
          };
        } else if (post.reactOn === "comment" && msg.id === post.post_id) {
          const updatedCommentDetails = msg.comment_details?.map(comment => {
            if (comment.id === post.id) {
              const commentReactions = comment.reactions || {};
              const newWaitingReactions = { ...comment.waitingReactions };
              const userReacts = comment.user_reacts || [];
              let hadUserReact = comment.had_user_react || false;

              if (post.temporaryReactionId && newWaitingReactions[post.temporaryReactionId]) {
                delete newWaitingReactions[post.temporaryReactionId];
              }

              if (post.react_uuid === uuid && !userReacts.includes(post.reactionType)) {
                hadUserReact = true;
                userReacts.push(post.reactionType);
              }

              commentReactions[post.reactionType] = (Number(commentReactions[post.reactionType]) || 0) + 1;

              return {
                ...comment,
                reactions: post.reactions,
                waitingReactions: newWaitingReactions,
                user_reacts: userReacts,
                had_user_react: hadUserReact,
              };
            }
            return comment;
          });

          return { ...msg, comment_details: updatedCommentDetails };
        }
        return msg;
      });

      return {
        channels: {
          ...state.channels,
          [channelId]: {
            ...channel,
            messages: updatedMessages
          }
        }
      };
    });
  },

  deleteReaction: (post, uuid) => {
    set((state) => {
      const channelId = post.channel_id.toString();
      const channel = state.channels[channelId];
      if (!channel) return state;

      const updatedMessages = channel.messages.map(msg => {
        if (post.reactOn === "post" && msg.id === post.id) {
          const newWaitingReactions = { ...msg.waitingReactions };
          const userReacts = [...(msg.user_reacts || [])];
          let hadUserReact = msg.had_user_react || false;

          // Supprime la réaction temporaire si elle existe
          if (post.temporaryReactionId && newWaitingReactions[post.temporaryReactionId]) {
            delete newWaitingReactions[post.temporaryReactionId];
          }

          // Si l'UUID correspond, on retire la réaction de l'utilisateur
          if (post.react_uuid === uuid && userReacts.includes(post.reactionType)) {
            const index = userReacts.indexOf(post.reactionType);
            userReacts.splice(index, 1);
            hadUserReact = userReacts.length > 0; // Met à jour had_user_react en fonction des réactions restantes
          }

          return {
            ...msg,
            reactions: post.reactions, // Les nouvelles réactions mises à jour venant du backend
            waitingReactions: newWaitingReactions,
            user_reacts: userReacts,
            had_user_react: hadUserReact,
          };
        } else if (post.reactOn === "comment" && msg.id === post.post_id) {
          const updatedCommentDetails = msg.comment_details?.map(comment => {
            if (comment.id === post.id) {
              const commentReactions = { ...comment.reactions };
              const newWaitingReactions = { ...comment.waitingReactions };
              const userReacts = [...(comment.user_reacts || [])];
              let hadUserReact = comment.had_user_react || false;

              // Supprime la réaction temporaire
              if (post.temporaryReactionId && newWaitingReactions[post.temporaryReactionId]) {
                delete newWaitingReactions[post.temporaryReactionId];
              }

              // Si l'UUID correspond, on retire la réaction
              if (post.react_uuid === uuid && userReacts.includes(post.reactionType)) {
                const index = userReacts.indexOf(post.reactionType);
                userReacts.splice(index, 1);
                hadUserReact = userReacts.length > 0;

                // Décrémente le compteur de réactions pour ce type
                if (commentReactions[post.reactionType]) {
                  commentReactions[post.reactionType] = Math.max(0, (commentReactions[post.reactionType] || 0) - 1);
                  if (commentReactions[post.reactionType] === 0) {
                    delete commentReactions[post.reactionType];
                  }
                }
              }

              return {
                ...comment,
                reactions: post.reactions || commentReactions,
                waitingReactions: newWaitingReactions,
                user_reacts: userReacts,
                had_user_react: hadUserReact,
              };
            }
            return comment;
          });

          return { ...msg, comment_details: updatedCommentDetails };
        }
        return msg;
      });

      return {
        channels: {
          ...state.channels,
          [channelId]: {
            ...channel,
            messages: updatedMessages
          }
        }
      };
    });
  },

  addWaitingReaction: (post, reactionType, tempId, type) => {
    set((state) => {
      const channelId = post.channel_id.toString();
      const channel = state.channels[channelId];
      if (!channel) return state;

      const updatedMessages = channel.messages.map(msg => {
        if (msg.id === post.id) {
          if (type === 'post') {
            
            const reactions = msg.reactions || {};
            const waitingReactions = msg.waitingReactions || {};
            const userReacts = msg.user_reacts || [];
            let hadUserReact = msg.had_user_react || false;
            const currentReactionsCount = Number(reactions[reactionType]) || 0;
            
            if (!userReacts.includes(reactionType)) {
              userReacts.push(reactionType);
              hadUserReact = true;
              reactions[reactionType] = currentReactionsCount + 1;
            } else {
              reactions[reactionType] = currentReactionsCount - 1;
              if (reactions[reactionType] === 0) {
                delete reactions[reactionType];
              }
              userReacts.splice(userReacts.indexOf(reactionType), 1);
            }
            waitingReactions[tempId] = reactionType;

            return { ...msg, reactions, waitingReactions, user_reacts: userReacts, had_user_react: hadUserReact };
          }

          if (type === 'comment' && post.commentId) {
            const updatedCommentDetails = msg.comment_details.map(comment => {
              if (comment.id === post.commentId) {
                const commentReactions = comment.reactions || {};
                const waitingReactions = comment.waitingReactions || {};
                const userReacts = comment.user_reacts || [];
                let hadUserReact = comment.had_user_react || false;
                const currentReactionsCount = Number(commentReactions[reactionType]) || 0;
                commentReactions[reactionType] = currentReactionsCount + 1;

                if (!userReacts.includes(reactionType)) {
                  userReacts.push(reactionType);
                  hadUserReact = true;
                }
                waitingReactions[tempId] = reactionType;

                return { ...comment, reactions: commentReactions, waitingReactions, user_reacts: userReacts, had_user_react: hadUserReact };
              }
              return comment;
            });

            return { ...msg, comment_details: updatedCommentDetails };
          }
        }
        return msg;
      });

      return {
        channels: {
          ...state.channels,
          [channelId]: {
            ...channel,
            messages: updatedMessages
          }
        }
      };
    });
  },

  updateChannelInfo: (channelId, channelInfo) => {
    set((state) => ({
      channels: {
        ...state.channels,
        [channelId.toString()]: {
          ...state.channels[channelId.toString()],
          ...channelInfo
        }
      }
    }));
  },

  addChannels: (channels) => {
    set((state) => {
      const updatedChannels = { ...state.channels };
      channels.forEach(channel => {
        updatedChannels[channel.id.toString()] = {
          ...channel,
          messages: state.channels[channel.id.toString()]?.messages || []
        };
      });
      return { channels: updatedChannels };
    });
  },

  resetNewPostsCount: (channelId: string) => {
    set((state) => {
      const channel = state.channels[channelId];
      if (!channel) return state;

      return {
        channels: {
          ...state.channels,
          [channelId.toString()]: {
            ...channel,
            new_posts_count: "0"
          }
        }
      };
    });
  },
  updateMessageSavedStatus: (channelId, messageId, isSaved) => {
    set((state) => {
      const channel = state.channels[channelId.toString()];
      if (!channel) return state;

      const updatedMessages = channel.messages.map(msg => {
        if (msg.id === messageId) {
          return { ...msg, is_saved: isSaved };
        }
        return msg;
      });

      return {
        channels: {
          ...state.channels,
          [channelId.toString()]: {
            ...channel,
            messages: updatedMessages
          }
        }
      };
    });
  },

  updateMessageSignaledStatus: (channelId, messageId, isSignaled) => {
    set((state) => {
      const channel = state.channels[channelId.toString()];
      if (!channel) return state;

      const updatedMessages = channel.messages.map(msg => {
        if (msg.id === messageId) {
          return { ...msg, is_signaled: isSignaled };
        }
        return msg;
      });

      return {
        channels: {
          ...state.channels,
          [channelId.toString()]: {
            ...channel,
            messages: updatedMessages
          }
        }
      };
    });
  },
}));