import { useMessageStore } from 'stores/feed/channelMessage';
import { useFetcher } from './api'
import { useEffect } from 'react';

export type GetAllChannelsFromCommunityTypes = {
  refetchOnMount?: boolean;
  communityId: number;
  channelId: number;
};

export type GetAllResponseOfPost = {
  refetchOnMount?: boolean;
  postId: string;
  communityId: string;
  channelId: string;
};

export type ChannelsListObj = Partial<{
  id: number;
  community_id: number;
  name: string;
  description: string;
  members: number;
  created_at: string;
  created_by: string;
  can_users_publish: boolean;
}>

export const useGetOnePost = ({ postId, communityId, channelId, refetchOnMount = true, enabled = true }: any) => {

  const addMessages = useMessageStore((state: any) => state.addMessages);

  const { data, refetch, isError, error, isLoading } = useFetcher<any, ChannelsListObj[]>({ key: `community-${communityId}-${channelId}-${postId}-posts`, path: `community/${communityId}/${channelId}/${postId}`, refetchOnMount, enabled })

  useEffect(() => {
    if (data) {
      addMessages(channelId, [data]);
    }
  }, [data, channelId, addMessages]);

  return { data, refetch, isError, error, isLoading };

}

export const useAllPostOfChannel = ({ communityId, channelId, refetchOnMount = true }: GetAllChannelsFromCommunityTypes) => {
  const addMessages = useMessageStore((state: any) => state.addMessages);

  const { data, refetch, isError, error, isLoading } = useFetcher<any, ChannelsListObj[]>({ key: `community-${communityId}-${channelId}-posts`, path: `community/${communityId}/${channelId}/posts`, refetchOnMount })

  useEffect(() => {
    if (data) {
      addMessages(channelId, data);
    }
  }, [data, channelId, addMessages]);

  return { data, refetch, isError, error, isLoading };
}

export const useAllResponseOfPost = ({ postId, communityId, channelId, refetchOnMount = true, enabled = true }: any) => {

  const addComments = useMessageStore((state: any) => state.addComments);

  const { data, refetch, isError, error, isLoading } = useFetcher<any, ChannelsListObj[]>({ key: `response-${postId}-posts`, path: `community/${communityId}/${channelId}/${postId}/comments`, refetchOnMount, enabled })

  useEffect(() => {
    if (data) {
      addComments(channelId, postId, data);
    }
  }, [data, channelId, addComments]);

  return { data, refetch, isError, error, isLoading };
}

