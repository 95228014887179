import { useNavigate } from "react-router-dom";
import { useAuthStore, logoutSelector } from "stores/auth"
import { useModaleStore } from "stores/modale";

export const ProfileModal = () => {

    const logout = useAuthStore(logoutSelector)
    const { setModale } = useModaleStore();
    const navigate = useNavigate()

    const handleLogout = async () => {
        setModale(false, '', '')
        await logout()
    }

    
    const handleCreateCommunity = async () => {
        setModale(false, '', '')
        navigate('/create-community')
    }

    return (
        <div className="flex flex-col gap-3 items-center">
            <button
                className="bg-grey-500 text-white p-mini rounded-micro w-[300px] text-center"
                onClick={handleCreateCommunity}
            >
                Créer une communauté
            </ button>
            <button
                className="bg-grey-500 text-white p-mini rounded-micro w-[300px] text-center"
                onClick={handleLogout}
            >
                Déconnexion
            </ button>
        </div>
    )
}