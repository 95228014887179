interface ToggleSwitchProps {
  isOn: boolean;
  handleToggle: (checked: boolean) => void;
  label?: string;
}

export const SwitchButton = ({ isOn, handleToggle, label } : ToggleSwitchProps) => {
  return (
    <label className="flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          className="sr-only"
          checked={isOn}
          onChange={(e) => handleToggle(e.target.checked)}
        />
        <div
          className={`block w-14 h-8 rounded-full transition-colors duration-300 ease-in-out ${
            isOn ? 'bg-blue-500' : 'bg-gray-300'
          }`}
        ></div>
        <div
          className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform duration-300 ease-in-out ${
            isOn ? 'transform translate-x-full' : ''
          }`}
        ></div>
      </div>
      {label && <span className="ml-3 text-sm font-medium text-gray-900">{label}</span>}
    </label>
  );
};