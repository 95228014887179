import { useGetSearchUsers } from "@hooks/queries/search"
import React, { useState, useEffect, useCallback } from 'react';
import { UserSearchResults } from "components/search/SearchResultUserTab";
import { Config } from "services/config";
import { useModaleStore } from "stores/modale";
import CharacterCount from '@tiptap/extension-character-count'
import Document from '@tiptap/extension-document'
import Mention from '@tiptap/extension-mention'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import Text from '@tiptap/extension-text'
import { EditorContent, useEditor } from '@tiptap/react'
import suggestion from "components/community/feed/tiptap/suggestion";
import { AnimatedButton } from 'components/globals/buttons/AnimatedButton.tsx'
import { Icon } from 'assets/icons.tsx'
import { useSendConversationMessage } from '@hooks/queries/messages.ts'

const limit = 280

export const NewUserMessageModale = () => {

    const [step, setStep] = useState<number>(0);
    const [selectedUser, setSelectedUser] = useState<any>(undefined);

    return (
        <div className="w-full md:min-w-[600px]">
            {
                step === 0 ? (
                    <SearchUser setSelectedUser={setSelectedUser} setStep={setStep} />
                ) : (
                    <StartNewConversation selectedUser={selectedUser} setStep={setStep} />
                )
            }
        </div>
    )
}

const SearchUser = ({ setSelectedUser, setStep } : any ) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [previousData, setPreviousData] = useState<any>(undefined);
    const [shouldFetch, setShouldFetch] = useState(false);

    const { data, isLoading, isError, refetch } = useGetSearchUsers({
        search: searchTerm,
        enabled: shouldFetch,
        refetchOnMount: false,
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
    };

    const handleStepChange = (step: number) => {
        setStep(step);
    }

    const handleSearch = useCallback(() => {
        if (searchTerm.trim().length > 2) {
            setShouldFetch(true);
        } else {
            setShouldFetch(false);
        }
    }, [searchTerm]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    useEffect(() => {
        if (shouldFetch) {
            refetch();
        }
    }, [shouldFetch, refetch]);

    useEffect(() => {
        if (data) {
            setPreviousData(data);
        }
    }, [data]);

    return (
        <div className="w-full">
            <div className="flex bg-lightGray items-center w-full md:min-w-96 justify-start gap-3 py-mini rounded-micro px-small">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.1094" cy="12.1094" r="6.51042" stroke="#8E8E93" strokeWidth="1.82292" />
                    <path d="M20.3125 20.3125L17.1875 17.1875" stroke="#8E8E93" strokeWidth="1.82292" strokeLinecap="round" />
                </svg>
                <input
                    type="text"
                    id="search-users"
                    placeholder="Rechercher dans Feder"
                    className="bg-transparent w-full focus:outline-none font-[300]"
                    onChange={handleInputChange}
                    value={searchTerm}
                />
            </div>
            <UserSearchResults
                data={data}
                previousData={previousData}
                isLoading={isLoading}
                isError={isError}
                searchTerm={searchTerm}
                setStep={handleStepChange}
                userSelected={(user) => { setSelectedUser(user) }}
            />
        </div>
    )
}

export const StartNewConversation = ({ selectedUser, setStep } : any) => {

    const { sendMessage, isPending } = useSendConversationMessage({ recipientId: selectedUser.uuid });
    const { setModale } = useModaleStore();

    const generateProfilPic = (uuid: string) => {
        return `${Config.CDN_HOST}user%2F${uuid}`
    }

    const editor = useEditor({
        extensions: [
            Document,
            Paragraph,
            Text,
            CharacterCount.configure({
                limit,
            }),
            Mention.configure({
                HTMLAttributes: {
                    class: 'mention',
                },
                suggestion,
            }),
            Placeholder.configure({
                placeholder: 'Votre message …',
            }),
        ],
        editorProps: {
            attributes: {
                class: '!w-full !px-4 py-3 !rounded-tiny border bg-white min-h-[200px]',
            },
        },
        content: ``,
    })

    const handleSendMessage = async () => {
        const content = editor?.getJSON()
        if (!content) return;
        editor?.commands.clearContent()
        await sendMessage({ content })
        setModale(false, '', '', {});
    };

    return (
        <div className="w-full flex flex-col items-start justify-start gap-6">
            <AnimatedButton className="flex items-center justify-center gap-2" onClick={() => setStep(0)}>
                <Icon type={"ArrowLeft"} size={20} color={"black"} />
                <span className="text-gray-600 font-light">Retour à la liste</span>
            </AnimatedButton>
            <div className="w-full flex flex-col items-start justify-start gap-5">
                <div className="w-full flex items-center justify-start gap-4">
                    <img src={generateProfilPic(selectedUser.uuid)} alt="profile" className="w-20 h-20 rounded-full object-fit-cover" />
                    <div className="w-full flex flex-col items-start justify-start gap-0">
                        <p className="text-2xl">{selectedUser.user_name}</p>
                        {
                            selectedUser.first_name && selectedUser.last_name && (
                                <p className="text-lg text-gray-600 font-light italic">{selectedUser.first_name} {selectedUser.last_name}</p>
                            )
                        }
                    </div>
                </div>
                <p className="text-gray-500 font-light">Membre depuis : {selectedUser.created_at}</p>
                <div className="w-full flex flex-col items-center justify-between editorParentBox relative gap-3">
                    <EditorContent editor={editor} />
                    <AnimatedButton disabled={isPending} className="disabled:bg-gray-300 bg-darkBlue rounded-tiny w-full py-2.5 flex items-center justify-center gap-2" type="button" onClick={handleSendMessage}>
                        <p className="text-white text-xl">Envoyer</p>
                        <Icon type={"Send"} size={20} color={"white"} variant={"Bold"} />
                    </AnimatedButton>
                </div>
            </div>
        </div>
    )
}