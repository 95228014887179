import { useActivityStore } from "stores/activityStore";
import { useCallback, useEffect } from "react";
import { useSocket } from "@hooks/websocket/useSocket";

export const CommunityActivity = ({ communityId }: { communityId: number }) => {

    const { updateCommunityTimestamp } = useActivityStore();
    const { subscribeToCommunity, unsubscribeFromCommunity } = useSocket();

    useEffect(() => {
        console.log('CommunityLayout rendering')
        subscribeToCommunity(`${communityId}`);

        return () => {
            console.log('CommunityLayout unmounting')
            unsubscribeFromCommunity(`${communityId}`);
        }

    }, []);

    const updateTimestamp = useCallback(() => {
        updateCommunityTimestamp(`${communityId}`);
    }, [communityId, updateCommunityTimestamp]);

    useEffect(() => {
        updateTimestamp();
        const interval = setInterval(updateTimestamp, 5000);
        return () => clearInterval(interval);
    }, [updateTimestamp]);

    return null;

}