
import { useGetAllCategories, useGetCategoriesFromCommunity } from "@hooks/queries/categories";
import { Icon } from "assets/icons";
import { AnimatedButton } from "components/globals/buttons";
import { useState, useMemo, ChangeEvent, useEffect } from "react";
import { CategoryItemType } from "interfaces/categories";
import { useAdminCommunity } from "stores/adminCommunity";
import { useMutator } from "@hooks/queries/api";

export const AdminUpdateCategoriesComponent = () => {
    const { communityId } = useAdminCommunity();
    const { data: allCategories, isLoading, isError } = useGetAllCategories({ refetchOnMount: false });
    const {
        data: actualCategories,
        isLoading: isActualCategoriesLoading,
        isError: isActualCategoriesError
    } = useGetCategoriesFromCommunity({
        communityId: communityId,
        refetchOnMount: false,
        enabled: communityId ? true : false
    });

    const {
        mutateAsync: updateCategories,
        isError: isUpdateError,
        isPending: isUpdatePending,
        isSuccess: isUpdateSuccess
    } = useMutator<any>(`/community/${communityId}/categories/update`);

    const [selectedCategories, setSelectedCategories] = useState<CategoryItemType[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");

    // Initialiser les catégories sélectionnées avec les données actuelles
    useEffect(() => {
        if (actualCategories?.list && !isActualCategoriesLoading) {
            setSelectedCategories(actualCategories.list);
        }
    }, [actualCategories, isActualCategoriesLoading]);

    const handleSelectCategory = (category: CategoryItemType) => {
        if (selectedCategories.length < 3) {
            setSelectedCategories([...selectedCategories, category]);
            setSearchQuery(""); // Réinitialiser la recherche après sélection
        }
    }

    const handleRemoveCategory = (categoryId: number) => {
        setSelectedCategories(selectedCategories.filter((c) => c.id !== categoryId));
    }

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handleUpdateCategories = async () => {
        try {
            await updateCategories({
                categories: selectedCategories.map(cat => cat)
            });
            // Optionnel: Ajouter un message de succès ou une redirection
        } catch (error) {
            // Optionnel: Gérer l'erreur (afficher un message, etc.)
            console.error('Erreur lors de la mise à jour des catégories:', error);
        }
    };

    // Filtrer les catégories disponibles en fonction de la sélection ET de la recherche
    const availableCategories = useMemo(() => {
        const unselectedCategories = allCategories?.list.filter(
            category => !selectedCategories.some(selected => selected.id === category.id)
        ) || [];

        if (!searchQuery.trim()) return unselectedCategories;

        return unselectedCategories.filter(category =>
            category.name.toLowerCase().includes(searchQuery.toLowerCase().trim())
        );
    }, [allCategories?.list, selectedCategories, searchQuery]);

    if (isError || isActualCategoriesError) {
        return <div>Erreur lors du chargement des catégories</div>;
    }

    if (isLoading || isActualCategoriesLoading) {
        return <div>Chargement...</div>;
    }

    return (
        <div className="w-full h-full flex flex-col items-center justify-center gap-4">
            <h1 className="text-xl">Dans quelle catégorie placer cette communauté ?</h1>
            <p className="text-sm text-gray-500">
                {`Sélectionnez jusqu'à ${3 - selectedCategories.length} catégorie${3 - selectedCategories.length > 1 ? 's' : ''} supplémentaire${3 - selectedCategories.length > 1 ? 's' : ''}`}
            </p>

            {/* Catégories sélectionnées */}
            {selectedCategories.length > 0 && (
                <div className="bg-app rounded-md p-4 flex gap-2 flex-wrap items-center justify-center min-h-[60px]">
                    {selectedCategories.map((category) => (
                        <div
                            key={category.id}
                            style={{
                                backgroundColor: `${category.assets.primaryColor}30`,
                                borderColor: category.assets.primaryColor,
                                color: category.assets.primaryColor
                            }}
                            className="px-3 py-1.5 rounded text-center cursor-pointer flex items-center gap-2"
                            onClick={() => handleRemoveCategory(category.id)}
                        >
                            <p>{category.name}</p>
                            <Icon
                                type="CloseSquare"
                                size={12}
                                color={category.assets.primaryColor}
                                className="cursor-pointer"
                            />
                        </div>
                    ))}
                </div>
            )}

            {/* Barre de recherche */}
            <div className="w-full max-w-md relative">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Rechercher une catégorie..."
                    className="w-full px-4 py-2 border rounded-md pr-10"
                />
                {searchQuery && (
                    <Icon
                        type="CloseSquare"
                        size={16}
                        className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer text-gray-500 hover:text-gray-700"
                        onClick={() => setSearchQuery("")}
                    />
                )}
            </div>

            {/* Catégories disponibles */}
            <div className="w-full flex gap-2 flex-wrap items-center justify-center min-h-[60px]">
                {availableCategories.length === 0 && searchQuery && (
                    <p className="text-gray-500">Aucune catégorie trouvée pour "{searchQuery}"</p>
                )}
                {!isLoading && availableCategories.map((category) => (
                    <AnimatedButton
                        key={category.id}
                        style={{
                            backgroundColor: `${category.assets.primaryColor}30`,
                            borderColor: category.assets.primaryColor,
                            color: category.assets.primaryColor
                        }}
                        className="px-3 py-1.5 rounded text-center"
                        onClick={() => handleSelectCategory(category)}
                        disabled={selectedCategories.length >= 3}
                    >
                        <p>{category.name}</p>
                    </AnimatedButton>
                ))}
            </div>

            {/* Bouton de validation */}
            <AnimatedButton
                className="w-full max-w-md py-2 rounded-md bg-black text-white disabled:opacity-50"
                onClick={handleUpdateCategories}
                disabled={isUpdatePending || selectedCategories.length === 0}
            >
                {isUpdatePending ? "Mise à jour..." : "Valider"}
            </AnimatedButton>
            {isUpdateError && (
                <div className="w-full max-w-md py-2 rounded-md bg-red-400 text-white disabled:opacity-50">
                    <p className="text-red-700 text-center">Erreur lors de la mise à jour des catégories</p>
                </div>
            )}
            {isUpdateSuccess && (
                <div className="w-full max-w-md py-2 rounded-md bg-green-400 text-white disabled:opacity-50">
                    <p className="text-green-700 text-center">Les catégories ont été mises à jour avec succès</p>
                </div>
            )}
        </div>
    );
}