import { useEffect } from 'react';
import { useMutator } from "@hooks/queries/api";
import { useCommunityById } from "@hooks/queries/community";
import { useModaleStore } from "stores/modale";
import { CommunityLandingPageContent } from "components/community/landing"
import { LandingSkeleton } from "skeleton";
import { useAuthStore } from "stores/auth";
import { useTopbarMobileData } from "stores/topbarMobileData";
import { useLandingStore } from 'stores/landing';
import { useThemeCommunity } from 'stores/themeCommunity';

export type CommunityLandingPagePropsType = {
    communityId: string;
    template?: string;
    isWaiting?: boolean;
    isAdmin?: boolean;
    refetch?: () => void;
}

export const CommunityLandingPage = ({ communityId, template, isAdmin = false, refetch, isWaiting }: CommunityLandingPagePropsType) => {

    const { data: communityData, isLoading: isGetLoading } = useCommunityById({ communityId, refetchOnMount: false, enabled: !isAdmin });
    const { mutateAsync: joinCommunity, isError: isJoinError, error: joinError, isPending: isJoinLoading } = useMutator<any>(`/community/${communityId}/join`)
    const { setModale } = useModaleStore();
    const { isLogged } = useAuthStore.getState()
    const { setTopbarMobileData } = useTopbarMobileData();
    const { setBlocks } = useLandingStore();
    const { theme } = useThemeCommunity();

    const handleJoinCommunity = async () => {
        if (!isLogged) {
            return setModale(true, 'AuthAndJoin', 'modaleFullHeight', { community: {communityId, communityName: communityData?.name}, callbackFunction: joinCommunity, loginTitle: "Connexion et rejoindre", registerTitle: "Inscription et rejoindre" })
        }

        await joinCommunity({})
        if (communityData?.type_id === 2) {
            setModale(true, 'JoinCommunityForm', 'modale', { communityId });
        } else {
            refetch && refetch()
        }
    }

    useEffect(() => {
        setTopbarMobileData({ showTopbar: false });
    }, [setTopbarMobileData]);

    useEffect(() => {
        if (template) {
            setBlocks(JSON.parse(template));
        }
    }, [template]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const join = urlParams.get('join');
        if (join && join === 'true' && !isWaiting) {
            handleJoinCommunity();
        }
    }, [communityId])

    if (isGetLoading) {
        return <LandingSkeleton />;
    }

    return (
        <CommunityLandingPageContent
            communityId={communityId}
            isAdmin={false}
            joinCommunity={handleJoinCommunity}
            isLoading={isJoinLoading}
            isError={isJoinError}
            error={joinError}
            isWaiting={isWaiting}
            theme={theme}
        />
    );
}