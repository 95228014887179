import { Icon } from "assets/icons";
import { Config } from "services/config";
import { useModaleStore } from "stores/modale";

export const ImageDisplayModale = () => {

    const { modaleProps, setModale } = useModaleStore()
    
    const closeModale = () => {
        setModale(false, '', '', {...modaleProps});
    }

    const url = Config.CDN_HOST + "user%2F" + modaleProps.owner_uuid;

    return (
        <div className="flex flex-col gap-3 items-center w-full lg:w-[1080px] cursor-auto">
            <div className="w-full flex items-center justify-between border-b border-gray-100 pb-4 mb-4">
                <div className="flex items-center justify-start gap-3">
                    <img src={url} alt="" className="w-10 h-10 rounded-full object-cover"/>
                    <span className="text-black">{modaleProps?.user_name}</span>
                </div>
                <Icon type="CloseCircle" size={28} color="black" onClick={closeModale} className="cursor-pointer" />
            </div>
            <img src={modaleProps?.imageUrl} alt="image" className="max-w-full lg:max-h-[65vh]"/>
        </div>
    )
}