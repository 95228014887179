import { useMemo } from "react";
import { AnimatedButton } from "components/globals/buttons";
import { useNavigate } from "react-router-dom";
import { generateHTML } from '@tiptap/html';
import Text from '@tiptap/extension-text';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Image from '@tiptap/extension-image';
import { useBreakpointContext } from "context/BreakpointContext";

export const ConversationItem = ({ conversation, conversationId }: any) => {
    
    const navigate = useNavigate();
    const { isMobile } = useBreakpointContext();

    const previewMessage = useMemo(() => {
        const html = generateHTML(conversation?.last_message_content, [
            Text,
            Document,
            Paragraph,
            Image
        ]);

        // Remove any img tags from the HTML
        const withoutImages = html.replace(/<img[^>]*>/g,"");

        // Parse the HTML to plain text
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = withoutImages;
        let text = tempDiv.textContent || tempDiv.innerText || "";

        // Limit to 30 characters
        text = text.slice(0, 30);
        
        // Add ellipsis if the text was truncated
        if (text.length === 30 && conversation?.last_message_content.length > 30) {
            text += "...";
        }

        return text;
    }, [conversation?.last_message_content]);

    const handleOpenConversation = (id: string) => {
        navigate('/messages/' + id);
    };

    return (
        <AnimatedButton
            className={`w-full flex items-center justify-start gap-2 relative ${conversationId === conversation.other_user_uuid ? "bg-gray-100" : "bg-white"} ${isMobile ? "!bg-app" : "p-2"} rounded-tiny`}
            onClick={() => handleOpenConversation(conversation.other_user_uuid)}
        >
            <img 
                src={`https://pub-388e882330ee44c7b74215d8e0935cb9.r2.dev/user%2F${conversation.other_user_uuid}`} 
                alt="" 
                className="w-10 h-10 rounded-full object-cover" 
            />
            <div className="flex flex-col items-start justify-start">
                <p className="text-black">{conversation.other_user_name}</p>
                <p className="text-gray-400 italic font-light text-sm">{previewMessage}</p>
            </div>
            {conversation.unread_count > 0 && (
                <div className="absolute py-0.5 px-3 flex items-center justify-end bottom-1/2 right-2 translate-y-[50%] gap-2 bg-red-100 rounded-full">
                    <div className="w-2 h-2 rounded-full bg-red-700" />
                    <p>{conversation.unread_count}</p>
                </div>
            )}
        </AnimatedButton>
    );
};