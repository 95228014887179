import React, { useState, useEffect, useCallback } from 'react';
import { useGetSearch } from "@hooks/queries/search";
import { SearchResultsTabs } from 'components/search/SearchResultsTabs';

export const SearchModale: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [shouldFetch, setShouldFetch] = useState(false);
    const [previousData, setPreviousData] = useState<any>(undefined);

    const { data, isLoading, isError, refetch } = useGetSearch({ 
        search: searchTerm, 
        enabled: shouldFetch,
        refetchOnMount: false,
    });

    const handleSearch = useCallback(() => {
        if (searchTerm.trim().length > 2) {
            setShouldFetch(true);
        } else {
            setShouldFetch(false);
        }
    }, [searchTerm]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    useEffect(() => {
        if (shouldFetch) {
            refetch();
        }
    }, [shouldFetch, refetch]);

    useEffect(() => {
        if (data) {
            setPreviousData(data);
        }
    }, [data]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
    };

    return (
        <div className="w-full md:min-w-[600px]">
            <div className="flex bg-lightGray items-center w-full md:min-w-96 justify-start gap-3 py-mini rounded-micro px-small">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.1094" cy="12.1094" r="6.51042" stroke="#8E8E93" strokeWidth="1.82292" />
                    <path d="M20.3125 20.3125L17.1875 17.1875" stroke="#8E8E93" strokeWidth="1.82292" strokeLinecap="round" />
                </svg>
                <input 
                    type="text" 
                    id="search" 
                    placeholder="Rechercher dans Feder" 
                    className="bg-transparent w-full focus:outline-none font-[300]"
                    onChange={handleInputChange}
                    value={searchTerm}
                />
            </div>
            <SearchResultsTabs 
                data={data} 
                previousData={previousData}
                isLoading={isLoading} 
                isError={isError}
                searchTerm={searchTerm}
            />
        </div>
    );
};