import { useFetcher } from "./api";

export type CommunityRequestTypes = {
    refetchOnMount?: boolean;
    communityId?: string;
};

export interface CommunityRewards {
    id: number;
    name: string;
    description: string | null;
    community_id: number;
    need_for_unlock: {
      referral: number;
    };
    is_active: boolean;
    created_at: string;
    alreadyWon?: boolean;
  }

export const useGetAdminActions = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return useFetcher<any, any>({ key: `community-admin-actions-${communityId}`, path: `/community/${communityId}/actions/admin`, refetchOnMount })
}

export const useGetAllAdminRewards = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return useFetcher<any, CommunityRewards[]>({ key: `community-admin-rewards-${communityId}`, path: `/community/${communityId}/admin/gamification/rewards`, refetchOnMount })
}

export const useGetAllRewards = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
  return useFetcher<any, CommunityRewards[]>({ key: `community-rewards-${communityId}`, path: `/community/${communityId}/admin/gamification/rewards`, refetchOnMount })
}