import React, { useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useAllResponseOfPost, useGetOnePost } from "@hooks/queries/posts";
import { useMessageStore } from "stores/feed/channelMessage";
import { MessagesFeed } from "components/community/feed";
import { CommentItem } from "components/community/feed/comments";
import { InputComments } from "components/community/feed/inputComment";
import { PostSkeleton } from "skeleton/community/postSkeleton";
import { useTopbarMobileData } from "stores/topbarMobileData";

const ThreadContent: React.FC<{ communityId: string; channelId: string; postId: string }> = ({ communityId, channelId, postId }) => {
    const bottomRef = useRef<HTMLDivElement>(null);

    const parsedPostId = parseInt(postId);

    const postWithComments = useMessageStore(state => {
        const channelMessages = state.channels[channelId]?.messages || [];
        return channelMessages.find((msg: any) => msg.id === parsedPostId);
    });

    // TODO : Add error handling
    const { isLoading } = useAllResponseOfPost({ 
        communityId, 
        channelId, 
        postId, 
        enabled: postWithComments !== undefined && postWithComments?.comments !== postWithComments?.comment_details?.length && postWithComments?.comments !== 0 
    });
    const { isLoading: isPostLoading, isError: isPostError } = useGetOnePost({ 
        postId, 
        communityId, 
        channelId, 
        refetchOnMount: false, 
        enabled: postWithComments === undefined 
    });

    const scrollToBottom = () => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (!isLoading && !isPostLoading) {
            scrollToBottom();
        }
    }, [isLoading, isPostLoading]);

    if (isPostLoading || !postWithComments) {
        return <PostSkeleton />;
    }

    if (isPostError) {
        return <div>Erreur lors du chargement du post</div>;
    }

    return (
        <>
            <div className="w-full h-full overflow-auto hideScrollBar">
                <MessagesFeed post={postWithComments} isThread={true} communityId={communityId} channelId={channelId} />
                
                {(postWithComments?.comments ?? 0) > 0 && (
                    <div className="w-full flex gap-3 items-center px-intermediate">
                        <span className="font-light text-xs text-grey-400">
                            {postWithComments?.comments} {postWithComments?.comments ?? 0 > 1 ? "réponses" : "réponse"}
                        </span>
                        <div className="h-[1px] flex-1 bg-grey-200 w-full"></div>
                    </div>
                )}
                
                {isLoading ? (
                    Array.from({ length: postWithComments?.comments || 0 }, (_, i) => <PostSkeleton key={i} />)
                ) : (
                    postWithComments?.comment_details?.map((comment: any) => (
                        <CommentItem 
                            key={comment.id} 
                            comment={comment} 
                            communityId={communityId} 
                            channelId={channelId} 
                            postId={postWithComments.id} 
                        />
                    ))
                )}
                
                <div ref={bottomRef}></div>
            </div>
            <InputComments communityId={communityId} channelId={channelId} postId={postId} />
        </>
    );
};

export const ThreadPage: React.FC = () => {
    const { communityId, channelId, postId } = useParams();
    const { setTopbarMobileData } = useTopbarMobileData();

    useEffect(() => {
        setTopbarMobileData({ pageName: 'post', showTopbar: true, withGoBack: true, withGoHome: false });
    }, [setTopbarMobileData]);

    return (
        <div className="w-full h-full flex flex-col items-center justify-between  overflow-hidden relative">
            {communityId && channelId && postId ? (
                <ThreadContent communityId={communityId} channelId={channelId} postId={postId} />
            ) : (
                <div>Paramètres manquants</div>
            )}
        </div>
    );
};