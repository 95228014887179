export const fakeTheme = {
    primaryColor: "#0000FF",  
    secondaryColor: "#3333FF",  
    tertiaryColor: "#6666FF",  
    quaternaryColor: "#9999FF",  
    name: "Blue",
}

export interface themeTypes {
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    quaternaryColor: string;
    name: string;
}