import React, { useState, useEffect } from 'react';
import { useLandingStore } from "stores/landing"
import { AnimatedButton } from "../buttons"
import { Icon } from "assets/icons"
import { Routes } from "navigation/useAppRoutes"
import { useNavigate, useLocation } from "react-router-dom"
import { useAdminCommunity } from 'stores/adminCommunity';
import { useMutator } from '@hooks/queries/api';
import { useAboutStore } from 'stores/about';

export const AdminBuilderTopbar = () => {
    const PageBuilderItems = [
        {
            label: 'Landing Page',
            route: Routes.adminLanding,
            path: 'landing'
        },
        {
            label: 'À propos',
            route: Routes.adminAbout,
            path: 'about'
        }
    ]

    const { blocks } = useLandingStore()
    const { content } = useAboutStore()
    const navigate = useNavigate()
    const location = useLocation()
    const { communityId } = useAdminCommunity()
    const [selectedPage, setSelectedPage] = useState(PageBuilderItems[0].label)
    const { mutateAsync: udpateLanding, isPending: isUdpateLandingLoading, isError: isUdpateLandingError, isSuccess: isLandingSucces } = useMutator<any>("/community/" + communityId + "/admin/landing/update");
    const { mutateAsync: updateAbout, isPending: isUpdateAboutLoading, isError: isUpdateAboutError, isSuccess: isAboutSucces } = useMutator<any>("/community/" + communityId + "/admin/about/update");

    useEffect(() => {
        const currentPath = location.pathname.split('/').pop()
        const currentPage = PageBuilderItems.find(item =>
            item.path === currentPath ||
            location.pathname.includes(item.path)
        )
        if (currentPage) {
            setSelectedPage(currentPage.label)
        }
    }, [location.pathname])

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedItem = PageBuilderItems.find(item => item.label === event.target.value)
        if (selectedItem) {
            setSelectedPage(selectedItem.label)
            const newPath = selectedItem.route.replace(':communityId', communityId)
            if (location.pathname !== newPath) {
                navigate(newPath)
            }
        }
    }

    const handleGoBack = () => {
        navigate(Routes.adminSettings.replace(':communityId', communityId))
    }

    const handleSave = async () => {
        if (selectedPage === 'Landing Page') {
            await udpateLanding({ landing: blocks })
        } else if (selectedPage === 'À propos') {
            await updateAbout({ content: content })
        }
    }

    return (
        <div className="w-full bg-white border-b border-0.5 flex items-center justify-between px-mini py-tiny gap-3 relative z-[30]">
            <div className="w-full bg-white flex items-center justify-start gap-3">
                <AnimatedButton onClick={handleGoBack} className={`bg-gray-200 inline-flex gap-2 items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-9 px-4 py-2`}>
                    <Icon type="ArrowLeft" size={20} />
                    <span className="text-sm text-black">Retour</span>
                </AnimatedButton>
            </div>
            <div className="w-full bg-white flex items-center justify-center gap-3">
                <select value={selectedPage} onChange={handleSelectChange}>
                    {PageBuilderItems.map((item, index) => (
                        <option key={index} value={item.label}>{item.label}</option>
                    ))}
                </select>
            </div>
            <div className="w-full bg-white flex items-center justify-end gap-3">
                {
                    isUdpateLandingLoading || isUpdateAboutLoading ? <span className="text-black">Sauvegarde en cours...</span> 
                    : isUdpateLandingError || isUpdateAboutError ? <span className="text-red-500">Erreur lors de la sauvegarde</span> 
                    : isLandingSucces || isAboutSucces ? <span className="text-green-500">Sauvegardé</span> 
                    : <></>
                }
                <AnimatedButton onClick={handleSave} className={`bg-black inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-9 px-4 py-2`}>
                    <span className="text-sm text-white">Sauvegarder</span>
                </AnimatedButton>
            </div>
        </div>
    )
}