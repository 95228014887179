import { Config } from "services/config";
import { UserType } from "interfaces/users";
import { useNavigate } from "react-router-dom";
import { useModaleStore } from "stores/modale";

export const UserItem = ({ user, setStep, userSelected }: { user: UserType, setStep?: (step: number) => void, userSelected?: (user: UserType) => void }) => {

    const profilPic = Config.CDN_HOST + "user%2F" + user.uuid;
    const navigate = useNavigate();
    const { setModale, modaleProps } = useModaleStore();

    const handleClickOnUser = () => {
        if(setStep && userSelected) {
            userSelected(user);
            setStep(2);
        } else {
            navigate(`/user/${user.uuid}`)
            setModale(false, '', '', {...modaleProps });
        }
    }

    return (
        <div className="w-full flex items-center justify-start gap-3" onClick={handleClickOnUser}>
            <div className="w-[35px] !h-[35px] rounded-full bg-center bg-cover cursor-pointer" style={{ backgroundImage: `url(${profilPic})` }} />
            <div className="flex flex-col items-start justify-start gap-0">
            <p className="text-sm font-[500]">{user.user_name}</p>
            <p className="text-xs text-gray-400 font-[400]">{user.first_name} {user.last_name}</p>
            </div>
        </div>
    )
}