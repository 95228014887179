import { AdminLayout } from "layout/AdminLayout";
import { useParams } from "react-router-dom";
import { AdminChannelsList } from "components/admin/channels";
import { AnimatedButton } from "components/globals/buttons";
import { useModaleStore } from "stores/modale";
import { Icon } from "assets/icons";
import { useBreakpointContext } from "context/BreakpointContext";

export const AdminChannelsPage = () => {

    const { communityId } = useParams();

    if (!communityId) {
        return null
    }
    const { setModale } = useModaleStore();
    const { isMobile } = useBreakpointContext();

    const handleCreateChannel = async () => {
        setModale(true, "CreateNewChannelModale", "modale", { communityId: communityId })
    }

    return (
        <AdminLayout>
            <div className="bg-app h-full w-full overflow-hidden">
                <div className="h-full w-full bg-app flex flex-col gap-8 items-center p-intermediate overflow-auto">
                    <div className="w-full flex justify-between items-start">
                        <div className="flex flex-col gap-1">
                            <p className="font-light text-sm opacity-80">Ma communauté &gt; Réglages &gt; Channels</p>
                            <h1 className="text-left w-full text-xl">Mes channels</h1>
                        </div>
                        <AnimatedButton onClick={handleCreateChannel} className="bg-black py-tiny px-intermediate rounded-tiny text-white flex items-center justify-center gap-2">
                            <Icon type="Add" size={18} color={"white"} />
                            {!isMobile && <span>Créer un nouveau channel</span>}
                        </AnimatedButton>
                    </div>
                    <AdminChannelsList communityId={parseInt(communityId)} />
                </div>
            </div>
        </AdminLayout>
    );
}