import { ChangeBackground, ChangeFeatureImage } from "components/admin/settings"
import { AdminLayout } from "layout/AdminLayout"

export const AdminSettingsImages = () => {
    return (
        <AdminLayout>
            <div className="bg-app h-full w-full overflow-hidden">
                <div className="h-full w-full bg-app flex flex-col gap-6 items-center p-intermediate overflow-auto">
                    <div className="w-full flex flex-col items-start justify-start gap-2">
                        <p className="font-light text-sm opacity-80">Ma communauté &gt; Réglages &gt; Images</p>
                        <h1 className="text-left w-full text-xl">Modifier mes images</h1>
                    </div>
                    <div className="w-full flex flex-col items-start justify-start gap-2">
                        <p className="text-gray-600 font-[600]">Changer de background</p>
                        <ChangeBackground />
                    </div>
                    <div className="w-full flex flex-col items-start justify-start gap-2">
                        <p className="text-gray-600 font-[600]">Changer mes images de fonctionnalités</p>
                        <ChangeFeatureImage />
                    </div>
                    <div className="w-full flex flex-col items-start justify-start gap-2">
                        <p className="text-gray-600 font-[600]">Changer mes images de présentation</p>
                        <ChangeBackground />
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}