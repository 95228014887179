export function timeSince(dateIso : string) {
    const now = new Date();
    const past = new Date(dateIso);
    // @ts-ignore
    const secondsPast = Math.floor((now - past) / 1000);
  
    if(secondsPast === 0){
      return `maintentant`;
    }else if (secondsPast < 60) {
      return `Il y a ${secondsPast} secondes`;
    } else if (secondsPast < 3600) {
      return `Il y a ${Math.floor(secondsPast / 60)} minutes`;
    } else if (secondsPast < 86400) {
      return `Il y a ${Math.floor(secondsPast / 3600)} heures`;
    } else if (secondsPast < 604800) {
      return `Il y a ${Math.floor(secondsPast / 86400)} jours`;
    } else {
      return `Il y a ${Math.floor(secondsPast / 604800)} semaines`;
    }
  }
  