export const LogoIcon = () => {
    return (
        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.550679" y="0.550679" width="60.8986" height="60.8986" rx="10.4629" fill="#1B1B1B" stroke="white" strokeWidth="1.10136" />
            <path d="M33.0405 44.5969C33.2329 46.0603 32.0939 47.3589 30.618 47.3589C29.1514 47.3589 28.0152 46.076 28.1925 44.6202L29.8342 31.1336C29.8672 30.8622 29.8672 30.5879 29.8342 30.3166L28.1925 16.8299C28.0152 15.3741 29.1514 14.0913 30.618 14.0913C32.0939 14.0913 33.233 15.3898 33.0405 16.8532L31.2746 30.2844C31.2361 30.5769 31.2361 30.8732 31.2746 31.1658L33.0405 44.5969Z" fill="white" />
            <path d="M19.8228 39.7469C18.6517 40.6452 16.9576 40.3081 16.2196 39.0299C15.4863 37.7598 16.0292 36.1344 17.3786 35.56L29.8792 30.2384C30.1307 30.1314 30.3683 29.9942 30.5867 29.8299L41.4457 21.6648C42.6178 20.7835 44.2969 21.126 45.0302 22.3961C45.7682 23.6743 45.2131 25.31 43.8495 25.875L31.3348 31.0613C31.0623 31.1742 30.8056 31.3224 30.5715 31.502L19.8228 39.7469Z" fill="white" />
            <path d="M17.4136 25.8756C16.0501 25.3105 15.495 23.6748 16.2329 22.3966C16.9662 21.1265 18.6453 20.784 19.8175 21.6654L30.6764 29.8304C30.8948 29.9947 31.1324 30.1319 31.3839 30.2389L43.8845 35.5605C45.2339 36.1349 45.7768 37.7603 45.0436 39.0304C44.3056 40.3086 42.6115 40.6457 41.4404 39.7474L30.6916 31.5025C30.4575 31.3229 30.2009 31.1747 29.9283 31.0618L17.4136 25.8756Z" fill="white" />
            <circle cx="30.5305" cy="31.4698" r="6.10606" fill="white" />
        </svg>

    )
}