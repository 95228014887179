import { ChecklistBlockProps } from "../types";
//import { Icon } from "assets/icons";
import { EditableBlockLayout } from "../EditableBlockLayout";
import { useEffect, useState } from "react";

export interface ChecklistItem {
    name: string;
}


export const ChecklistBlock: React.FC<ChecklistBlockProps> = ({
    id,
    items,
    isAdmin,
    onUpdate
}) => {
    const [localItems, setLocalItems] = useState<ChecklistItem[]>(items);

    useEffect(() => {
        setLocalItems(items);
    }, [items]);

    const handleDataChange = (newItems: ChecklistItem[]) => {
        setLocalItems(newItems);
        onUpdate(id, newItems);
    };

    return (
        <EditableBlockLayout
            id={id}
            isAdmin={isAdmin || false}
            onUpdate={onUpdate}
        >
            {(isEditing) => (
                <div className="flex flex-col gap-2">
                    {localItems.map((item, index) => (
                        <div key={index} className="flex items-center gap-3 p-3 bg-white border rounded">
                            <div className="w-5 h-5 rounded-full bg-blue-100 flex items-center justify-center">
                                ✓
                            </div>

                            {isEditing ? (
                                <div className="flex flex-1 items-center gap-2">
                                    <input
                                        type="text"
                                        value={item.name}
                                        onChange={(e) => {
                                            const newItems = [...localItems];
                                            newItems[index] = { name: e.target.value };
                                            handleDataChange(newItems);
                                        }}
                                        className="flex-1 p-2 border rounded"
                                    />
                                    <button
                                        onClick={() => {
                                            handleDataChange(localItems.filter((_, i) => i !== index));
                                        }}
                                        className="text-red-500 px-2"
                                    >
                                        ×
                                    </button>
                                </div>
                            ) : (
                                <span>{item.name}</span>
                            )}
                        </div>
                    ))}

                    {isEditing && (
                        <button
                            onClick={() => handleDataChange([...localItems, { name: '' }])}
                            className="mt-2 p-2 border-2 border-dashed border-gray-300 rounded text-gray-500 w-full"
                        >
                            + Ajouter un élément
                        </button>
                    )}
                </div>
            )}
        </EditableBlockLayout>
    );
};