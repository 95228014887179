import React from 'react';
import { MessagesFeed } from ".";
import { PostFeed } from "@hooks/queries/communities";
import { PostSkeleton } from "skeleton/community/postSkeleton";

interface PostsListProps {
    data?: PostFeed[];
    isLoading?: boolean;
    isFullWidth?: boolean;
}

export const PostsList: React.FC<PostsListProps> = ({ data = [], isLoading = false, isFullWidth = false }) => {
    if (isLoading) {
        return (
            <div className={`w-full flex flex-col items-center justify-center`}>
                {[...Array(5)].map((_, index) => (
                    <PostSkeleton key={index} />
                ))}
            </div>
        );
    }

    return (
        <>
            {data.map((post: PostFeed, index: number) => (
                <MessagesFeed
                    key={index}
                    post={post}
                    isFullWidth={isFullWidth}
                    communityId={post.community_id}
                    channelId={post.channel_id}
                    isPreview={true}
                />
            ))}
        </>
    );
};