import { create } from 'zustand'

export interface OpenedCommunityStore {
    communityId: string;
    communityName: string | null;
    channelId: string;
    setOpenedCommunity: (id: string, name?: string) => void;
    setOpenedChannel: (id: string) => void;
}

export const useOpenedCommunity = create<OpenedCommunityStore>((set) => ({
    communityId: '',
    communityName: '',
    channelId: '',
    hadCommunityChange: 0,
    setOpenedCommunity: (id: string, name?: string) => set({
        communityId: id,
        communityName: name || null,
    }),
    setOpenedChannel: (id: string) => set({ channelId: id }),
}));
