import React from 'react';

type CommunityCardsSkeletonProps = {
    isInline?: boolean;
};

export const CommunityCardsSkeleton: React.FC<CommunityCardsSkeletonProps> = ({ isInline = false }) => {
    if (isInline) {
        return (
            <div className="w-full bg-white py-2 pl-2 pr-5 rounded-tiny overflow-hidden flex items-center justify-between animate-pulse">
                <div className="w-full flex items-center justify-start gap-3">
                    <div className="h-[50px] w-[50px] rounded-mimi bg-lightGray" />
                    <div className="flex flex-col items-start gap-0.5">
                        <div className="h-4 w-24 bg-lightGray rounded" />
                        <div className="flex gap-1 items-center justify-start">
                            <div className="w-[20px] lg:w-[30px] h-[20px] lg:h-[30px] rounded-full bg-lightGray" />
                            <div className="h-3 w-16 bg-lightGray rounded" />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-end justify-end">
                    <div className="h-4 w-8 bg-lightGray rounded" />
                    <div className="h-3 w-12 bg-lightGray rounded mt-1" />
                </div>
            </div>
        );
    }

    return (
        <div className="w-full bg-white rounded-mini flex flex-col gap-2 animate-pulse">
            <div className="!h-36 !w-36 rounded-tiny bg-lightGray" />
            <div className="w-full flex gap-1.5 items-center">
                <div className="w-[25px] lg:w-[30px] h-[25px] lg:h-[30px] rounded-full bg-lightGray" />
                <div className="flex flex-col gap-1.5 items-start justify-start">
                    <div className="bg-lightGray h-4 w-24 rounded-md" />
                    <div className="bg-lightGray h-2 w-12 rounded" />
                </div>
            </div>
        </div>
    );
};