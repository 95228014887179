import { useAdminCommunity } from "stores/adminCommunity";
import { useAdminSidebarMenu } from "navigation/useAdminSidebarMenu";
import { Link } from "react-router-dom";

export const MobileAdminTopbar = () => {

    const { communityId } = useAdminCommunity();
    const navigationMenuPages = useAdminSidebarMenu();

    return (
        <div className="w-full h-max overflow-y-hidden">
            <div className="w-full overflow-auto flex gap-3 items-center px-3">
                {
                    navigationMenuPages.map(({ key, path, label }) => (
                        <Link to={path.replace(':communityId', communityId)} key={key} className="h-max">
                            <div className="w-full flex gap-2 items-center justify-start px-small py-mini rounded-tiny w-max" key={key}>
                                {/* <Icon type={icon} color="#000" size={16} /> */}
                                <p className="text-base font-light">{label}</p>
                            </div>
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}