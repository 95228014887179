import React from 'react';
import { useBreakpointContext } from "context/BreakpointContext";
import { AnimatedButton } from "components/globals/buttons";
import {
    BlockProps,
    JoinCommunityBlockProps,
} from './types';
import {
    GalleryBlock,
    ChecklistBlock,
    TextBlock,
    TitleBlock,
    CallToActionBlock,
    HeroBlock,
    StatsBlock,
    ReviewsBlock
} from "./components";
import { useLandingStore } from 'stores/landing';
import { BuilderSidebar } from 'components/globals/sidebar/builder';

const JoinCommunityBlock: React.FC<JoinCommunityBlockProps & { joinCommunity: () => void; isLoading: boolean }> = ({
    title,
    description,
    joinCommunity,
    isLoading,
    isWaiting
}) => {
    const { isDesktop } = useBreakpointContext();

    return (
        <div className={`w-full lg:bg-white lg:sticky lg:top-5 lg:p-small lg:p-small relative rounded-lg flex flex-col gap-3 lg:border`}>
            {isDesktop && (
                <>
                    <p className="text-lg font-bold">{title}</p>
                    <p className="font-light">{description}</p>
                </>
            )}
            {
                !isWaiting ? (
                    <AnimatedButton
                        className="w-full bg-black text-white py-small rounded-md"
                        onClick={joinCommunity}
                    >
                        {isLoading ? "Chargement..." : "Rejoindre la communauté"}
                    </AnimatedButton>
                ) : (
                    <>
                        <p className='text-gray-600'>Une demande est déjà en attente</p>
                        <AnimatedButton
                            className="w-full bg-black text-white py-small rounded-md"
                        >
                            {isLoading ? "Chargement..." : "Modifier ma demande"}
                        </AnimatedButton>
                    </>
                )
            }
        </div>
    );
};

// Main Component
export const CommunityLandingPageContent: React.FC<{
    communityId: string;
    joinCommunity: () => void;
    isLoading: boolean;
    isError: boolean;
    error: any;
    isWaiting?: boolean;
    isAdmin: boolean;
    theme: any;
}> = ({
    communityId,
    joinCommunity,
    isLoading,
    isError,
    error,
    isWaiting,
    isAdmin,
    theme
}) => {

        const { blocks, updateBlock } = useLandingStore();
        const { isDesktop } = useBreakpointContext();

        const enrichBlocks = (blocks: BlockProps[]): BlockProps[] => {
            return blocks.map(block => {
                switch (block.type) {
                    case 'stats':
                        return {
                            ...block,
                        };
                    case 'joinCommunity':
                        return {
                            ...block,
                            isWaiting: isWaiting
                        } as JoinCommunityBlockProps;
                    default:
                        return block;
                }
            });
        };

        const enrichedBlocks = enrichBlocks(blocks as any);

        const joinCommunityBlock = enrichedBlocks.find(block => block.type === 'joinCommunity') as JoinCommunityBlockProps | undefined;
        const otherBlocks = enrichedBlocks.filter(block => block.type !== 'joinCommunity');

        const renderBlock = (block: BlockProps) => {
            switch (block.type) {
                case 'header':
                    return <HeroBlock key={block.id} communityId={communityId} {...block} />;
                case 'text':
                    return <TextBlock
                        key={block.id}
                        {...block}
                        isAdmin={isAdmin}
                    />
                case 'title':
                    return <TitleBlock
                        key={block.id}
                        {...block}
                        isAdmin={isAdmin}
                        onUpdate={(id, newContent) => updateBlock(id, block => ({ ...block, content: newContent }))}
                    />
                case 'gallery':
                    return <GalleryBlock key={block.id} {...block} isAdmin={isAdmin} communityId={communityId}/>;
                case 'stats':
                    return <StatsBlock key={block.id} {...block} communityId={communityId} />;
                case 'checklist':
                    return <ChecklistBlock
                        key={block.id}
                        {...block}
                        isAdmin={isAdmin}
                        onUpdate={(id, updatedItems) => updateBlock(id, block => ({ ...block, items: updatedItems }))}
                    />;
                case 'cta':
                    return <CallToActionBlock
                        key={block.id}
                        theme={theme}
                        joinCommunity={joinCommunity}
                        isWaiting={isWaiting}
                        {...block}
                    />;
                case 'reviews':
                    return <ReviewsBlock key={block.id} {...block} />;
                default:
                    console.warn(`Unknown block type: ${(block as any).type}`);
                    return null;
            }
        };

        return (
            <div className="bg-app w-full">
                <div className="w-full flex flex-col items-center p-intermediate relative bg-app">
                    <div className='w-full flex items-center justify-center gap-6'>
                        <div className="max-w-[1080px] flex flex-col lg:flex-row gap-6 relative w-full pb-[60px] lg:pb-[50px]">
                            <div className={`w-full lg:w-8/12 flex flex-col gap-8 ${isAdmin ? "pb-10" : "pb-auto"}`}>
                                {otherBlocks.map(renderBlock)}
                            </div>

                            {joinCommunityBlock && (
                                <div className="w-full lg:w-4/12 lg:sticky lg:top-0 lg:h-screen">
                                    <JoinCommunityBlock
                                        {...joinCommunityBlock}
                                        joinCommunity={joinCommunity}
                                        isLoading={isLoading}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    {isDesktop && isAdmin && (
                        <div className='w-full max-w-[350px] mt-4 fixed bottom-5 right-5'>
                            <BuilderSidebar />
                        </div>
                    )}

                    {isError && (
                        <div className="w-full bg-red-200 text-red-800 p-3 rounded-md mt-4">
                            <p className="text-center">{error?.data?.error || 'An error occurred'}</p>
                        </div>
                    )}
                </div>
            </div>
        );
    };