import { CommunityRewards, useGetAllAdminRewards } from "@hooks/queries/gamification";
import { useModaleStore } from "stores/modale";
import { AnimatedButton } from "components/globals/buttons";
import { AdminLayout } from "layout/AdminLayout"
import { useParams } from "react-router-dom"
import { Icon } from "assets/icons";
//import { useGetAdminActions } from "@hooks/queries/gamification"
//import { AdminActionsList, AdminActionsListError, AdminActionsListLoading } from "components/admin/settings/gamification/ActionList"

export const AdminSettingsGamificationPage = () => {

    const { communityId } = useParams();
    const { setModale } = useModaleStore();
    //const { isLoading: isActionsLoading, isError: isActionsError } = useGetAdminActions({ communityId, refetchOnMount: false })

    const { data, isLoading: isRewardsLoading, isError: isRewardsError, refetch: refetchRewards } = useGetAllAdminRewards({ communityId, refetchOnMount: false })

    const handleCreateNewRewards = () => {
        setModale(true, "CreateRewardsModale", "modale", { communityId: communityId, type: "create", refetchRewards })
    }

    const handleUpdateRewards = (reward: CommunityRewards) => {
        setModale(true, "CreateRewardsModale", "modale", { communityId: communityId, type: "update", reward, refetchRewards })
    }


    return (
        <AdminLayout>
            <div className="bg-app h-full w-full overflow-hidden">
                <div className="h-full w-full bg-app flex flex-col gap-6 items-center p-intermediate overflow-auto">
                    <div className="w-full flex flex-col gap-1">
                        <p className="font-light text-sm opacity-80">Ma communauté &gt; Réglages &gt; Gamification</p>
                        <h1 className="text-left w-full text-xl">Gamification</h1>
                    </div>

                    {/* {
                        isActionsLoading ? <AdminActionsListLoading /> : isActionsError ? <AdminActionsListError /> : <AdminActionsList />
                    } */}

                    <div className="w-full p-6 rounded-small bg-gray-100 flex flex-col items-center justify-center gap-2">
                        <p className="text-center text-xl">🚀</p>
                        <p className="text-center font-[300]">Actuellemement 1 filleul = 1 point, nous sommes en train de travailler sur une nouvelle version beaucoup plus poussée !</p>
                    </div>

                    <hr className="w-full" />
                    <div className="w-full flex flex-col items-start justify-start gap-2">
                        <p className="text-lg">Quelles récompenses je donne ?</p>
                    </div>
                    <div className="w-full flex flex-col items-start justify-start gap-2">
                        {
                            isRewardsLoading ? (
                                <>
                                {[1, 2, 3].map((_, index) => (
                                    <div className="animate-pulse border w-full h-16 rounded-lg flex items-center justify-between bg-gray-100" key={index} />
                                ))}
                                </>
                            ) : isRewardsError ? (
                                <div>Error...</div>
                            ) : data?.length === 0 ? (
                                <div>Pas encore de rewards</div>
                            ) : (
                                data?.map((reward, index) => {

                                    const needForUnlock = reward.need_for_unlock.referral || 0

                                    return (
                                        <div className="border w-full py-3 px-3 rounded-lg flex items-center justify-between bg-white cursor-pointer hover:bg-gray-100 bg-white transition-all" key={index} onClick={() => handleUpdateRewards(reward)}>
                                            <div className="flex flex-col items-start justify-start gap-0">
                                                <p className="font-light">{needForUnlock} filleuls</p>
                                                <div className="flex items-center justify-start gap-2">
                                                    <div className={`w-2 h-2 rounded-full ${reward.is_active ? "bg-green-600" : "bg-red-600"}`} />
                                                    <p className="font-[600]">{reward.name}</p>
                                                </div>
                                            </div>
                                            <Icon type="More" size={20} color={"black"} />
                                        </div>
                                    )
                                })
                            )
                        }
                    </div>
                    <AnimatedButton onClick={handleCreateNewRewards} className="bg-grey-100 hover:bg-grey-200 rounded-md flex items-center justify-center cursor-pointer w-full py-2.5">Ajouter une récompense</AnimatedButton>
                </div>
            </div>
        </AdminLayout>
    )
}