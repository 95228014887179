import { useAuthStore } from "stores/auth";
import { useBreakpointContext } from "context/BreakpointContext";
import { DesktopTopBar } from "./desktopTopbar";
import { MobileTopBar } from "./mobileTopbar";


export const TopBar = () => {

    const { uuid } = useAuthStore.getState()
    const { isDesktop } = useBreakpointContext()

    return (
        <>
            {
                isDesktop ? (
                    <DesktopTopBar uuid={uuid} />
                ) : (
                    <MobileTopBar uuid={uuid} />
                )
            }
        </>
    )
}