import { useMyAdminCommunities, useMyCommunities } from "hooks/queries/communities";
import { useState } from "react";
import { MyCommunityCards, MyCommunityCardsSkeleton } from "./MyCommunityCards";
import { CommunitiesList } from "./communitiesList";
import { MyCommunitiesListProps } from "./myCommunitiesList";

export const MyCommunitiesPage = ({ isSlider, isHomePage = true, onDisableDrag }: MyCommunitiesListProps) => {

    isSlider = isSlider || false;

    const { data: communities = { page: 0, list: [] }, isLoading, isError, error } = useMyCommunities({ refetchOnMount: false, enabled: !isHomePage });
    const { data: adminCommunities = { page: 0, list: [] }, isLoading: isAdminCommunityLoading, isError: isAdminCommunityError, error:AdminError } = useMyAdminCommunities({ refetchOnMount: false });

    const [fakeCommunities, _] = useState([1, 2, 3, 4, 5]);

    const handleWheel = (boolean: boolean) => {
        onDisableDrag?.(boolean);
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <div className="w-full flex flex-col gap-4">
                <div className="w-full flex flex-col gap-0">
                    <h2 className="lg:text-xl text-lg">Mes communautés</h2>
                    <p className="text-sm font-[300] text-textGray">Vous gérez ces communautés</p>
                </div>
                <CommunitiesList
                    communities={adminCommunities}
                    isError={isAdminCommunityError}
                    isLoading={isAdminCommunityLoading}
                    onWheel={handleWheel}
                    error={AdminError}
                />
            </div>

            {isLoading && (
                <div className="w-full flex flex-col gap-3 overflow-x-hidden">
                    <div className="w-full flex flex-col gap-0">
                        <h2 className="lg:text-xl text-lg">Vous êtes membre</h2>
                    </div>
                    <div className={`gap-1.5 lg:gap-3 grid grid-cols-2 lg:grid-cols-3 w-full`}>
                        {fakeCommunities.map((_, index) => (
                            <MyCommunityCardsSkeleton key={index} />
                        ))}
                    </div>
                </div>
            )}

            {isError && (
                // @ts-ignore
                <div>{error ? error.error : "Une erreur est survenue"}</div>
            )}

            {!isLoading && communities.list.length === 0 && (
                <div className="text-center">Aucune communauté disponible</div>
            )}

            {!isLoading && communities.list.length > 0 && (
                <div
                    className="w-full flex flex-col gap-3"
                >
                    <div className="w-full flex flex-col gap-0">
                        <h2 className="lg:text-xl text-lg">Vous êtes membre</h2>
                    </div>
                    <div className={`gap-1.5 lg:gap-3 grid grid-cols-2 lg:grid-cols-3 w-full`}>
                        {communities.list.map((community :any, index: number) => (
                            <MyCommunityCards key={index} community={community} isSlider={isSlider} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
