import { useState } from "react";
import { Icon } from "assets/icons";
import { AnimatedButton } from "components/globals/buttons";

type ChangeUserNameTypes = {
    username: string;
    setUsername: (username: string) => void;
}

export const ChangeUsername = ({ username, setUsername }: ChangeUserNameTypes) => {
    const [isEditing, setIsEditing] = useState(false);
    const [localUsername, setLocalUsername] = useState(username);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalUsername(e.target.value);
    };

    const handleSave = () => {
        setUsername(localUsername);
        setIsEditing(false);
    };

    return (
        <div className="w-full flex items-center justify-center gap-4">
            {!isEditing ? (
                <div className="flex items-center justify-center gap-1">
                    <span className="text-2xl">@</span>
                    <span className="text-2xl w-44 text-left">{localUsername}</span>
                </div>
            ) : (
                <div className="flex items-center justify-center gap-1">
                    <span className="text-2xl">@</span>
                    <input
                        type="text"
                        value={localUsername}
                        onChange={handleUsernameChange}
                        className="text-2xl bg-transparent border-b-2 border-gray-300 focus:outline-none w-44"
                        autoFocus
                    />
                </div>
            )}
            <AnimatedButton onClick={isEditing ? handleSave : handleEditClick} className="cursor-pointer">
                <Icon type={isEditing ? "ArrowCircleRight2" : "Edit"} size={16} />
            </AnimatedButton>
        </div>
    );
}
