import { create } from 'zustand'

interface AboutStore {
    content: any;
    setContent: (content: any) => void;
}

export const useAboutStore = create<AboutStore>((set) => ({
    content: {},
    setContent: (content) => set({content}),
}));