import { useMutator } from "@hooks/queries/api";
import { useAllAdminChannels } from "@hooks/queries/channels";
import { AnimatedButton } from "components/globals/buttons";
import { useEffect, useState } from "react";
import { useModaleStore } from "stores/modale";

export const CreateNewChannelModale = () => {

    const [communityId, setCommunityId] = useState<number | null>(null);
    const { modaleProps, setModale } = useModaleStore();
    const communityIdProps = modaleProps?.communityId;

    const { refetch, isLoading: isRefetchLoading } = useAllAdminChannels({ 
        communityId: communityIdProps ? communityIdProps : undefined, 
        refetchOnMount: false, 
        enabled: !!communityIdProps 
    });
    
    useEffect(() => {
        if (modaleProps?.communityId) {
            setCommunityId(modaleProps.communityId);
        } else {
            setModale(false, "", '');
        }
    }, [modaleProps, setModale]);

    const [form, setForm] = useState({
        name: '',
        canUsersPublish: false,
    })
    const { mutateAsync: createNewChannel, isError, isPending, isSuccess } = useMutator<any>(`/community/${communityId}/admin/channel/new`)

    useEffect(() => {
        if (isSuccess) {
            refetch().then(() => {
                setModale(false, "", '');
            });
        }
    }, [isSuccess, refetch, setModale]);

    const handleCreateChannel = async () => {
        const body = { name: form.name, canUsersPublish: form.canUsersPublish, channelType: 'public' }
        await createNewChannel(body)
    }

    const handleClose = () => {
        setModale(false, "", '');
    }

    const handleUpdateName = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setForm({
            ...form,
            name: e.target.value,
        })
    }

    const handleUpdateCanUsersPublish = (canUserPublishRes: boolean) => {
        setForm({
            ...form,
            canUsersPublish: canUserPublishRes,
        })
    }

    return (
        <div className="modal-container px-4 py-6 flex flex-col items-center space-y-8 md:w-[500px] cursor-auto">
            <div className="flex flex-col items-start justify-start w-full gap-5 font-light">
                <div className="flex flex-col items-start justify-start w-full gap-5 font-light">
                    <h2 className="font-bold text-xl">Créer un nouveau channel</h2>
                    <hr className="w-full" />
                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                        <label>Nom du channel</label>
                        <input type="text" placeholder="Nom du channel..." onChange={handleUpdateName} className="bg-transparent w-full focus:outline-none border h-10 rounded-lg px-3" />
                    </div>
                    <div className="flex flex-col gap-2 items-start justify-start">
                        <label>Les membres peuvent publier ?</label>
                        <div className="flex items-center justify-start gap-3">
                            <div className={`w-20 h-10 rounded-lg flex items-center justify-center border cursor-pointer ${form.canUsersPublish ? "bg-gray-300" : "bg-white"}`} onClick={() => handleUpdateCanUsersPublish(true)}>
                                <span>Oui</span>
                            </div>
                            <div className={`w-20 h-10 rounded-lg flex items-center justify-center border cursor-pointer ${!form.canUsersPublish ? "bg-gray-300" : "bg-white"}`} onClick={() => handleUpdateCanUsersPublish(false)}>
                                <span>Non</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full items-center justify-start gap-3">
                        <AnimatedButton onClick={handleClose} className="bg-grey-100 hover:bg-grey-200 rounded-md py-1.5 px-3 flex items-center justify-center cursor-pointer">Annuler</AnimatedButton>
                        <AnimatedButton onClick={handleCreateChannel} className="bg-red-300 hover:bg-red-400 rounded-md py-1.5 px-3 flex items-center justify-center cursor-pointer">Confirmer</AnimatedButton>
                    </div>
                    {
                        isError || isPending || isRefetchLoading && (
                            <div>
                                {isError && <p className='text-red-500'>Une erreur est survenue</p>}
                                {isPending || isRefetchLoading && <p>En cours...</p>}
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}