import React, { useEffect, useState } from 'react';
import { AnimatedButton } from "components/globals/buttons";
import { CommunityThemeConfig } from "data/communityThemeConfig";
import { useMutator } from '@hooks/queries/api';
import { useParams } from 'react-router-dom';

export type SelectCommunityColorsTypes = {
    selectedTheme: string;
    actualTheme: string;
    setSelectedTheme: (theme: string) => void;
}

type ThemeType = {
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    quaternaryColor: string;
    name: string;
}

export const SelectColor: React.FC<SelectCommunityColorsTypes> = ({ selectedTheme, actualTheme, setSelectedTheme }) => {

    const { communityId } = useParams();

    const { mutateAsync: updateTheme, isError, isPending, isSuccess } = useMutator<any>("/community/" + communityId + "/admin/theme/update");
    const themes = Object.values(CommunityThemeConfig);
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (!isInitialized && actualTheme) {
            setSelectedTheme(actualTheme);
            setIsInitialized(true);
        }
    }, [actualTheme, setSelectedTheme, isInitialized]);

    const handleThemeSelect = (themeName: string) => {
        setSelectedTheme(themeName);
    };

    const handlePublishTheme = () => {
        updateTheme({ theme: selectedTheme });
    }

    const renderThemeColors = (theme: ThemeType) => (
        <div className="w-full flex h-4 md:h-8 rounded-md overflow-hidden">
            <div className="w-full h-full" style={{ backgroundColor: theme.primaryColor }} />
            <div className="w-full h-full" style={{ backgroundColor: theme.secondaryColor }} />
            <div className="w-full h-full" style={{ backgroundColor: theme.tertiaryColor }} />
            <div className="w-full h-full" style={{ backgroundColor: theme.quaternaryColor }} />
        </div>
    );

    return (
        <div className="flex flex-col items-center w-full gap-8">
            <div className="w-full flex flex-col gap-5">
                <div className="w-full flex items-center justify-between">
                    <h1 className="text-xl">Mon thème actuel</h1>
                    <AnimatedButton onClick={handlePublishTheme} className={`bg-black hover:bg-grey-700 rounded-md flex items-center justify-center cursor-pointer px-small py-tiny text-white`}>
                        { isPending ? "Chargement..." : isSuccess ? "Thème publié" : "Publier" }
                    </AnimatedButton>
                </div>
                {isError && <p className="text-red-500">Une erreur est survenue</p>}
                <div className="flex flex-col items-center w-full gap-3">
                    {themes.filter(theme => theme.name === selectedTheme).map((theme, index) => (
                        <div key={index} className="w-full flex flex-col gap-2 items-start p-small border-2 rounded-xl bg-gray-100">
                            <p className="font-bold">{theme.name}</p>
                            {renderThemeColors(theme)}
                        </div>
                    ))}
                </div>
            </div>

            <div className="w-full h-0.5 bg-grey-300" />

            <div className="w-full flex flex-col gap-5">
                <h1 className="text-xl">Les autres thèmes disponibles</h1>
                <div className="flex flex-col items-center w-full gap-3">
                    {themes.filter(theme => theme.name !== selectedTheme).map((theme, index) => (
                        <div key={index}
                            className="w-full flex flex-col gap-2 items-start p-small border rounded-xl cursor-pointer hover:bg-gray-100"
                            onClick={() => handleThemeSelect(theme.name)}>
                            <p>{theme.name}</p>
                            {renderThemeColors(theme)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}