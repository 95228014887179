import { AnimatedButton } from "components/globals/buttons";
import { useModaleStore } from "stores/modale";
import { useMutator } from '@hooks/queries/api';

export const ChangeCommunityVisibility = () => {

    const { setModale, modaleProps } = useModaleStore();

    if (!modaleProps?.communityId || !modaleProps?.newCommunityType) {
        setModale(false, "", '');
    };

    const { mutateAsync: updateCommunityType, isError, isPending } = useMutator<any>("/community/" + modaleProps?.communityId + "/type/update");

    const handleClose = () => {
        setModale(false, "", '');
    }

    const handleValidate = async () => {
        await updateCommunityType({ newType: modaleProps?.newCommunityType });
        setModale(false, "", '');
    }


    return (
        <div className="modal-container px-4 py-6 flex flex-col items-center space-y-8 md:w-[500px] cursor-auto">
            <div className="flex flex-col items-start justify-start w-full gap-5 font-light">
                <div className="flex flex-col items-start justify-start w-full gap-2 font-light">
                    <h2 className="font-bold text-xl">Changer la visibilité</h2>
                    <hr className="w-full" />
                    <p>Un changement de visibilité est un choix important, voici ce qu'il faut savoir.</p>
                    <p>Si votre communauté passe de privée à publique :</p>
                    <ul className="list-disc list-inside ml-3">
                        <li>Tous les membre en attente seront acceptés</li>
                        <li>Vous ne pourrez plus filtrer l'entrée</li>
                        <li>Vous pourrez toujours rendre des canaux privés</li>
                    </ul>
                    <p>Si votre communauté passe de publique à privée :</p>
                    <ul className="list-disc list-inside ml-3">
                        <li>Vous devrez accepter manuellement les membres</li>
                        <li>Vous pourrez toujours rendre des canaux privés</li>
                    </ul>
                </div>
                <div className="flex w-full items-center justify-start gap-3">
                    <AnimatedButton onClick={handleClose} className="bg-grey-100 hover:bg-grey-200 rounded-md py-1.5 px-3 flex items-center justify-center cursor-pointer">Annuler</AnimatedButton>
                    <AnimatedButton onClick={handleValidate} className="bg-red-300 hover:bg-red-400 rounded-md py-1.5 px-3 flex items-center justify-center cursor-pointer">Confirmer</AnimatedButton>
                </div>
                {
                    isError || isPending && (
                        <div>
                            {isError && <p className='text-red-500'>Une erreur est survenue</p>}
                            {isPending && <p>En cours...</p>}
                        </div>
                    )
                }
            </div>
        </div>
    )
}