import { Icon } from "assets/icons"
import { useModaleStore } from "stores/modale"
import { Config } from "services/config"
import { useProfileRefresh } from "stores/profileRefresh"
import { useParams } from "react-router-dom"

export const ChangeBackground = () => {

    const { communityId } = useParams();
    const { imageUpdateCounter }: any = useProfileRefresh();
    const { setModale } = useModaleStore();

    const openModale = () => {
        setModale(true, 'ChangeCommunityBackgroundModal', 'modale', { communityId });
    };

    const url = Config.CDN_HOST + "community%2F" + communityId + "%2Fbg?counter=" + imageUpdateCounter;


    return (
        <div className="rounded-lg flex justify-center items-center relative">
            <div onClick={openModale} className="bg-white flex justify-center items-center gap-2 py-1.5 px-3 absolute bottom-2 left-2 rounded-lg shadow-medium cursor-pointer">
                <p>Modifier</p>
                <Icon type="Camera" size={26} color="#8E8E93" />
            </div>
            <div className="rounded overflow-hidden w-full max-w-[500px] h-full">
                <img src={url} alt="" className="w-full h-full object-cover" />
            </div>
        </div>
    )
}