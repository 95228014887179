//import { useState } from "react"
import { AnimatedButton } from "components/globals/buttons"
import { useSendReaction } from "hooks/feed/reactions"
import { useModaleStore } from "stores/modale"
import { useEmojis } from 'stores/emojis'
import { useFetchEmojis } from '@hooks/queries/emojis'

export const ReactPicker = ({ post, communityId, channelId, type, comment }: { post?: any, communityId: string | number, type: string, channelId: string | number, comment?: any }) => {

    const { sendReaction } = useSendReaction({ communityId, channelId, postId: post.id });
    const { emojis: emojiData, isError } = useEmojis((state: any) => ({ emojis: state.emojis, isError: state.isError }));
    const { fetchEmojis } = useFetchEmojis();
    fetchEmojis(Object.keys(emojiData).length === 0);

    const handleAddReaction = async (event: React.MouseEvent | any, reaction: string) => {
        event.stopPropagation();
        sendReaction({ reaction, type, commentId: comment?.id || null })
    }

    const userHasReacted = (reaction: string) => {
        if (type === "comment") return comment.had_user_react && comment.user_reacts.includes(reaction);
        if (type === "post") return post.had_user_react && post.user_reacts.includes(reaction);
    }

    const { setModale } = useModaleStore();

    const handleOpenReactModale = (event: any) => {
        event.stopPropagation();
        setModale(true, 'ReactModale', 'snapped', { communityId, channelId, postId: post.id, type, commentId: comment?.id || null });
    }

    return (
        <>
            {
                !isError && (
                    <div className="flex items-stretch justify-start gap-2 flex-wrap flex-wrap" onClick={handleOpenReactModale}>
                        <div className="relative py-1 px-2 border rounded-tiny cursor-pointer hover:bg-grey-200 flex items-center justify-center">
                            <img src="/img/addReaction.png" alt="add reaction" className="w-4 h-4" />
                        </div>
                        <>
                            {
                                type === "post" && post.reactions && Object.entries(post.reactions).map(([reaction, count]: [reaction: any, count: any]) => (
                                    (emojiData.emojis[reaction] && count > 0) && (
                                        <AnimatedButton
                                            key={reaction}
                                            className={`flex items-center gap-1 px-2 border rounded-tiny cursor-pointer hover:bg-grey-200 ${userHasReacted(reaction) ? "border-grey-600 bg-grey-200" : ""}`}
                                            onClick={(e: any) => handleAddReaction(e, reaction)}
                                        >
                                            <p className="text-xs">{emojiData.emojis[reaction].skins[0].native}</p>
                                            <p className="text-xs font-light">{count}</p>
                                        </AnimatedButton>
                                    )
                                ))
                            }
                            {
                                type === "comment" && comment.reactions && Object.entries(comment.reactions).map(([reaction, count]: [reaction: any, count: any]) => (
                                    emojiData.emojis[reaction] && (
                                        <AnimatedButton
                                            key={reaction}
                                            className={`flex items-center gap-1 px-2 border rounded-tiny cursor-pointer hover:bg-grey-200 ${userHasReacted(reaction) ? "border-grey-600 bg-grey-200" : ""}`}
                                            onClick={(e : any) => handleAddReaction(e, reaction)}
                                        >
                                            <p className="text-xs">{emojiData.emojis[reaction].skins[0].native}</p>
                                            <p className="text-xs font-light">{count}</p>
                                        </AnimatedButton>
                                    )
                                ))
                            }
                        </>
                    </div>
                )
            }
        </>
    )
}