import { AnimatedButton } from "components/globals/buttons"
import { useMutator } from "hooks/queries/api"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CommunityThemeConfig } from 'data/communityThemeConfig'
import { useTopbarMobileData } from "stores/topbarMobileData"

export const CreateCommunityPage = () => {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [color, setColor] = useState("#FF0000")
    const [type, setType] = useState(2)
    const navigate = useNavigate()
    const { setTopbarMobileData } = useTopbarMobileData();

    const { mutateAsync: createCommunity, isError, error, isPending } = useMutator<{ name: string }>('community/new')

    const handleCreateCommunity = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            const body: any = { communityName: name, communityDescription: description, communityType: type, communityColor: color }
            const community = await createCommunity(body)
            navigate("/admin/" + community.id + "/quick-start")
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        setTopbarMobileData({ showTopbar: false, withLinearGradient: false });
    }, []);

    return (
        <div className="w-full h-full flex flex-col items-center justify-center gap-10 px-intermediate">
            <h1 className="text-2xl font-bold">Créer une communauté</h1>
            <div className="flex flex-col gap-5 items-center justify-center w-full lg:w-[450px]">
                { /* @ts-ignore */}
                {isError && <div className="w-full bg-red-200 text-red-800 p-3 rounded-md">{error?.data?.error}</div>}
                <div className="w-full flex justify-between">
                    {Object.values(CommunityThemeConfig).map((colorItem, index) => (
                        <div key={index} onClick={() => setColor(colorItem.name)} className={`w-8 h-8 rounded-full cursor-pointer ${color === colorItem.name ? "border-[5px] border border-black" : ""}`} style={{ backgroundColor: colorItem.primaryColor }} />
                    ))}
                </div>
                <form onSubmit={handleCreateCommunity} className="w-full flex flex-col gap-3 items-center justify-center">
                    <input type="text" placeholder="Nom de la communauté" onChange={(e) => setName(e.target.value)} className="w-full focus:outline-none border border-grey-100 bg-white py-small rounded-tiny px-small" />
                    <input type="text" placeholder="Description de la communauté" onChange={(e) => setDescription(e.target.value)} className="w-full focus:outline-none border border-grey-100 bg-white py-small rounded-tiny px-small" />
                    <div className="flex w-full gap-3 items-center">
                        <div className={`w-full text-black py-3 rounded-tiny text-center cursor-pointer ${type === 1 ? "border border-grey-700 bg-grey-200" : "border border-grey-100" }`} onClick={() => setType(1) }>
                            Publique
                        </div>
                        <div className={`w-full text-black py-3 rounded-tiny text-center cursor-pointer ${type === 2 ? "border border-grey-700 bg-grey-200" : "border border-grey-100" }`} onClick={() => setType(2) }>
                            Privée
                        </div>
                    </div>
                    <AnimatedButton type="submit" className="w-full text-white py-mini rounded-tiny" style={{ backgroundColor: color }}>{isPending ? "Création en cours.." : "Créer ma communauté"}</AnimatedButton>
                </form>
            </div>
        </div>
    )
}