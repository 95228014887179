import { EmailValidationForm } from "components/onboarding/EmailValidationForm"
import { motion } from "framer-motion"
import { Blue, Yellow } from "assets/backgroundSvgs"
import { LogoIcon } from "assets/logoIcon"

export const EmailValidationPage = () => {
    return (
        <div className="w-full h-full flex justify-center items-center relative overflow-hidden">
            <div className="absolute top-[-200px] right-[-200px] z-10 opacity-50 lg:opacity-80"><Yellow /></div>
            <div className="absolute top-[200px] left-[-200px] z-10 opacity-50 lg:opacity-80"><Blue /></div>

            <div className="relative z-20 flex flex-col justify-center items-center md:w-[350px] w-full max-w-[80%] h-full gap-10">

                <motion.div
                    key="logo-animation"
                    initial={{ scale: 0.5 }}
                    animate={{ scale: 1 }}
                    transition={{ type: 'spring', stiffness: 260, damping: 20 }}
                    layout
                >
                    <LogoIcon />
                </motion.div>

                <div className="w-full text-center flex flex-col gap-5 justify-center items-center">
                    <h1 className="font-bold md:text-3xl text-2xl">Validation d'email</h1>
                    <p className="md:text-lg text-base text-grey-600">Veuillez indiquer le code reçu par email.</p>
                    
                    <div className="flex flex-col gap-3 items-center justify-center w-full">
                        <EmailValidationForm displayIn="page" />
                    </div>
                </div>
            </div>
        </div>
    )
}