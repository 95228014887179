import React, { useState, useEffect, useRef } from 'react';
import { useMutator } from "@hooks/queries/api";
import { useNavigate } from "react-router-dom";
import { ProtectedRoutes } from 'navigation/useAppRoutes';
import { profileType } from 'components/auth';
import { joinCommunityType } from 'stores/joinAfterOnboarding';
import { Config } from 'services/config';

export type EmailValidationFormTypes = {
  context?: string;
  displayIn?: string;
  callbackFunction?: (result: any) => void;
};

export const EmailValidationForm: React.FC<EmailValidationFormTypes> = ({
  //context = "signinAndJoinCommunity",
  displayIn = "page",
  callbackFunction
}) => {
  const { mutateAsync: validateEmailRequest, isError, error, isPending } = useMutator<{ code: string }>("/profile/validate-email");
  const [code, setCode] = useState<string[]>(["", "", "", ""]);
  const [joinCommunity, setJoinCommunity] = useState<joinCommunityType | undefined>();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const navigate = useNavigate();

  const handleChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(-1);
    if (!/^\d*$/.test(value)) return;

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value && index < 3) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').replace(/\D/g, '').slice(0, 4);
    const newCode = [...code];
    for (let i = 0; i < pastedData.length; i++) {
      newCode[i] = pastedData[i];
    }
    setCode(newCode);

    const nextEmptyIndex = newCode.findIndex(digit => digit === '');
    const focusIndex = nextEmptyIndex === -1 ? 3 : nextEmptyIndex;
    inputRefs.current[focusIndex]?.focus();
  };

  const checkProfileAndNavigate = (profile: profileType) => {
    let route: string;

    if (!profile.isProfileComplete) {
      route = ProtectedRoutes.completeProfile;
    } else if (!profile.isEmailVerified) {
      route = ProtectedRoutes.verifyEmail;
    } else {
      route = ProtectedRoutes.home;
    }

    if (joinCommunity?.communityId && joinCommunity.communityName && route !== ProtectedRoutes.home) {
      route += `?communityName=${encodeURIComponent(joinCommunity.communityName)}&communityId=${encodeURIComponent(joinCommunity.communityId)}`;
    }

    if (joinCommunity?.communityId && joinCommunity.communityName && route === ProtectedRoutes.home) {
      route = '/' + joinCommunity.communityId + "?join=true";
    }

    return route;
  }

  useEffect(() => {
    const tryValidateCode = async () => {
      if (code.every(digit => digit.length === 1)) {
        const joinedCode = code.join("");
        try {
          const tryValidate = await validateEmailRequest({ code: joinedCode });
          if (displayIn === "page") {
            const nextRoute = checkProfileAndNavigate(tryValidate);
            navigate(nextRoute);
          } else if (displayIn === "modale" && callbackFunction) {
            callbackFunction(tryValidate);
          }
        } catch (error) {
          console.error("Validation failed:", error);
        }
      }
    };

    tryValidateCode();
  }, [code]);

  useEffect(() => {
    setCode(["", "", "", ""]);
  }, [error]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const communityName = params.get('communityName');
    const communityId = params.get('communityId');
    if (communityName && communityId) {
      setJoinCommunity({ communityName, communityId });
    }
  }, []);

  return (
    <div className="w-full max-w-md mx-auto p-6 space-y-6">
      {
        displayIn !== "page" && (
          <h2 className="text-2xl font-bold text-center text-gray-800">Email Validation</h2>
        )
      }
      <div className="flex justify-center items-center space-x-4">
        {code.map((digit, index) => (
          <input
            key={index}
            ref={el => inputRefs.current[index] = el}
            type="text"
            inputMode="numeric"
            maxLength={1}
            className="w-14 h-14 text-center text-2xl font-bold border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500 transition-colors"
            value={digit}
            onChange={handleChange(index)}
            onKeyDown={handleKeyDown(index)}
            onPaste={handlePaste}
            disabled={isPending}
            aria-label={`Digit ${index + 1}`}
          />
        ))}
      </div>
      {isError && (
        <div className="bg-red-100 border-red-500 text-red-700 p-4 rounded text-center" role="alert">
          <p>{(error as any)?.response.data.error || 'An error occurred'}</p>
        </div>
      )}
      {isPending && (
        <div className="text-center text-gray-600">
          <p>Validating...</p>
        </div>
      )}
      {
        joinCommunity && (
          <div className="w-webkit flex flex-col md:flex-row gap-2 items-center justify-center bg-app rounded-md border py-3">
            <p className="font-light text-gray-500">Pour rejoindre :</p>
            <div className="w-full md:w-max flex items-center justify-center gap-2">
              <img src={`${Config.CDN_HOST}community%2F${joinCommunity.communityId}%2Fbg`} alt="communauté bg" className="w-7 h-7 rounded-full object-cover" />
              <p className="font-[500]">{joinCommunity.communityName}</p>
            </div>
          </div>
        )
      }
    </div>
  );
};