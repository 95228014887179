import { useEffect, useState } from 'react';
import { motion, useMotionValue, useAnimation, useTransform } from 'framer-motion';
import { useMyAdminCommunities, useMyCommunities } from "@hooks/queries/communities";
import { useTopbarMobileData } from "stores/topbarMobileData";
import { MyCommunitiesPage } from "components/communities";
import { DiscoverPage } from "components/home/discoverPage";
import { useBreakpointContext } from "context/BreakpointContext";

export const CommunityPage = () => {
  const [tabSelected, setTabSelected] = useState('mine');
  const { setTopbarMobileData } = useTopbarMobileData();
  const [isDragging, setIsDragging] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [disableDrag, setDisableDrag] = useState(false);

  const { isDesktop } = useBreakpointContext();

  useEffect(() => {
    setTopbarMobileData({ showTopbar: true, pageName: 'Communautés', withLogo: true });
  }, []);

  useMyCommunities({ refetchOnMount: false });
  useMyAdminCommunities({ refetchOnMount: false });

  const x = useMotionValue(0);
  const controls = useAnimation();

  const underlinePosition = useTransform(
    x,
    [-window.innerWidth, 0],
    isDesktop ? ["100%", "0%"] : ["50%", "0%"]
  );

  //@ts-ignore
  const handleDragEnd = (event, info) => {
    setIsDragging(false);

    if (info.offset.x < -100 && tabSelected === 'mine') {
      setTabSelected('discover');
      controls.start({ x: -window.innerWidth });
    } else if (info.offset.x > 100 && tabSelected === 'discover') {
      setTabSelected('mine');
      controls.start({ x: 0 });
    } else {
      controls.start({ x: tabSelected === 'discover' ? -window.innerWidth : 0 });
    }
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleScroll = () => {
    if (!isDragging) {
      setIsScrolling(true);
      setTimeout(() => setIsScrolling(false), 100);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleTabClick = (tab:string) => {
    setTabSelected(tab);
    controls.start({ x: tab === 'discover' ? -window.innerWidth : 0 });
  };

  return (
    <div className="w-full flex flex-col gap-0 items-center" onTouchStart={() => setDisableDrag(false)} onDrag={() => setDisableDrag(false)}>
      <div className="w-full lg:w-[1080px] flex gap-0 items-center justify-center sticky top-0 bg-white z-20">
        <div
          className={`w-full text-center py-2.5 cursor-pointer text-black z-40`}
          onClick={() => handleTabClick("mine")}
        >
          Communautés
        </div>
        <div
          className={`w-full text-center py-2.5 cursor-pointer text-black z-40`}
          onClick={() => handleTabClick("discover")}
        >
          Découvrir
        </div>
        <motion.div
          className="absolute bottom-0 h-0.5 bg-black"
          style={{
            width: isDesktop ? "50%" : "50%",
            left: underlinePosition,
          }}
          transition={{ duration: 0.3 }}
        />
      </div>
      {isDesktop ? (
        <div className="w-full max-w-[1080px] py-intermediate">
          {tabSelected === 'mine' && <MyCommunitiesPage isHomePage={false} onDisableDrag={(disabled) => setDisableDrag(disabled)} />}
          {tabSelected === 'discover' && <DiscoverPage onDisableDrag={(disabled) => setDisableDrag(disabled)} />}
        </div>
      ) : (
        <motion.div
          className="relative w-full overflow-hidden"
          style={{ minHeight: "100%" }}
        >
          <motion.div
            className="flex w-full"
            style={{ minHeight: "100%", x }}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            drag={disableDrag ? false : "x"}
            dragConstraints={{
              left: tabSelected === 'discover' ? -window.innerWidth : 0,
              right: tabSelected === 'mine' ? 0 : -window.innerWidth,
            }}
            dragElastic={0.4}
            dragTransition={{ bounceStiffness: 900, bounceDamping: 200 }}
            animate={controls}
            whileTap={{ cursor: isScrolling ? 'auto' : 'grabbing' }}
          >
            <motion.div
              className={`flex-shrink-0 px-intermediate py-intermediate gap-6 w-screen`}
            >
              <MyCommunitiesPage isHomePage={false} onDisableDrag={(disabled) => setDisableDrag(disabled)} />
            </motion.div>
            <motion.div
              className={`flex-shrink-0 px-intermediate py-intermediate gap-6 w-screen`}
            >
              <DiscoverPage onDisableDrag={(disabled) => setDisableDrag(disabled)} />
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};