import { AdminLayout } from "layout/AdminLayout"
import { MainKpis, MemberGraph } from "components/admin/home"
import { useParams } from "react-router-dom"
import { useGetAnalytics } from "@hooks/queries/community";
//import { useAdminCommunity } from "stores/adminCommunity";

export const AdminHomePage = () => {

    const { communityId } = useParams<{ communityId: string }>();

    useGetAnalytics({ communityId, refetchOnMount: false });

    return (
        <AdminLayout>
            <div className="bg-app h-full w-full overflow-hidden">
                <div className="h-full w-full bg-app flex flex-col gap-8 items-center p-intermediate overflow-auto">
                    <div className="w-full flex flex-col gap-3">
                        <p className="font-light text-sm opacity-80">Ma communauté &gt;</p>
                        <h1 className="text-left w-full text-xl">Tableau de bord de ma communauté</h1>
                    </div>
                    <div className="w-full flex flex-col items-center justify-start gap-2">
                        <MainKpis communityId={communityId} />
                        <MemberGraph dataSetKey={"members_evolution"} title={"Évolution des membres"} communityId={communityId} />
                        <MemberGraph dataSetKey={"posts_evolution"} title={"Évolution des posts"} communityId={communityId} />
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}