import { create } from 'zustand'

interface EmojiState {
    emojis: object;
    isError: boolean;
    setEmojis: (emojis: object) => void;
    setError: (isError: boolean) => void;
}

export const useEmojis = create<EmojiState>((set) => ({
    emojis: {},
    isError: false,
    setEmojis: (emojis: object) => {
        set({ emojis });
    },
    setError: (isError: boolean) => {
        set({ isError });
    },
}));
