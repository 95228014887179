export const Blue = () => {
    return (
        <svg width="714" height="714" viewBox="0 0 714 714" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_1840_1157)">
                <circle cx="357" cy="357" r="163" fill="#C7EBFC" />
            </g>
            <defs>
                <filter id="filter0_f_1840_1157" x="0.300003" y="0.300003" width="713.4" height="713.4" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="96.85" result="effect1_foregroundBlur_1840_1157" />
                </filter>
            </defs>
        </svg>
    )
}