import { CompleteProfileForm } from "components/onboarding/completeProfileForm"
import { Blue, Yellow } from "assets/backgroundSvgs"

export const ProfileInformationPage = () => {
    return (
        <div className="w-full h-full flex justify-center items-center relative overflow-hidden">
            <div className="absolute top-[-200px] right-[-200px] z-10 opacity-50 lg:opacity-80"><Yellow /></div>
            <div className="absolute top-[200px] left-[-200px] z-10 opacity-50 lg:opacity-80"><Blue /></div>

            <div className="relative z-20 flex flex-col justify-center items-center md:w-[450px] w-full max-w-[80%] h-full gap-10">
                <div className="w-full text-center flex flex-col gap-8 justify-center items-center">
                    <h1 className="font-bold md:text-3xl text-2xl">Complétion de votre profil</h1>
                    <div className="flex flex-col gap-3 items-center justify-center w-full">
                        <CompleteProfileForm context="page"/>
                    </div>
                </div>
            </div>
        </div>
    )
}