import { useAdminCommunity } from "stores/adminCommunity";
import { Icon } from "assets/icons"
import { Routes } from "navigation/useAppRoutes";
import { AdminLayout } from "layout/AdminLayout"
import { useNavigate } from "react-router-dom"

export const AdminSettingsPage = () => {

    const AdminSettingsSubPages = [
        {
            name: "Images de la communauté",
            path: Routes.adminSettingsImages,
            icon: "Image"
        },
        {
            name: "Rejoindre la communauté",
            path: Routes.adminSettingsJoin,
            icon: "User"
        },
        {
            name: "Branding",
            path: Routes.adminTheme,
            icon: "ColorSwatch"
        },
        {
            name: "Channels",
            path: Routes.adminChannels,
            icon: "Hashtag"
        },
        {
            name: "Gamification",
            path: Routes.adminGamification,
            icon: "Cup"
        },
        {
            name: "Page Builder",
            path: Routes.adminLanding,
            icon: "Designtools"
        },
        {
            name: "Catégories",
            path: Routes.adminCategories,
            icon: "Designtools"
        }
    ]

    const navigate = useNavigate()
    const { communityId } = useAdminCommunity();

    return (
        <AdminLayout>
            <div className="bg-app h-full w-full overflow-hidden">
                <div className="h-full w-full bg-app flex flex-col gap-8 items-center p-intermediate overflow-auto">
                    <div className="w-full flex flex-col gap-3">
                        <p className="font-light text-sm opacity-80">Ma communauté &gt; Réglages</p>
                        <h1 className="text-left w-full text-xl">Réglages de ma communauté</h1>
                    </div>
                    <div className="w-full flex flex-col items-center justify-start gap-2">
                        {AdminSettingsSubPages.map((subPage, index) => (
                            <div onClick={() => navigate(subPage.path.replace(':communityId', communityId))} key={index} className="cursor-pointer hover:bg-gray-100 bg-white p-4 rounded-lg shadow-md flex items-center gap-4 border w-full">
                                <Icon type={subPage.icon} className="w-6 h-6" />
                                <span>{subPage.name}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}