import { Icon } from "assets/icons"
import { useModaleStore } from "stores/modale"
import { Config } from "services/config"
import { useProfileRefresh } from "stores/profileRefresh"
import { useParams } from "react-router-dom"
import { itemTypes, useSidebarMenu } from "navigation/useSidebarMenu"

export const ChangeFeatureImage = () => {

    const { communityId } = useParams();
    const allItems = useSidebarMenu();
    const { imageUpdateCounter }: any = useProfileRefresh();
    const { setModale } = useModaleStore();

    const openModale = (key: string) => {
        setModale(true, 'ChangeCommunityFeatureImageModal', 'modale', { communityId, key });
    };

    const generateUrl = (key: string) => {
        return Config.CDN_HOST + "community%2F" + communityId + "%2F" + key + "#" + imageUpdateCounter;
    }


    return (
        <div className="w-full overflow-auto hideScrollBar">
            <div className="min-w-max flex items-start justify-start hideScrollBar gap-3 overflow-x-scroll">
                {
                    allItems.map((item: itemTypes, index: number) => {
                        return (
                            <div key={index} className="rounded-lg flex flex-col justify-center items-center relative w-full max-w-[300px] h-full gap-2 border shadow-md pb-2 overflow-hidden">
                                <div onClick={() => openModale(item.key)} className="bg-white flex justify-center items-center gap-2 py-1.5 px-3 absolute bottom-12 left-2 rounded-lg shadow-medium cursor-pointer">
                                    <p>Modifier</p>
                                    <Icon type="Camera" size={26} color="#8E8E93" />
                                </div>
                                <div className="overflow-hidden w-full h-full">
                                    <img src={generateUrl(item.key)} alt="" className="w-[300px] h-44 h-full object-cover" />
                                </div>
                                <p>{item.label}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}