export const PostSkeleton = () => {
    return (
        <div className={`w-full flex flex-col gap-3 relative`}>
            <div className={`w-full flex gap-3 px-small py-small`}>
                <div className="">
                    <div className="w-[40px] !h-[40px] rounded-full bg-center bg-gray-100 animate-pulse" />
                    <div className="flex w-full lg:h-webkit items-center justify-center">
                        <div className="h-full w-[1.5px] bg-grey-400"></div>
                    </div>
                </div>
                <div className="flex flex-col gap-3 w-full">
                    <div className="flex w-full items-center justify-between">
                        <div className="flex gap-2 items-center">
                            <div className="w-[200px] h-3 bg-grey-100 animate-pulse rounded"></div>
                        </div>
                        <div className="flex gap-2 items-center">
                            
                        </div>
                    </div>
                    <div className="w-full flex flex-col gap-1.5">
                        <div className="w-full h-3 bg-grey-100 animate-pulse rounded"/>
                        <div className="w-full h-3 bg-grey-100 animate-pulse rounded"/>
                        <div className="w-full h-3 bg-grey-100 animate-pulse rounded"/>
                    </div>
                    <div className="flex gap-2 items-stretch">
                        
                    </div>
                </div>
            </div>
        </div>
    )
}