import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';

interface MemberData {
    date: string;
    messages: number;
    reactions: number;
    comments: number;
}

const data: MemberData[] = [
    { date: '2023-01-01', messages: 30, reactions: 10, comments: 54 },
    { date: '2023-02-01', messages: 45, reactions: 32, comments: 76 },
    { date: '2023-03-01', messages: 60, reactions: 30, comments: 34 },
    { date: '2023-04-01', messages: 75, reactions: 65, comments: 76 },
    { date: '2023-05-01', messages: 90, reactions: 78, comments: 38 },
    { date: '2023-06-01', messages: 120, reactions: 21, comments: 39 },
    { date: '2023-07-01', messages: 150, reactions: 43, comments: 32 },
    { date: '2023-08-01', messages: 180, reactions: 32, comments: 78 },
];

export const ActivityGraph = () => {
    return (
        <div className="w-full h-52 flex flex-col items-center justify-start gap-2">
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    data={data}
                    margin={{
                        top: 0, right: 0, left: 0, bottom: 0,
                    }}
                >
                    <defs>
                        <linearGradient id="colorMessages" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorReactions" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorComments" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#ff5733" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#ff5733" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid horizontal={true} vertical={false} height={1} opacity={0.5} />
                    <XAxis dataKey="date" />
                    <YAxis hide />
                    <Tooltip />
                    <Area type="monotone" dataKey="messages" stroke="#8884d8" activeDot={{ r: 2 }} fill="url(#colorMessages)" />
                    <Area type="monotone" dataKey="reactions" stroke="#8884d8" activeDot={{ r: 2 }} fill="url(#colorReactions)" />
                    <Area type="monotone" dataKey="comments" stroke="#8884d8" activeDot={{ r: 2 }} fill="url(#colorComments)" />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}