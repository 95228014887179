
import { useMutator } from "@hooks/queries/api";
import { v4 as uuidv4 } from 'uuid';
import { useMessageStore } from 'stores/feed/channelMessage';
import { uuidSelector, useAuthStore, userNameSelector } from "stores/auth"

export type SendMessageType = {
    communityId: string;
    channelId: any;
};

export type UpdateMessageType = {
    post: any;
    communityId: string;
    channelId: any;
}

export type SendMessageContent = {
    content: any;
}   

export const useSendMessage = ({ communityId, channelId } : SendMessageType ) => {
    
    const { mutateAsync: sendNewMessage } = useMutator<any>(`/community/${communityId}/${channelId}/post/new`);
    const addWaitingMessage = useMessageStore(state => state.addWaitingMessage);
    const uuid : any = useAuthStore(uuidSelector);
    const userName : any = useAuthStore(userNameSelector);
    
    const sendMessage = ({ content }: SendMessageContent ) => {
        const uniqueId = uuidv4() + "_" + new Date().getTime();
        //@ts-ignore
        addWaitingMessage(channelId, { content, tempId: uniqueId, waiting: true, owner_uuid: uuid, user_name: userName, id: uniqueId, is_pinned: false, created_at: new Date(), channel_id : channelId, is_comment_disabled: false, is_archived : false, is_banned: false, comment_details: [] }, uniqueId);
        return sendNewMessage({ content, postId: uniqueId });
    };

    return { sendMessage };
};

export const useUpdateMessage = ({ post, communityId, channelId } : UpdateMessageType ) => {
    
    const { mutateAsync: updateMessageFromApi } = useMutator<any>(`/community/${communityId}/${channelId}/${post.id}/update`);
    const addWaitingUpdateMessage = useMessageStore(state => state.addWaitingUpdateMessage);
    
    const updateMessage = ({ content }: SendMessageContent ) => {
        //@ts-ignore
        addWaitingUpdateMessage(channelId, { ...post, content, waiting: true });
        return updateMessageFromApi({ content });
    };

    return { updateMessage };
};
