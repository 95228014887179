// GalleryBlock.tsx
import { Icon } from "assets/icons";
import React, { useState } from "react";
import { GalleryBlockProps, GalleryImage } from "../types";
import { useLandingStore } from "stores/landing";
import { useFileMutator } from "@hooks/queries/api";
import { Config } from "services/config";

export const GalleryBlock: React.FC<GalleryBlockProps> = ({ id, images, isAdmin, communityId }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedImages, setEditedImages] = useState<GalleryImage[]>(images);
    const [isUploading, setIsUploading] = useState(false);

    const { mutateAsync: uploadImage } = useFileMutator<any>("/community/" + communityId + "/assets/new");
    const { updateBlock } = useLandingStore();

    const handleImageUpload = async (index: number, file: File) => {
        if (!file) return;

        try {
            setIsUploading(true);
            const formData = new FormData();
            formData.append("file", file);

            const response = await uploadImage(formData);
            const imageUrl = `${Config.CDN_HOST}${response.path}`;

            const newImages = [...editedImages];
            newImages[index] = {
                ...newImages[index],
                url: imageUrl
            };
            setEditedImages(newImages);
        } catch (error) {
            console.error("Erreur lors de l'upload:", error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleSave = () => {
        updateBlock(id, (block) => ({
            ...block,
            images: editedImages,
        }));
        setIsEditing(false);
    };

    const handleAdd = () => {
        if (editedImages.length < 3) {
            setEditedImages([...editedImages, { url: '', alt: '' }]);
        }
    };

    const handleDelete = (index: number) => {
        setEditedImages(editedImages.filter((_, i) => i !== index));
    };

    const handleAltChange = (index: number, alt: string) => {
        const newImages = [...editedImages];
        newImages[index] = { ...newImages[index], alt };
        setEditedImages(newImages);
    };

    const getGridLayout = () => {
        switch (editedImages.length) {
            case 1: return 'grid-cols-1';
            case 2: return 'grid-cols-2';
            case 3: return 'grid-cols-2 grid-rows-2';
            default: return 'grid-cols-1';
        }
    };

    return (
        <div className="w-full">
            <div className={`grid ${getGridLayout()} gap-4 h-[400px]`}>
                {editedImages.map((image, index) => (
                    <div
                        key={index}
                        className={`relative rounded-lg overflow-hidden ${editedImages.length === 3 && index === 0 ? 'row-span-2' : ''
                            }`}
                    >
                        {image.url ? (
                            <img
                                src={image.url}
                                alt={image.alt}
                                className="w-full h-full object-cover"
                            />
                        ) : (
                            <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                                Aucune image
                            </div>
                        )}

                        {isEditing && (
                            <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center p-4">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        if (file) handleImageUpload(index, file);
                                    }}
                                    className="mb-2"
                                />
                                <input
                                    type="text"
                                    value={image.alt}
                                    onChange={(e) => handleAltChange(index, e.target.value)}
                                    placeholder="Texte alternatif"
                                    className="w-full p-2 mb-2 rounded"
                                />
                                <button
                                    onClick={() => handleDelete(index)}
                                    className="bg-red-500 text-white p-2 rounded"
                                    disabled={isUploading}
                                >
                                    <Icon type="Trash" />
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {isAdmin && (
                <div className="mt-4 flex justify-end gap-2">
                    {!isEditing ? (
                        <button
                            onClick={() => setIsEditing(true)}
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                        >
                            Modifier
                        </button>
                    ) : (
                        <>
                            <button
                                onClick={handleAdd}
                                disabled={editedImages.length >= 3 || isUploading}
                                className="bg-green-500 text-white px-4 py-2 rounded disabled:bg-gray-400"
                            >
                                Ajouter une image
                            </button>
                            <button
                                onClick={handleSave}
                                disabled={isUploading}
                                className="bg-blue-500 text-white px-4 py-2 rounded"
                            >
                                Sauvegarder
                            </button>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};