import React from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import { useGetAnalytics, type AdminAnalyticsDataEvolution } from "@hooks/queries/community";

type MemberGraphProps = {
  dataSetKey: string;
  title: string;
  communityId?: string;
}

const formatDate = (dateString : string) => {
  const date = new Date(dateString);
  const options : any = { day: '2-digit', month: 'long' };
  return date.toLocaleDateString('fr-FR', options);
};

export const MemberGraph = ({ dataSetKey, title, communityId } : MemberGraphProps) => {
  const { data, isLoading, isError } = useGetAnalytics({ communityId, refetchOnMount: false });

  const formattedData = React.useMemo(() => {
    if (!data || !data[dataSetKey]) return [];
    return data[dataSetKey].map((item : AdminAnalyticsDataEvolution ) => ({
      ...item,
      date: formatDate(item.date),
      total: parseInt(item.total, 10)
    }));
  }, [data, dataSetKey]);

  return (
    <div className="w-full h-full p-4 min-h-80 max-h-96 rounded-lg bg-white shadow border flex flex-col gap-3">
      <h2 className="text-gray-400 font-light text-sm">{title}</h2>
      <div className="flex justify-center items-center h-full">
        {isLoading ? (
          <div className='w-full h-64 rounded-mini bg-gray-100 animate-pulse'></div>
        ) : isError ? (
          <div className="w-full border border-red-700 bg-red-100 rounded-mini p-mini">
            <p className="text-red-700 text-center w-full">Une erreur est survenue veuillez réessayer</p>
          </div>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={formattedData}
              margin={{
                top: 0, right: 0, left: 0, bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid horizontal={true} vertical={false} height={1} opacity={0.5} />
              <XAxis dataKey="date" />
              <YAxis hide />
              <Tooltip />
              <Area type="monotone" dataKey="total" stroke="#8884d8" activeDot={{ r: 8 }} fill="url(#colorUv)" />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
}