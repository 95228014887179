import { useMemo } from "react";
import { Routes } from "./useAppRoutes";

export type itemTypes = {
    key: string,
    label: string,
    path: any,
    icon: string,
    isComingSoon: boolean,
    isDisplayOnMobileHome: boolean,
    type: string,
    isDisplayOnDesktopSidebar: boolean,
}

export const useSidebarMenu = (): itemTypes[] => {


    return useMemo(
        () =>
            [
                {
                    key: "home",
                    label: "Accueil",
                    path: Routes.communityHome,
                    icon: "Home",
                    isComingSoon: false,
                    isDisplayOnMobileHome: false,
                    isDisplayOnDesktopSidebar: true,
                    type: "page",
                },
                {
                    key: "discussions",
                    label: "Discussions",
                    path: Routes.communityChannels,
                    icon: "Hashtag",
                    isComingSoon: false,
                    isDisplayOnMobileHome: true,
                    isDisplayOnDesktopSidebar: false,
                    type: "modale",
                    modalComponent: "ChannelsModale"
                },
                {
                    key: "leaderboard",
                    label: "Classement",
                    path: Routes.communityLeaderboard,
                    icon: "Chart1",
                    isComingSoon: false,
                    isDisplayOnMobileHome: true,
                    isDisplayOnDesktopSidebar: true,
                    type: "page",
                },
                {
                    key: "referral",
                    label: "Parrainage",
                    path: Routes.communityReferral,
                    icon: "People",
                    isComingSoon: false,
                    isDisplayOnMobileHome: true,
                    isDisplayOnDesktopSidebar: true,
                    type: "page",
                },
                {
                    key: "aboutus",
                    label: "À propos",
                    path: Routes.communityAbout,
                    icon: "Bubble",
                    isComingSoon: false,
                    isDisplayOnMobileHome: true,
                    isDisplayOnDesktopSidebar: true,
                    type: "page",
                },
                {
                    key: "resources",
                    label: "Ressources",
                    path: Routes.communityResource,
                    icon: "Messages",
                    isComingSoon: false,
                    isDisplayOnMobileHome: true,
                    isDisplayOnDesktopSidebar: true,
                    type: "page",
                },
                {
                    key: "cours",
                    label: "Cours",
                    path: Routes.communityHome,
                    icon: "VideoSquare",
                    isComingSoon: true,
                    isDisplayOnMobileHome: true,
                    isDisplayOnDesktopSidebar: true,
                    type: "page",
                },
                {
                    key: "events",
                    label: "Évènements",
                    path: Routes.communityContents,
                    icon: "Calendar",
                    isComingSoon: true,
                    isDisplayOnMobileHome: true,
                    isDisplayOnDesktopSidebar: true,
                    type: "page",
                },
            ] as any[],
        [],
    );
};
