import { AnimatedButton } from "components/globals/buttons"
import { useNavigate, useParams } from "react-router-dom"
import { PostsList } from "components/community/feed/messages/postsList"
import { useModaleStore } from "stores/modale"
import { useEffect } from "react"
import { useTopbarMobileData } from "stores/topbarMobileData"
import { useSidebarMenu } from "navigation/useSidebarMenu"
import { useThemeCommunity } from "stores/themeCommunity"
import { BgPrimary } from "assets/backgroundSvgs/bgPrimary"
import { CommunityLeaderboardPodium } from "components/community/leaderboard"
import { CommunityRoutes } from "navigation/useAppRoutes"
import { useCommunityFeed } from "@hooks/queries/community"
import { useAllChannels } from "@hooks/queries/channels"

export const CommunityHomePage = () => {

    const { communityId } = useParams();
    if (!communityId) return null

    const navigate = useNavigate()
    const { setTopbarMobileData } = useTopbarMobileData();
    const { theme } = useThemeCommunity();

    const navigationMenuPages = useSidebarMenu();
    const { setModale } = useModaleStore();
    const { data, isLoading, isError } = useCommunityFeed({ communityId, page: 1, refetchOnMount: false, keepPreviousData: true, enabled: true });
    const { data: allChannels = [] } = useAllChannels({ communityId: parseInt(communityId), refetchOnMount: false, withNotifications: true });

    useEffect(() => {
        setTopbarMobileData({ pageName: "Accueil", communityId, showTopbar: true, withGoHome: true });
    }, []);

    const hasUnreadNotifications = () => {
        return allChannels.some(channel => channel.new_posts_count && parseInt(channel.new_posts_count) > 0);
    };

    const handleClickOnItems = (item: any) => {

        if (item.isComingSoon) {
            setModale(true, 'ComingSoonModale', 'modale');
            return;
        }

        if (item.type === "page") {
            navigate(item.path.replace(':communityId', communityId))
        } else {
            setModale(true, item.modalComponent, 'modale', { communityId });
        }
    }

    const handleOpenChannelModale = () => {
        setModale(true, 'ChannelsModale', 'modale', { communityId });
    }

    return (

        <div className="bg-app h-full w-full overflow-hidden">
            <div className="h-full w-full bg-appp flex flex-col gap-8 items-center p-intermediate overflow-auto relative">
                <div className="fixed bottom-[-200px] right-[-200px] w-full z-0 flex justify-end opacity-50">
                    <BgPrimary color={theme.primaryColor} />
                </div>
                <div className="fixed top-[-200px] left-[-200px] w-full z-0 flex justify-end opacity-20">
                    <BgPrimary color={theme.primaryColor} />
                </div>
                <div className="w-full flex flex-col gap-3 relative z-10">
                    <div className="w-full flex flex-col gap-4 items-start justify-start">
                        <div className="w-full bg-appp overflow-x-auto hideScrollBar">
                            <div className="w-full bg-app grid grid-cols-2 gap-3 items-center hideScrollBar"> {/* w-max flex gap-3 */}
                                {
                                    navigationMenuPages.map((item, index) => {

                                        if (!item.isDisplayOnMobileHome) return null;

                                        const showNotificationDot = item.key === 'discussions' && hasUnreadNotifications();

                                        return (
                                            <AnimatedButton
                                                key={index}
                                                className="flex justify-start items-center w-full gap-2 rounded bg-gradient-to-r from-gray-200/40 to-gray-200/40 overflow-hidden relative"
                                                onClick={() => handleClickOnItems(item)}
                                            >
                                                <div className="!w-12 !h-12 flex items-center justify-center shadow-lg overflow-hidden bg-white">
                                                    <img src={`https://pub-388e882330ee44c7b74215d8e0935cb9.r2.dev/community%2F${communityId}%2F${item.key}`} alt={item.key} className="w-full h-full object-cover" />
                                                </div>
                                                <div className={`flex gap-2 items-center justify-start gap-2`}>
                                                    <p className="font-[300]">{item.label}</p>
                                                    {showNotificationDot && (
                                                        <div className="w-[7px] h-[7px] bg-red-500 rounded-full" />
                                                    )}
                                                </div>
                                            </AnimatedButton>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full flex flex-col gap-3 relative z-10">
                    <div className="w-full overflow-x-hidden flex flex-col gap-7">
                        <h2 className="text-xl">Les 3 meilleurs parrains</h2>
                        <CommunityLeaderboardPodium />
                        <AnimatedButton className="w-full flex items-center justify-center gap-2 py-3 rounded-lg" onClick={() => navigate(CommunityRoutes.communityLeaderboard.replace(':communityId', communityId))} style={{ backgroundColor: theme.quaternaryColor }}>
                            <span>Voir tout</span>
                        </AnimatedButton>
                    </div>
                </div>

                {
                    !isError && (
                        <div className="w-full flex flex-col gap-3 relative z-10">
                            <div className="w-full overflow-x-hidden flex flex-col gap-5">
                                <h2 className="text-xl">Les dernières publications</h2>
                                <div className="w-full flex flex-col gap-4">
                                    <PostsList data={data} isLoading={isLoading} isFullWidth={true} />
                                </div>
                                <AnimatedButton className="w-full flex items-center justify-center gap-2 py-3 rounded-lg" onClick={handleOpenChannelModale} style={{ backgroundColor: theme.quaternaryColor }}>
                                    <span>Voir tout</span>
                                </AnimatedButton>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>

    )
}