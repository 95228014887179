import { useMemo } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "assets/icons";
import heart from "assets/emojis/heart.png";
import { timeSince } from "services/timeSince";
import { generateHTML } from '@tiptap/html';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Mention from '@tiptap/extension-mention';
import Image from '@tiptap/extension-image';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';

export type DiscussionsTypes = {
    owner_uuid: string,
    id: number,
    comments: number,
    content: any,
    created_at: string,
    channel_name: string,
    community_id: number,
    community_name: string,
    total_reactions: string,
    community_type: string,
    user_name: string,
    channel_id: number
}

const postContentStyles = `
  .post-content img {
    display: none;
  }
`;

const PostContent = ({ content } : { content: any }) => {
    const [contentHtml, imageUrls] = useMemo(() => {
        let html = '';
        const images : string[] = [];
        if (typeof content === "string") {
            try {
                const contentJSON = JSON.parse(content);
                html = generateHTML(contentJSON, [
                    Document, Paragraph, Text, Mention, Image, Bold, Italic, Link
                ]);
            } catch {
                html = content;
            }
        } else {
            html = generateHTML(content, [
                Document, Paragraph, Text, Mention, Image, Bold, Italic, Link
            ]);
        }

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const imgElements = doc.getElementsByTagName('img');
        Array.from(imgElements).forEach((img) => {
            images.push(img.src);
        });

        return [doc.body.innerHTML, images];
    }, [content]);

    return (
        <>
            <style dangerouslySetInnerHTML={{ __html: postContentStyles }} />
            <div className="font-light text-left post-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />
            {imageUrls.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-2">
                    {imageUrls.map((url, index) => (
                        <img
                            key={index}
                            src={url}
                            alt={`Image ${index + 1}`}
                            className="w-20 h-20 object-cover rounded-md cursor-pointer"
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export const DiscussionProfileTab = ({ posts }: { posts: DiscussionsTypes[] }) => {
    return (
        <>
            <div className="w-screen flex flex-col items-center justify-start">
                {posts.map((post, index) => (
                    <div key={index} className={`w-full flex flex-col gap-3 bg-white relative hover:bg-grey-100 cursor-pointer border-b px-intermediate py-6`} >
                        <div className={`w-full flex gap-3`}>
                            <div className="">
                                <div className="w-[40px] !h-[40px] rounded-full bg-center bg-cover cursor-pointer" style={{ backgroundImage: `url(https://pub-388e882330ee44c7b74215d8e0935cb9.r2.dev/user%2F${post.owner_uuid})` }} />
                                <div className="flex w-full lg:h-webkit items-center justify-center">
                                    <div className="h-full w-[1.5px] bg-grey-400"></div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <div className="flex w-full items-center justify-between">
                                    <div className="flex gap-2 items-center">
                                        <p className="text-base font-medium">{post.user_name}</p>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <p className="font-light text-gray-4O0 text-sm">{timeSince(post.created_at)}</p>
                                        <Icon type={"More"} size={17} color={"black"} />
                                    </div>
                                </div>
                                <PostContent content={post.content} />
                                <div className="flex gap-2 items-start md:items-stretch min-w-max justify-start">
                                    <div className="bg-white border rounded-lg py-1.5 px-3 flex items-center gap-2">
                                        <img src={heart} alt="" className="w-4 h-4" />
                                        <p className="text-grey-600 text-sm">{post.total_reactions} réactions</p>
                                    </div>
                                    <div className="bg-white border rounded-lg py-1.5 px-3 flex items-center gap-2">
                                        <Icon type={"Message"} size={17} color={"black"} />
                                        <p className="text-grey-600 text-sm">{post.comments} réponses</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <RouterLink to={`/${post.community_id}/${post.channel_id}`}>
                            <div className="bg-gray-100 w-full border rounded-lg py-1.5 px-3 flex items-center gap-1">
                                <div className="w-5 h-5 rounded bg-cover bg-center" style={{ backgroundImage: `url(https://pub-388e882330ee44c7b74215d8e0935cb9.r2.dev/community%2F${post.community_id}%2Fbg)` }} />
                                <p className="text-sm">{post.community_name}</p>
                                <p className="text-sm">-</p>
                                <p className="text-sm font-light">Publié dans <span className="font-[500]">#{post.channel_name}</span></p>
                            </div>
                        </RouterLink>
                    </div>
                ))}
            </div>
        </>
    );
};

export const DiscussionProfileTabSkeleton = () => {
    return (
        <div className="w-full flex flex-col items-center justify-start gap-2">
            {Array.from({ length: 2 }).map((_, index) => (
                <div key={index} className="w-full h-36 bg-gray-100 flex items-end justify-start rounded-lg p-3 relative">
                    <div className="bg-gray-200 w-28 rounded-lg h-7" />
                </div>
            ))}
        </div>
    )
}

export const DiscussionProfileTabError = () => {
    return (
        <div className="w-full flex items-center justify-center bg-red-100">
            <p className="text-red-400 font-light text-lg">Aucune discussion</p>
        </div>
    )
}