import { Icon } from "assets/icons";
import { AnimatedButton } from "components/globals/buttons";
import { useState } from "react";
import { useFileMutator } from "@hooks/queries/api";
import { useModaleStore } from "stores/modale";
import { useProfileRefresh } from "stores/profileRefresh";

export const ChangeProfilPicModal = () => {

  const { mutateAsync: newImageProfile } = useFileMutator<any>("/profile/pic")
  const { setModale } = useModaleStore();
  const { incrementImageUpdateCounter } : any = useProfileRefresh();

  const memojiImages = Array.from({ length: 6 }, (_, i) => `/img/memojis/memoji${i + 1}.png`);
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // @ts-ignore
      setSelectedImage(URL.createObjectURL(selectedFile));
      setFile(selectedFile);
    }
  };

  const handleSelectMemoji = async (memoji: any) => {
    setSelectedImage(memoji);
    const response = await fetch(memoji);
    const blob = await response.blob();
    const file = new File([blob], memoji.split('/').pop(), { type: blob.type });
    // @ts-ignore
    setFile(file);
  };

  const handleSubmit = async () => {

    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      await newImageProfile(formData);
      incrementImageUpdateCounter();
      setModale(false, "", '');
    } else {
      console.log('No file selected');
    }

  };


  return (
    <div className="modal-container px-4 py-6 flex flex-col items-center space-y-8 md:w-[400px]">
      <div className="flex flex-col justify-center gap-5 w-webkit">
        <div className="flex items-center justify-start gap-3">
          <Icon type="UserOctagon" size={26} color="#8E8E93" />
          <p>Utiliser un memoji</p>
        </div>
        <div className='grid grid-cols-3 gap-6 justify-center '>
          {memojiImages.map((image, index) => (
            <div
              className={`w-full flex justify-center items-center cursor-pointer`}
              key={"memoji_" + index}
              onClick={() => handleSelectMemoji(image)}
            >
              <img src={image} alt={"memoji_" + index} className={`w-20 h-20 rounded-full ${selectedImage === image ? 'ring-2 ring-green-500' : ''}`} />
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col justify-center gap-5 w-webkit">
        <div className="flex items-center justify-between gap-3 w-full">
          <div className="flex items-center justify-start gap-3 w-full">
            <Icon type="UserSquare" size={26} color="#8E8E93" />
            <p>Utiliser une photo personnelle</p>
          </div>
          {selectedImage && <AnimatedButton className='text-grey-600 rounded-tiny' onClick={() => setSelectedImage(null)}>Change</AnimatedButton>}
        </div>
        <div className="flex items-center justify-start gap-3 w-webkit">
          <div className="w-full">
            {selectedImage ? (
              <div className="w-full flex justify-center items-center">
                <div className="rounded-full w-[130px] h-[130px] border-2 border-green-500" style={{ backgroundImage: `url(${selectedImage})`, backgroundPosition: "center", backgroundSize: "cover" }} />
              </div>
            ) : (
              <div className="flex items-center justify-center w-full">
                <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                  </div>
                  <input id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} accept="image/*" />
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
      <AnimatedButton onClick={handleSubmit} className='w-full bg-black text-white py-mini rounded-tiny'>Valider</AnimatedButton>
    </div>
  );
};
