import { LoginForm } from "components/auth/LoginForm";
import { LogoIcon } from "assets/logoIcon";
import { useNavigate } from "react-router-dom";
import { AnimatedButton } from "components/globals/buttons";
import { Yellow, Blue } from "assets/backgroundSvgs";
import { motion } from 'framer-motion';

export const LoginPage = () => {

    const navigate = useNavigate();

    const handleCreateAccount = () => {
        navigate('/register');
    };

    return (
        <div className="w-full h-full flex justify-center items-center relative overflow-hidden">
            <div className="absolute top-[-200px] right-[-200px] z-10"><Yellow /></div>
            <div className="absolute top-[200px] left-[-200px] z-10"><Blue /></div>

            <div className="relative z-20 flex flex-col justify-center items-center md:w-[350px] w-full max-w-[80%] h-full gap-10">

                <motion.div
                    key="logo-animation"
                    initial={{ scale: 0.5 }}
                    animate={{ scale: 1 }}
                    transition={{ type: 'spring', stiffness: 260, damping: 20 }}
                    layout
                >
                    <LogoIcon />
                </motion.div>

                <div className="w-full text-center flex flex-col gap-5 justify-center items-center">
                    <h1 className="font-bold md:text-3xl text-2xl">Connexion</h1>
                    <p className="md:text-lg text-base text-grey-600">Welcome Back 🙌🏻</p>
                    <div className="flex flex-col gap-3 items-center justify-center w-full">
                        <LoginForm />
                        <AnimatedButton onClick={handleCreateAccount} className="w-full bg-grey-100 text-black py-mini rounded-tiny">Créer un compte</AnimatedButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
