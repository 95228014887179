import {  AuthModale } from "./authModale"
import { useState } from "react";
import { JoinCommunityForm } from "./joinCommunityForm";

export const AuthAndJoin = () => {
    
    const [type, _] = useState('auth');
    
    return (
        <div className="flex flex-col gap-3 items-center w-full lg:w-[500px] lg:p-6 cursor-auto">
            {
                type === "auth" && (
                    <AuthModale  />
                )
            }
            {
                type === "join" && (
                    <JoinCommunityForm />
                )
            }
        </div>
    )
}