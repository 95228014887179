import { useGetSearchUsers } from "@hooks/queries/search"
import React, { useState, useEffect, useCallback } from 'react';
import { UserSearchResults } from "components/search/SearchResultUserTab";
import { Config } from "services/config";
import { AnimatedButton } from "components/globals/buttons";
import { Icon } from "assets/icons";
import { useModaleStore } from "stores/modale";
import { useMutator } from "@hooks/queries/api";
import { employeeRole } from "data/employeeRole";

export const SearchUsersModale = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [shouldFetch, setShouldFetch] = useState(false);
    const [previousData, setPreviousData] = useState<any>(undefined);
    const [step, setStep] = useState<number>(0);
    const [selectedUser, setSelectedUser] = useState<any>(undefined);
    const [invitationMessage, setInvitationMessage] = useState('');
    const [newEmployeeRole, setNewEmployeeRole] = useState<number>(employeeRole[0].id);

    const { modaleProps, setModale } = useModaleStore();
    const communityId = modaleProps?.communityId;

    if (!communityId) {
        return null;
    }

    const { mutateAsync: createEmployee, isError: isCreateEmployeeError, isPending: isCreateEmployeeLoading } = useMutator<any>("/community/" + communityId + "/admin/team/new");

    const generateProfilPic = (uuid: string) => {
        return `${Config.CDN_HOST}user%2F${uuid}`
    }

    const { data, isLoading, isError, refetch } = useGetSearchUsers({
        search: searchTerm,
        enabled: shouldFetch,
        refetchOnMount: false,
    });

    const handleSearch = useCallback(() => {
        if (searchTerm.trim().length > 2) {
            setShouldFetch(true);
        } else {
            setShouldFetch(false);
        }
    }, [searchTerm]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    useEffect(() => {
        if (shouldFetch) {
            refetch();
        }
    }, [shouldFetch, refetch]);

    useEffect(() => {
        if (data) {
            setPreviousData(data);
        }
    }, [data]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
    };

    const handleStepChange = (step: number) => {
        setStep(step);
    }

    const handleSubmit = async () => {
        console.log('submit', { selectedUser, invitationMessage, newEmployeeRole });
        await createEmployee({ 
            new_employee_uuid: selectedUser.uuid, 
            new_employee_role: newEmployeeRole,
            invitation_message: invitationMessage 
        });
        //send invitation
        await modaleProps?.refetch();
        setModale(false, '', '', { ...modaleProps, refetch: modaleProps?.refetch });
    }

    const handleInvitationMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInvitationMessage(e.target.value);
    };

    const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setNewEmployeeRole(Number(e.target.value));
    };

    return (
        <div className="w-full md:min-w-[600px]">
            {
                step === 0 ? (
                    <div className="w-full">
                        <div className="flex bg-lightGray items-center w-full md:min-w-96 justify-start gap-3 py-mini rounded-micro px-small">
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12.1094" cy="12.1094" r="6.51042" stroke="#8E8E93" strokeWidth="1.82292" />
                                <path d="M20.3125 20.3125L17.1875 17.1875" stroke="#8E8E93" strokeWidth="1.82292" strokeLinecap="round" />
                            </svg>
                            <input
                                type="text"
                                id="search-users"
                                placeholder="Rechercher dans Feder"
                                className="bg-transparent w-full focus:outline-none font-[300]"
                                onChange={handleInputChange}
                                value={searchTerm}
                            />
                        </div>
                        <UserSearchResults
                            data={data}
                            previousData={previousData}
                            isLoading={isLoading}
                            isError={isError}
                            searchTerm={searchTerm}
                            setStep={handleStepChange}
                            userSelected={(user) => { setSelectedUser(user) }}
                        />
                    </div>
                ) : (
                    <div className="w-full flex flex-col items-start justify-start gap-6">
                        <AnimatedButton className="flex items-center justify-center gap-2" onClick={() => setStep(0)}>
                            <Icon type={"ArrowLeft"} size={20} color={"black"} />
                            <span className="text-gray-600 font-light">Retour à la liste</span>
                        </AnimatedButton>
                        <div className="w-full flex flex-col items-start justify-start gap-5">
                            <div className="w-full flex items-center justify-start gap-4">
                                <img src={generateProfilPic(selectedUser.uuid)} alt="profile" className="w-20 h-20 rounded-full object-fit-cover" />
                                <div className="w-full flex flex-col items-start justify-start gap-0">
                                    <p className="text-2xl">{selectedUser.user_name}</p>
                                    {
                                        selectedUser.first_name && selectedUser.last_name && (
                                            <p className="text-lg text-gray-600 font-light italic">{selectedUser.first_name} {selectedUser.last_name}</p>
                                        )
                                    }
                                </div>
                            </div>
                            <p className="text-gray-500 font-light">Membre depuis : {selectedUser.created_at}</p>
                            <div className="w-full flex flex-col items-start justify-center gap-4">
                                <div className="w-full">
                                    <label htmlFor="role-select" className="block mb-2 text-sm font-medium text-gray-900">
                                        Sélectionner un rôle
                                    </label>
                                    <select
                                        id="role-select"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        value={newEmployeeRole}
                                        onChange={handleRoleChange}
                                    >
                                        {employeeRole.map((role) => (
                                            <option key={role.id} value={role.id}>
                                                {role.name.charAt(0).toUpperCase() + role.name.slice(1)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <textarea 
                                    name="invitation" 
                                    id="invit_member" 
                                    placeholder="Ajouter un message d'invitation" 
                                    className="w-full border rounded p-3"
                                    value={invitationMessage}
                                    onChange={handleInvitationMessageChange}
                                    rows={10}
                                />
                                <AnimatedButton 
                                    onClick={handleSubmit} 
                                    className="w-full bg-gray-200 py-2.5 rounded-mimi"
                                    disabled={isCreateEmployeeLoading}
                                >
                                    {isCreateEmployeeLoading ? 'Envoi en cours...' : 'Inviter'}
                                </AnimatedButton>
                                {isCreateEmployeeError && (
                                    <p className="text-red-500 text-sm">Une erreur s'est produite lors de l'invitation. Veuillez réessayer.</p>
                                )}
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}