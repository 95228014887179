import { AdminLayout } from "layout/AdminLayout"
import { AdminUpdateCategoriesComponent } from "components/admin/categories/UpdateCategories";

export const AdminQuickStartPage = () => {
    

    return (
        <AdminLayout>
            <AdminUpdateCategoriesComponent />
        </AdminLayout>
    )
}