import { ChannelInterface, useMessageStore } from 'stores/feed/channelMessage';
import { useFetcher } from './api'
import { useEffect } from 'react';

export type GetAllChannelsFromCommunityTypes = {
    refetchOnMount?: boolean;
    communityId: number;
    withNotifications?: boolean;
    enabled?: boolean;
};

export const useAllChannels = ({ communityId, refetchOnMount = true, withNotifications = false, enabled = true }: GetAllChannelsFromCommunityTypes) => {

    const addChannels = useMessageStore(state => state.addChannels);

    const { data, refetch, isError, error, isLoading } = useFetcher<any, ChannelInterface[]>({ key: `community-${communityId}-channels${withNotifications ? "-with-notif" : "-without-notif"}`, path: `/community/${communityId}/channels${withNotifications ? "?withNotifications=true" : "?withNotifications=false"}`, refetchOnMount, enabled })

    useEffect(() => {
        if (data) {
            addChannels(data);
        }
    }, [data, addChannels]);

    return { data, refetch, isError, error, isLoading };

}

export const useAllAdminChannels = ({ communityId, refetchOnMount = true, enabled = true }: GetAllChannelsFromCommunityTypes) => {
    return useFetcher<any, ChannelInterface[]>({ key: `community-${communityId}-admin-channels`, path: `/community/${communityId}/admin/channels`, refetchOnMount, enabled });
}