import { useModaleStore } from "stores/modale";
import { Icon } from "assets/icons";
import { useEffect, useState } from "react";
import { useMutator } from "@hooks/queries/api";
import { useGetActionsTypes } from "@hooks/queries/community";

export type ActionType = {
    action_type: number,
    quantity: number,
    points: number
    name: string,
    description: string,
    with_manual_check: boolean,
    is_active: boolean,
}

type ActionDefaultType = {
    type: string,
    name: string,
    id: number
}

const ChooseActionType = ({ setStep, setAction, action }: { setStep: React.Dispatch<React.SetStateAction<number>>, setAction: any, action: ActionType }) => {

    const [selectedType, setSelectedType] = useState(action.action_type);
    const [error, setError] = useState("");
    const { modaleProps } = useModaleStore();

    const { data, isLoading, isError } = useGetActionsTypes({ communityId: modaleProps.communityId || modaleProps.community_id, refetchOnMount: false })

    const handleValidateType = () => {
        if (selectedType === 0) {
            setError("Veuillez choisir un type d'action");
            return;
        };
        setAction("action_type", selectedType);
        setStep(2);
    }

    return (
        <div className="w-full flex flex-col items-start justify-start gap-3">
            <h2>Choisissez votre type d'action</h2>
            {error && <p className="text-red-500">{error}</p>}
            <div className="w-full grid grid-cols-2 gap-3">
                {
                    isLoading ?
                        Array.from({ length: 5 }).map((_, i) => (
                            <div
                                className={`bg-gray-100 animate-pulse w-full h-24 rounded-lg border flex flex-col items-center justify-center gap-2`}
                                key={i}
                            />
                        )) : isError ? <p>Error</p> :
                            data?.map((type: ActionDefaultType, i:number) => (
                                <div
                                    onClick={() => setSelectedType(type.id)}
                                    className={`${selectedType === type.id ? "bg-gray-100" : "bg-white"} w-full h-24 rounded-lg border flex flex-col items-center justify-center gap-2`}
                                    key={i}
                                >
                                    <Icon type={"Menu"} size={24} />
                                    <p className="font-light">{type.name}</p>
                                </div>
                            ))
                }
            </div>
            <button className="w-full bg-gray-100 py-2 rounded-lg" onClick={handleValidateType}>Continuer</button>
        </div >
    )
}

const ChooseActionQuantity = ({ setStep, setAction, action }: { setStep: React.Dispatch<React.SetStateAction<number>>, setAction: any, action: ActionType }) => {

    const [count, setCount] = useState(action.quantity);

    const handleAddQuantity = () => {
        setAction("quantity", count);
        setStep(3);
    }

    return (
        <div className="w-full flex flex-col items-start justify-start gap-3">
            <h2>Combien de fois doivent-ils le réaliser ?</h2>
            <div className="w-full flex items-center justify-center py-5">
                <Icon type={"Minus"} size={24} onClick={() => setCount(count - 1)} />
                <p className="mx-3 text-3xl font-bold">{count}</p>
                <Icon type={"Add"} size={24} onClick={() => setCount(count + 1)} />
            </div>
            <button className="w-full bg-gray-100 py-2 rounded-lg" onClick={handleAddQuantity}>Continuer</button>
            <button className="w-full bg-gray-100 py-2 rounded-lg" onClick={() => setStep(1)}>Retour</button>
        </div >
    )
}

const ChooseActionPoints = ({ setStep, setAction, action }: { setStep: React.Dispatch<React.SetStateAction<number>>, setAction: any, action: ActionType }) => {

    const [count, setCount] = useState(action.points);

    const handleAddPoints = () => {
        setAction("points", count);
        setStep(4);
    }

    return (
        <div className="w-full flex flex-col items-start justify-start gap-3">
            <h2>Combien de points gagnent-ils ?</h2>
            <div className="w-full flex items-center justify-center py-5">
                <Icon type={"Minus"} size={24} onClick={() => setCount(count - 1)} />
                <p className="mx-3 text-3xl font-bold">{count}</p>
                <Icon type={"Add"} size={24} onClick={() => setCount(count + 1)} />
            </div>
            <button className="w-full bg-gray-100 py-2 rounded-lg" onClick={handleAddPoints}>Continuer</button>
            <button className="w-full bg-gray-100 py-2 rounded-lg" onClick={() => setStep(1)}>Retour</button>
        </div >
    )
}

const CreateActionRecap = ({ action }: { action: any }) => {

    const { modaleProps } = useModaleStore();
    const { mutateAsync: createAction, isError, isPending } = useMutator<any>("/community/" + modaleProps.communityId + "/actions/new");

    const generateText = (type: string) => {
        switch (type) {
            case "react":
                return "réagissent à";
            case "comment":
                return "commentent";
            case "post":
                return "postent";
            case "refer":
                return "parrainent";
            case "manual":
                return "réalisent votre action manuelle";
            default:
                return "dansent";
        }
    }

    const generateUnit = (type: string) => {
        switch (type) {
            case "react":
                return "posts";
            case "comment":
                return "posts";
            case "post":
                return "posts";
            case "refer":
                return "filleuls";
            case "manual":
                return "";
            default:
                return "danses";
        }
    }

    const handleValidate = async () => {
        console.log("Action created", action);
        await createAction({ ...action, name: "Action test", description: "Description test", type: 1 });
    }


    return (
        <div className="w-full flex flex-col items-start justify-start gap-3">
            <h2>Récapitulatif de l'action</h2>
            <p>Vos membres gagneront {action.points} points, lorsqu'ils {generateText(action.type)} {action.quantity} {generateUnit(action.type)}</p>
            <div className="w-full flex flex-col items-center justify-center py-5">
                <p className="mx-3 text-3xl font-bold">{action.quantity}</p>
                <p className="mx-3 text-3xl font-bold">{action.type}</p>
                <p className="mx-3 text-3xl font-bold">{action.points}</p>
            </div>
            <button className="w-full bg-gray-100 py-2 rounded-lg" onClick={handleValidate}>{isPending ? "Loading..." : 'Continuer'}</button>
            {isError && <p className="text-red-500">Une erreur est survenue</p>}
        </div >
    )
}

export const CreateCommunityAction = () => {

    const [step, setStep] = useState(1);

    const { modaleProps } = useModaleStore();

    const [action, setAction] = useState<ActionType>(modaleProps);

    const updateAction = (key: string, value: any) => {
        setAction({ ...action, [key]: value });
    }

    useEffect(() => {
        console.log(action)
    }, [action])

    return (
        <div className="lg:w-[600px] cursor-auto h-full">
            {step === 1 && <ChooseActionType action={action} setAction={updateAction} setStep={setStep} />}
            {step === 2 && <ChooseActionQuantity action={action} setAction={updateAction} setStep={setStep} />}
            {step === 3 && <ChooseActionPoints action={action} setAction={updateAction} setStep={setStep} />}
            {step === 4 && <CreateActionRecap action={action} />}
        </div>
    )
}