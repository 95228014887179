import { /*useNavigate, */ useParams } from "react-router-dom"
import { useEffect } from "react"
import { useTopbarMobileData } from "stores/topbarMobileData"
import { BgPrimary } from "assets/backgroundSvgs/bgPrimary";
import { useThemeCommunity } from "stores/themeCommunity";
import { CommunityLeaderboardTops, CommunityLeaderboardGains } from 'components/community/leaderboard'

export const CommunityLeaderboardPage = () => {

    const { communityId } = useParams();
    if (!communityId) return null

    //const navigate = useNavigate()
    //const { isMobileOrTable } = useBreakpointContext();
    const { setTopbarMobileData } = useTopbarMobileData();
    const { theme } = useThemeCommunity();

    useEffect(() => {
        setTopbarMobileData({ pageName: `Leaderboard`, communityId, showTopbar: true, withGoHome: true });
    }, []);


    return (
        <div className="bg-app h-full w-full overflow-hidden relative">
            <div className="fixed bottom-[-200px] right-[-200px] w-full z-0 flex justify-end opacity-50">
                <BgPrimary color={theme.primaryColor} />
            </div>
            <div className="fixed top-[-200px] left-[-200px] w-full z-0 flex justify-end opacity-20">
                <BgPrimary color={theme.primaryColor} />
            </div>
            <div className="h-full w-full flex flex-col gap-8 items-center p-intermediate overflow-auto relative z-20">

                <CommunityLeaderboardGains />

                <div className="flex w-full flex-col items-start justify-start gap-4">
                    <div className="flex w-full flex-col items-start justify-start gap-2">
                        <h2 className="w-full text-xl font-[500]">Classement de la communauté</h2>
                        <p className="w-full text-sm font-light">Voici les top membres de la communauté</p>
                    </div>
                    <CommunityLeaderboardTops />
                </div>

            </div>
        </div>
    )
}