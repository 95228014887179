import { AnimatedButton } from "components/globals/buttons"
import { Link, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useTopbarMobileData } from "stores/topbarMobileData"
import { useGetMyReferral } from "@hooks/queries/community"
import { useAuthStore } from "stores/auth"
import { BgPrimary } from "assets/backgroundSvgs/bgPrimary"
import { useThemeCommunity } from "stores/themeCommunity"

export const CommunityReferralPage = () => {

    const { communityId } = useParams();
    if (!communityId) return null

    const { data, isLoading, isError } = useGetMyReferral({ communityId, refetchOnMount: false });
    const [copied, setCopied] = useState(false);

    const { setTopbarMobileData } = useTopbarMobileData();
    const { userName } = useAuthStore.getState()
    const { theme } = useThemeCommunity();

    useEffect(() => {
        setTopbarMobileData({ pageName: `Parrainage`, communityId, showTopbar: true, withGoHome: true });
    }, []);

    const domain = window.location.origin;

    const handleCopy = () => {
        const url = `${domain}/${communityId}?referrer=${userName}`;
        navigator.clipboard.writeText(url);
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
    }

    const fomatDate = (date: string) => {
        const newDate = new Date(date);
        return `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`
    }


    return (
        <div className="bg-app h-full w-full overflow-hidden relative">
            <div className="fixed bottom-[-200px] right-[-200px] w-full z-0 flex justify-end opacity-50">
                <BgPrimary color={theme.primaryColor} />
            </div>
            <div className="fixed top-[-200px] left-[-200px] w-full z-0 flex justify-end opacity-20">
                <BgPrimary color={theme.primaryColor} />
            </div>
            <div className="h-full w-full flex flex-col gap-8 items-center p-intermediate overflow-auto relative z-20">
                <div className="flex w-full flex-col items-start justify-start gap-2">
                    <h2 className="w-full text-xl font-[500]">Invitez vos amis à rejoindre la communuauté</h2>
                    <p className="w-full text-sm font-light">Gagnez des points aux classement pour chaque nouvel ami parrainé !</p>
                    <div className="w-full bg-white rounded-lg bg-white p-2 border flex items-center justify-between">
                        <p className="text-sm font-light">{domain}/{communityId}?referrer={userName}</p>
                        <AnimatedButton className="p-mimi rounded-mimi text-sm" style={{ backgroundColor: theme.quaternaryColor}} onClick={handleCopy}>{!copied ? "Copier" : "Copié !"}</AnimatedButton>
                    </div>
                </div>

                <hr className="w-full" />

                <div className="flex w-full flex-col items-start justify-start gap-4">
                    <h2 className="w-full text-xl font-[500]">Vos filleuls</h2>
                    {
                        isLoading ? (
                            <div className="w-full flex flex-col items-start justify-start gap-2">
                                {
                                    Array.from({ length: 5 }).map((_, index: number) => (
                                        <div className="border w-full py-2 px-3 rounded-lg flex items-center bg-white" key={index}>
                                            <div className="w-10 h-10 bg-grey-200 rounded-full animate-pulse" />
                                            <div className="flex flex-col gap-2 items-start ml-2">
                                                <div className="w-24 h-3 bg-gray-100 animate-pulse rounded" />
                                                <div className="w-16 h-2 bg-gray-100 animate-pulse rounded" />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ) : isError ? (
                            <div>
                                <p>Une erreur est survenue</p>
                            </div>
                        ) : data.length > 0 ? (
                            <div className="w-full flex flex-col items-start justify-start gap-2">
                                {
                                    data.map((item: any, index: number) => (
                                        <Link key={index} to={`/user/${item.uuid}`} className="w-full">
                                            <div className="border w-full py-2 px-3 rounded-lg flex items-center bg-white">
                                                <div className="w-10 h-10 bg-grey-200 rounded-full bg-cover bg-center" style={{ backgroundImage: `url(https://pub-388e882330ee44c7b74215d8e0935cb9.r2.dev/user%2F5853235c-0276-4dab-8ee6-195e3a0227a1)` }} />
                                                <div className="flex flex-col gap-0 items-start ml-2">
                                                    <p className="text-sm font-[500]">@{item.user_name}</p>
                                                    <p className="text-xs font-light text-gray-500">Inscrit le {fomatDate(item.created_at)}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    ))
                                }
                            </div>
                        ) : (
                            <div className="w-full flex flex-col items-start justify-start gap-5">
                                <p className="text-sm font-light">Vous n'avez aucun filleul pour le moment, copiez votre lien de parrainage pour le partager à vos amis !</p>
                                <AnimatedButton className="py-mini rounded-lg w-full" style={{ backgroundColor: theme.quaternaryColor}} onClick={handleCopy}>{!copied ? "Copier" : "Copié !"}</AnimatedButton>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}