
import { useMutator } from "@hooks/queries/api";
import { v4 as uuidv4 } from 'uuid';
import { useMessageStore } from 'stores/feed/channelMessage';

export type SendReactionType = {
    communityId: string | number;
    channelId: any;
    postId: any;
};

export type SendReactionContent = {
    reaction: string;
    type: string;
    commentId?: number | null;
}   

export const useSendReaction = ({ communityId, channelId, postId } : SendReactionType ) => {
    
    const { mutateAsync: sendNewReaction } = useMutator<any>(`/community/${communityId}/${channelId}/${postId}/reaction/new`);
    const addWaitingReaction = useMessageStore(state => state.addWaitingReaction);
    
    const sendReaction = async ({ reaction, type, commentId }: SendReactionContent ) => {
        
        const uniqueId = uuidv4() + "_" + new Date().getTime();
        //@ts-ignore
        addWaitingReaction({id : postId, channel_id: channelId, commentId : commentId ? commentId : undefined}, reaction, uniqueId, type);
        return sendNewReaction({ reaction, type, commentId, postId, temporaryId: uniqueId});
    };

    return { sendReaction };
};

