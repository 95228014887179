import { Config } from "services/config";
import { useModaleStore } from "stores/modale"
import { generateHTML } from '@tiptap/html'
import Document from '@tiptap/extension-document'
import Mention from '@tiptap/extension-mention'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import { useMemo, useState } from "react";
import { Icon } from "assets/icons";
import { AnimatedButton } from "components/globals/buttons";
import { useMutator } from "@hooks/queries/api";
import { useMessageStore } from "stores/feed/channelMessage";
import { useAuthStore } from "stores/auth";

export const PostSettingsModale = () => {

    const { modaleProps, setModale } = useModaleStore();
    const { uuid } = useAuthStore();
    const url = Config.CDN_HOST + "user%2F" + modaleProps.post.owner_uuid;
    const { mutateAsync: savePost } = useMutator<any>("/community/" + modaleProps.communityId + "/" + modaleProps.post.channel_id + "/" + modaleProps.post.id + "/save");
    const { mutateAsync: signalPost, isPending:isSignalmentPending } = useMutator<any>("/community/" + modaleProps.communityId + "/" + modaleProps.post.channel_id + "/" + modaleProps.post.id + "/signal");
    const { mutateAsync: deletePost, isPending:isDeletePending } = useMutator<any>("/community/" + modaleProps.communityId + "/" + modaleProps.post.channel_id + "/" + modaleProps.post.id + "/archive");
    const { updateMessageSavedStatus, updateMessageSignaledStatus } = useMessageStore();
    const [isManualySaved, setIsManualySaved] = useState(false);
    const [shouldSignal, setShouldSignal] = useState(false);
    const [signalReason, setSignalReason] = useState('');
    const [isManualySignaled, setIsManualySignaled] = useState(false);

    const [contentHtml, imageUrls] = useMemo(() => {
        let html = '';
        const images: string[] = [];
        if (typeof modaleProps.post.content === "string") {
            try {
                const contentJSON = JSON.parse(modaleProps.post.content);
                html = generateHTML(contentJSON, [
                    Document, Paragraph, Text, Mention, Image, Bold, Italic, Strike, Link
                ]);
            } catch {
                html = modaleProps.post.content;
            }
        } else {
            html = generateHTML(modaleProps.post.content, [
                Document, Paragraph, Text, Mention, Image, Bold, Italic, Strike, Link
            ]);
        }

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const imgElements = doc.getElementsByTagName('img');
        Array.from(imgElements).forEach((img) => {
            images.push(img.src);
        });

        return [doc.body.innerHTML, images];
    }, [modaleProps.post.content]);

    const handleSavePost = async () => {
        await savePost({})
        updateMessageSavedStatus(modaleProps.post.channel_id, modaleProps.post.id, true);
        setIsManualySaved(true);
    }

    const handleSignalPost = async () => {
        await signalPost({ reason: signalReason });
        updateMessageSignaledStatus(modaleProps.post.channel_id, modaleProps.post.id, true);
        setIsManualySignaled(true);
    }

    const handleDeletePost = async () => {
        await deletePost({})
        setModale(false, '', '', {...modaleProps});
    }

    return (
        <div className="w-full md:min-w-[600px] flex flex-col items-start justify-center gap-5 cursor-auto">
            {/* Display preview of post */}
            <div className="w-full flex items-center justify-start gap-2">
                <div className="w-[30px] !h-[30px] rounded-full bg-center bg-cover cursor-pointer" style={{ backgroundImage: `url(${url})` }} />
                <div className="flex gap-2 items-center">
                    <p className="text-base font-medium">{modaleProps.post.user_name}</p>
                </div>
            </div>
            <div className="w-full">
                <div className="font-light text-left post-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />
                {imageUrls.length > 0 && (
                    <div className="flex flex-wrap gap-2 mt-2">
                        {imageUrls.map((url, index) => (
                            <img
                                key={index}
                                src={url}
                                alt={`Image ${index + 1}`}
                                className="w-20 h-20 object-cover rounded-md cursor-pointer"
                            />
                        ))}
                    </div>
                )}
            </div>
            <hr className="w-full" />
            {/* Display two button for save and signal */}
            <div className="w-full flex items-center justify-center gap-3">
                {
                    (!modaleProps.post.is_saved && !isManualySaved) && (
                        <AnimatedButton
                            className={`w-full rounded-lg flex flex-col gap-1 items-center justify-center py-3 hover:bg-gray-300 transition bg-gray-100`}
                            onClick={handleSavePost}
                        >
                            <Icon type={"Bookmark2"} size={20} color={"black"} />
                            <p>Enregistrer</p>
                        </AnimatedButton>
                    )
                }
                {
                    (modaleProps.post.is_saved || isManualySaved) && (
                        <AnimatedButton
                            className={`w-full rounded-lg flex flex-col gap-1 items-center justify-center py-3 hover:bg-gray-300 transition bg-darkBlue text-white`}
                            onClick={handleSavePost}
                        >
                            <Icon type={"Bookmark2"} size={20} color={"black"} />
                            <p>Enregistré</p>
                        </AnimatedButton>
                    )
                }
                <AnimatedButton
                    className="w-full bg-gray-100 rounded-lg flex flex-col gap-1 items-center justify-center py-3 hover:bg-gray-300 transition"
                    onClick={() => setShouldSignal(true)}
                >
                    <Icon type={"Warning2"} size={20} color={"black"} />
                    <p>Signaler</p>
                </AnimatedButton>
            </div>
            {
                    (modaleProps.post.owner_uuid === uuid) && (
                        <AnimatedButton
                            className={`w-full rounded-lg flex flex-col gap-1 items-center justify-center py-3 hover:bg-gray-300 transition bg-red-200 text-white`}
                            onClick={handleDeletePost}
                        >
                            {isDeletePending ? "Suppression..." : "Supprimer"}
                        </AnimatedButton>
                    )
                }
            {
                (shouldSignal && !isManualySignaled) && (
                    <div className="w-full flex flex-col items-start justify-start gap-3">
                        <p>Pourquoi souhaitez-vous signaler ce poste ?</p>
                        <input
                            type="text"
                            placeholder="..."
                            className='bg-transparent w-full focus:outline-none border h-10 rounded px-3'
                            onChange={(e) => setSignalReason(e.target.value)}
                        />
                        <AnimatedButton
                            className="w-full bg-red-200 text-white rounded-lg flex items-center justify-center py-3 hover:bg-red-500 transition"
                            onClick={handleSignalPost}
                        >
                            {isSignalmentPending ? "Envoi..." : "Envoyer"}
                        </AnimatedButton>
                    </div>
                )
            }
            {
                (isManualySignaled || modaleProps.post.is_signaled) && (
                    <div className="w-full flex flex-col items-start justify-start gap-3 px-4 py-2 rounded-md bg-yellow-100">
                        <p>Poste signalé</p>
                    </div>
                )
            }
        </div>
    )
}