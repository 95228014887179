//import { useMutator } from "@hooks/queries/api"
import { useModaleStore } from "stores/modale";
import { JoinForm } from "components/admin/settings/joinForm"
import { AdminLayout } from "layout/AdminLayout"
import { useParams } from "react-router-dom";
import { useCommunityById, useCommunityForm } from "hooks/queries/community";

export const AdminJoinSettings = () => {

    const { communityId } = useParams();
    const { data: communityData, isLoading:isCommunityDataLoading, isError:isCommunityDataError } = useCommunityById({ communityId });
    useCommunityForm({ communityId, refetchOnMount: true });

    const { setModale } = useModaleStore();

    const handleChangeVisibility = async (visibility: number) => {
        if(communityData?.type_id === visibility) return;
        setModale(true, "ChangeCommunityVisibility", "modale", { communityId: communityId, actualCommunityType: 1, newCommunityType: visibility })
    }

    return (
        <AdminLayout>
            <div className="bg-app h-full w-full overflow-hidden">
                <div className="h-full w-full bg-app flex flex-col gap-8 items-center p-intermediate overflow-auto">
                    <div className="w-full flex items-center justify-between">
                        <p className="md:text-lg text-md">Ma communauté est :</p>
                        {
                            isCommunityDataLoading && !isCommunityDataError ? (
                                <div className="flex gap-4">
                                    <button className="bg-gray-200 animate-pulse w-[90px] h-[40px] rounded-lg"></button>
                                    <button className="bg-gray-200 animate-pulse w-[90px] h-[40px] rounded-lg"></button>
                                </div>
                            ) : (
                                <div className="flex gap-4">
                                    <button onClick={() => handleChangeVisibility(1)} className={communityData?.type_id === 1 ? "bg-gray-500 px-4 py-2 rounded-lg text-white" : "bg-gray-100 px-4 py-2 rounded-lg"}>Publique</button>
                                    <button onClick={() => handleChangeVisibility(2)} className={communityData?.type_id === 2 ? "bg-gray-500 px-4 py-2 rounded-lg text-white" : "bg-gray-100 px-4 py-2 rounded-lg"}>Privée</button>
                                </div>
                            )
                        }
                    </div>
                    <div className="h-[1px] border-t w-full"/>
                    <JoinForm />
                </div>
            </div>
        </AdminLayout>
    )
}