import { useFetcher } from './api'

export type RefetchOnMountType = {
    refetchOnMount?: boolean;
    enabled?: boolean;
}

export const useGetSavedPosts = ({ refetchOnMount = true, enabled = true }: RefetchOnMountType) => {
    return useFetcher<any, any>({ key: `post-saved`, path: `/post/saved`, refetchOnMount, enabled })
}
