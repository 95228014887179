import { Router } from './router';


export const AppContent = () => {

    return (
        <>
            <Router />
        </>
    );
};

