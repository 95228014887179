import { useFetchEmojis } from "@hooks/queries/emojis";
import { useCommunities, useMyAdminCommunities, useMyCommunities } from "@hooks/queries/communities";
import { useEffect } from "react";
import { useAuthStore } from "stores/auth";
import { useAllConversation } from "@hooks/queries/messages";

export const InitCalls = () => {
    
    const { isLogged } = useAuthStore();
    const { fetchEmojis } = useFetchEmojis();

    const { refetch: refetchCommunities } = useCommunities({ refetchOnMount: false });
    const { refetch: refetchMine } = useMyCommunities({ refetchOnMount: false });
    const { refetch: refetchAdmin } = useMyAdminCommunities({ refetchOnMount: false });
    const { refetch: refetchConversation } = useAllConversation({ refetchOnMount: false, enabled: isLogged });


    useEffect(() => {
        refetchCommunities()
        refetchMine()
        refetchAdmin()

        if(isLogged) {
            refetchConversation()
        }
        
    }, [isLogged])

    fetchEmojis()

    return <></>
}