import { CategoryItemType } from 'interfaces/categories';
import { usePublicFetcher } from './api'

export type RefetchOnMountType = {
    refetchOnMount?: boolean;
}

export type PropsFromCommunityQuery = {
    refetchOnMount?: boolean;
    communityId: string;
    enabled?: boolean;
}

export type CategoryRequestTypes = {
    refetchOnMount?: boolean;
    slug?: string;
}

export interface CategoryListType {
    list : CategoryItemType[];
    page : number;
}

export const useGetTopsCategories = ({ refetchOnMount = true }: RefetchOnMountType) => {
    return usePublicFetcher<any, CategoryItemType[]>({ key: `categories-top`, path: `/categories/tops`, refetchOnMount })
}

export const useGetAllCategories = ({ refetchOnMount = true }: RefetchOnMountType) => {
    return usePublicFetcher<any, CategoryListType>({ key: `categories-all`, path: `/categories`, refetchOnMount })
}

export const useGetCategoriesFromCommunity = ({ communityId, refetchOnMount = true, enabled }: PropsFromCommunityQuery) => {
    return usePublicFetcher<any, CategoryListType>({ key: `categories-${communityId}`, path: `community/${communityId}/categories`, refetchOnMount, enabled })
}

export const useGetCategoryBySlug = ({ slug, refetchOnMount = true }: CategoryRequestTypes) => {
    return usePublicFetcher<any, any>({ key: `category-by-${slug}`, path: `/categories/${slug}`, refetchOnMount })
}

export const useCommunitiesByCategories = ({ slug, refetchOnMount = true }: CategoryRequestTypes) => {
    return usePublicFetcher<any, any>({ key: `category-${slug}-communities`, path: `/categories/${slug}/communities`, refetchOnMount })
}
