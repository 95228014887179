import { create } from 'zustand'

export interface NotificationInterface {
    id: number,
    title: string,
    description: string,
    type_id: number,
    community_id: number,
    user_uuid: string,
    notifier_uuid: string,
    is_viewed: boolean,
    created_at: string | Date
}

export interface NotificationStore {
    notifications: NotificationInterface[];
    addNotification: (notification: NotificationInterface | NotificationInterface[]) => void;
    setNotifications: (notifications: NotificationInterface[]) => void;
    hasUnviewedNotifications: () => boolean;
    updateNotification: (id: number, updates: Partial<NotificationInterface>) => void;
}

export const useNotificationStore = create<NotificationStore>((set, get) => ({
    notifications: [],
    addNotification: (notification) => set((state) => {
        const notificationsToAdd = Array.isArray(notification) ? notification : [notification];
        
        // Filtrer les nouvelles notifications pour éviter les doublons
        const newNotifications = notificationsToAdd.filter(newNotif => 
            !state.notifications.some(existingNotif => existingNotif.id === newNotif.id)
        );
        
        // Combiner les nouvelles notifications avec les existantes
        const updatedNotifications = [...state.notifications, ...newNotifications];
        
        // Trier les notifications par created_at en ordre décroissant
        const sortedNotifications = updatedNotifications.sort((a, b) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        });
        
        return { notifications: sortedNotifications };
    }),
    setNotifications: (notifications) => set({ 
        notifications: notifications.sort((a, b) => 
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        ) 
    }),
    hasUnviewedNotifications: () => {
        return get().notifications.some(notification => !notification.is_viewed);
    },
    updateNotification: (id, updates) => set((state) => {
        const updatedNotifications = state.notifications.map(notification =>
            notification.id === id ? { ...notification, ...updates } : notification
        );
        // Trier après la mise à jour au cas où created_at aurait été modifié
        return { 
            notifications: updatedNotifications.sort((a, b) => 
                new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            ) 
        };
    }),
}));