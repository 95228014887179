import { useGetCommunityLandingStatistique } from "@hooks/queries/community";
import { StatsBlockProps } from "../types";

export const StatsBlock: React.FC<StatsBlockProps> = ({ communityId }) => {

    console.log('communityId', communityId);

    const { data: communityStats, isLoading: isGetLoading, isError } = useGetCommunityLandingStatistique({ communityId, refetchOnMount: false });

    console.log('communityStats', communityStats);

    return (
        <div className="w-full flex items-center justify-between lg:gap-4 gap-2 ">
            { isError && <div>Erreur</div> }
            <div className="w-full bg-white rounded-lg border p-small">
                { isGetLoading ? <div className="h-9 w-12 bg-gray-100 animate-pulse rounded"></div> : <p className="text-3xl font-bold">{communityStats.member_count}</p> }
                <p className="text-xs font-light lg:text-base">Nombre de membres</p>
            </div>
            <div className="w-full bg-white rounded-lg border p-small">
                { isGetLoading ? <div className="h-9 w-12 bg-gray-100 animate-pulse rounded"></div> : <p className="text-3xl font-bold">{communityStats.last_month_posts_count}</p> }
                <p className="text-xs font-light lg:text-base">Posts les 30 derniers jours</p>
            </div>
        </div>
    )
};