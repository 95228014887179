export const BgPrimary = ({ color } : { color : string }) => {
    return (
        <svg width="561" height="625" viewBox="0 0 561 625" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <g filter="url(#filter0_f_2986_9655)">
                    <ellipse cx="86.4494" cy="117.885" rx="86.4494" ry="117.885" transform="matrix(1 8.74228e-08 8.74228e-08 -1 194.102 430.385)" fill={color} />
                </g>
            </g>
            <defs>
                <filter id="filter0_f_2986_9655" x="0.401566" y="0.914261" width="560.299" height="623.171" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="96.85" result="effect1_foregroundBlur_2986_9655" />
                </filter>
            </defs>
        </svg>

    )
}