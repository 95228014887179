import { useMemo, useCallback } from 'react';
import { Config } from "services/config";
import { Icon } from "assets/icons";
import { ReactPicker } from "../reactions/reactPicker";
import { motion } from 'framer-motion';
import { useNavigate } from "react-router-dom";
import { useModaleStore } from "stores/modale";
import Document from '@tiptap/extension-document';
import Mention from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Image from '@tiptap/extension-image';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Link from '@tiptap/extension-link';
import { generateHTML } from '@tiptap/html';
import { useBreakpointContext } from "context/BreakpointContext";

const commentContentStyles = `
  .comment-content img {
    display: none;
  }
`;

export const CommentItem = ({ comment, communityId, channelId, postId }: { comment: any, communityId: string, channelId: number | string, postId: any }) => {
    const navigate = useNavigate();
    const { isMobile } = useBreakpointContext();
    const { setModale } = useModaleStore();

    const url = Config.CDN_HOST + "user%2F" + comment.user_uuid;

    const handleGoToProfile = () => {
        navigate("/user/" + comment.user_uuid);
    };

    const handleTriggerSettings = () => {
        setModale(true, 'CommentSettingsModale', 'snapped', { comment, communityId });
    };

    const triggerImageModal = useCallback((imageUrl: string) => {
        setModale(true, 'ImageDisplayModale', 'modale', { imageUrl, user_name: comment.user_name, owner_uuid: comment.user_uuid });
    }, [setModale, comment.user_name, comment.user_uuid]);

    const [contentHtml, imageUrls] = useMemo(() => {
        let html = '';
        const images: string[] = [];
        if (typeof comment?.comment === "string") {
            try {
                const contentJSON = JSON.parse(comment.comment);
                html = generateHTML(contentJSON, [
                    Document, Paragraph, Text, Mention, Image, Bold, Italic, Strike, Link
                ]);
            } catch {
                html = comment.comment;
            }
        } else {
            html = generateHTML(comment?.comment, [
                Document, Paragraph, Text, Mention, Image, Bold, Italic, Strike, Link
            ]);
        }

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const imgElements = doc.getElementsByTagName('img');
        Array.from(imgElements).forEach((img) => {
            images.push(img.src);
        });

        return [doc.body.innerHTML, images];
    }, [comment.comment]);

    const extractTime = (dateString: string): string => {
        const date = new Date(dateString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return `${hours}h${minutes.toString().padStart(2, '0')}`;
    };

    return (
        <div className={`w-full flex flex-col gap-0 relative bg-white`}>
            <style dangerouslySetInnerHTML={{ __html: commentContentStyles }} />
            
            <div className={`w-full flex gap-3 px-small py-small`}>
                <div className="">
                    <div onClick={handleGoToProfile} className="w-[40px] !h-[40px] rounded-full bg-center bg-cover cursor-pointer" style={{ backgroundImage: `url(${url})` }} />
                    <div className="flex w-full lg:h-webkit items-center justify-center">
                        <div className="h-full w-[1.5px] bg-grey-400"></div>
                    </div>
                </div>
                <div className="flex flex-col gap-3 w-full">
                    <div className="flex w-full items-center justify-between">
                        <div className="flex gap-2 items-center">
                            <p className="text-base font-medium">{comment.user_name}</p>
                            {!isMobile && <p className="text-sm font-light text-grey-600">{comment.waiting && <span className="italic text-grey-400">En attente - </span>}{extractTime(comment.created_at)}</p>}
                        </div>
                        {isMobile && (
                            <div className="flex gap-2 items-center">
                                <p className="text-sm font-light text-grey-600">{extractTime(comment.created_at)}{comment.waiting && <span className="italic text-grey-400"> - En attente</span>}</p>
                                <Icon type={"More"} size={17} color={"black"} onClick={handleTriggerSettings} />
                            </div>
                        )}
                    </div>
                    <motion.div className="w-full">
                        <div className="font-light text-left comment-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />
                        {imageUrls.length > 0 && (
                            <div className="flex flex-wrap gap-2 mt-2">
                                {imageUrls.map((url, index) => (
                                    <img 
                                        key={index}
                                        src={url}
                                        alt={`Image ${index + 1}`}
                                        className="w-20 h-20 object-cover rounded-md cursor-pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            triggerImageModal(url);
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                    </motion.div>
                    <div className="flex flex-col-reverse md:flex-row gap-2 items-start md:items-stretch justify-start">
                        <ReactPicker post={{id: postId}} communityId={communityId} type="comment" channelId={channelId} comment={comment} />
                    </div>
                </div>
            </div>
        </div>
    );
};