export const CommunitySkeleton = () => {
    return (
        <div className="h-full w-full flex items-center justify-center">
            <style>
                {`
                @keyframes pulse {
                    0%, 100% {
                        transform: scale(1);
                    }
                    50% {
                        transform: scale(1.1);
                    }
                }
                `}
            </style>
            <div className="bg-gray-50 w-[240px] h-[240px] rounded-full" style={{ animation: 'pulse 0.5s infinite' }} />
        </div>

    )
}