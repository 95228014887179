import { useAuthStore } from "stores/auth";
import { AnimatedButton } from "components/globals/buttons"
import { useGetUserProfile } from "@hooks/queries/users";
import { Config } from "services/config";
import { useState } from "react";

export const updateProfileModal = () => {

    const { uuid } = useAuthStore();
    const { data: profile, isError: isProfileError, isLoading: isProfileLoading } = useGetUserProfile({ userId: uuid, refetchOnMount: false, enabled: true });
    const url = Config.CDN_HOST + "user%2F" + uuid;
    const [new_username, setNewUsername] = useState("");

    const handleUpdateProfile = (e:any) => {
        e.preventDefault();
        console.log(new_username);
    }

    if (isProfileLoading) return updateProfileSkeleton();
    if (isProfileError) return updateProfileError();

    return (
        <div>
            <div className="w-full flex flex-col justify-start gap-6">
                <div className="w-full flex flex-col items-center justify-center gap-3">
                    <AnimatedButton
                        className="w-[100px] h-[100px] rounded-full bg-center bg-cover" style={{ backgroundImage: `url(${url})` }}
                    />
                </div>
                <form className="w-full flex flex-col items-start justify-start gap-3" onSubmit={handleUpdateProfile}>
                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                        <p className="text-black text-lg font-bold">Nom d'utilisateur</p>
                        <input type="text" className="w-full h-10 rounded-md border border-gray-300 p-2" defaultValue={profile.user_name} onChange={(e) => setNewUsername(e.target.value)}/>
                    </div>
                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                        <p className="text-black text-lg font-bold">Nom</p>
                        <input type="text" className="w-full h-10 rounded-md border border-gray-300 p-2" defaultValue={profile.first_name} />
                    </div>
                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                        <p className="text-black text-lg font-bold">Prénom</p>
                        <input type="text" className="w-full h-10 rounded-md border border-gray-300 p-2" defaultValue={profile.last_name} />
                    </div>
                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                        <p className="text-black text-lg font-bold">Bio</p>
                        <textarea className="w-full h-20 rounded-md border border-gray-300 p-2" defaultValue={profile.bio} />
                    </div>
                    <AnimatedButton
                        className="w-full bg-darkBlue text-white rounded-lg flex items-center justify-center py-3 hover:bg-gray-300 transition"
                        type="submit"
                    >
                        Enregistrer
                    </AnimatedButton>
                </form>
            </div>
        </div>
    )
}

const updateProfileSkeleton = () => {
    return (
        <div>loading...</div>
    )
}

const updateProfileError = () => {
    return (
        <div>error...</div>
    )
}