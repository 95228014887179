import { timeSince } from "services/timeSince";
import { Icon } from "assets/icons";
import { AnimatedButton } from "components/globals/buttons";
import { Config } from "services/config";
import { useModaleStore } from "stores/modale";
import { ActivityGraph } from "components/admin/members";
import { useNavigate } from "react-router-dom";
import { useMutator } from "@hooks/queries/api";

export const MemberModale = () => {

    const { modaleProps, setModale } = useModaleStore();
    const {
        mutateAsync: updateCategories,
        isError: isUpdateError,
        isPending: isUpdatePending,
        isSuccess: isUpdateSuccess
    } = useMutator<any>(`/community/${modaleProps.member.community_id}/admin/member/${modaleProps.member.member_uuid}/update-waiting-status`);
    const navigate = useNavigate();

    const url = Config.CDN_HOST + "user%2F" + modaleProps.member.member_uuid;

    const handleGoToProfile = () => {
        setModale(false, '', '', {...modaleProps });
        navigate("/user/" + modaleProps.member.member_uuid);
    }

    const handleValidateMembership = async () => {
        await updateCategories({})
        console.log("Validate membership");
    }

    return (
        <div className="flex flex-col gap-6 items-center w-full lg:w-[500px] lg:p-3 cursor-auto">
            <div className="w-full flex items-center justify-center gap-3">
                <img src={url} className="h-12 w-12 rounded-full object-fit-cover" alt="" />
                <div className="w-webkit flex flex-col items-start justify-start gap-0">
                    <p className="text-xl font-light">{modaleProps.member.first_name} {modaleProps.member.last_name}</p>
                    <p className="text-base flex items-center justify-center gap-0.5 font-[400]">@<span className="text-base font-[500]">{modaleProps.member.user_name}</span></p>
                </div>
                <div className="flex items-center justify-end gap-2">
                    <Icon type="ArrowSquareLeft" color={"black"} className='cursor-pointer' />
                    <Icon type="ArrowSquareRight" color={"black"} className='cursor-pointer' />
                </div>
            </div>
            <div className="flex w-full gap-2 items-center justify-center">
                <AnimatedButton onClick={handleGoToProfile} className="bg-white hover:bg-gray-100 border border-black text-black py-nano rounded-micro w-full text-center font-[400] text-sm">Voir le profil</AnimatedButton>
                <AnimatedButton className="bg-white hover:bg-gray-100 border border-black text-black py-nano rounded-micro w-full text-center font-[400] text-sm">Message</AnimatedButton>
            </div>
            <hr className="w-full" />
            {
                modaleProps.member.is_waiting ? (
                    <div className="w-full bg-blue-100 px-5 py-4 rounded-lg flex flex-col items-start justify-start gap-4">
                        <p className="text-blue-500 text-lg">En attente de validation</p>
                        {modaleProps.member.isFormResponse ?
                            <hr className="w-full" /> :
                            <span className="font-light">Cet utilisateur est en attente de validation sans aucune réponse au formulaire</span>
                        }
                        {
                            modaleProps.member.isFormResponse && (
                                <div className="w-full rounded-lg flex flex-col items-start justify-start">
                                    {
                                        modaleProps.member.documents?.map((document: any) => (
                                            <div className="w-full flex flex-col gap-2 items-start justify-start" key={document._id}>
                                                <span className="text-gray-400 font-light italic text-sm">Complété {timeSince(document.timestamp)}</span>
                                                <div className="w-full flex items-center justify-between">
                                                    {
                                                        document.additionalInfo.map((form: any, index: number) => (
                                                            <div className="w-full flex flex-col gap-1 items-start justify-start p-3 rounded-lg bg-white" key={index}>
                                                                <span className="text-gray-600 italic font-light">{form.question}</span>
                                                                <span>{form.response}</span>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                        <AnimatedButton 
                            className="bg-green-500 hover:bg-green-600 border text-white py-tiny rounded-micro w-full text-center"
                            onClick={handleValidateMembership}
                        >{isUpdatePending ? "En cours..." : "Valider l'adhésion"}</AnimatedButton>
                        {
                            isUpdateError && (
                                <div className="w-full max-w-md py-2 rounded-md bg-red-400 text-white">
                                    Une erreur est survenue lors de la validation de l'adhésion
                                </div>
                            )
                        }
                        {
                            isUpdateSuccess && (
                                <div className="w-full max-w-md py-2 rounded-md bg-green-400 text-white">
                                    L'adhésion a été validée avec succès
                                </div>
                            )
                        }
                    </div>
                ) : (
                    <div className="w-full flex flex-col items-start justify-start gap-6">
                        <div className="w-full flex flex-col items-start justify-start gap-4">
                            <div className="flex w-full flex-col items-start justify-start gap-2">
                                <p className="text-gray-500 font-light">A rejoint la communauté {timeSince(modaleProps.member.created_at)}</p>
                                <div className="flex w-full items-center justify-start gap-1">
                                    <p className="text-gray-600 font-light">Nombre de points : </p>
                                    <p className="text-gray-700 font-[700]">{modaleProps.member.points}</p>
                                </div>
                            </div>
                        </div>
                        <hr className="w-full" />
                        <div className="w-full flex flex-col items-start justify-start gap-2">
                            <p className="font-[500]">Activité dans la communauté</p>
                            <ActivityGraph />
                        </div>
                        <AnimatedButton className="bg-blue-500 hover:bg-blue-600 border border-black text-white py-tiny rounded-micro w-full text-center">Follow</AnimatedButton>
                    </div>
                )
            }
        </div>
    )
}