import { useMemo } from "react";
import { Routes } from "./useAppRoutes";

export const useAdminSidebarMenu = (): any[] => {
    

    return useMemo(
        () =>
            [
                {
                    key: "home",
                    label: "Dashboard",
                    path: Routes.admin,
                    icon: "Home",
                },
                {
                    key: "members",
                    label: "Membres",
                    path: Routes.adminUsers,
                    icon: "Profile2User",
                },
                {
                    key: "config",
                    label: "Configuration",
                    path: Routes.adminSettings,
                    icon: "Setting2",
                },
                {
                    key: "employees",
                    label: "La team",
                    path: Routes.adminEmployees,
                    icon: "UserTick",
                },
                {
                    key: "content",
                    label: "Ressources",
                    path: Routes.adminContent,
                    icon: "Book",
                },
                {
                    key: "monetise",
                    label: "Monétisation",
                    path: Routes.adminStripeConfig,
                    icon: "Money",
                },
                
            ] as any[],
        [],
    );
};
