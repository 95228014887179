import { Config } from "services/config";
import { Icon } from "assets/icons";
import { useMemo, useCallback, useState } from "react";
import { ReactPicker } from "../reactions/reactPicker";
import { motion } from 'framer-motion'
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useModaleStore } from "stores/modale";
import Document from '@tiptap/extension-document'
import Mention from '@tiptap/extension-mention'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import { generateHTML } from '@tiptap/html'
import { EditorContent, useEditor } from '@tiptap/react';
import { useBreakpointContext } from "context/BreakpointContext";
import { AnimatedButton } from "components/globals/buttons";
import { useUpdateMessage } from "@hooks/feed/messages";
import { useAuthStore } from "stores/auth";

const postContentStyles = `
  .post-content img {
    display: none;
  }
`;

export const MessagesFeed = ({ post, isThread = false, isPreview = false, communityId, channelId, isFullWidth = false }: any) => {

    const navigate = useNavigate()

    const [isEditing, setIsEditing] = useState(false);
    const [uploadedImages, setUploadedImages] = useState<string[]>([]);
    const [isHovered, setIsHovered] = useState(false);
    const { isMobile } = useBreakpointContext()
    const { setModale } = useModaleStore()
    const { updateMessage } = useUpdateMessage({ post, communityId, channelId });
    const { uuid } = useAuthStore();

    const url = Config.CDN_HOST + "user%2F" + post.owner_uuid;

    const handleGoToPost = () => {
        if (isThread) return;
        navigate("/" + communityId + "/" + channelId + "/" + post.id);
    }

    const handleGoToProfile = () => {
        navigate("/user/" + post.owner_uuid);
    }

    const handleTriggerSettings = () => {
        setModale(true, 'PostSettingsModale', 'snapped', { post, communityId });
    }

    const triggerImageModal = useCallback((imageUrl: string) => {
        setModale(true, 'ImageDisplayModale', 'modale', { imageUrl, user_name: post.user_name, owner_uuid: post.owner_uuid });
    }, [setModale]);

    const toggleFormat = (format: 'bold' | 'italic') => {
        if (!editor) return;

        switch (format) {
            case 'bold':
                editor.chain().focus().toggleBold().run();
                break;
            case 'italic':
                editor.chain().focus().toggleItalic().run();
                break;
        }
    };

    const [contentHtml, imageUrls] = useMemo(() => {
        let html = '';
        const images: string[] = [];
        if (typeof post?.content === "string") {
            try {
                const contentJSON = JSON.parse(post.content);
                html = generateHTML(contentJSON, [
                    Document, Paragraph, Text, Mention, Image, Bold, Italic, Strike, Link
                ]);
            } catch {
                html = post.content;
            }
        } else {
            html = generateHTML(post?.content, [
                Document, Paragraph, Text, Mention, Image, Bold, Italic, Strike, Link
            ]);
        }

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const imgElements = doc.getElementsByTagName('img');
        Array.from(imgElements).forEach((img) => {
            images.push(img.src);
        });

        return [doc.body.innerHTML, images];
    }, [post.content]);

    const editor = useEditor({
        extensions: [
            StarterKit,
            Image,
            Link,
            Placeholder.configure({
                placeholder: 'Modifier votre message...',
            }),
        ],
        content: post.content,
        editorProps: {
            attributes: {
                class: 'focus:outline-none min-h-[20px] max-h-[300px] overflow-y-auto p-3 font-light',
            },
        },
    });

    const extractTime = (dateString: string): string => {
        const date = new Date(dateString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return `${hours}h${minutes.toString().padStart(2, '0')}`;
    };

    const handleOpenReactModale = (event: any) => {
        event.stopPropagation();
        setModale(true, 'ReactModale', 'snapped', { communityId, channelId, postId: post.id, type: "post", commentId: null });
    }

    const handleEditPost = () => {
        setIsEditing(true);
        if (editor) {
            const content = editor.getJSON().content;
            if (content) {
                editor.commands.setContent(
                    content.filter((node: any) => node.type !== 'image')
                );
            } else {
                editor.commands.setContent('');
            }
        }
        setUploadedImages(imageUrls);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setUploadedImages(imageUrls);
    };

    const handleUpdatePost = async () => {
        if (!editor) return;
        const content = editor.getJSON();

        // Ajouter les images à la fin du contenu JSON
        uploadedImages.forEach(imageUrl => {
            content?.content?.push({
                type: 'image',
                attrs: { src: imageUrl }
            });
        });

        await updateMessage({ content });
        setIsEditing(false);
        setUploadedImages(imageUrls);
    };

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageUrl = e.target?.result as string;
                setUploadedImages(prev => [...prev, imageUrl]);
            };
            reader.readAsDataURL(file);
        }
    };

    const removeImage = (index: number) => {
        setUploadedImages(prev => prev.filter((_, i) => i !== index));
    };

    if (isEditing) {
        return (
            <div className='bg-yellow-100 w-full p-mini w-full flex gap-3'>
                <div className="">
                    <div onClick={handleGoToProfile} className="w-[40px] !h-[40px] rounded-full bg-center bg-cover cursor-pointer" style={{ backgroundImage: `url(${url})` }} />
                    <div className="flex w-full lg:h-webkit items-center justify-center">
                        <div className="h-full w-[1.5px] bg-grey-400"></div>
                    </div>
                </div>
                <div className="bg-white rounded-lg shadow-md border border-gray-300 overflow-hidden w-full">
                    <div className="flex items-center space-x-2 p-2 border-b border-gray-200">
                        <button onClick={() => toggleFormat('bold')} className="p-1 rounded hover:bg-gray-100">
                            <Icon type="TextBold" size={20} color={editor?.isActive('bold') ? 'blue' : 'black'} />
                        </button>
                        <button onClick={() => toggleFormat('italic')} className="p-1 rounded hover:bg-gray-100">
                            <Icon type="TextItalic" size={20} color={editor?.isActive('italic') ? 'blue' : 'black'} />
                        </button>
                    </div>
                    <div className="p-2">
                        <EditorContent editor={editor} />
                    </div>
                    {uploadedImages.length > 0 && (
                        <div className="flex flex-wrap gap-2 p-2">
                            {uploadedImages.map((url, index) => (
                                <div key={index} className="relative">
                                    <img
                                        src={url}
                                        alt={`Uploaded ${index + 1}`}
                                        className="w-20 h-20 object-cover rounded-md"
                                    />
                                    <button
                                        onClick={() => removeImage(index)}
                                        className="absolute top-1 right-1 bg-white rounded-full p-1"
                                    >
                                        <Icon type="CloseCircle" size={16} color="black" />
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="flex items-center justify-between p-2">
                        <div className="flex items-center space-x-2">
                            <label htmlFor="image-upload" className="cursor-pointer p-1 rounded hover:bg-gray-200">
                                <Icon type="Image" size={20} color="gray" />
                            </label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageUpload}
                                className="hidden"
                                id="image-upload"
                            />
                        </div>
                        <div className="flex space-x-2">
                            <AnimatedButton onClick={handleCancelEdit} className="bg-gray-200 text-black rounded-md px-4 py-1">
                                Annuler
                            </AnimatedButton>
                            <AnimatedButton onClick={handleUpdatePost} className="bg-darkBlue text-white rounded-md px-4 py-1">
                                Mettre à jour
                            </AnimatedButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            className={`w-full flex flex-col gap-0 relative ${!isPreview ? "md:hover:bg-grey-100 bg-app" : ""} cursor-pointer`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <style dangerouslySetInnerHTML={{ __html: postContentStyles }} />

            {isHovered && !isMobile && !isThread && (
                <div className="absolute top-2 right-5 z-10 -top-5 bg-white rounded-md flex justify-end items-center p-1 gap-1 cursor-pointer">
                    <div className="bg-gray-50 cursor-pointer hover:bg-gray-200 h-10 w-10 rounded flex items-center justify-center" onClick={handleOpenReactModale}>
                        <Icon type="EmojiHappy" size={17} color="black" className="cursor-pointer" />
                    </div>
                    {
                        post.owner_uuid === uuid && (
                            <div className="bg-gray-50 cursor-pointer hover:bg-gray-200 h-10 w-10 rounded flex items-center justify-center" onClick={handleEditPost}>
                                <Icon type="Edit" size={17} color="black" className="cursor-pointer" />
                            </div>
                        )
                    }
                    <div className="bg-gray-50 cursor-pointer hover:bg-gray-200 h-10 w-10 rounded flex items-center justify-center" onClick={handleGoToPost}>
                        <Icon type="DirectSend" size={17} color="black" className="cursor-pointer" />
                    </div>
                    <div className="bg-gray-50 cursor-pointer hover:bg-gray-200 h-10 w-10 rounded flex items-center justify-center" onClick={handleTriggerSettings}>
                        <Icon type="More" size={17} color="black" className="cursor-pointer" />
                    </div>
                </div>
            )}

            <div className={`w-full flex gap-3 ${isFullWidth ? "px-none" : "px-small"} py-small`}>
                <div className="w-min">
                    <div onClick={handleGoToProfile} className="w-[40px] !h-[40px] rounded-full bg-center bg-cover cursor-pointer" style={{ backgroundImage: `url(${url})` }} />
                    <div className="flex w-full lg:h-webkit items-center justify-center">
                        <div className="h-full w-[1.5px] bg-grey-400"></div>
                    </div>
                </div>
                <div className="flex flex-col gap-3 w-full">
                    <div className="flex w-full items-center justify-between">
                        <div className="flex gap-2 items-center">
                            <p className="text-base font-medium">{post.user_name}</p>
                            {!isMobile && <p className="text-sm font-light text-grey-600">{post.waiting && <span className="italic text-grey-400">En attente - </span>}{extractTime(post.created_at)}{post.is_update && <span className="italic text-grey-400"> - ( Modifié )</span>}</p>}
                        </div>
                        {isMobile && (
                            <div className="flex gap-2 items-center">
                                <p className="text-sm font-light text-grey-600">{extractTime(post.created_at)}{post.waiting && <span className="italic text-grey-400"> - En attente</span>}</p>
                                <Icon type={"More"} size={17} color={"black"} onClick={handleTriggerSettings} />
                            </div>
                        )}
                    </div>
                    <motion.div onClick={handleGoToPost} className="w-full">
                        <div className="font-light text-left post-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />
                        {imageUrls.length > 0 && (
                            <div className="flex flex-wrap gap-2 mt-2">
                                {imageUrls.map((url, index) => (
                                    <img
                                        key={index}
                                        src={url}
                                        alt={`Image ${index + 1}`}
                                        className="w-20 h-20 object-cover rounded-md cursor-pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            triggerImageModal(url);
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                    </motion.div>
                    <div className="flex flex-col-reverse md:flex-row gap-2 items-start md:items-stretch justify-start">
                        {!isThread && post.comments > 0 && (
                            <div className="flex items-center gap-2 py-1 px-2 border rounded-tiny cursor-pointer hover:bg-grey-200 w-max" onClick={handleGoToPost}>
                                <div className="flex -space-x-2">
                                    <div className="w-5 h-5 rounded-full bg-cover bg-center" style={{ backgroundImage: "url(/img/memojis/memoji1.png)" }} />
                                    {post.comments > 1 && <div className="w-5 h-5 rounded-full bg-cover bg-center" style={{ backgroundImage: "url(/img/memojis/memoji2.png)" }} />}
                                    {post.comments > 2 && <div className="w-5 h-5 rounded-full bg-cover bg-center" style={{ backgroundImage: "url(/img/memojis/memoji3.png)" }} />}
                                </div>
                                <p className="text-grey-600 text-sm w-full">{post.comments} réponses</p>
                            </div>
                        )}
                        <ReactPicker post={post} communityId={communityId} type="post" channelId={channelId} />
                    </div>
                </div>
            </div>
            {isPreview && (
                <RouterLink to={`/${post.community_id}/${post.channel_id}`}>
                    <div className="bg-gray-100 w-full border rounded-lg py-1.5 px-3 flex items-center gap-1">
                        <div className="w-5 h-5 rounded bg-cover bg-center" style={{ backgroundImage: `url(https://pub-388e882330ee44c7b74215d8e0935cb9.r2.dev/community%2F${post.community_id}%2Fbg)` }} />
                        <p className="text-sm">{post.community_name}</p>
                        <p className="text-sm">-</p>
                        <p className="text-sm font-light">Publié dans <span className="font-[500]">#{post.channel_name}</span></p>
                    </div>
                </RouterLink>
            )}
        </div>
    )
}