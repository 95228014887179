export const CommunityThemeConfig = {
    blue : {
        primaryColor: "#0000FF",  
        secondaryColor: "#3333FF",  
        tertiaryColor: "#6666FF",  
        quaternaryColor: "#9999FF",  
        name: "Blue",
    },
    red : {
        primaryColor: "#FF0000",  
        secondaryColor: "#FF3333",  
        tertiaryColor: "#FF6666",  
        quaternaryColor: "#FF9999",  
        name: "Red",
    },
   orange : {
        primaryColor: "#FFA500",  
        secondaryColor: "#FFB733",  
        tertiaryColor: "#FFC966",  
        quaternaryColor: "#FFDB99",  
        name: "Orange",
    },
    green : {
        primaryColor: "#008000", 
        secondaryColor: "#339933",  
        tertiaryColor: "#66CC66", 
        quaternaryColor: "#99FF99",  
        name: "Green",
    },
    black : {
        primaryColor: "#000000", 
        secondaryColor: "#333333",
        tertiaryColor: "#666666", 
        quaternaryColor: "#999999",
        name: "Black",
    }
}
