import { BrowserRouter, useRoutes } from "react-router-dom";
import type { RouteObject } from "react-router-dom";
import { useMatchRoutes } from "hooks/useMatchRoutes";
import AdminListener from "services/adminListener";
import { TopBar, BottomBar } from "components/globals";
import { useAuthStore } from "stores/auth"
import { MainLayout } from "layout/MainLayout";
import { Modale } from 'components/modales'
import { QueryClientProvider } from '@tanstack/react-query'

import { PublicRoutes, useAppRoutes, ProtectedRoutes, AdminRoutes } from "./useAppRoutes";
import { queryClient } from "api/queryConfig";
import { useBreakpointContext } from "context/BreakpointContext";
//import { useSocket } from "@hooks/websocket/useSocket";
import { InitCalls } from "./initCalls";
import { useGlobalWebSocket } from "@hooks/websocket/useGlobalSocket";


type CurrentRouteProps = {
    currentRoutes: RouteObject[];
};

const CurrentRoute = ({ currentRoutes }: CurrentRouteProps) => {

    const route = useRoutes(currentRoutes);

    return (
        <div className="bg-white w-full overflow-auto h-full relative">{route}</div>
    );
};

const Routes = () => {

    const { config } = useAppRoutes();
    const isLogged = useAuthStore(state => state.isLogged)
    // const { connect } = useSocket();
    // if (isLogged) { connect() }
    useGlobalWebSocket();

    const shouldDisplayTopBar = !useMatchRoutes([
        PublicRoutes.login,
        PublicRoutes.register,
        ProtectedRoutes.verifyEmail,
        ProtectedRoutes.completeProfile
    ])
    const shouldDisplayAdminBar = useMatchRoutes([
        AdminRoutes.admin,
        AdminRoutes.adminUsers,
        AdminRoutes.adminReports,
        AdminRoutes.adminSettings,
        AdminRoutes.adminTheme,
        AdminRoutes.adminChannels
    ])

    const shouldDisplayBottomBar = useMatchRoutes([
        ProtectedRoutes.home,
        ProtectedRoutes.community,
        ProtectedRoutes.saved,
        ProtectedRoutes.profile,
        ProtectedRoutes.messages,
        ...Object.values(AdminRoutes)
    ])

    const { isMobile } = useBreakpointContext()

    return (
        <MainLayout shouldDisplayBottomBar={shouldDisplayBottomBar} isMobile={isMobile}>
            {shouldDisplayAdminBar && isLogged && <AdminListener />}
            <InitCalls />
            <Modale />
            {shouldDisplayTopBar && <TopBar />}
            <div className="relative w-full flex flex-1 h-webkit overflow-hidden safeAreaBottom">
                <CurrentRoute currentRoutes={config} />
            </div>
            {shouldDisplayBottomBar && isMobile && <BottomBar />}
        </MainLayout>
    )
}

export const Router = () => (
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
                <Routes />
        </QueryClientProvider>
    </BrowserRouter>
)