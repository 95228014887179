import type { ReactNode } from "react";

type MainLayoutProps = {
    children: ReactNode;
    shouldDisplayBottomBar: boolean;
    isMobile: boolean;
}

export const MainLayout = ({ children, shouldDisplayBottomBar,isMobile } : MainLayoutProps) => {
    return (
        <div vaul-drawer-wrapper="" className={`w-full h-[100svh] flex flex-col overflow-hidden relative ${shouldDisplayBottomBar && isMobile ? 'pb-[60px]' : ''}`}>
            {children}
        </div>
    )
}