export const LandingSkeleton = () => {
    return (
        <div className="bg-app h-full w-full overflow-hidden">
            <div className="h-full w-full bg-app flex flex-col gap-3 items-center lg:p-intermediate overflow-auto">
                <div className="lg:w-[1080px] w-full flex gap-6 relative animate-pulse">
                    <div className="lg:w-8/12 w-full flex flex-col gap-5">
                        <div className="w-full h-[350px] rounded-xl bg-gray-100 flex items-end justify-start">
                            <div className="flex gap-1 z-20 p-intermediate text-white">
                                <div className="h-12 bg-gray-200 w-3/4 rounded"></div>
                            </div>
                        </div>
                        <div className="p-intermediate lg:p-0 w-full flex flex-col gap-5">
                            <div className="flex gap-2 items-center">
                                <div className="w-9 h-9 rounded-full bg-gray-200" />
                                <div className="w-32 h-6 bg-gray-200 rounded"></div>
                            </div>
                            <div className="h-6 bg-gray-200 rounded w-full"></div>
                            <div className="w-full flex items-center justify-between gap-4">
                                <div className="w-full bg-gray-100 rounded-lg p-small">
                                    <div className="flex justify-start items-center gap-3">
                                        <div className="w-16 h-6 bg-gray-200 rounded"></div>
                                        <div className="flex">
                                            <div className="w-5 h-5 rounded-full bg-gray-100" />
                                            <div className="ml-[-5px] w-5 h-5 rounded-full bg-gray-200" />
                                            <div className="ml-[-5px] w-5 h-5 rounded-full bg-gray-200" />
                                        </div>
                                    </div>
                                    <div className="w-full h-6 bg-gray-200 rounded mt-2"></div>
                                </div>
                                <div className="w-full bg-gray-100 rounded-lg p-small">
                                    <div className="flex justify-start items-center gap-3">
                                        <div className="w-16 h-6 bg-gray-200 rounded"></div>
                                        <div className="bg-gray-200 w-3 h-3 rounded-full" />
                                    </div>
                                    <div className="w-full h-6 bg-gray-200 rounded mt-2"></div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 grid-rows-2 gap-4 h-[300px]">
                                <div className="row-span-2 col-span-1 rounded-lg bg-gray-200" />
                                <div className="col-span-1 rounded-lg bg-gray-200" />
                                <div className="col-span-1 rounded-lg bg-gray-200" />
                            </div>
                            <div className="h-6 bg-gray-200 rounded w-3/4 mt-2"></div>
                            <div className="w-full flex flex-col gap-2">
                                <div className="w-full bg-gray-100 p-small rounded-mini flex gap-3 items-center">
                                    <div className="w-5 h-5 rounded-full bg-gray-200" />
                                    <div className="w-full h-6 bg-gray-200 rounded"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hidden w-4/12 bg-white sticky top-[0px] p-small h-min rounded-lg lg:flex flex-col gap-3 border">
                        <div className="w-full h-6 bg-gray-200 rounded"></div>
                        <div className="w-full h-6 bg-gray-200 rounded"></div>
                        <div className="w-full h-6 bg-gray-200 rounded"></div>
                        <hr />
                        <div className="w-full h-12 bg-gray-200 rounded"></div>
                        <hr />
                        <div className="flex gap-2 items-center">
                            <div className="bg-gray-200 w-5 h-5 rounded-full"></div>
                            <div className="w-3/4 h-6 bg-gray-200 rounded"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}