import { CommunitiesResponse } from "@hooks/queries/communities";
import { CommunityCards, CommunityCardsSkeleton } from ".";

export const CommunitiesList = ({ 
    communities, 
    isLoading, 
    isError, 
    onWheel,
    error
} : { 
    communities: CommunitiesResponse, 
    isLoading: boolean, 
    isError: boolean,
    error: any,
    onWheel?: (boolean:boolean) => void 
}) => {

    const handleBlockTabChange = (e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (onWheel) {
            onWheel(true);
        }
    }

    return (
        <>
            {isLoading && (
                <div className="relative w-full">
                    <div className="w-10 h-full top-0 right-[-2px] absolute bg-gradient-to-r from-white/0 to-white/100" />
                    <div className="w-full flex items-start justify-start gap-3 overflow-hidden">
                        {Array.from({ length: 10 }).map((_, index) => (
                            <CommunityCardsSkeleton key={index} />
                        ))}
                    </div>
                </div>
            )}

            {isError && (
                // @ts-ignore
                <div>{error ? error.error : "Une erreur est survenue"}</div>
            )}

            {!isLoading && communities.list.length === 0 && (
                <div className="text-center">Aucune communauté disponible</div>
            )}

            {
                !isLoading && communities.list.length > 0 && (
                    <div className="relative w-full" onDrag={handleBlockTabChange} >
                        <div className="w-10 h-full top-0 right-[-2px] absolute bg-gradient-to-r from-white/0 to-white/100" />
                        <div className="w-full flex items-start justify-start gap-3 overflow-auto hideScrollBar pr-12" onTouchStart={handleBlockTabChange} onScroll={handleBlockTabChange} >
                            {communities.list.map((community, index) => (
                                <CommunityCards key={index} community={community} />
                            ))}
                        </div>
                    </div>
                )
            }
        </>
    );
};
