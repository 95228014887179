import { useModaleStore } from "stores/modale";
import * as Modales from './allModales';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from "react";
import { useBreakpointContext } from "context/BreakpointContext";
import { Drawer } from 'vaul';

const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
    exit: { opacity: 0, transition: { duration: 0.2 } }
};

const modaleDesktop = {
    hidden: { scale: 0.9, opacity: 0 },
    visible: {
        scale: 1, opacity: 1,
        transition: { ease: "easeOut", duration: 0.2 }
    },
    exit: { scale: 0.9, opacity: 0, transition: { duration: 0.2 } }
};

const modaleMobile = {
    hidden: { y: '100%', opacity: 0 },
    visible: {
        y: 0, opacity: 1,
        transition: { duration: 0.3, type: "easeOut", stiffness: 290, damping: 25 }
    },
    exit: { y: '100%', opacity: 0, transition: { duration: 0.2 } }
};

const drawer = {
    hidden: { x: '100%' },
    visible: {
        x: 0,
        transition: { type: "easeOut", stiffness: 300, damping: 30 }
    },
    exit: { x: '100%', transition: { duration: 0.2 } }
};

export const Modale = () => {
    const [snap, setSnap] = useState<number | string | null>(0.4);
    const { modaleId, setModale, modaleType, modaleOpen, modaleProps } = useModaleStore();
    const [isClosing, setIsClosing] = useState(false);
    const { isMobile } = useBreakpointContext();

    const ModaleContent: React.ComponentType<any> = Modales[modaleId as keyof typeof Modales];

    const isDrawer = modaleType === 'drawer';
    const isSnapped = modaleType === 'snapped';
    const isFullHeight = modaleType === 'modaleFullHeight';

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'visible';
        };
    }, []);

    const handleCloseVaul = () => {
        setIsClosing(true);
        setTimeout(() => {
            setModale(false, '', '', modaleProps);
            setIsClosing(false);
        }, 300);
    };

    const handleClose = () => {
        setModale(false, '', '', modaleProps);
    };

    return (
        <AnimatePresence>
            <Drawer.Root key="drawer-mobile" direction="right" open={modaleOpen && isMobile && isDrawer} onClose={handleClose}>
                <Drawer.Portal>
                    <Drawer.Overlay className="fixed inset-0 bg-black/40 z-30" />
                    <Drawer.Content className="bg-white flex flex-col rounded-t-[10px] h-full w-[95%] mt-24 fixed bottom-0 right-0 z-30">
                        <Drawer.Title className=""></Drawer.Title>
                        <Drawer.Content className=""></Drawer.Content>
                        <Drawer.Description className=""></Drawer.Description>
                        <div className="p-4 bg-white flex-1 h-full">
                            {ModaleContent && <ModaleContent />}
                        </div>
                    </Drawer.Content>
                </Drawer.Portal>
            </Drawer.Root>
            <Drawer.Root
                snapPoints={[0.4, 1]}
                key="snapped-mobile"
                fadeFromIndex={0}
                activeSnapPoint={snap}
                setActiveSnapPoint={setSnap}
                open={modaleOpen && isMobile && isSnapped}
                //onClose={() => { setModale(false, '', '', modaleProps) }}
                onOpenChange={(open) => {
                    if (!open) handleCloseVaul();
                }}

            >
                <Drawer.Overlay className="fixed inset-0 bg-black/40 z-30" />
                <Drawer.Portal>
                    <Drawer.Content className="fixed flex flex-col bg-white border border-gray-200 border-b-none rounded-t-[10px] bottom-0 left-0 right-0 h-full max-h-[97%] mx-[-1px] z-30">
                        <Drawer.Title className=""></Drawer.Title>
                        <Drawer.Content className=""></Drawer.Content>
                        <Drawer.Description className=""></Drawer.Description>
                        <div className="p-4 bg-white rounded-t-[10px] flex flex-col flex-grow">
                            <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-zinc-300 mb-8" />
                            {ModaleContent && <ModaleContent isClosing={isClosing} />}
                        </div>
                    </Drawer.Content>
                </Drawer.Portal>
            </Drawer.Root>
            <Drawer.Root open={modaleOpen && isMobile && !isSnapped && !isDrawer} onClose={handleClose} key="modale-mobile">
                <Drawer.Portal>
                    <Drawer.Overlay className="fixed inset-0 bg-black/40 z-20" />
                    <Drawer.Content className="z-[100] bg-zinc-100 flex flex-col rounded-t-[10px] h-[96%] mt-24 fixed bottom-0 left-0 right-0">
                        <Drawer.Title className=""></Drawer.Title>
                        <Drawer.Content className=""></Drawer.Content>
                        <Drawer.Description className=""></Drawer.Description>
                        <div className="p-4 bg-white rounded-t-[10px] flex-1">
                            <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-zinc-300 mb-8" />
                            {ModaleContent && <ModaleContent />}
                        </div>
                    </Drawer.Content>
                </Drawer.Portal>
            </Drawer.Root>
            <Drawer.Root direction="right" open={modaleOpen && !isMobile && isDrawer} onClose={handleClose} key="drawer-desktop">
                <Drawer.Portal>
                    <Drawer.Overlay className="fixed inset-0 bg-black/40 z-50 cursor-pointer" onClick={handleClose} />
                    <Drawer.Content className="bg-white flex flex-col rounded-t-[10px] h-full w-[600px] mt-24 fixed bottom-0 right-0 z-[80]">
                        <Drawer.Title className=""></Drawer.Title>
                        <Drawer.Content className=""></Drawer.Content>
                        <Drawer.Description className=""></Drawer.Description>
                        <div className="p-4 bg-white flex-1 h-full">
                            {ModaleContent && <ModaleContent />}
                        </div>
                    </Drawer.Content>
                </Drawer.Portal>
            </Drawer.Root>

            {
                modaleOpen && !isMobile && !isDrawer && (
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={backdrop}
                        key={`backdrop`}
                        className={`fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-[60] cursor-pointer ${isDrawer ? '' : 'flex md:items-center justify-center items-end pt-10'}`}
                        onClick={() => setModale(false, '', '', modaleProps)}
                    >
                        <motion.div
                            variants={isDrawer ? drawer : (!isMobile ? modaleDesktop : modaleMobile)}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            key={`modale`}
                            onClick={(e) => e.stopPropagation()}
                            className={`${isDrawer ? 'fixed right-0 top-0 h-full w-10/12 md:w-6/12 bg-white' : 'relative p-4 md:rounded-lg bg-white shadow-lg w-webkit md:w-auto rounded-t-lg'} ${isFullHeight ? 'h-full md:h-auto' : ''}`}
                        >
                            {ModaleContent && <ModaleContent />}
                        </motion.div>
                    </motion.div>
                )}
        </AnimatePresence>
    );
};
