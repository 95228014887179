import React from 'react';
import { useGetTopsCategories } from "@hooks/queries/categories";
import { CategorieItem, CategorieSkeleton } from "./CategorieItem";
import { AxiosError } from 'axios';
import { CategoryItemType } from 'interfaces/categories';


export const CategoriesList: React.FC = () => {
    const {
        data: categories = [],
        isLoading,
        isError,
        error
    } = useGetTopsCategories({ refetchOnMount: false });

    if (isLoading) {
        return (
            <div className="w-full grid grid-cols-3 gap-2">
                {Array.from({ length: 6 }).map((_, index) => (
                    <CategorieSkeleton key={index} />
                ))}
            </div>
        );
    }

    if (isError) {
        const errorMessage = error instanceof AxiosError
            ? error.response?.data?.error || 'Une erreur est survenue'
            : 'Une erreur est survenue';

        return (
            <div className="w-full text-center text-red-500">
                <p>Erreur : {errorMessage}</p>
            </div>
        );
    }

    return (
        <div className="w-full grid grid-cols-3 gap-2">
            {categories.map((category: CategoryItemType) => (
                <CategorieItem key={category.id} category={category} />
            ))}
        </div>
    );
};