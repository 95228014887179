import { useAllChannels } from "@hooks/queries/channels";
import { Icon } from "assets/icons";
import { useNavigate } from "react-router-dom";
import { useModaleStore } from "stores/modale";

export const ChannelsModale = () => {
    const { setModale, modaleProps } = useModaleStore();
    const communityId = modaleProps?.communityId;

    const navigate = useNavigate();

    const { data: allChannels = [], isLoading, isError, error } = useAllChannels({ communityId: parseInt(communityId), refetchOnMount: false, withNotifications: true, enabled: modaleProps?.communityId ? true : false });
    const skeletonArray = Array.from({ length: 8 }, (_, i) => i);

    const handleCloseAndRedirect = ({ id }: { id: number }) => {
        setModale(false, '', '', {})
        navigate(`/${communityId}/${id}`)
    }

    if (isError) {
        return (
            <div className="flex flex-col items-center justify-center w-full p-4">
                <svg className="w-12 h-12 text-red-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <p className="text-lg font-medium text-red-600">Une erreur s'est produite</p>
                <p className="text-sm text-gray-500 mt-2">{error instanceof Error ? error.message : "Impossible de charger les canaux"}</p>
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-3 items-center w-full">
            {isLoading && skeletonArray.map((_, index) => (
                <div className="w-full flex gap-2 items-center justify-start bg-grey-200 p-tiny rounded-tiny cursor-pointer h-[36px]" key={index} />
            ))}

            {!isLoading && allChannels.map((channel) => (
                <div onClick={() => channel.id && handleCloseAndRedirect({ id: channel.id })} className="w-full flex gap-2 items-center justify-between hover:bg-grey-200 p-tiny rounded-tiny cursor-pointer" key={channel.id}>
                    <div className="flex gap-2 items-center justify-start">
                        <Icon type={channel.can_users_publish ? "Hashtag" : "Lock1"} color="#000" size={16} />
                        <p className="text-sm font-medium">{channel.name}</p>
                    </div>
                    {channel?.new_posts_count && parseInt(channel.new_posts_count) > 0 && (
                        <div className="flex gap-1 items-center justify-start">
                            <div className="w-2 h-2 rounded-full bg-red-600" />
                            <p className="text-sm font-medium">{channel?.new_posts_count}</p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}