import { useTopbarMobileData } from "stores/topbarMobileData";
import { useEffect } from "react";
import { AnimatedButton } from "components/globals/buttons";
import { Icon } from "assets/icons";
import { useBreakpointContext } from "context/BreakpointContext";
import { useAllConversation } from "@hooks/queries/messages";
import { MessageLayout } from "layout/MessageLayout";
import { ConversationItem } from "components/conversation/conversationItem";
import { useModaleStore } from "stores/modale";
import { useAuthStore } from "stores/auth";

export const MessagesPages = () => {

    const { setTopbarMobileData } = useTopbarMobileData();
    const { isMobile } = useBreakpointContext();
    const { setModale } = useModaleStore();
    const { isLogged } = useAuthStore();

    useEffect(() => {
        setTopbarMobileData({ pageName: 'Messages', showTopbar: true, communityName: 'Communautés', withLogo: true });
    }, []);

    const { data, isLoading, isError } = useAllConversation({ refetchOnMount: false, enabled: isLogged });

    const handleNewConversation = () => {
        setModale(true, 'NewUserMessageModale', 'modale', {});
    }

    return (
        <MessageLayout>
            {
                !isMobile ? (
                    <div className="h-full w-full flex flex-col gap-3 items-center px-intermediate py-intermediate bg-app">

                    </div>
                ) : (
                    <div className="h-full w-full flex flex-col gap-3 items-center px-intermediate py-intermediate bg-app">
                        <div className="w-full lg:w-[1080px] flex flex-col items-center justify-center gap-6">
                            <div className="w-full flex justify-between items-start">
                                <div className="flex flex-col gap-1">
                                    <p className="text-lg">Messages privées</p>
                                </div>
                                <AnimatedButton className="bg-black py-tiny px-intermediate rounded-tiny text-white flex items-center justify-center gap-2" onClick={handleNewConversation}>
                                    <Icon type="Add" size={18} color={"white"} />
                                    {!isMobile && <span>Nouveau message</span>}
                                </AnimatedButton>
                            </div>
                            <div className="w-full flex flex-col items-center justify-center gap-4 bg-app">
                                {
                                    !isLoading && !isError && data?.map((conversation, index) => <ConversationItem conversation={conversation} key={index} conversationId={null} />)
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </MessageLayout>
    )
}