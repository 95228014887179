import React from 'react';
import { useThemeCommunity } from "stores/themeCommunity";
import { Icon } from "assets/icons"
import { useGetAllRewards } from "@hooks/queries/gamification";
import { useParams } from "react-router-dom";

const SkeletonItem = () => (
    <div className="w-full min-w-[150px] md:min-w-auto border bg-gray-100 rounded-lg flex flex-col items-center gap-3 justify-center p-4 animate-pulse">
        <div className="w-10 h-10 bg-gray-200 rounded-full"></div>
        <div className="w-20 h-4 bg-gray-200 rounded"></div>
        <div className="w-16 h-3 bg-gray-200 rounded"></div>
    </div>
);

const ErrorState = () => (
    <div className="w-full flex flex-col items-center justify-center gap-4 py-8">
        <svg className="w-16 h-16 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p className="text-gray-500 text-center">Une erreur est survenue lors du chargement des récompenses.</p>
    </div>
);

export const CommunityLeaderboardGains: React.FC = () => {
    const { theme } = useThemeCommunity();
    const { communityId } = useParams<{ communityId: string }>();
    const { data, isLoading: isRewardsLoading, isError: isRewardsError } = useGetAllRewards({ communityId, refetchOnMount: false });

    if (isRewardsLoading) {
        return (
            <div className="w-full flex items-center gap-3 overflow-hidden">
                <div className="w-max md:w-full flex items-stretch justify-start md:justify-between gap-3 overflow-x-scroll">
                    {[...Array(3)].map((_, index) => (
                        <SkeletonItem key={index} />
                    ))}
                </div>
            </div>
        );
    }

    if (isRewardsError) {
        return <ErrorState />;
    }

    if (!isRewardsLoading && !isRewardsError && data?.length === 0) return null;

    return (
        <>
            <div className="flex w-webkit flex-col items-start justify-start gap-4">
                <div className="flex w-full flex-col items-start justify-start gap-2">
                    <h2 className="w-full text-xl font-[500]">Les gains</h2>
                    <p className="w-full text-sm font-light">Voici ce que @Frenchstartupper fait gagner</p>
                </div>
                <div className="w-full flex items-center gap-3 overflow-hidden">
                    <div className="w-max md:w-full flex items-stretch justify-start md:justify-between gap-3 overflow-x-scroll">
                        {data && data.length > 0 && data.map((gain, index) => (
                            <div
                                key={index}
                                className="w-full min-w-[150px] md:min-w-auto border bg-gray-100 rounded-lg flex flex-col items-center gap-3 justify-center p-4"
                                style={{
                                    backgroundColor: gain.alreadyWon ? theme.primaryColor : "#fff",
                                    borderColor: theme.primaryColor,
                                    color: gain.alreadyWon ? "#fff" : "#000"
                                }}
                            >
                                <div className="flex items-center justify-center gap-2">
                                    {Array.from({ length: 1 }).map((_, index) => (
                                        <Icon type="Star1" size={30} color={gain.alreadyWon ? "#fff" : "#000"} key={index} />
                                    ))}
                                </div>
                                <p className="text-sm font-[500] text-center">{gain.name}</p>
                                {gain.alreadyWon ? (
                                    <p className="text-gray-100 font-[300] text-xs">Débloqué</p>
                                ) : (
                                    <div className="flex items-center justify-center gap-2">
                                        <Icon type="Lock1" size={20} color="black" />
                                        <p className={`text-gray-500 font-[300] text-xs`}>{gain.need_for_unlock.referral} filleuls</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <hr className="w-full" />
        </>
    );
};