import React, { useEffect } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { PostSkeleton } from "skeleton/community/postSkeleton";
import { MessagesFeed } from '.';
import { PostFeed } from '@hooks/queries/communities';
import { useInfiniteFetcher } from '@hooks/queries/api';

const POSTS_PER_PAGE = 10;

export const VirtualizedInfinitePostsList: React.FC<{ 
    isFullWidth?: boolean,
    parentRef: React.RefObject<HTMLDivElement>
}> = ({ isFullWidth = false, parentRef }) => {

    const {
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        isError,
        allItems: allPosts
    } = useInfiniteFetcher<PostFeed, any>({ key: ['posts'], path: 'feed', limit: POSTS_PER_PAGE });

    const rowVirtualizer = useVirtualizer({
        count: hasNextPage ? allPosts.length + 1 : allPosts.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 250,
        overscan: 5,
    });

    useEffect(() => {
        const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

        if (!lastItem) {
            return;
        }

        if (
            lastItem.index >= allPosts.length - 1 &&
            hasNextPage &&
            !isFetchingNextPage
        ) {
            fetchNextPage();
        }
    }, [
        hasNextPage,
        fetchNextPage,
        allPosts.length,
        isFetchingNextPage,
        rowVirtualizer.getVirtualItems(),
    ]);

    if (isLoading) {
        return (
            <div className={`w-full flex flex-col items-center justify-center`}>
                {[...Array(5)].map((_, index) => (
                    <PostSkeleton key={index} />
                ))}
            </div>
        );
    }

    if (isError) {
        return <div>Une erreur est survenue</div>;
    }

    return (
        <div
            style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
            }}
        >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const post = allPosts[virtualRow.index];
                const isLoaderRow = virtualRow.index > allPosts.length - 1;
                return (
                    <div
                        key={virtualRow.index}
                        data-index={virtualRow.index}
                        ref={rowVirtualizer.measureElement}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            transform: `translateY(${virtualRow.start}px)`,
                        }}
                    >
                        {isLoaderRow ? (
                            <PostSkeleton />
                        ) : (
                            <MessagesFeed
                                post={post}
                                isFullWidth={isFullWidth}
                                communityId={post.community_id}
                                channelId={post.channel_id}
                                isPreview={true}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};