export const employeeRole = [
    {
        name: "administrator",
        id: 2,
        priority: 2
    },
    {
        name: "manager",
        id: 3,
        priority: 3
    },
    {
        name: "moderator",
        id: 4,
        priority: 4
    },
]