
//import { Icon } from "assets/icons";
import { useSidebarMenu } from "navigation/useSidebarMenu";
import { useNavigate } from "react-router-dom";
import { type SidebarProps } from "../sidebar/channels/";
import { useThemeCommunity } from "stores/themeCommunity"
import { AnimatedButton } from "../buttons";
import { useModaleStore } from "stores/modale";

export const MobileCommunityTopBar = ({ communityId } : SidebarProps) => {

    const navigationMenuPages = useSidebarMenu();
    const { theme } = useThemeCommunity();
    const currentPath = window.location.pathname.split('/').slice(2).join('/');
    const navigate = useNavigate();
    const { setModale } = useModaleStore();

    const handleOpenTab = ({ path, isComingSoon } : { path: string, isComingSoon: boolean }) => {
        if(isComingSoon){
            setModale(true, 'ComingSoonModale', 'modale');
        }else{
            // @ts-ignore
            navigate(path.replace(':communityId', communityId ));
        }
    }

    if (!communityId) return null;

    return (
        <div className="w-full h-max bg-app py-2">
            <div className="w-full overflow-auto flex gap-3 items-center px-2 relative z-20">
                {
                    navigationMenuPages.map(({ key, path, label, isComingSoon }) => {
                        return (
                            <AnimatedButton key={key} className="w-max" onClick={() => handleOpenTab({ path, isComingSoon })}>
                                <div className={`w-full flex gap-2 items-center justify-start px-small py-tiny rounded-tiny w-max border rounded-md ${isComingSoon ? "text-gray-300" : "text-black"}`} style={{ backgroundColor: path.replace(':communityId', communityId) === currentPath ? theme.quaternaryColor : "#fff" }} >
                                    <p className="text-base font-light">{label}</p>
                                </div>
                            </AnimatedButton>
                        )
                    })
                }
            </div>
        </div>
    )
}
