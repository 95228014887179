import { create } from 'zustand'

export interface ModaleStore {
    modaleOpen: boolean;
    modaleId: string;
    modaleType: string;
    modaleProps: any;
    setModale: (isOpen: boolean, id: string, type: string, props?: any) => void;
}

export const useModaleStore = create<ModaleStore>(set => ({
    modaleOpen: false,
    modaleType : '',
    modaleId: '',
    modaleProps: {},
    setModale: (isOpen: boolean, id: string, type: string, props: any) => {
        set({ modaleOpen: isOpen, modaleId: id, modaleType: type, modaleProps: props});
    },
}));
