import { useParams } from "react-router-dom"
import { AboutCommunityPage } from "components/community/about/aboutPage"
import { useEffect } from "react";
import { useTopbarMobileData } from "stores/topbarMobileData";
import { useGetAllAdminCommunityReviews, useGetAllCommunityReviews } from "@hooks/queries/reviews";

export const AboutPage = () => {

    const { communityId } = useParams();
    const { setTopbarMobileData } = useTopbarMobileData();

    useEffect(() => {
        setTopbarMobileData({ pageName: `À propos`, communityId, showTopbar: true, withGoBack: true, withGoHome: false, withLogo: false });
    }, [communityId]);

    useGetAllCommunityReviews({ communityId: communityId || "", refetchOnMount: false, enabled: true })
    useGetAllAdminCommunityReviews({ communityId: communityId || "", refetchOnMount: false, enabled: true })

    if (!communityId) return null
    return (
        <div className="h-full w-full overflow-hidden relative">
            <AboutCommunityPage isAdmin={false} communityId={communityId} />
        </div>
    )
}