import { useFetcher } from "./api"

type CommunityRequestTypes = {
    refetchOnMount?: boolean;
    communityId?: string;
    enabled?: boolean;
};

type CommunityWithContentId = {
    refetchOnMount?: boolean;
    communityId?: string;
    enabled?: boolean;
    contentId: string;
}

export interface ContentInterface {
    id: number;
    community_id: number;
    content: any;
    content_type: number;
    created_at: Date | string;
    description: string;
    status: "draft" | "published" | "deleted";
    title: string;
    updated_at: Date | string;
    visibility: "public" | "private";
}

interface ContentResponse {
    list: ContentInterface[];
}

export const useAllContent = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return useFetcher<any, ContentResponse>({ key: `community-${communityId}-contents`, path: `/community/${communityId}/content/all`, refetchOnMount })
}

export const useAllAdminContent = ({ communityId, refetchOnMount = true }: CommunityRequestTypes) => {
    return useFetcher<any, ContentResponse>({ key: `community-${communityId}-admin-contents`, path: `/community/${communityId}/admin/content/all`, refetchOnMount })
}

export const useContentById = ({ communityId, contentId, refetchOnMount = true }: CommunityWithContentId) => {
    return useFetcher<any, ContentInterface>({ key: `community-${communityId}-content-${contentId}`, path: `/community/${communityId}/content/${contentId}`, refetchOnMount })
}