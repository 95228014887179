import { usePublicFetcher } from './api'

export type UserProfileRequestTypes = {
    refetchOnMount?: boolean;
    userId: string;
    enabled?: boolean;
}

export const useGetUserProfile = ({ userId, refetchOnMount = true, enabled = true }: UserProfileRequestTypes) => {
    return usePublicFetcher<any, any>({ key: `profile-${userId}`, path: `/user/${userId}/profile`, refetchOnMount, enabled })
}

export const useGetUserFollwerCount = ({ userId, refetchOnMount = true }: UserProfileRequestTypes) => {
    return usePublicFetcher<any, any>({ key: `profile-follower-count-${userId}`, path: `/user/${userId}/profile/followers`, refetchOnMount })
}

export const useGetUserFollwerSimilar = ({ userId, refetchOnMount = true }: UserProfileRequestTypes) => {
    return usePublicFetcher<any, any>({ key: `profile-follower-similar-${userId}`, path: `/user/${userId}/profile/followers/similar`, refetchOnMount })
}

export const useGetUserCommunityCount = ({ userId, refetchOnMount = true }: UserProfileRequestTypes) => {
    return usePublicFetcher<any, any>({ key: `profile-community-count-${userId}`, path: `/user/${userId}/profile/community/count`, refetchOnMount })
}

export const useGetUserFollowingStatus = ({ userId, refetchOnMount = true }: UserProfileRequestTypes) => {
    return usePublicFetcher<any, any>({ key: `profile-following-status-${userId}`, path: `/user/${userId}/profile/following/status`, refetchOnMount })
}

export const useGetUserCommunities = ({ userId, refetchOnMount = true }: UserProfileRequestTypes) => {
    return usePublicFetcher<any, any>({ key: `profile-communities-${userId}`, path: `/user/${userId}/profile/communities`, refetchOnMount })
}

export const useGetUserPosts = ({ userId, refetchOnMount = true }: UserProfileRequestTypes) => {
    return usePublicFetcher<any, any>({ key: `profile-posts-${userId}`, path: `/user/${userId}/profile/posts`, refetchOnMount })
}
