// chooseSexeInput.tsx
import { useState } from "react";
import { motion } from "framer-motion";
import { Icon } from "assets/icons";

type ChooseSexeInputProps = {
    sexe: string;
    setSexe: (sexe: string) => void;
};

const sexeOptions = [
    { label: "Homme", value: "H" },
    { label: "Femme", value: "F" },
    { label: "Non-genré", value: "N" }
];

export const ChooseSexeInput = ({ sexe, setSexe } : ChooseSexeInputProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const selectSexe = (sexe: string) => {
        setSexe(sexe);
        setIsOpen(false);
    };

    const getSexeLabel = (value: string) => {
        const option = sexeOptions.find(option => option.value === value);
        return option ? option.label : "Sélectionner";
    };

    return (
        <div className="relative w-full">
            <div className="w-full border border-grey-100 py-small rounded-tiny px-small flex justify-between items-center cursor-pointer bg-white" onClick={toggleDropdown}>
                <span>{getSexeLabel(sexe)}</span>
                <Icon type="ArrowDown2" size={16} />
            </div>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                    transition={{ duration: 0.2 }}
                    className="absolute top-full mt-2 w-full bg-white border border-grey-100 rounded-tiny shadow-lg z-10"
                >
                    {sexeOptions.map(option => (
                        <div
                            key={option.value}
                            className="py-small px-small hover:bg-grey-100 cursor-pointer"
                            onClick={() => selectSexe(option.value)}
                        >
                            {option.label}
                        </div>
                    ))}
                </motion.div>
            )}
        </div>
    );
};
