import React from 'react';
import { useGetLeaderBoard } from "@hooks/queries/leaderboard";
import { useParams } from "react-router-dom";
import { useThemeCommunity } from "stores/themeCommunity";

const TopThreeSkeleton: React.FC = () => (
    <div className="w-full flex items-end justify-center justify-between md:justify-center md:gap-12 px-4 md:px-0 animate-pulse">
        {[...Array(3)].map((_, index) => (
            <div key={index} className="w-full flex flex-col items-center justify-center">
                <div className={`bg-gray-100 rounded-full mb-4 ${index === 1 ? 'w-24 h-24' : 'w-20 h-20'}`}></div>
                <div className={`bg-gray-100 h-4 w-20 mb-2`}></div>
                <div className="bg-gray-100 h-4 w-16"></div>
            </div>
        ))}
    </div>
)

const LeaderboardSkeleton: React.FC = () => (
    <div className="animate-pulse w-full">
        <TopThreeSkeleton />
        <div className="w-full mt-8">
            {[...Array(5)].map((_, index) => (
                <div key={index} className="w-full bg-gray-100 h-12 w-full mb-2 rounded"></div>
            ))}
        </div>
    </div>
);

export const CommunityLeaderboardPodium = () => {

    const { communityId } = useParams<{ communityId: string }>();
    const { data, isLoading } = useGetLeaderBoard({ communityId, refetchOnMount: false });
    const { theme } = useThemeCommunity();

    if (isLoading) {
        return <TopThreeSkeleton />;
    }

    if (!data || data.length === 0) {
        return <div className="text-center py-8">No leaderboard data available.</div>;
    }

    const topThree = data.slice(0, 3);

    return (
        <div className="w-full flex items-end justify-center justify-between md:justify-center md:gap-12 px-4 md:px-0">
            {topThree.map((item, index) => (
                <div key={item.uuid} className="flex flex-col items-center justify-center">
                    <div className="w-full py-4 rounded-lg flex flex-col items-center justify-center" style={{ height: index === 1 ? '200px' : '150px' }}>
                        <div className="w-full flex flex-col items-center justify-center gap-5">
                            <div className={`shadow bg-white ${index === 1 ? 'w-24 h-24' : 'w-20 h-20'} rounded-full bg-cover bg-center border border-4 relative`} style={{ borderColor: theme.primaryColor }}>
                                <img src={`https://pub-388e882330ee44c7b74215d8e0935cb9.r2.dev/user%2F${item.uuid}`} alt="" className="w-full h-full object-cover rounded-full" />
                                <div className={`absolute bottom-[-16px] rounded-full ${index === 1 ? 'w-9 h-9' : 'w-8 h-8'} flex items-center justify-center right-[50%] translate-x-1/2`} style={{ backgroundColor: theme.primaryColor }}>
                                    <span className="text-white font-bold">{index + 1}</span>
                                </div>
                            </div>
                            <p className="font-[500]">@{item.user_name}</p>
                        </div>
                        <p className="text-sm font-[400] text-gray-600">{item.points} pts</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export const CommunityLeaderboardTops: React.FC = () => {

    const { communityId } = useParams<{ communityId: string }>();
    const { data, isLoading } = useGetLeaderBoard({ communityId, refetchOnMount: false });

    if (isLoading) {
        return <LeaderboardSkeleton />;
    }

    if (!data || data.length === 0) {
        return <div className="text-center py-8">No leaderboard data available.</div>;
    }

    const restOfTop = data.slice(3, 10);

    return (
        <div className="w-full flex flex-col items-start justify-start gap-4">
            <CommunityLeaderboardPodium />

            <div className="w-full flex flex-col items-start justify-start gap-2">
                {restOfTop.map((item) => (
                    <div className="border w-full py-2 px-3 rounded-lg flex items-center justify-between bg-white" key={item.uuid}>
                        <div className="flex items-center justify-start gap-2">
                            <p className="text-sm font-[300] text-gray-500">{item.position}</p>
                            <div className="w-10 h-10 bg-grey-200 rounded-full bg-cover bg-center" style={{ backgroundImage: `url(https://pub-388e882330ee44c7b74215d8e0935cb9.r2.dev/user%2F${item.uuid})` }} />
                            <div className="flex flex-col gap-0 items-start">
                                <p className="text-sm font-[500]">@{item.user_name}</p>
                            </div>
                        </div>
                        <div>
                            <p className="font-[400] text-gray-600">{item.points} pts</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};