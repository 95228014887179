import { useMutator } from "@hooks/queries/api";
import { Config } from "services/config";
import { AnimatedButton } from "components/globals/buttons";
import { useState } from "react";
import { useModaleStore } from "stores/modale";

export const LeaveCommunityModale = () => {

    const [confirmText, setConfirmText] = useState('');
    const { modaleProps, setModale } = useModaleStore();

    const { mutateAsync: leaveCommunity, isError, isPending } = useMutator<any>(`/community/${modaleProps?.community.id || 0}/leave`)

    const handleCreateChannel = async () => {
        await leaveCommunity({})
        await modaleProps?.refetch();
        setModale(false, "", '');
    }

    const handleClose = () => {
        setModale(false, "", '');
    }

    return (
        <div className="modal-container px-4 py-6 flex flex-col items-center space-y-8 md:w-[500px] cursor-auto">
            <div className="flex flex-col items-start justify-start w-full gap-5 font-light">
                <div className="flex flex-col items-start justify-start w-full gap-5 font-light">
                    <p className="text-black font-bold text-xl">Êtes-vous certain de vouloir quitter cette communauté ? </p>
                    <div className="flex items-center justify-start gap-3">
                        <img src={Config.CDN_HOST + "community%2F" + modaleProps?.community?.id + "%2Fbg"} alt={modaleProps?.community?.name} className="w-16 h-16 rounded-mimi object-fit-cover" />
                        <div>
                            <p className="text-black font-bold">{modaleProps?.community?.name}</p>
                            <span className="text-gray-600 italic font-light">Membre depuis : {modaleProps?.community?.created_at}</span>
                        </div>
                    </div>
                    <div className="w-full bg-yellow-100 rounded-tiny p-3">
                        <p>Cette action est irréversible, vous pourrez toujours rejoindre à nouveau la communauté mais avec les nouvelles conditions</p>
                    </div>
                    <div className="w-full flex flex-col items-center justify-center gap-2">
                        <p className="font-400">Afin de valider votre action, veuillez écrire "<span className="font-bold">confirmer</span>" dans le champs ci-dessous</p>
                        <input type="text" className="border h-10 w-full rounded-md px-3 " placeholder="confirmer..." onChange={(e) => setConfirmText(e.target.value)}/>
                    </div>
                    <div className="flex w-full items-center justify-start gap-3">
                        <AnimatedButton onClick={handleClose} className="w-full bg-grey-100 hover:bg-grey-200 rounded-md py-1.5 px-3 flex items-center justify-center cursor-pointer">Annuler</AnimatedButton>
                        <AnimatedButton disabled={confirmText !== "confirmer"} onClick={handleCreateChannel} className="w-full bg-red-300 hover:bg-red-400 rounded-md py-1.5 px-3 flex items-center justify-center cursor-pointer disabled:bg-gray-100 disabled:text-gray-600 disabled:cursor-not-allowed">Confirmer</AnimatedButton>
                    </div>
                    {
                        isError || isPending && (
                            <div>
                                {isError && <p className='text-red-500'>Une erreur est survenue</p>}
                                {isPending && <p>En cours...</p>}
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}