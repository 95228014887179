import { Icon } from "assets/icons"
import { useModaleStore } from "stores/modale"
import { AnimatedButton } from "components/globals/buttons";

export const SearchBox = () => {

    const { setModale } = useModaleStore();

    const openModale = () => {
        setModale(true, 'SearchModale', 'modale');
    };

    return (

        <AnimatedButton
            className=""
            onClick={openModale}
        >
            <Icon type="SearchNormal1" size={20} color="black" />

        </AnimatedButton>

    )
}