import { useMutator } from "@hooks/queries/api"
import { loginSelector } from "stores/auth";
import { useAuthStore } from "stores/auth";
import { useState } from "react"
import { useNavigate } from "react-router-dom";
import { ProtectedRoutes, Routes } from "navigation/useAppRoutes";
import { InputWithIcon } from "components/globals/inputs";
import { AnimatedButton } from "components/globals/buttons";
import { motion } from "framer-motion";
import { type profileType } from "./LoginForm"
import { useModaleStore } from "stores/modale";

export type RegisterBody = {
    email: string;
    pass: string;
}


export type RegisterFormProps = {
    context?: string,
    displayIn?: string
    community?: { communityId: string, communityName: string }
}

export const RegisterForm = ({ context, displayIn, community }: RegisterFormProps) => {

    context = context || "signin"
    displayIn = displayIn || "modale"

    //if(community?.communityId) console.log(community)

    const { mutateAsync: enterPassMutation, isError, error, isPending } = useMutator<RegisterBody>("/auth/register")
    const login = useAuthStore(loginSelector);
    const navigate = useNavigate();
    const { setModale } = useModaleStore();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [userName, setUserName] = useState('')

    const checkProfileAndNavigate = (profile: profileType) => {
        let route: string;

        if (!profile.isProfileComplete) {
            route = ProtectedRoutes.completeProfile;
        } else if (!profile.isEmailVerified) {
            route = ProtectedRoutes.verifyEmail;
        } else {
            route = ProtectedRoutes.home;
        }

        if (community?.communityId && route !== ProtectedRoutes.home) {
            route += `?communityName=${encodeURIComponent(community.communityName)}&communityId=${encodeURIComponent(community.communityId)}`;
        }

        if (community?.communityId && community.communityName && route === ProtectedRoutes.home) {
            route = Routes.communityHome.replace(':communityId', community.communityId);
        }

        return route;
    }

    const checkIfJoinCommunity = (profile: profileType) => {
        if (context === "signinAndJoinCommunity" && profile.isProfileComplete && profile.isEmailVerified) {
            //setModale(true, 'JoinCommunityForm', 'modale', { communityId: community?.communityId });
            console.log('Join community' + community?.communityId)
        }
    }

    const handleLogin = async (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        const body = { email, pass: password, user_name: userName }
        const tryRegister = await enterPassMutation(body)
        await login(email, tryRegister).catch(() => { console.log('Error saving tokens') });
        const nextRoute = checkProfileAndNavigate(tryRegister);
        checkIfJoinCommunity(tryRegister);
        if (displayIn === "page") navigate(nextRoute);
        if (displayIn === "modale") {
            setModale(false, "", "")
            navigate(nextRoute);
        }
    }

    return (
        <motion.div
            key="logo-animation"
            className="w-full h-full"
        >
            <form onSubmit={handleLogin} className="w-full flex flex-col justify-center items-center gap-3">
                {/* @ts-ignore */}
                {isError && <div className="w-full bg-red-200 text-red-800 p-3 rounded-md">{error.response.data.error}</div>}
                <InputWithIcon icon="Sms" placeholder="Votre email" value={email} onChange={setEmail} type="email" />
                <InputWithIcon icon="TagUser" placeholder="Votre pseudo" value={userName} onChange={setUserName} type="text" />
                <InputWithIcon icon="KeySquare" placeholder="Mot de passe" value={password} onChange={setPassword} type="password" withView={true} />
                <AnimatedButton type="submit" className="w-full bg-black text-white py-mini rounded-tiny">{isPending ? "Chargement" : "Créer mon compte"}</AnimatedButton>
            </form>
        </motion.div>
    )
}