import { motion } from "framer-motion"

export type AnimatedButtonProps = {
    children?: React.ReactNode;
    onClick?: any;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    style?: React.CSSProperties;
    type?: "button" | "submit" | "reset";
}

export const AnimatedButton = ({ children, type = "button", onClick, className, disabled = false, style, ...props } : AnimatedButtonProps ) => {
    return (
        <motion.button 
            className={className} 
            onClick={onClick} 
            disabled={disabled}
            whileTap={{ scale: 0.97 }}
            style={style}
            {...props}
        >
            {children}
        </motion.button>
    )
}