import React, { useEffect, useState } from 'react';
import { useMutator } from '@hooks/queries/api';
import { useCommunityForm } from '@hooks/queries/community';
import { Icon } from 'assets/icons';
import { AnimatedButton } from 'components/globals/buttons';
import { FormField, FormStep } from 'components/modales/allModales';
import { useParams } from 'react-router-dom';
import { motion, Reorder, useDragControls } from 'framer-motion';
import { GripVertical } from 'lucide-react';

type MutatorFormType = {
  newForm: FormStep[];
}

interface FieldItemProps {
  field: FormField;
  onDelete: (stepId: number, fieldId: number) => void;
  stepId: number;
}

const InputForLabel = ({ onSubmit, setNewField, newField }: any) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewField({ ...newField, [name]: value });
  };

  return (
    <div className='flex flex-col gap-2 items-start justify-start w-full'>
      <form
        className='w-full flex flex-col justify-items-center items-stretch gap-3'
        onSubmit={onSubmit}
      >
        <span>Quelle question souhaitez-vous poser ?</span>
        <input
          type="text"
          placeholder="Écrivez votre question ici"
          className='bg-transparent w-full focus:outline-none border h-10 rounded px-3'
          name="label"
          value={newField.label}
          onChange={handleChange}
          required
        />
        <span>Ajoutez un placeholder</span>
        <input
          type="text"
          placeholder="Ceci est un placeholder pour aider l'utilisateur"
          className='bg-transparent w-full focus:outline-none border h-10 rounded px-3'
          name="placeholder"
          value={newField.placeholder}
          onChange={handleChange}
          required
        />
        <div className='flex gap-2 items-center justify-start'>
          <input
            type="checkbox"
            name="required"
            checked={newField.required}
            onChange={(e) => setNewField({ ...newField, required: e.target.checked })}
          />
          <span>Cette question est obligatoire</span>
        </div>
        <AnimatedButton className='py-2 w-full bg-gray-200 rounded' type='submit'>Valider</AnimatedButton>
      </form>
    </div>
  )
}

const NewField = ({ onSubmit, setNewField, newField }: any) => {
  return (
    <div className='w-full flex flex-col gap-2 items-start jutify-start border rounded-lg p-4'>
      <InputForLabel onSubmit={onSubmit} setNewField={setNewField} newField={newField} />
    </div>
  )
}

const FieldItem = ({ field, onDelete, stepId }: FieldItemProps) => {
  const controls = useDragControls();

  return (
    <Reorder.Item
      value={field}
      id={field.id.toString()}
      dragControls={controls}
      className='w-full'
    >
      <motion.div
        className='w-full flex flex-col gap-2 items-start justify-start border rounded-lg p-4 bg-white'
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.2 }}
      >
        <div className='flex flex-col gap-2 items-start justify-start w-full'>
          <div className='flex w-full items-center justify-between'>
            <span>{field.label}</span>
            <div className='flex gap-2 items-center'>
              <AnimatedButton
                // @ts-ignore
                onPointerDown={(e: React.PointerEvent) => controls.start(e)}
                onTouchStart={(e: React.TouchEvent) => {
                  e.preventDefault();
                  // @ts-ignore
                  controls.start(e.touches[0]);
                }}
              >
                <GripVertical size={22} />
              </AnimatedButton>
              <AnimatedButton onClick={() => onDelete(stepId, field.id)}>
                <Icon type='Trash' size={22} />
              </AnimatedButton>
            </div>
          </div>
          <input
            type="text"
            placeholder={field.placeholder}
            className='bg-transparent w-full focus:outline-none border h-10 rounded px-3'
            required={field.required}
          />
        </div>
      </motion.div>
    </Reorder.Item>
  );
};

export const JoinForm = () => {
  const { communityId } = useParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const { data: communityForm, isLoading: isCommunityFormLoading, isError: isCommunityFormError } = useCommunityForm({ communityId, refetchOnMount: false });
  const { mutateAsync: updateJoinForm, isError, isPending } = useMutator<MutatorFormType>("/community/" + communityId + "/admin/form/update");

  const [form, setForm] = useState<FormStep[]>([]);

  useEffect(() => {
    if (communityForm?.additionalInfo) {
      setForm(communityForm.additionalInfo);
    } else {
      setForm([
        {
          stepId: 1,
          fields: [],
        },
      ]);
    }
  }, [communityForm]);

  const [newField, setNewField] = useState<FormField>({
    id: 1,
    label: '',
    placeholder: '',
    type: 'text',
    required: false,
    position: 1,
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (newField.label && newField.placeholder) {
      setForm((prevForm) => {
        const lastStep = prevForm[prevForm.length - 1];
        const updatedFields = [...lastStep.fields, { ...newField, id: lastStep.fields.length + 1, position: lastStep.fields.length + 1 }];
        return prevForm.map((step) =>
          step.stepId === lastStep.stepId ? { ...step, fields: updatedFields } : step
        );
      });
    }
  };

  const handleDelete = (stepId: number, fieldId: number) => {
    setForm((prevForm) => {
      return prevForm.map((step) => {
        if (step.stepId === stepId) {
          const updatedFields = step.fields.filter(field => field.id !== fieldId);
          return { ...step, fields: updatedFields };
        }
        return step;
      });
    });
  };

  const handleReorder = (newOrder: FormField[], stepId: number) => {
    setForm((prevForm) => {
      return prevForm.map((step) => {
        if (step.stepId === stepId) {
          return { ...step, fields: newOrder };
        }
        return step;
      });
    });
  };

  const handlePublish = async () => {
    await updateJoinForm({ newForm: form });
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
    }, 2000);
  }

  return (
    <div className='w-full flex flex-col items-start justify-center gap-4'>
      <div className='flex w-full items-center justify-between'>
        <h2>Mon formulaire d'entrée</h2>
        {
          isError ? (
            <div>
              <p className='text-red-500'>Une erreur est survenue</p>
            </div>
          ) : (
            <button className="bg-gray-300 text-black py-2 px-5 rounded-lg" onClick={handlePublish} disabled={isPending}>
              {isPending && 'En cours...'}
              {isSuccess && <span>Publié !</span>}
              {!isPending && !isSuccess && 'Publier'}
            </button>
          )
        }
      </div>
      <div className='w-full'>
        {isCommunityFormLoading && (
          <div className='w-full h-28 bg-gray-100 animate-pulse' />
        )}
        {!isCommunityFormLoading && !isCommunityFormError && form && form[0]?.fields?.length === 0 && (
          <div className='w-full flex flex-col gap-2 items-start justify-start border rounded-lg p-4 text-black bg-yellow-100'>
            <p>Vous n'avez pas encore de question</p>
          </div>
        )}
        {!isCommunityFormLoading && !isCommunityFormError && form.length && form?.map((step) => (
          <div key={step.stepId} className='w-full flex flex-col gap-2 items-center justify-center'>
            <Reorder.Group axis="y" onReorder={(newOrder) => handleReorder(newOrder, step.stepId)} values={step.fields} className='w-full flex flex-col items-center justify-center gap-3'>
              {step.fields.map((field) => (
                <FieldItem key={field.id} field={field} onDelete={handleDelete} stepId={step.stepId} />
              ))}
            </Reorder.Group>
          </div>
        ))}
      </div>
      <div className='w-full flex flex-col items-start justify-center gap-4'>
        <div className='h-[1px] w-full border-t' />
        <p>Ajouter une question : (Sera ajoutée lorsque vous validez)</p>
      </div>
      <NewField onSubmit={handleSubmit} setNewField={setNewField} newField={newField} />
    </div>
  );
};