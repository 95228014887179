import { useOpenedCommunity } from "stores/openedCommunity";
import { Config } from "services/config";
import { useThemeCommunity } from "stores/themeCommunity";


export const ActualCommunityTopBar = () => {

    const communityId = useOpenedCommunity(state => state.communityId);
    const communityName = useOpenedCommunity(state => state.communityName);
    //const isCommunityNameLoading = useOpenedCommunity(state => state.isCommunityNameLoading);
    const background_url = Config.CDN_HOST + "community%2F" + communityId + "%2Fbg";
    const { theme } = useThemeCommunity();

    if (!communityId) return null


    return (
        <div className="flex items-center justify-start gap-4">
            <span>&gt;</span>
            <div className="flex items-center gap-2 p-1 border-0.5 rounded-lg pr-3">
                <div className="w-6 h-6 rounded-md bg-grey-100 border" style={{ background: `url(${background_url}) center center / cover`, borderColor: theme.primaryColor }} />
                {
                    communityName === "" ? (
                        <div className="w-18 h-3 bg-gray-100 animate-pulse rounded-full" />
                    ) : (
                        <p className="text-grey-500 font-light">{communityName}</p>
                    )
                }
            </div>
        </div>
    )
}