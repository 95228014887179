import { Icon } from "assets/icons"
import { useModaleStore } from "stores/modale"
import { uuidSelector, useAuthStore } from "stores/auth"
import { Config } from "services/config"
import { useProfileRefresh } from "stores/profileRefresh"

export const UploadProfileImage = () => {

    const { imageUpdateCounter } : any = useProfileRefresh();
    const uuid : any = useAuthStore(uuidSelector);
    const { setModale } = useModaleStore();

    const openModale = () => {
        setModale(true, 'ChangeProfilPicModal', 'modale');
    };

    const url = Config.CDN_HOST + "user%2F" + uuid + "?counter=" + imageUpdateCounter;


    return (
        <div className="rounded-full w-[160px] h-[160px] bg-grey-100 flex justify-center items-center relative">
            <div onClick={openModale} className="bg-white flex justify-center items-center w-[45px] h-[45px] absolute bottom-0 right-0 rounded-full shadow-medium cursor-pointer">
                <Icon type="Camera" size={26} color="#8E8E93"/>
            </div>
            <div style={{ background: `url(${url}) center center / cover no-repeat` }} className="rounded-full w-[120px] h-[120px]" />
        </div>
    )
}