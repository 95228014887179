import { useModaleStore } from "stores/modale";
import { Config } from "services/config";
import { timeSince } from "services/timeSince"

export const MemberBox = ({ member } : { member : any }) => {

    const url = Config.CDN_HOST + "user%2F" + member.member_uuid;

    const { setModale } = useModaleStore();

    const openModale = () => {
        setModale(true, 'MemberModale', 'modale', { member });
    };

    return (
        <div className={`w-full cursor-pointer flex items-center justify-between border p-3 rounded-lg ${member.is_waiting ? "border-blue-400" : ""}`} onClick={openModale}>
            <div className="flex gap-2 items-center justify-start">
                <div className="w-9 h-9 rounded-full bg-cover bg-center" style={{ backgroundImage: `url(${url})`}} />
                <span>{member.first_name} {member.last_name}</span>
                { member.is_waiting && <span className="py-0.5 px-2 bg-gray-100 text-gray-500 font-light rounded-md">(en attente)</span>}
            </div>
            <div className="flex items-center justify-end gap-2 font-light text-gray-400 text-sm">
                {/* <p className="hidden md:block">Membre depuis : </p> */}
                <span>{timeSince(member.created_at)}</span>
            </div>
        </div>
    )
}