import { AnimatedButton } from "components/globals/buttons"
import { AdminLayout } from "layout/AdminLayout"

export const StripeConfigPage = () => {
    return (
        <AdminLayout>
            <div className="bg-app h-full w-full overflow-hidden">
                <div className="h-full w-full bg-app flex flex-col gap-8 items-center p-intermediate overflow-auto">
                    <div className="w-full flex flex-col gap-6">
                        <div className="flex justify-between items-start">
                            <div className="flex flex-col gap-1">
                                <p className="font-light text-sm opacity-80">Ma communauté &gt; Ma team</p>
                                <h1 className="text-left w-full text-xl">Ma team complète</h1>
                            </div>
                        </div>
                    </div>
                    <hr className="w-full" />
                    <div className="w-full flex flex-col items-start justify-start gap-5">
                        <div className="w-full flex flex-col items-start justify-start gap-2">
                            <h1 className="text-xl">Configurez votre compte pour accepter les paiements</h1>
                            <p className="font-light text-sm">Pour accepter les paiements, nous utilisons Stripe, vous pouvez configurer votre compte en quelques clics juste ici.</p>
                        </div>
                        <div className="w-full flex items-center justify-start">

                            <AnimatedButton className="bg-black py-tiny px-intermediate rounded-tiny text-white flex items-center justify-center gap-2">
                                <span>Configurer mon compte Stripe</span>
                            </AnimatedButton>

                        </div>

                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}