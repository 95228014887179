import React, { useState, useCallback, useEffect } from 'react';
import { AnimatedButton } from "components/globals/buttons";
import { useMutator } from '@hooks/queries/api';
import { useGetMineCommunityReviews } from '@hooks/queries/reviews';
import { useCommunityById } from '@hooks/queries/community';

interface StarProps {
    filled: boolean;
    onClick?: () => void;
}

const Star: React.FC<StarProps> = React.memo(({ filled, onClick }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="28"
        height="28"
        fill={filled ? '#ca8a04' : 'none'}
        stroke="#ca8a04"
        strokeWidth="0.5"
        className={onClick ? "transition-all duration-300 cursor-pointer" : ""}
        onClick={onClick}
    >
        <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
    </svg>
));

interface StarRatingProps {
    score: number;
    editable?: boolean;
    onChange?: (newScore: number) => void;
}

const StarRating: React.FC<StarRatingProps> = React.memo(({ score, editable = false, onChange }) => {
    const fullStars = Math.floor(score);
    const partialStar = score % 1;

    return (
        <div className="flex items-center">
            {[...Array(5)].map((_, index) => (
                <div key={index} className="relative">
                    <Star
                        filled={index < fullStars}
                        onClick={editable ? () => onChange?.(index + 1) : undefined}
                    />
                    {index === fullStars && partialStar > 0 && (
                        <div
                            className="absolute top-0 left-0 overflow-hidden"
                            style={{ width: `${partialStar * 100}%` }}
                        >
                            <Star
                                filled={true}
                                onClick={editable ? () => onChange?.(index + 1) : undefined}
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
});

export const CollectReviews: React.FC<{ isAdmin: boolean; communityId: string }> = ({ isAdmin, communityId }) => {

    const { data: myReview, isLoading: isMyReviewLoading, isError: isMyReviewError, refetch: refetchMineReview } = useGetMineCommunityReviews({ communityId: communityId || "", refetchOnMount: false, enabled: true });
    const { data: communityData, isLoading: isCommunityDataLoading, refetch:refetchAllReviews } = useCommunityById({ communityId, refetchOnMount: false });
    const [rate, setRate] = useState<number>(0);
    const [text, setText] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [missingRate, setMissingRate] = useState<boolean>(false);
    const { mutateAsync: sendReview, isError: isSendReviewError, isPending: isSendReviewLoading, isSuccess: isSendReviewSuccess } = useMutator<any>("/community/" + communityId + "/reviews/new");

    useEffect(() => {
        if (myReview && myReview.length > 0) {
            setRate(myReview[0].rating_out_of_five || 0);
            setText(myReview[0].text || '');
            setIsEditing(false);
        } else {
            setIsEditing(true);
        }
    }, [myReview]);

    const handleSubmit = useCallback(() => {
        if (!rate) {
            setMissingRate(true);
            setTimeout(() => setMissingRate(false), 2000);
            return;
        }
        sendReview({ note: rate, review: text }).then(() => {
            setIsEditing(false);
            refetchMineReview();
            refetchAllReviews();
        });
    }, [rate, text, sendReview, refetchMineReview, refetchAllReviews]);

    const handleTextChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    }, []);

    if (isMyReviewError) {
        return null;
    }

    if (isCommunityDataLoading || !communityData) {
        return null
    }

    return (
        <div className="w-full flex flex-col items-start justify-start gap-6">
            <div className="w-full flex flex-col items-start justify-start gap-1">
                <h3 className="text-xl font-semibold">Les avis des membres :</h3>
                <p className="text-sm text-gray-600 font-light">
                    Les membres de la communauté ont la possibilité de mettre un avis, voici la synthèse.
                </p>
            </div>
            <div className='w-full flex flex-col items-start justify-start gap-2'>
                {
                    communityData.reviews_count && communityData.reviews_rate && communityData.reviews_count > 0 ? (
                        <div className="w-full flex items-center justify-start gap-4">
                            <StarRating score={parseFloat(communityData.reviews_rate)} />
                            <div className="flex items-center justify-start gap-1">
                                <p className="text-base md:text-lg font-semibold text-gray-800">{communityData.reviews_rate}</p>
                                <p className="text-base md:text-lg text-gray-600 font-light">/5</p>
                                <p className="text-base md:text-lg text-gray-600 font-light">(sur {communityData.reviews_count} avis)</p>
                            </div>
                        </div>
                    ) : (
                        <div className='w-full bg-gray-100 rounded-lg py-2.5 px-4'>
                            <p className="text-gray-600 font-light">Aucun avis pour le moment</p>
                        </div>
                    )
                }

                <div className="flex flex-col w-full gap-3 items-start justify-start bg-gray-100 rounded-lg p-4">
                    <h3 className="text-xl font-semibold">Votre avis :</h3>
                    {isMyReviewLoading ? (
                        <div className='w-full h-28 rounded-md bg-gray-200 animate-pulse' />
                    ) : (
                        <>
                            <StarRating score={rate} editable={isEditing} onChange={setRate} />
                            {isEditing ? (
                                <textarea
                                    name="feedback"
                                    id="feedback"
                                    rows={4}
                                    className="border rounded-md w-full p-3"
                                    placeholder='Votre avis ...'
                                    value={text}
                                    onChange={handleTextChange}
                                ></textarea>
                            ) : (
                                <p className="text-gray-700">{text}</p>
                            )}
                            <div className="flex flex-col w-full items-center justify-center gap-2">
                                {missingRate && <span className="text-sm text-red-500 font-light">Veuillez mettre une note</span>}
                                {isSendReviewError && <span className="text-sm text-red-500 font-light">Une erreur est survenue lors de l'envoi de votre avis</span>}
                                {isSendReviewSuccess && <span className="text-sm text-green-500 font-light">Votre avis a bien été envoyé</span>}
                                {isEditing ? (
                                    <AnimatedButton
                                        className="bg-gray-600 text-white hover:bg-black rounded-md py-2.5 w-full transition-all disabled:bg-gray-200 text-gray-500 disabled:cursor-not-allowed"
                                        onClick={handleSubmit}
                                        disabled={isAdmin || isSendReviewLoading}
                                    >
                                        {isSendReviewLoading ? 'Envoi en cours ...' : 'Valider et envoyer'}
                                    </AnimatedButton>
                                ) : (
                                    <AnimatedButton
                                        className="bg-gray-600 text-white hover:bg-black rounded-md py-2.5 w-full transition-all"
                                        onClick={() => setIsEditing(true)}
                                        disabled={isAdmin}
                                    >
                                        Modifier votre avis
                                    </AnimatedButton>
                                )}
                                <span className="text-sm text-gray-500 font-light text-center">Le propriétaire de la communauté verra votre nom Feder associé à l'avis.</span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};