import { AdminLayout } from "layout/AdminLayout";
import { useBreakpointContext } from "context/BreakpointContext";
import { AdminBuilderTopbar } from "components/globals/topbar/AdminBuilderTopbar";
import { useParams } from "react-router-dom";
import { useCommunityLanding } from "@hooks/queries/community";
import { useTopbarMobileData } from "stores/topbarMobileData";
import { useLandingStore } from "stores/landing";
import { useEffect } from "react";
import { LandingSkeleton } from "skeleton";
import { CommunityLandingPageContent } from "components/community/landing";
import { fakeTheme } from "components/community/landing/fakeData/fakeTheme";

export const AdminLandingPage = () => {

    const { communityId } = useParams<{ communityId: string }>();
    const { data: communityLanding, isLoading: isCommunityLandingLoading, isError: isCommunityLandingError } = useCommunityLanding({ communityId, refetchOnMount: false, enabled: true });
    const { setTopbarMobileData } = useTopbarMobileData();
    const { setBlocks } = useLandingStore();

    const { isDesktop } = useBreakpointContext();

    useEffect(() => {
        setTopbarMobileData({ showTopbar: false });
    }, [setTopbarMobileData]);

    useEffect(() => {
        if (communityLanding) {
            setBlocks(communityLanding);
        }
    }, [communityLanding, isCommunityLandingLoading]);

    if (isCommunityLandingLoading) {
        return <LandingSkeleton />;
    }

    if (!communityId) return null;

    return (
        <AdminLayout>
            <div className="w-full flex flex-col flex-1">
                {
                    isCommunityLandingLoading || isCommunityLandingError ? <LandingSkeleton /> : (
                        <>

                            {isDesktop && <AdminBuilderTopbar />}
                            <div className="h-full w-webkit bg-app flex gap-8 items-start p-intermediate overflow-auto">
                                <CommunityLandingPageContent
                                    communityId={communityId}
                                    isAdmin={true}
                                    joinCommunity={() => console.log('join')}
                                    isLoading={isCommunityLandingLoading}
                                    isError={isCommunityLandingError}
                                    error={() => console.log('error join')}
                                    isWaiting={false}
                                    theme={fakeTheme}
                                />
                            </div>
                        </>
                    )
                }
            </div>
        </AdminLayout>
    )
}