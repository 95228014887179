
import { useMutator } from "@hooks/queries/api";
import { v4 as uuidv4 } from 'uuid';
import { useMessageStore } from 'stores/feed/channelMessage';
import { uuidSelector, useAuthStore, userNameSelector } from "stores/auth"

export type SendCommentType = {
    communityId: string;
    channelId: any;
    postId: any;
};

export type SendCommentContent = {
    content: any;
}   

export const useSendComment= ({ communityId, channelId, postId } : SendCommentType ) => {
    
    const { mutateAsync: sendNewComment } = useMutator<any>(`/community/${communityId}/${channelId}/${postId}/comments/new`);
    const addWaitingComment = useMessageStore(state => state.addWaitingComment);
    const uuid : any = useAuthStore(uuidSelector);
    const userName : any = useAuthStore(userNameSelector);
    
    const sendComment = ({ content }: SendCommentContent ) => {
        const uniqueId = uuidv4() + "_" + new Date().getTime();
        //@ts-ignore
        addWaitingComment(channelId, postId, {tempId: uniqueId, type:"comment",post_id: postId,comment:content,user_uuid:uuid, created_at: new Date(),reactions:{},comment_id:null,user_name:userName,channel_id:channelId,comment_count:5},uniqueId);
        return sendNewComment({ content, temporaryId: uniqueId });
    };

    return { sendComment };
};	