import { CategoryListType, useGetAllCategories } from "@hooks/queries/categories";
import { CategoryItemType } from "interfaces/categories";
import { AxiosError } from "axios";
import { CategorieItem, CategorieSkeleton } from "components/communities/Categories/CategorieItem";

export const CategoriesList = () => {

    const {
        data: categories = [],
        isLoading,
        isError,
        error
    } = useGetAllCategories({ refetchOnMount: false });

    if (isLoading) {
        return (
            <div className="w-full grid grid-cols-3 gap-2">
                {Array.from({ length: 6 }).map((_, index) => (
                    <CategorieSkeleton key={index} />
                ))}
            </div>
        );
    }

    if (isError) {
        const errorMessage = error instanceof AxiosError
            ? error.response?.data?.error || 'Une erreur est survenue'
            : 'Une erreur est survenue';

        return (
            <div className="w-full text-center text-red-500">
                <p>Erreur : {errorMessage}</p>
            </div>
        );
    }

    return (
        <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-2">
            {categories && (categories as CategoryListType).list.map((category: CategoryItemType) => (
                <CategorieItem key={category.id} category={category} />
            ))}
        </div>
    );
}

export const CategoriesPage = () => {
    return (
        <div className="bg-app h-full w-full overflow-hidden relative flex items-center justify-center">
            <div className="h-full w-full max-w-[1080px] flex flex-col gap-8 items-center p-intermediate overflow-auto relative z-20">
                <CategoriesList />
            </div>
        </div>
    )
}