import AdminListener from "services/adminListener";
import { useEffect, type ReactNode } from "react";
import { useBreakpointContext } from "context/BreakpointContext";
import { AdminSidebar } from "components/globals/sidebar/admin";
import { MobileAdminTopbar } from "components/globals/topbar/MobileAdmin";
import { useNavigate, useParams } from "react-router-dom";
import { useAdminCommunity } from "stores/adminCommunity";
import { useCheckCommunityAdminById } from "hooks/queries/community";
import { CommunitySkeleton } from "skeleton";
import { useTopbarMobileData } from "stores/topbarMobileData";
import { AdminRoutes } from "navigation/useAppRoutes";
import { useMatchRoutes } from "@hooks/useMatchRoutes";

type MainLayoutProps = {
    children: ReactNode;
}

export const AdminLayout = ({ children }: MainLayoutProps) => {
    const { communityId } = useParams<{ communityId: string }>();
    if (!communityId) return null;

    const { data: community, isLoading, isError, error } = useCheckCommunityAdminById({ communityId, refetchOnMount: false });
    const navigate = useNavigate();
    const { setAdminCommunity } = useAdminCommunity();

    useEffect(() => {
        if (community) {
            setAdminCommunity(communityId, community.role);
        }

        return () => {
            setAdminCommunity('', 0);
        };
    }, [communityId, community]);

    const { isDesktop, isMobileOrTable } = useBreakpointContext();

    const { setTopbarMobileData } = useTopbarMobileData();

    useEffect(() => {
        setTopbarMobileData({ showTopbar: true, pageName: 'Admin', withGoBack: true});
    }, []);

    const shouldDisplayBuilderSidebar = useMatchRoutes([
        AdminRoutes.adminLanding,
        AdminRoutes.adminAbout,
        AdminRoutes.adminQuickStart
    ])

    return (
        <>
            {
                isError && (
                    // @ts-ignore
                    <div>{error.error}</div>
                )
            }
            {
                isLoading ? (
                    isDesktop ? (<CommunitySkeleton />) : (<CommunitySkeleton />)
                ) : (
                    <>
                        {
                            community?.isAdmin ? (
                                <div className="w-full flex lg:flex-row flex-col flex-1 h-full">
                                    <AdminListener />
                                    { isDesktop && !shouldDisplayBuilderSidebar && <AdminSidebar /> }
                                    { isMobileOrTable && !shouldDisplayBuilderSidebar && <MobileAdminTopbar /> }
                                    { children }
                                </div>
                            ) : navigate('/')
                        }
                    </>
                )
            }
        </>
    );
}
