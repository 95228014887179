// CompleteProfileForm.tsx
import { AnimatedButton } from "components/globals/buttons";
import { UploadProfileImage } from "./uploadProfileImage";
import { useEffect, useState } from "react";
import { ChooseSexeInput } from "./chooseSexeInput";
import { useMutator } from "@hooks/queries/api";
import { ChangeUsername } from "./changeUsername";
import { useAuthStore, userNameSelector } from "stores/auth"
import { useNavigate } from "react-router-dom";
import { ProtectedRoutes } from "navigation/useAppRoutes";
import { profileType } from "components/auth";
import { Config } from "services/config";

export type CompleteProfileFormProps = {
    last_name: string;
    first_name: string;
    user_name: string;
    sexe: string;
};

export type ProfilCompletionPropsType = {
    context?: string,
    displayIn?: string,
    callbackFunction?: ({ }: any) => void
}

export const CompleteProfileForm = ({ context, displayIn, callbackFunction }: ProfilCompletionPropsType) => {

    context = context || "signinAndJoinCommunity"
    displayIn = displayIn || "page"

    const { mutateAsync: completeProfileRequest, isError, error, isPending } = useMutator<CompleteProfileFormProps>("/profile/update");
    const navigate = useNavigate();
    const userName: string = useAuthStore(userNameSelector);
    type JoinCommunityType = {
        communityName: string | null;
        communityId: string | null;
    };

    const [joinCommunity, setJoinCommunity] = useState<JoinCommunityType | undefined>();

    const [profile, setProfile] = useState({
        last_name: "",
        first_name: "",
        user_name: userName,
        sexe: "H",
    });

    const checkProfileAndNavigate = (profile: profileType) => {
        let route: string;

        if (!profile.isProfileComplete) {
            route = ProtectedRoutes.completeProfile;
        } else if (!profile.isEmailVerified) {
            route = ProtectedRoutes.verifyEmail;
        } else {
            route = ProtectedRoutes.home;
        }

        if (joinCommunity?.communityId && joinCommunity.communityName && route !== ProtectedRoutes.home) {
            route += `?communityName=${encodeURIComponent(joinCommunity.communityName)}&communityId=${encodeURIComponent(joinCommunity.communityId)}`;
        }

        if (joinCommunity?.communityId && joinCommunity.communityName && route === ProtectedRoutes.home) {
            route = '/' + joinCommunity.communityId + "?join=true";
        }

        return route;
    }

    const handleCreateProfile = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const tryCreateProfile = await completeProfileRequest(profile);
        const nextRoute = checkProfileAndNavigate(tryCreateProfile);
        if (displayIn === "page") navigate(nextRoute);
        if (displayIn === "modale") {
            callbackFunction && callbackFunction(tryCreateProfile)
        }
    };

    const handleInputChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile({
            ...profile,
            [field]: e.target.value
        });
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const communityName = params.get('communityName');
        const communityId = params.get('communityId');
        if (communityName && communityId) {
            setJoinCommunity({ communityName, communityId });
        }
    }, []);

    return (
        <div className="w-full flex flex-col items-center justify-center gap-6">
            <div className="w-full flex flex-col gap-5 items-center justify-center">
                <UploadProfileImage />
                <ChangeUsername username={userName} setUsername={(user_name) => setProfile({ ...profile, user_name })} />
            </div>
            <div className="w-full flex flex-col gap-5 items-center justify-center">
                {isPending && <p>Chargement...</p>}
                { /* @ts-ignore */}
                {isError && <div className="w-full bg-red-200 text-red-800 p-3 rounded-md">{error?.data?.error}</div>}
                <form onSubmit={handleCreateProfile} className="w-full flex flex-col gap-2 items-center justify-center">
                    <ChooseSexeInput sexe={profile.sexe} setSexe={(sexe) => setProfile({ ...profile, sexe })} />
                    <div className="flex gap-2 items-center justify-center w-full">
                        <input type="text" onChange={handleInputChange('first_name')} placeholder="Votre prénom" className="w-full focus:outline-none border border-grey-100 bg-white py-small rounded-tiny px-small" />
                        <input type="text" onChange={handleInputChange('last_name')} placeholder="Votre nom" className="w-full focus:outline-none border border-grey-100 bg-white py-small rounded-tiny px-small" />
                    </div>
                    <AnimatedButton type="submit" className="w-full bg-black text-white py-mini rounded-tiny">Valider</AnimatedButton>
                </form>
                {
                    joinCommunity && (
                        <div className="w-webkit flex flex-col md:flex-row gap-2 items-center justify-center bg-app rounded-md border py-3">
                            <p className="font-light text-gray-500">Pour rejoindre :</p>
                            <div className="w-full md:w-max flex items-center justify-center gap-2">
                                <img src={`${Config.CDN_HOST}community%2F${joinCommunity.communityId}%2Fbg`} alt="communauté bg" className="w-7 h-7 rounded-full object-cover" />
                                <p className="font-[500]">{joinCommunity.communityName}</p>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
