import { useFeaturedCommunitiesAndCreators } from "@hooks/queries/communities";
import { AnimatedButton } from "components/globals/buttons"
import { useNavigate } from "react-router-dom";

const Star = () => {
    return (
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.29311 0.786952C6.54817 0.00195158 7.65873 0.00195386 7.91379 0.786954L8.82512 3.59172C8.93919 3.94278 9.26633 4.18047 9.63546 4.18047H12.5846C13.41 4.18047 13.7531 5.23668 13.0854 5.72184L10.6995 7.45528C10.4009 7.67225 10.2759 8.05683 10.39 8.4079L11.3013 11.2127C11.5564 11.9977 10.6579 12.6504 9.99015 12.1653L7.60427 10.4318C7.30564 10.2149 6.90126 10.2149 6.60263 10.4318L4.21675 12.1653C3.54899 12.6504 2.65052 11.9977 2.90559 11.2127L3.81691 8.40789C3.93098 8.05683 3.80602 7.67225 3.50739 7.45528L1.12151 5.72184C0.453748 5.23668 0.796934 4.18047 1.62233 4.18047H4.57144C4.94057 4.18047 5.26771 3.94278 5.38178 3.59172L6.29311 0.786952Z" fill="#22201F" />
        </svg>
    )
}

export const FeaturedCommunityBox = () => {

    const { data: featured, isLoading: isFeaturedLoading, isError: isFeaturedError } = useFeaturedCommunitiesAndCreators({ refetchOnMount: false });

    const navigate = useNavigate()

    const handleClickOnBox = () => {
        navigate(`/${featured?.communities.id}`)
    }

    return (
        <>
            {
                isFeaturedLoading ? (
                    <div className="w-full bg-gray-100 flex items-stretch justify-between rounded-lg overflow-hidden">
                        <div className="w-8/12 p-mini flex flex-col items-start justify-start gap-3">
                            <div className="w-full flex gap-1 items-center">
                                <div className="w-[25px] lg:w-[30px] h-[25px] lg:h-[30px] rounded-full bg-gray-200 animate-pulse" />
                                <div className="flex flex-col gap-0 items-start justify-start">
                                    <div className="w-24 h-4 bg-gray-200 rounded animate-pulse" />
                                    <div className="w-20 h-3 bg-gray-200 rounded animate-pulse mt-1" />
                                </div>
                            </div>
                            <div className="w-32 h-4 bg-gray-200 rounded animate-pulse" />
                            <div className="bg-gray-200 py-2 w-full rounded-md animate-pulse" />
                        </div>
                        <div className="w-4/12 bg-gray-200 animate-pulse" />
                    </div>
                ) : isFeaturedError ? (
                    <div className="bg-red-100 rounded-mini border border-red-700 w-full p-3">
                        <p className="text-red-700">Une erreur est survenue</p>
                    </div>
                ) : (
                    <AnimatedButton className="w-full bg-lightGray flex items-stretch justify-between rounded-lg overflow-hidden" onClick={handleClickOnBox}>
                        <div className="w-8/12 p-mini flex flex-col items-start justify-start gap-3">
                            <div className="w-full flex gap-1 items-center">
                                <div className="w-[25px] lg:w-[30px] h-[25px] lg:h-[30px] rounded-full" style={{ background: `url(https://pub-388e882330ee44c7b74215d8e0935cb9.r2.dev/user%2F${featured?.communities.owner_uuid}) center center / cover` }} />
                                <div className="flex flex-col gap-0 items-start justify-start">
                                    <h2 className="text-sm">{featured?.communities.name}</h2>
                                    <p className="text-textGray text-xs font-light">{featured?.communities.members} membres</p>
                                </div>
                            </div >
                            <div className="flex items-center justify-start gap-2">
                                <div className="flex items-center justify-start gap-0">
                                    {
                                        Array.from({ length: 5 }).map((_, index) => (
                                            <Star key={index} />
                                        ))
                                    }
                                </div>
                                <p className="font-[400] text-textGray text-xs"><span className="font-bold">4,9/5</span> sur 300 avis</p>
                            </div>
                            <div className="bg-white py-2 w-full rounded-md text-black">Voir la communauté</div>
                        </div >
                        <div className="w-4/12 bg-cover bg-center" style={{ background: `url(https://pub-388e882330ee44c7b74215d8e0935cb9.r2.dev/community%2F${featured?.communities.id}%2Fbg) center center / cover` }} />
                    </AnimatedButton >
                )
            }
        </>
    )
}