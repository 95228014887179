import React, { useEffect, useState } from 'react';
import { useWebSocketStore } from 'hooks/websocket/websocketStore';

const WebSocketStatusIndicator: React.FC = () => {
  const [status, setStatus] = useState<'connected' | 'connecting' | 'disconnected'>('disconnected');
  const service = useWebSocketStore(state => state.service);
  const isConnecting = useWebSocketStore(state => state.isConnecting);

  useEffect(() => {
    const checkStatus = () => {
      if (isConnecting) {
        setStatus('connecting');
      } else if (service && service.isConnected()) {
        setStatus('connected');
      } else {
        setStatus('disconnected');
      }
    };

    // Vérifier le statut immédiatement et toutes les 5 secondes
    checkStatus();
    const interval = setInterval(checkStatus, 5000);

    return () => clearInterval(interval);
  }, [service, isConnecting]);

  const getStatusColor = () => {
    switch (status) {
      case 'connected':
        return 'bg-green-500';
      case 'connecting':
        return 'bg-yellow-500';
      case 'disconnected':
        return 'bg-red-500';
    }
  };

  return (
    <div className="flex items-center">
      <div className={`w-3 h-3 rounded-full ${getStatusColor()} mr-2`}></div>
    </div>
  );
};

export default WebSocketStatusIndicator;