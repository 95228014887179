import React, { useMemo, useState } from 'react';
import { useAllConversation } from "@hooks/queries/messages";
import { useModaleStore } from "stores/modale";
import { Icon } from "assets/icons";
import { AnimatedButton } from "components/globals/buttons";
import { useParams } from "react-router-dom";
import { useConversationsStore } from 'stores/conversation';
import { ConversationItem } from './conversationItem';

export const ConversationSidebar: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const { isLoading, isError } = useAllConversation({ refetchOnMount: false });
    const { conversationSummaries } = useConversationsStore();
    const { setModale } = useModaleStore();
    const params = useParams();

    const conversationId = params.conversationId;

    const handleNewConversation = () => {
        setModale(true, 'NewUserMessageModale', 'modale', {});
    }

    const filteredConversations = useMemo(() => {
        return conversationSummaries
            ?.filter(conversation =>
                conversation.other_user_name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .sort((a, b) => {
                // Assuming last_message_time is a string in ISO format
                return new Date(b.last_message_time).getTime() - new Date(a.last_message_time).getTime();
            });
    }, [conversationSummaries, searchTerm]);

    return (
        <div className="bg-white border-r min-w-[350px] px-3 py-5 flex flex-col items-center justify-start gap-5">
            <div className="flex items-stretch justify-center items-center w-full gap-2">
                <input
                    type="text"
                    className="w-full border border-gray-200 rounded-tiny px-3 h-10 font-light"
                    placeholder="Rechercher une conversation.."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <AnimatedButton className="bg-black py-tiny px-intermediate rounded-tiny text-white flex items-center justify-center gap-2" onClick={handleNewConversation}>
                    <Icon type="Add" size={18} color={"white"} />
                </AnimatedButton>
            </div>
            <div className="w-full flex flex-col items-center justify-center gap-2">
                {
                    !isLoading && !isError && filteredConversations?.map((conversation, index) => <ConversationItem conversation={conversation} key={index} conversationId={conversationId} />)
                }
                {
                    isLoading && Array.from({ length: 3 }).map((_, i) => (
                        <div className='w-full h-14 rounded-tiny animate-pulse flex items-center justfiy-start gap-2' key={i}>
                            <div className="!w-10 !h-10 bg-gray-100 rounded-full flex-shrink-0"></div>
                            <div className='flex flex-col items-start justify-start w-webkit gap-2'>
                                <div className='w-20 h-5 bg-gray-100 rounded' />
                                <div className='w-8/12 h-3 bg-gray-100 rounded' />
                            </div>
                        </div>
                    ))
                }
                {
                    !isLoading && isError && (
                        <div className='flex flex-col items-center justify-center gap-2 py-20'>
                            <svg className="w-16 h-16 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <p className='text-red-700 text-center'>Une erreur est survenue, veuillez réessayer plus tard</p>
                        </div>
                    )
                }
            </div>
        </div>
    )
}