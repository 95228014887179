import { create } from 'zustand';

interface MessageContent {
    type: string;
    content: Array<{
        type: string;
        content: Array<{
            text: string;
            type: string;
        }>;
    }>;
}

interface ConversationMessage {
    id?: number;
    temporaryId?: string;
    sender_uuid: string;
    user_name: string;
    content: MessageContent;
    created_at: string;
    waiting?: boolean;
    correspondantId: string;
    recipient_name: string;
    is_pinned: boolean;
    sender_name: string;
}

interface ConversationSummary {
    conversation_id?: number;
    other_user_uuid: string;
    other_user_name: string;
    last_message_content: MessageContent;
    last_message_time: string;
}

interface ConversationsState {
    conversations: Record<string, ConversationMessage[]>;
    conversationSummaries: ConversationSummary[];
    addConversationMessage: (message: ConversationMessage, currentUserName: string) => void;
    addConversationMessages: (correspondantId: string, newMessages: ConversationMessage[]) => void;
    addWaitingConversationMessage: (correspondantId: string, message: ConversationMessage) => void;
    confirmConversationMessage: (correspondantId: string, temporaryId: string, confirmedMessage: ConversationMessage) => void;
    setAllConversations: (summaries: ConversationSummary[]) => void;
    setConversationMessages: (correspondantId: string, messages: ConversationMessage[]) => void;
}

export const useConversationsStore = create<ConversationsState>(set => ({
    conversations: {},
    conversationSummaries: [],

    addConversationMessage: (message, currentUserName) => {
        set((state) => {
            const conversationMessages = state.conversations[message.correspondantId] || [];
            const existingIndex = conversationMessages.findIndex(msg =>
                msg.id === message.id || msg.temporaryId === message.temporaryId
            );

            let updatedConversations = { ...state.conversations };

            if (existingIndex === -1) {
                // Message doesn't exist, add it to the conversation
                updatedConversations[message.correspondantId] = [...conversationMessages, message];
            } else {
                // Message exists, update it
                const updatedMessages = [...conversationMessages];
                updatedMessages[existingIndex] = { ...message, waiting: false };
                updatedConversations[message.correspondantId] = updatedMessages;
            }

            // Determine the other user's name
            const otherUserName = message.sender_name === currentUserName ? message.recipient_name : message.sender_name;

            // Check if we need to create a new conversation summary
            const existingSummary = state.conversationSummaries.find(
                summary => summary.other_user_uuid === message.correspondantId
            );

            let updatedSummaries = [...state.conversationSummaries];

            if (!existingSummary) {
                // Create a new conversation summary
                const newSummary: ConversationSummary = {
                    conversation_id: message.id, // Using the message id as conversation id
                    other_user_uuid: message.correspondantId,
                    other_user_name: otherUserName,
                    last_message_content: message.content,
                    last_message_time: message.created_at
                };
                updatedSummaries.push(newSummary);
            } else {
                // Update the existing summary
                updatedSummaries = updatedSummaries.map(summary =>
                    summary.other_user_uuid === message.correspondantId
                        ? { 
                            ...summary, 
                            other_user_name: otherUserName,
                            last_message_content: message.content, 
                            last_message_time: message.created_at 
                          }
                        : summary
                );
            }

            return {
                conversations: updatedConversations,
                conversationSummaries: updatedSummaries
            };
        });
    },

    addConversationMessages: (correspondantId, newMessages) => {
        set((state) => {
            const existingMessages = state.conversations[correspondantId] || [];
            const filteredMessages = newMessages.filter(newMsg =>
                !existingMessages.some(msg => msg.id === newMsg.id || msg.temporaryId === newMsg.temporaryId)
            );
            return {
                conversations: {
                    ...state.conversations,
                    [correspondantId]: [...existingMessages, ...filteredMessages]
                }
            };
        });
    },

    addWaitingConversationMessage: (correspondantId, message) => {
        set((state) => {
            const newMessage = { ...message, waiting: true };
            const conversationMessages = state.conversations[correspondantId] || [];
            return {
                conversations: {
                    ...state.conversations,
                    [correspondantId]: [...conversationMessages, newMessage]
                }
            };
        });
    },

    confirmConversationMessage: (correspondantId, temporaryId, confirmedMessage) => {
        set((state) => {
            const conversationMessages = state.conversations[correspondantId] || [];
            const updatedMessages = conversationMessages.map(msg =>
                msg.temporaryId === temporaryId ? { ...confirmedMessage, waiting: false } : msg
            );
            return {
                conversations: {
                    ...state.conversations,
                    [correspondantId]: updatedMessages
                }
            };
        });
    },

    setAllConversations: (summaries) => {
        set({ conversationSummaries: summaries });
    },

    setConversationMessages: (correspondantId, messages) => {
        set((state) => ({
            conversations: {
                ...state.conversations,
                [correspondantId]: messages
            }
        }));
    },

}));