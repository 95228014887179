import { useModaleStore } from "stores/modale";
import { useBreakpointContext } from "context/BreakpointContext";
import { useNavigate, useParams } from "react-router-dom";
import { AdminLayout } from "layout/AdminLayout";
import { AnimatedButton } from "components/globals/buttons";
import { Icon } from "assets/icons";
import { useAllAdminContent } from "@hooks/queries/content";
import { timeSince } from "services/timeSince";

export const AdminContentPage = () => {

    const { communityId } = useParams();
    const { isMobile } = useBreakpointContext();
    const { data, isLoading, isError, refetch } = useAllAdminContent({ communityId, refetchOnMount: false })
    const { setModale } = useModaleStore();
    const navigate = useNavigate();

    const handleCreateNewcontent = () => {
        setModale(true, "CreateContentModale", "modale", { communityId: communityId, refetch: refetch });
    }

    const handleGoToContent = (content: any) => {
        navigate(`/admin/${communityId}/content/${content.id}`)
    }

    return (
        <AdminLayout>
            <div className="bg-app h-full w-full overflow-hidden">
                <div className="h-full w-full bg-app flex flex-col gap-8 items-center p-intermediate overflow-auto">
                    <div className="w-full flex flex-col gap-6">
                        <div className="flex justify-between items-start">
                            <div className="flex flex-col gap-1">
                                <p className="font-light text-sm opacity-80">Ma communauté &gt; Resources</p>
                                <h1 className="text-left w-full text-xl">Toutes mes resources</h1>
                            </div>
                            <AnimatedButton className="bg-black py-tiny px-intermediate rounded-tiny text-white flex items-center justify-center gap-2" onClick={handleCreateNewcontent}>
                                <Icon type="Add" size={18} color={"white"} />
                                {!isMobile && <span>Nouvelle resource</span>}
                            </AnimatedButton>
                        </div>
                    </div>
                    <hr className="w-full" />
                    <div className="w-full flex flex-col items-center justify-start gap-2">
                        {
                            isLoading && (
                                <div className="w-full flex flex-col gap-2">
                                    {Array.from({ length: 4 }).map((_, index) => (
                                        <div className="grid grid-cols-12 gap-3 h-12 items-center bg-gray-100 px-5 py-3 rounded" key={index} />
                                    ))}
                                </div>
                            )
                        }
                        {
                            isError && <div>Une erreur est survenue</div>
                        }
                        {
                            data?.list.length === 0 ? (
                                <div>Aucun membre trouvé</div>
                            ) : (
                                data?.list.map((content, index: number) => (
                                    <AnimatedButton onClick={() => handleGoToContent(content)} className={`w-full flex items-center justify-between border ${content.status === "draft" ? "border-blue-400" : content.status === "published" ? "border-green-400" : content.status === "deleted" ? "border-red-400" : ""} rounded-lg`} key={index}>
                                        <div className="flex gap-2 items-center justify-start">
                                            <div className="flex flex-col gap-0 items-start justify-start p-3">
                                                <div className="flex gap-2 items-center justify-start">
                                                    <span>{content.title}</span>
                                                    {content.status === "draft" && <div className="w-2 h-2 rounded-full bg-blue-500" />}
                                                    {content.status === "deleted" && <div className="w-2 h-2 rounded-full bg-red-500" />}
                                                    {content.status === "published" && <div className="w-2 h-2 rounded-full bg-green-500" />}
                                                    {!isMobile && content.status === "draft" && <span className="py-0.5 px-2 bg-gray-100 text-gray-500 font-light rounded-md">(Brouillon)</span>}
                                                </div>
                                                <span className="text-sm text-gray-500">{content.visibility}</span>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end gap-2 font-light text-gray-400 text-sm">
                                            <p className="hidden md:block">Créé </p>
                                            <span>{timeSince(content.created_at as string)}</span>
                                        </div>
                                    </AnimatedButton>
                                )))
                        }
                    </div>
                </div>
            </div>
        </AdminLayout>
    )
}