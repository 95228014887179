import { useFetcher } from './api'

export type GetAllEmployeeFromCommunityTypes = {
    refetchOnMount?: boolean;
    communityId: number;
    withNotifications?: boolean;
    enabled?: boolean;
};

interface EmployeeResponse {
    id: number;
    member_uuid: string;
    community_id: number;
    role_id: number;
    status: string;
    created_at: string;
    created_by: null | string; // Supposant que created_by pourrait être une chaîne si non null
    first_name: string;
    last_name: string;
    user_name: string;
}

export const useAllEmployee = ({ communityId, refetchOnMount = true }: any) => {
    return useFetcher<any, EmployeeResponse[]>({ key: `community-${communityId}-admin-employee`, path: `/community/${communityId}/admin/team/all`, refetchOnMount })
}
