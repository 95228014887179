import React, { useEffect, useMemo, useState } from "react";
import { useTopbarMobileData } from "stores/topbarMobileData";
import { useGetSavedPosts } from "@hooks/queries/saved";
import { PostsList } from "components/community/feed/messages/postsList";
//import { PullToRefresh } from "layout/PullToRefreshLayout";
import { type PostFeed } from "@hooks/queries/communities";
import { useAuthStore } from "stores/auth";
import { AnimatedButton } from "components/globals/buttons";
import { useModaleStore } from "stores/modale";

const TabSkeleton: React.FC = () => (
  <div className="h-10 w-24 bg-gray-200 animate-pulse rounded-md"></div>
);

export const SavedPage: React.FC = () => {
  const { setTopbarMobileData } = useTopbarMobileData();
  const [activeTab, setActiveTab] = useState<string>("all");
  const { isLogged } = useAuthStore();
  const { setModale } = useModaleStore();
  const { data, isLoading } = useGetSavedPosts({ refetchOnMount: false, enabled: isLogged });

  useEffect(() => {
    setTopbarMobileData({
      pageName: "Saved",
      showTopbar: true,
      communityName: "Communautés",
      withLogo: true,
    });
  }, [setTopbarMobileData]);

  const communities = useMemo(() => {
    if (!data) return ["all"];
    const uniqueCommunities = Array.from(
      new Set(
        data
          .map((post: PostFeed) =>
            post && typeof post === 'object' && 'community_name' in post
              ? post.community_name as string // Add type assertion here
              : null
          )
          .filter((name: string) => name !== null)
      )
    );
    return ["all", ...uniqueCommunities] as string[];
  }, [data]);

  const filteredPosts = useMemo(() => {
    if (!data) return [];
    if (activeTab === "all") return data;
    return data.filter((post: PostFeed) =>
      post && typeof post === 'object' && 'community_name' in post && post.community_name === activeTab
    );
  }, [data, activeTab]);

  // const handleRefresh = () => {
  //   window.location.reload();
  // };

  const handleLogin = () => {
    setModale(true, 'AuthModale', 'modale', {});
  }

  if (!isLogged) {
    return (
      <div className="w-11/12 mx-auto md:w-full h-full flex flex-col items-center justify-center gap-4">
        <p className="text-black text-lg md:text-xl text-center">Connectez-vous pour voir vos posts enregistrés</p>
        <AnimatedButton onClick={handleLogin} className="bg-black py-tiny px-intermediate rounded-tiny text-white flex items-center justify-center gap-2">
          <span>Me connecter</span>
        </AnimatedButton>
      </div>
    )
  }

  return (
    <div className="h-full w-full flex flex-col gap-3 items-center px-intermediate pt-intermediate">
      <div className="w-full lg:max-w-[1080px] flex flex-col gap-4">
        <div className="w-full flex flex-col gap-0">
          <h2 className="lg:text-xl text-lg">Vos posts enregistrés</h2>
        </div>
        {isLoading ? (
          <div className="flex gap-4 w-full overflow-x-auto pb-2">
            <TabSkeleton />
            <TabSkeleton />
          </div>
        ) : (
          <div className="flex gap-4 w-full overflow-x-auto pb-2">
            {communities.map((community) => (
              <button
                key={community}
                onClick={() => setActiveTab(community)}
                className={`px-4 py-2 rounded-md transition-colors ${activeTab === community
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                  }`}
              >
                {community === "all" ? "Tous" : community}
              </button>
            ))}
          </div>
        )}
        <PostsList data={filteredPosts} isLoading={isLoading} isFullWidth={true} />
      </div>
    </div>
  );
};