import React, { useState } from "react";
import { Icon } from "assets/icons";
import { useLandingStore } from "stores/landing";

interface TextBlockProps {
  id: string;
  content: string;
  isAdmin: boolean;
}

export const TextBlock: React.FC<TextBlockProps> = ({ id, content, isAdmin }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(content);
  const [isHovered, setIsHovered] = useState(false);
  const { updateBlock } = useLandingStore();

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    updateBlock(id, (block) => ({
      ...block,
      content: editedContent,
    } as any));
    setIsEditing(false);
  };

  return (
    <div
      className="w-full relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isAdmin && !isEditing && isHovered && (
        <button
          className="absolute top-0 right-0 bg-gray-500 text-white p-2 rounded"
          onClick={handleEdit}
        >
          <Icon type="Edit2" size={15} />
        </button>
      )}

      {isEditing ? (
        <div>
          <textarea
            className="w-full p-3 border rounded-md"
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            rows={5}
          />
          <button
            className="mt-2 bg-green-500 text-white p-2 rounded-md flex items-center justify-center gap-1 w-full"
            onClick={handleSave}
          >
            <Icon type="ArrowRight" />
            <span>Valider</span>
          </button>
          <hr className="w-full mt-5" />
        </div>
      ) : (
        <p className="font-light whitespace-pre-wrap">{content}</p>
      )}
    </div>
  );
};