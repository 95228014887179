import { create } from 'zustand'

export interface TopbarMobileDataType {
    showTopbar: boolean,
    communityId: string;
    communityName: string;
    pageName: string;
    isAdmin: boolean;
    withLinearGradient: boolean;
    withGoBack: boolean;
    withGoHome: boolean;
    withLogo: boolean;
    setTopbarMobileData: ({ communityName, communityId, pageName, isAdmin, showTopbar, withLinearGradient, withGoBack, withGoHome, withLogo } : TopbarMobileDataPropsType) => void;
}

interface TopbarMobileDataPropsType {
    showTopbar?: boolean;
    communityId?: string;
    communityName?: string;
    pageName?: string;
    isAdmin?: boolean;
    withLinearGradient?: boolean;
    withGoBack?: boolean;
    withGoHome?: boolean;
    withLogo?: boolean;
}

export const TopbarMobileCommunityId = (state: TopbarMobileDataType) => state.communityId;
export const TopbarMobileCommunityName = (state: TopbarMobileDataType) => state.communityName;
export const TopbarMobilePageName = (state: TopbarMobileDataType) => state.pageName;
export const TopbarMobileIsAdmin = (state: TopbarMobileDataType) => state.isAdmin
export const TopbarMobileIsShown = (state: TopbarMobileDataType) => state.showTopbar
export const TopbarMobileWithLinear = (state: TopbarMobileDataType) => state.withLinearGradient
export const TopbarMobileWithGoBack = (state: TopbarMobileDataType) => state.withGoBack
export const TopbarMobileWithGoHome = (state: TopbarMobileDataType) => state.withGoHome
export const TopbarMobileWithLogo = (state: TopbarMobileDataType) => state.withLogo


export const useTopbarMobileData = create<TopbarMobileDataType>(set => ({
    showTopbar: true,
    communityId: '',
    communityName: '',
    pageName: '',
    isAdmin: false,
    withLinearGradient: true,
    withGoBack: false,
    withGoHome: false,
    withCommunityImage: false,
    withLogo: false,
    setTopbarMobileData: ({ communityId, communityName, pageName, isAdmin, showTopbar, withLinearGradient, withGoBack, withGoHome, withLogo } : TopbarMobileDataPropsType) => {
        set(state => ({ ...state, communityId, communityName, pageName, isAdmin, showTopbar, withLinearGradient, withGoBack, withGoHome, withLogo }));
    },
}));
