import { useFetcher, usePublicFetcher } from './api'

export type CommunitiesRequestTypes = {
  refetchOnMount?: boolean;
  enabled?: boolean;
};

export type CommunityListObj = Partial<{
  id: string;
  name: string;
  description: string;
  pricing: string;
  owner_uuid: string;
  members: number;
  created_at: string;
  type_id: number;
  owner_name: string;
  reviews_count: number;
  reviews_rate: string;
}>

export type CommunitiesResponse = {
  page: number;
  list: CommunityListObj[];
}

export interface PostFeed {
  id: number;
  owner_uuid: string;
  reactions: Record<string, string>;
  comments: number;
  is_pinned: boolean;
  created_at: string;
  channel_id: number;
  is_comment_disabled: boolean;
  is_archived: boolean;
  is_banned: boolean;
  content: {
    type: string;
    content: Array<{
      type: string;
      content: Array<{
        text?: string;
        type: string;
        attrs?: {
          id: string;
          label: null | string;
        };
      }>;
    }>;
  };
  community_id: number | string;
  name: string;
  user_name: string;
  community_name: string;
  community_type_id: number;
}

export interface paginatedFeedResponseType {
  page: number;
  list: PostFeed[];
  nextPage: number | null;
}

export interface CreatorType {
  id: number,
  name: string,
  user_name: string,
  uuid: string
}

interface CommunityFeaturedType {
  name: string;
  owner_uuid: string;
  members: number;
  id: number;
}

interface CreatorCommunityData {
  creators: CreatorType[];
  communities: CommunityFeaturedType;
}

export const useCommunities = ({ refetchOnMount = true }: CommunitiesRequestTypes) => {
  return usePublicFetcher<any, CommunitiesResponse>({ key: `communities-get`, path: `/communities`, refetchOnMount })
}

export const useFeaturedCommunitiesAndCreators = ({ refetchOnMount = true }: CommunitiesRequestTypes) => {
  return usePublicFetcher<any, CreatorCommunityData>({ key: `top-all-featured-get`, path: `/communities/featured`, refetchOnMount })
}

export const useMyCommunities = ({ refetchOnMount = true, enabled }: CommunitiesRequestTypes) => {
  return usePublicFetcher<any, CommunitiesResponse>({ key: `my-communities-get`, path: `/communities/mine`, refetchOnMount, enabled })
}

export const useMyAdminCommunities = ({ refetchOnMount = true }: CommunitiesRequestTypes) => {
  return usePublicFetcher<any, CommunitiesResponse>({ key: `my-communities-admin-get`, path: `/communities/admin/mine`, refetchOnMount })
}

export const useForYouFeed = ({ page = 1, limit = 10, refetchOnMount, keepPreviousData, enabled }: { page?: number, limit?: number, refetchOnMount?: boolean, keepPreviousData?: boolean, enabled: boolean }) => {
  return useFetcher<any, paginatedFeedResponseType>({
    key: `page-${page}-feed`,
    path: '/feed?page=' + page + '&limit=' + limit,
    refetchOnMount,
    keepPreviousData,
    enabled
  });
};