import { useNotificationStore } from "stores/notifications";
import { useFetcher } from "./api";
import { useEffect } from "react";

export type CommunityRequestTypes = {
    refetchOnMount?: boolean;
    enabled?: boolean;
};

export interface CommunityRewards {
    id: number;
    name: string;
    description: string | null;
    community_id: number;
    need_for_unlock: {
        referral: number;
    };
    is_active: boolean;
    created_at: string;
}

export const useGetUserNotifications = ({ refetchOnMount = true, enabled = true }: CommunityRequestTypes) => {

    const addNotifications = useNotificationStore((state: any) => state.addNotification);

    const { data, refetch, isError, error, isLoading } = useFetcher<any, any>({ key: `user-all-notifications`, path: `/notifications/all`, refetchOnMount, enabled });

    useEffect(() => {
        if (data) {
            addNotifications(data);
        }
    }, [data, addNotifications]);

    return { data, refetch, isError, error, isLoading };

}